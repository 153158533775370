import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Atendimento } from 'src/app/models/atendimento';
import { Liberacao } from 'src/app/models/liberacao';
import { AtendimentoService } from 'src/app/services/atendimento.service';
import { LiberacaoService } from 'src/app/services/liberacao.service';
import { MensagemService } from 'src/app/services/mensagem.service';
import { DateUtils } from 'src/app/utilities/util/date-utils';
import { DateValidationItem, FormValidation, StringValidationItem } from 'src/app/utilities/util/form-validation';
import { LiberacaoAtendimento } from '../liberacao-atendimento/liberacao-atendimento.component';

@Component({
  selector: 'habil-liberacao-enterprise',
  templateUrl: './liberacao-enterprise.component.html',
  styleUrls: ['./liberacao-enterprise.component.scss']
})
export class LiberacaoEnterpriseComponent implements OnInit {

  liberacao: Liberacao;
  codigoAcesso: string = "";
  dataI: Date = new Date();
  dataF: Date = DateUtils.incMonth( new Date(), 1 );
  codigoLiberacao: string;
  loading: boolean = false;
  cadastraAtendimento: LiberacaoAtendimento = new LiberacaoAtendimento();

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private message: MessageService,
    private service: LiberacaoService,
    private atendimentoService: AtendimentoService,
    private mensagem: MensagemService,
  ) { }

  ngOnInit(): void {
    this.liberacao = this.config.data.liberacao;
    this.atendimentoService.localizaPelaLiberacao( this.liberacao ).subscribe( 
      at => {
        if ( at ) {
          this.cadastraAtendimento.atendimento = at;
        }
      }
    )
  }

  private validaCodigoLiberacao(): boolean {
    let ret: boolean = false;
    this.dataI = DateUtils.corrigeCampoData( this.dataI );
    this.dataF = DateUtils.corrigeCampoData( this.dataF );

    let valid: FormValidation = new FormValidation()
      .add(
        new StringValidationItem( this.liberacao.dadosCliente.chaveRegistro, "Chave de registro obrigatória!" )
      )
      .add(
        new DateValidationItem( this.dataI, "Data inicial obrigatória!" )
      )
      .add(
        new DateValidationItem( this.dataF, "Data final obrigatória!" )
      );
    ret = valid.isValid();
    if ( !ret ) {
      this.mensagem.erro( valid.messageToString() );
      this.message.add({
        detail: valid.messageToString(),
        severity: "info"
      });
    }

    return ret;
  }

  private getAtendimento(): Atendimento {
    if ( this.cadastraAtendimento.liberar ) {
      return this.cadastraAtendimento.atendimento;
    }
    return null;
}

  gerarCodigoLiberacao() {
    if (this.validaCodigoLiberacao() ) {
      this.service
        .geraContraSenhaEnterprise( this.liberacao.dadosCliente.chaveRegistro, this.codigoAcesso, this.dataI, this.dataF )
        .subscribe(
          data => {
            this.codigoLiberacao = data.value;
          },
          error => {
            this.message.add({
              severity: "error",
              detail: "Ocorreu um erro ao gerar! " + error.error.value
            });
          }
        ),
        error => this.service.handleError( error )
    }
  }

  changeAtendimentoLiberacao( attLib: LiberacaoAtendimento ) {
    this.cadastraAtendimento = attLib;
  }

  permiteFinalizar(): boolean {
    return ( this.liberacao.id > 0 ) && ( this.codigoLiberacao != undefined );
  }

  fechar() {
    this.ref.close();
  }

  finalizar() {
    this.loading = true;
    let valid: FormValidation = new FormValidation() 
      .add(
        new StringValidationItem( this.codigoLiberacao, "Código de liberação obrigatório!" ) 
      );
    

    if ( !valid.isValid() ) {
      this.message.add({
        severity: "info",
        detail: valid.messageToString()
      })
    }
    else {
      this.service
        .gravaLiberacao( this.liberacao, this.codigoLiberacao, this.dataI, this.dataF, this.getAtendimento() )
        .subscribe(
          data => {
            this.ref.close( data );
            this.loading = false;
          },
          error => {
            if ( error.status == 400 ) {
              this.message.add({
                severity: "error",
                detail: error.error.value
              })
            }
            else {
              this.service.handleError( error );
            }
            this.loading = false;
          }
        );
    }
  }
}
