import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { RetornoLogin } from "../models/admin-usuario";
import { AbstractService } from "../services/abstract.service";
import { AdminUsuarioService } from "../services/admin-usuario.service";
import { LoginService } from "../services/login.service";

@Component({
    selector: "app-login",
    templateUrl: "./app.login.component.html",
    styleUrls: ["./app.login.component.scss"],
    providers: [MessageService],
})
export class AppLoginComponent implements OnInit {
    constructor(
        private loginService: LoginService,
        private router: Router,
        private messageService: MessageService,
        private service: AdminUsuarioService
    ) {}
    ngOnInit(): void {
        AbstractService.saveToLocalStorage("token", "");
    }

    login: string = "";
    password: string = "";
    _loading = false;
    _type: string = "password";
    _icon: string = "pi pi-eye";
    _pwd_visible: boolean = false;
    _showCustom: boolean = false;
    msg: Object = new Object();

    entrar() {
        this._loading = true;
        this.loginService.validateUsername(this.login, this.password).subscribe(
            (ret) => {
                console.log(ret);
                this._loading = false;
                if (ret?.body) {
                    const retornoLogin: RetornoLogin = ret.body;
                    LoginService.usuarioLogado = retornoLogin.usuario;
                    AbstractService.saveToLocalStorage(
                        "token",
                        retornoLogin.token.replaceAll('"', "")
                    );
                    AbstractService.saveToLocalStorage(
                        "usuario",
                        retornoLogin.usuario
                    );
                    localStorage.setItem(
                        "Token_Report",
                        retornoLogin.token.replaceAll('"', "")
                    );
                    if (!retornoLogin.usuario.sysAdmin) {
                        this.router.navigate(["atendimento"]);
                    } else {
                        this.router.navigate(["home"]);
                    }
                } else {
                    this.messageService.add({
                        summary: "Atenção",
                        detail: "Dados incorretos!",
                    });
                }
            },
            (e) => {
                this._loading = false;
                if (e.status == 401) {
                    this.messageService.add({
                        severity: "warn",
                        summary: "Atenção",
                        detail: "Dados incorretos!",
                    });
                } else {
                    this.service.handleError(e);
                    this.messageService.add({
                        severity: "error",
                        summary: "Atenção",
                        detail: "Ocorreu um erro!",
                    });
                }

                console.log(e);
            }
        );
    }

    get theme() {
        // não implementado
        return "deeporange";
    }

    changeVisibility() {
        this._pwd_visible = !this._pwd_visible;
        if (this._pwd_visible) {
            this._icon = "pi pi-eye-slash";
            this._type = "text";
        } else {
            this._icon = "pi pi-eye";
            this._type = "password";
        }
    }
}
