import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { AbstractService } from 'src/app/services/abstract.service';
import { PlanoSuporteService } from 'src/app/services/plano-suporte.service';

@Component({
  selector: 'habil-plano-suporte',
  templateUrl: './plano-suporte.component.html',
  styleUrls: ['./plano-suporte.component.scss']
})
export class PlanoSuporteComponent implements OnInit{

  constructor(private bcservice: BreadcrumbService, public service: PlanoSuporteService, titleService: Title ) { 
    bcservice.setItems( [ { label: "Plano de Suporte" } ] );
    titleService.setTitle( "Planos de Suporte - " + AbstractService.app )
  }
  ngOnInit(): void {
    
  }

}
