import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, of } from 'rxjs';
import { Atendimento } from 'src/app/models/atendimento';
import { Liberacao } from 'src/app/models/liberacao';
import { Licenca, Registro, RegistroLicenca, RegistroLicencaAdicional, RegistroLicencaEmissao } from 'src/app/models/registro';
import { Classificacao, RegistroProduto } from 'src/app/models/registro-produto';
import { AbstractService } from 'src/app/services/abstract.service';
import { AtendimentoService } from 'src/app/services/atendimento.service';
import { LiberacaoService } from 'src/app/services/liberacao.service';
import { ProdutoService } from 'src/app/services/produto.service';
import { RegistroService } from 'src/app/services/registro.service';
import { clone } from 'src/app/utilities/util/clone-utils';
import { DateUtils } from 'src/app/utilities/util/date-utils';
import { DateValidationItem, FormValidation, NumberValidationItem, StringValidationItem } from 'src/app/utilities/util/form-validation';
import { LiberacaoAtendimento } from '../liberacao-atendimento/liberacao-atendimento.component';

@Component({
  selector: 'habil-liberacao-h10',
  templateUrl: './liberacao-h10.component.html',
  styleUrls: ['./liberacao-h10.component.scss']
})
export class LiberacaoH10Component implements OnInit {

  loading: boolean = false;
  liberacao: Liberacao;
  cliente: Registro = {
    nome: '',
    cancelamento: null,
    cnpj: '',
    email: '',
    endereco: undefined,
    responsavel: '',
    telefones: [],
    licencas: [],
    id: 0,
    idInterno: 0,
    creationDate: undefined,
    idHabil: '',
    lastModifiedDate: undefined,
    createdBy: 0,
    lastModifiedBy: 0,
    deleted: false
  };
  licenca: RegistroLicenca = {
    expDate: undefined,
    empresas: 0,
    boletos: 0,
    nroLicencas: 0,
    acessos: [],
    emissoes: [],
    adicionais: [],
    categoriasRelatorio: ''
  };
  licencaEmissao: RegistroLicencaEmissao[] = [];
  licencaAdicionais: RegistroLicencaAdicional[] = [];
  vcto: Date;
  produtosDisponiveis: RegistroProduto[] = [];
  produtosSelecionados: RegistroProduto[] = [];
  modulos: any[] = [];
  emissoes: any[] = [];
  cadastraAtendimento: LiberacaoAtendimento = new LiberacaoAtendimento();


  modulosAdicionais: any[] = [
    { value: "APP_GRAFICO_GRATUITO", label: "APP Gráfico Gratuito" },
    { value: "APP_GRAFICO_FINANCEIRO", label: "APP Gráfico Financeiro" },
    { value: "APP_GRAFICO_COMPRAEVENDA", label: "APP Gráfico Compras e Vendas" },
    { value: "APP_FORCA_VENDA", label: "APP Gráfico Força de Venda" },
    { value: "NUVEM_SHOP", label: "Nuvem Shop" },
    { value: "MAGENTO", label: "Magento" },
    { value: "IDERIS", label: "Ideris" },
  ]

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private liberacaoService: LiberacaoService,
    private message: MessageService,
    private registroService: RegistroService,
    private atendimentoService: AtendimentoService,
    private produtoService: ProdutoService) {
  }

  ngOnInit(): void {
    this.liberacao = this.config.data.liberacao;
    this.produtoService.findAll(0, 0, "").subscribe(
      list => {
        this.produtosDisponiveis = [...list];
        this.liberacao.produtos.forEach(
          p => {
            this.moveProdutos( p.idItem );
          }
        )
      }
    );
    this.atendimentoService.localizaPelaLiberacao( this.liberacao ).subscribe( 
      at => {
        if ( at ) {
          this.cadastraAtendimento.atendimento = at;
        }
      }
    )
    this.procuraCliente();
  }

  private getModuloLabel( value: string ): string {
    this.modulosAdicionais.forEach( 
      m => {
        if (m.value = value ) {
          return m.label;
        }
      }
    );
    return "";
  }

  private moveProdutos( idproduto?: number, prod?: RegistroProduto ) {
    let index: number = -1;
    let produto: RegistroProduto;
    for ( let i = 0; i < this.produtosDisponiveis.length; i++ ) {
      let achou: boolean;
      if (prod) {
        achou = prod.id == this.produtosDisponiveis[ i ].id;
      }
      else {
        achou = idproduto == this.produtosDisponiveis[ i ].idInterno;
      }

      if ( achou ) {
        index = i;
        produto = this.produtosDisponiveis[ i ];
        break;
      }
    }
    if ( index > -1 ) {
      this.produtosDisponiveis.splice( index, 1 );
      this.produtosSelecionados.push( produto );
    }
    this.produtosDisponiveis = [...this.produtosDisponiveis];
    this.produtosSelecionados = [...this.produtosSelecionados ];
  }

  private preencheModulos() {
    this.cliente.licencas.forEach( lic => { 
      this.modulos = [];
      if ( lic.software = 'HABIL_10' ) {
        this.produtoService.findAll( 0, 0, "" ).subscribe( list => {
          lic.modulosLicenciados.forEach( m => {
            for ( let p of list ) {
              if ( m.idModulo == p.id ) {
                this.modulos.push( { label: p.nomeProduto } );
                this.moveProdutos( undefined, p );
                break;
              }
            }
          });
        })
        return;
      }
    });
  }

  private preencheEmissoes( contem: boolean, cnpj: string, tipo: string, vcto: Date, qtde: number ) {
    if ( contem ) {
      this.emissoes.push( {
        cnpj: cnpj,
        tipo: tipo,
        vcto: vcto,
        qtde: qtde
      } );
    }
  }

  private procuraCliente() {
    if (this.liberacao.dadosCliente.cpfCnpj) {      
      this.registroService.findByCnpj( this.liberacao.dadosCliente.cpfCnpj ).subscribe(
        list => {
          if ( list ) {
            this.cliente = list[ 0 ];  
            if ( this.cliente.licencas ) {
              let lic: Licenca[] = [];
              let ids: number[] = [];
              for ( let l of this.cliente.licencas ) {
                const index = ids.indexOf( l.id );
                if ( index == -1 ) {
                  lic.push( l );
                  ids.push( l.id );
                }
              }
              this.cliente.licencas = [ ...lic ];
            }

            this.preencheModulos();
            this.registroService.getRegistroLicenca( this.cliente.id, "HABIL_10" ).subscribe(
              data => {
                this.licenca = data.body;
                if (this.licenca.emissoes ) {                  
                  this.licenca.emissoes.forEach( 
                    e => {
                      this.licencaEmissao.push( clone( e ) );
                      this.preencheEmissoes(
                        e.nfe, e.documento, "NF-e", e.vctoNfe, e.qtdeDocsNfe
                      );
                      this.preencheEmissoes(
                        e.nfce, e.documento, "NFC-e", e.vctoNfce, e.qtdeDocsNfce
                      );
                      this.preencheEmissoes(
                        e.nfs, e.documento, "NFS", e.vctoNfs, e.qtdeDocsNfs
                      );
                      this.preencheEmissoes(
                        e.sat, e.documento, "SAT", e.vctoSat, e.qtdeDocsSat
                      );
                    }
                  )
                }                
                if ( this.licenca.adicionais ) {
                  this.licenca.adicionais.forEach(
                    a => {
                      this.licencaAdicionais.push(
                        {
                          documento: a.documento,
                          vcto: a.vcto,
                          modulo: a.modulo,
                          licencas: a.licencas
                        }
                      );
                    }
                  )
                }
              }
            );            
          }
          else {            
            this.cliente.nome = this.liberacao.dadosCliente.nome;
            this.cliente.cnpj = this.liberacao.dadosCliente.cpfCnpj;
            this.cliente.email = this.liberacao.dadosCliente.email;         
          }
        },
        error => {
          console.log( "ERRO:");
          console.log( error );
          this.registroService.handleError( error );
        }
    )
    }
  }

  permiteFinalizar(): boolean {
    return this.liberacao.id > 0;
  }

  fechar() {
    this.ref.close();
  }

  private pegaLicenca( cliente: Registro ): Licenca {
    let licencas: Licenca[] = cliente.licencas.filter( l => l.software == "HABIL_10" );
    if ( !licencas || licencas.length == 0 ) {
      licencas.push( {
        software: "HABIL_10",
        dbToken: "",
        modulosLicenciados: [],
        licenceKey: "",
        timestamp: "",
        personalizada: false,
        usouDemo: false,
        tipo: "PAGO",
        id: 0,
        idInterno: 0,
        creationDate: undefined,
        idHabil: '',
        lastModifiedDate: undefined,
        createdBy: 0,
        lastModifiedBy: 0,
        deleted: false
      } );
    }
    let licenca = licencas[ 0 ];
    licenca.modulosLicenciados = [];
    this.produtosSelecionados.forEach( 
      p => {
        licenca.modulosLicenciados.push(
          {
            idModulo: p.id,
            id: 0,
            idInterno: 0,
            creationDate: undefined,
            idHabil: "",
            lastModifiedDate: undefined,
            createdBy: 0,
            lastModifiedBy: 0,
            deleted: false              
          }
        )
      }
    );
    return licenca;
  }

  private pegaRegistroLicenca(): RegistroLicenca {
    let ret: RegistroLicenca = this.licenca;
    ret.expDate = this.vcto;
    ret.nroLicencas = this.liberacao.qtdeUsuarios;
    ret.empresas = this.liberacao?.qtdeEmpresas || 0;
    ret.acessos = [];
    this.produtosSelecionados.forEach( 
      p => {
        for( const acesso of p.acessos ) {
          if ( ret.acessos.filter( a => a == acesso ).length == 0 ) {
            ret.acessos.push( acesso );
          }
        }
      }
    );
    ret.emissoes = [ ... this.licencaEmissao ];
    ret.adicionais = [ ... this.licencaAdicionais ];
    return ret;
  }

  private getAtendimento(): Atendimento {
    if ( this.cadastraAtendimento.liberar ) {
      return this.cadastraAtendimento.atendimento;
    }
    return null;
  }

  finalizar() {
    if ( this.fazValidacao() ) {
      this.loading = true;
      this.salvaClassificacaoNoCliente().subscribe( 
        c => {
          this.registroService
            .saveRegistroLicenca(
              c.id,
              this.pegaLicenca( c ),
              this.pegaRegistroLicenca()
            )
            .subscribe(
              () => {
                this.liberacao.idUsuario = AbstractService.getLocalStorage( "usuario" ).id;
                this.liberacaoService.finaliza( this.liberacao, "", this.getAtendimento() ).subscribe(
                  data => {
                    this.ref.close( data )
                    this.loading = false;
                  },
                  error => {
                    if ( error.status == 400 ) {
                      this.message.add({
                        severity: "error",
                        detail: error.error.value
                      })
                    }
                    else {
                      this.liberacaoService.handleError( error );
                    }
                    this.loading = false;
                  }
                );
              }
            )
        }
      );
    }
  }

  private procuraClassificaoEntreOsProdutos(): Classificacao {
    for ( const p of this.produtosSelecionados ) {
      if ( p.classificacao ) {
        return p.classificacao;
      }
    }
    return null;
  }

  private salvaClassificacaoNoCliente(): Observable<Registro> {
    const classificacao = this.procuraClassificaoEntreOsProdutos();
    if ( classificacao ) {
      this.cliente.classificacao = classificacao;
    }
    return this.registroService.save( this.cliente );
    // if ( !this.cliente.id ) {
    //   return this.registroService.save( this.cliente );
    // }  
    // return of( this.cliente );    
  }

  private fazValidacao(): boolean {
    this.vcto = DateUtils.corrigeCampoData( this.vcto );

    let cons: FormValidation = new FormValidation()
      .add( new NumberValidationItem( this.liberacao.qtdeUsuarios, "Número de licenças é obrigatório!" ) )
      .add( new DateValidationItem( this.vcto, "Novo vencimento é obrigatório!" ) )
      .add( {
        condition: this.produtosSelecionados.length > 0,
        message: "Nenhum produto selecionado!"
      } );
    if ( !this.cliente.id ) {
      cons 
        .add( new StringValidationItem( this.liberacao.dadosCliente.nome, "Nome do Cliente é obrigatório" ) )
        .add( new StringValidationItem( this.liberacao.dadosCliente.email, "E-mail é obrigatório" ) )
        .add( new StringValidationItem( this.liberacao.dadosCliente.cpfCnpj, "CPF/CNPJ é obrigatório" ) )
    }

    this.licencaEmissao.forEach(
      e => {
        cons.add( new StringValidationItem( e.documento, "CNPJ para emissões é obrigatório!" ) );
        if (e.nfe) {
          cons
            .add( new DateValidationItem( e.vctoNfe, "Vencimento da NF-e obrigatório" ) )
            .add( new NumberValidationItem( e.qtdeDocsNfe, "Qtde de documentos da NF-e obrigatório" ) )
        }
        if (e.nfce) {
          cons
            .add( new DateValidationItem( e.vctoNfce, "Vencimento da NFC-e obrigatório" ) )
            .add( new NumberValidationItem( e.qtdeDocsNfce, "Qtde de documentos da NFC-e obrigatório" ) )
        }
        if (e.nfs) {
          cons
            .add( new DateValidationItem( e.vctoNfs, "Vencimento da NFS obrigatório" ) )
            .add( new NumberValidationItem( e.qtdeDocsNfs, "Qtde de documentos da NFS obrigatório" ) )
        }
        if (e.sat) {
          cons
            .add( new DateValidationItem( e.vctoSat, "Vencimento do SAT obrigatório" ) )
            .add( new NumberValidationItem( e.qtdeDocsSat, "Qtde de documentos do SAT obrigatório" ) )
        }
      }
    );
    this.licencaAdicionais.forEach(
      a => {
        cons
          .add( new StringValidationItem( a.documento, "CNPJ para módulos adicionais é obrigatório" ) )
          .add( new DateValidationItem( a.vcto, "Vencimento para módulos é obrigatório!" ) )
          .add( new NumberValidationItem( a.licencas, "Qtde de licenças é obrigatório!" ) );
      }
    )

    if (!cons.isValid() ) {      
      cons.message().forEach(s => {
        this.message.add(
          {
            severity: "info",
            detail: s
          }
        )
      });
    }
    return cons.isValid();
  }

  changeAtendimentoLiberacao( cadastraAtendimento: LiberacaoAtendimento ) {
    this.cadastraAtendimento = cadastraAtendimento;
  }

  addEmissao() {
    let r: RegistroLicencaEmissao = {
      documento: this.liberacao.dadosCliente.cpfCnpj,
      nfe: false,
      vctoNfe: undefined,
      qtdeDocsNfe: 0,
      nfce: false,
      vctoNfce: undefined,
      qtdeDocsNfce: 0,
      nfs: false,
      vctoNfs: undefined,
      qtdeDocsNfs: 0,
      sat: false,
      vctoSat: undefined,
      qtdeDocsSat: 0
    }

    this.licencaEmissao.push(r);
  }

  delEmissao(e: RegistroLicencaEmissao) {
    const index = this.licencaEmissao.indexOf(e, 0);
    if (index > -1) {
      this.licencaEmissao.splice(index, 1);
    }
  }

  addAdicional() {
    this.licencaAdicionais.push(
      {
        documento: this.liberacao.dadosCliente.cpfCnpj,
        vcto: new Date(),
        modulo: 'MAGENTO',
        licencas: 1
      }
    );
  }

  delAdicional(a: RegistroLicencaAdicional) {
    const index = this.licencaAdicionais.indexOf(a, 0);
    if (index > -1) {
      this.licencaAdicionais.splice(index, 1);
    }
  }
}
