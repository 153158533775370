<p-tabView>
    <p-tabPanel header="Hábil 10">
        <div class="p-fluid p-grid">
            <div class="p-col-2">
                <habil-label name="pCnpj"
                    label="CNPJ">
                    <input type="text"
                        pInputText
                        [(ngModel)]="param.cnpj">
                </habil-label>
            </div>
            <div class="p-col-2">
                <habil-label name="pIp"
                    label="IP">
                    <input type="text"
                        pInputText
                        [(ngModel)]="param.ip">
                </habil-label>
            </div>
            <div class="p-col-2">
                <habil-label name="pController"
                    label="Controller">
                    <input type="text"
                        pInputText
                        [(ngModel)]="param.controller">
                </habil-label>
            </div>
            <div class="p-col-2 p-col-align-end"><button pButton
                    label="Buscar"
                    [disabled]="loading"
                    (click)="buscarH10()"></button></div>
            <div class="p-col-12"
                style="height: calc(50svh - 100px); overflow: scroll;">
                <p-table [value]="logsH10.result"
                    [(selection)]="logsH10.selection"
                    selectionMode="single"
                    [loading]="loading"
                    [tableStyle]="{width: '100%'}"
                    styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines ">
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="id"
                                style="width: 60px;">ID</th>
                            <th pSortableColumn="createdAt">Data</th>
                            <th pSortableColumn="cnpj">CNPJ</th>
                            <th pSortableColumn="usuario"
                                style="width: 60px;">Cód Usuário</th>
                            <th pSortableColumn="ip">IP</th>
                            <th pSortableColumn="versao">Versão</th>
                            <th pSortableColumn="controller">Controller</th>
                            <th pSortableColumn="origem">Origem</th>
                            <!-- <th>Ticket</th> -->
                            <!-- <th>Rota</th> -->
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body"
                        let-error>
                        <tr [pSelectableRow]="error">
                            <td>{{ error.id }}</td>
                            <td>{{ error.createdAt | date: 'dd/MM/yyy HH:mm:ss' }}</td>
                            <td>{{ error.cnpj }}</td>
                            <td>{{ error.usuario }}</td>
                            <td>{{ error.ip }}</td>
                            <td>{{ error.versao }}</td>
                            <td>{{ error.controller }}</td>
                            <td>{{ error.origem }}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            <div class="p-col-12"
                style="height: calc(50svh - 100px); overflow: scroll;">
                <div *ngIf="logsH10?.selection?.corpo">{{logsH10.selection.corpo}}</div>
            </div>
        </div>

    </p-tabPanel>
    <p-tabPanel header="Importações">
        <div class="p-fluid p-grid">
            <!-- <div class="p-col-2">
                <habil-label name="pCnpj"
                    label="CNPJ">
                    <input type="text"
                        pInputText
                        [(ngModel)]="param.cnpj">
                </habil-label>
            </div>
            <div class="p-col-2">
                <habil-label name="pIp"
                    label="IP">
                    <input type="text"
                        pInputText
                        [(ngModel)]="param.ip">
                </habil-label>
            </div> -->
            <div class="p-col-2 p-col-align-end"><button pButton
                    label="Buscar"
                    [disabled]="loading"
                    (click)="buscarImportacao()"></button></div>
            <div class="p-col-12"
                style="height: calc(100svh - 200px); overflow: scroll;">
                <p-table [value]="logsImport.result"
                    [(selection)]="logsImport.selection"
                    selectionMode="single"
                    [loading]="loading"
                    [tableStyle]="{width: '100%'}"
                    styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines ">
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="id"
                                style="width: 60px;">ID</th>
                            <th pSortableColumn="createdAt">Data</th>
                            <th pSortableColumn="cnpj">CNPJ</th>
                            <th pSortableColumn="usuario"
                                style="width: 60px;">Cód Usuário</th>
                            <th style="width: 40%;" pSortableColumn="dados">Informação</th>
                            <!-- <th>Ticket</th> -->
                            <!-- <th>Rota</th> -->
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body"
                        let-error>
                        <tr [pSelectableRow]="error">
                            <td>{{ error.id }}</td>
                            <td>{{ error.createdAt | date: 'dd/MM/yyy HH:mm:ss' }}</td>
                            <td>{{ error.cnpj }}</td>
                            <td>{{ error.usuario }}</td>
                            <td>{{ error.dados }}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </p-tabPanel>
</p-tabView>