<p-confirmDialog acceptLabel="Confirme" rejectLabel="Não" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text"></p-confirmDialog>
<p-toast></p-toast>
<habil-grupo>
  <p-panel header="Filtro">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-1">
        <habil-label name="datainicial" label="Data Inicial">
          <habil-date-edit [(ngModel)]="filtro.dataInicial"> </habil-date-edit>
        </habil-label>
      </div>
      <div class="p-field p-col-1">
        <habil-label name="datafinal" label="Data Final">
          <habil-date-edit [(ngModel)]="filtro.dataFinal"> </habil-date-edit>
        </habil-label>
      </div>
      <div class="p-field p-col-1">
        <habil-label name="lbstatus" label="Status">
          <p-dropdown
            name="cbbstatus"
            [options]="listastatus"
            optionLabel="label"
            optionValue="value"
            [(ngModel)]="filtro.status"
          ></p-dropdown>
        </habil-label>
      </div>
      <div class="p-field p-col-2">
        <habil-label name="lbtipo" label="Tipo">
          <p-dropdown
            name="cbbtipo"
            [options]="listatipos"
            optionLabel="label"
            optionValue="value"
            [(ngModel)]="filtro.tipo"
          ></p-dropdown>
        </habil-label>
      </div>
      <div class="p-field p-col-3">
        <habil-label name="nome" label="Nome do Cliente">
          <input type="text" [(ngModel)]="filtro.nome" pInputText />
        </habil-label>
      </div>
      <div class="p-field p-col-2">
        <habil-label name="doc" label="CPF/CNPJ">
          <input type="text" [(ngModel)]="filtro.cpfCnpj" pInputText />
        </habil-label>
      </div>
      <div class="p-col-2 p-field p-col-align-end">
        <p-button label="Visualizar" (onClick)="filtrar()"></p-button>
      </div>
    </div>
  </p-panel>
  <br />
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12">
      <p-table
        styleClass="p-datatable-sm"
        [value]="list"
        [lazy]="true"        
        (onLazyLoad)="loadRegs($event)"
        responsiveLayout="scroll"
        dataKey="id"
        [loading]="loading"
        [paginator]="true"        
        [rows]="10"
        [totalRecords]="count"
        [scrollHeight]="getScrollHeight()">
        <ng-template pTemplate="header">
          <tr>
            <!-- <th style="width: 10%;">ID</th> -->
            <th style="width: 10%;">Data</th>
            <th style="width: 15%;">Tipo</th>
            <th style="width: 25%;">Nome do Cliente</th>
            <th style="width: 10%;">CPF/CNPJ</th>
            <th style="width: 25%;">Descrição</th>
            <th style="width: 10%;">Status</th>
            <th style="width: 5%;">Ações</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-reg>
          <tr>
            <!-- <td>{{ reg.id }}</td> -->
            <td>{{ reg.data | date: "dd/MM/yyyy" }}</td>
            <td>
              <p-badge 
                [value]="getTipoLabel(reg.tipo)"
                [styleClass]="reg.tipo"
                size="large"
              ></p-badge>
            </td>
            <td>{{ reg.dadosCliente.nome }}</td> 
            <td>{{ reg.dadosCliente.cpfCnpj }}</td> 
            <td>{{ reg.descricao }}</td> 
            <td>{{ getStatusLabel( reg.status ) }}</td>
            <td>
              <button
                pButton
                title="Liberar"
                pRipple
                icon="pi pi-reply"
                style="text-align-last: center"
                class="p-button-rounded p-button-text"
                (click)="liberar(reg)">
              </button>
              <button
                pButton
                pRipple
                title="Finalizar"
                icon="pi pi-check"
                style="text-align-last: center"
                class="p-button-rounded p-button-text"
                (click)="finalizar(reg)">
              </button>
            </td>
          </tr>
        </ng-template> 
      </p-table>
    </div>
  </div>
  Total: {{count}}
  <p-panel header="Ações">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-3">
        <p-button label="Suporte Clube" (onClick)="geraSuporte()"></p-button>
      </div>
      <div class="p-field p-col-3">
        <p-button label="Suporte Premium" (onClick)="geraSuporte()"></p-button>
      </div>
      <div class="p-field p-col-3">
        <p-button label="Hábil Empresarial" (onClick)="geraChaveEmpresarial()"></p-button>
      </div>
      <div class="p-field p-col-3">
        <p-button label="Hábil Enteprise" (onClick)="geraLiberacaoEnterprise()"></p-button>
      </div>
      <!-- <div class="p-field p-col-2">
        <p-button label="Hábil 10" (onClick)="geraChaveH10()"></p-button>
      </div> -->
    </div>
  </p-panel>
</habil-grupo>
