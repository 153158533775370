<div class="p-grid button-demo">
	<div class="p-col-12 p-md-6">
		<div class="card">
			<h5>Default</h5>
			<p-button label="Submit" class="p-mr-2 p-mb-2"></p-button>
			<p-button label="Disabled" disabled="true" class="p-mr-2 p-mb-2"></p-button>
			<p-button label="Link" styleClass="p-button-link p-mr-2"></p-button>
		</div>
		<div class="card">
			<h5>Severities</h5>
			<button pButton pRipple type="button" label="Primary" class="p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" label="Secondary" class="p-button-secondary p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" label="Success" class="p-button-success p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" label="Info" class="p-button-info p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" label="Warning" class="p-button-warning p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" label="Help" class="p-button-help p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" label="Danger" class="p-button-danger p-mr-2 p-mb-2"></button>
		</div>
		<div class="card">
			<h5>Text</h5>
			<button pButton pRipple type="button" label="Primary" class="p-button-text p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" label="Secondary" class="p-button-secondary p-button-text p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" label="Success" class="p-button-success p-button-text p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" label="Info" class="p-button-info p-button-text p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" label="Warning" class="p-button-warning p-button-text p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" label="Help" class="p-button-help p-button-text p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" label="Danger" class="p-button-danger p-button-text p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" label="Plain" class="p-button-text p-button-plain p-mr-2 p-mb-2"></button>
		</div>
		<div class="card">
			<h5>Outlined</h5>
			<button pButton pRipple type="button" label="Primary" class="p-button-outlined p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" label="Secondary" class="p-button-outlined p-button-secondary p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" label="Success" class="p-button-outlined p-button-success p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" label="Info" class="p-button-outlined p-button-info p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" label="Warning" class="p-button-outlined p-button-warning p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" label="Help" class="p-button-outlined p-button-help p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" label="Danger" class="p-button-outlined p-button-danger p-mr-2 p-mb-2"></button>
		</div>
		<div class="card">
			<h5>Button Set</h5>
			<span class="p-buttonset">
				<button pButton pRipple label="Save" icon="pi pi-check"></button>
				<button pButton pRipple label="Delete" icon="pi pi-trash"></button>
				<button pButton pRipple label="Cancel" icon="pi pi-times"></button>
			</span>
		</div>
		<div class="card">
			<h5>SplitButton</h5>
			<p-splitButton label="Save" icon="pi pi-plus" [model]="items" styleClass="p-button-info p-mr-2 p-mb-2"></p-splitButton>
			<p-splitButton label="Save" icon="pi pi-plus" [model]="items" styleClass="p-button-success p-mr-2 p-mb-2"></p-splitButton>
			<p-splitButton label="Save" icon="pi pi-plus" [model]="items" styleClass="p-button-warning p-mr-2 p-mb-2"></p-splitButton>
			<p-splitButton label="Save" icon="pi pi-plus" [model]="items" styleClass="p-button-help p-mr-2 p-mb-2"></p-splitButton>
			<p-splitButton label="Save" icon="pi pi-plus" [model]="items" styleClass="p-button-danger p-mr-2 p-mb-2"></p-splitButton>
		</div>
	</div>
	<div class="p-col-12 p-md-6">
		<div class="card">
			<h5>Icons</h5>
			<p-button icon="pi pi-star" class="p-mr-2 p-mb-2"></p-button>
			<p-button label="Submit" icon="pi pi-bookmark" class="p-mr-2 p-mb-2"></p-button>
			<p-button label="Submit" icon="pi pi-bookmark" iconPos="right" class="p-mr-2 p-mb-2"></p-button>
		</div>
		<div class="card">
			<h5>Raised</h5>
			<button pButton pRipple type="button" label="Primary" class="p-button-raised p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" label="Secondary" class="p-button-raised p-button-secondary p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" label="Success" class="p-button-raised p-button-success p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" label="Info" class="p-button-raised p-button-info p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" label="Warning" class="p-button-raised p-button-warning p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" label="Help" class="p-button-raised p-button-help p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" label="Danger" class="p-button-raised p-button-danger p-mr-2 p-mb-2"></button>
		</div>
		<div class="card">
			<h5>Rounded</h5>
			<button pButton pRipple type="button" label="Primary" class="p-button-rounded p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" label="Secondary" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" label="Success" class="p-button-rounded p-button-success p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" label="Info" class="p-button-rounded p-button-info p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" label="Warning" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" label="Help" class="p-button-rounded p-button-help p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" label="Danger" class="p-button-rounded p-button-danger p-mr-2 p-mb-2"></button>
		</div>
		<div class="card">
			<h5>Rounded Icons</h5>
			<button pButton pRipple type="button" icon="pi pi-check" class="p-button-rounded p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" icon="pi pi-bookmark" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" icon="pi pi-search" class="p-button-rounded p-button-success p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" icon="pi pi-user" class="p-button-rounded p-button-info p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" icon="pi pi-bell" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" icon="pi pi-heart" class="p-button-rounded p-button-help p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-danger p-mr-2 p-mb-2"></button>
		</div>
		<div class="card">
			<h5>Rounded Text</h5>
			<button pButton pRipple type="button" icon="pi pi-check" class="p-button-rounded p-button-text p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" icon="pi pi-bookmark" class="p-button-rounded p-button-secondary p-button-text p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" icon="pi pi-search" class="p-button-rounded p-button-success p-button-text p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" icon="pi pi-user" class="p-button-rounded p-button-info p-button-text p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" icon="pi pi-bell" class="p-button-rounded p-button-warning p-button-text p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" icon="pi pi-heart" class="p-button-rounded p-button-help p-button-text p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" icon="pi pi-filter" class="p-button-rounded p-button-text p-button-plain p-mr-2 p-mb-2"></button>
		</div>
		<div class="card">
			<h5>Rounded Outlined</h5>
			<button pButton pRipple type="button" icon="pi pi-check" class="p-button-rounded p-button-outlined p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" icon="pi pi-bookmark" class="p-button-rounded p-button-secondary p-button-outlined p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" icon="pi pi-search" class="p-button-rounded p-button-success p-button-outlined p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" icon="pi pi-user" class="p-button-rounded p-button-info p-button-outlined p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" icon="pi pi-bell" class="p-button-rounded p-button-warning p-button-outlined p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" icon="pi pi-heart" class="p-button-rounded p-button-help p-button-outlined p-mr-2 p-mb-2"></button>
			<button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined p-mr-2 p-mb-2"></button>
		</div>
	</div>
</div>
