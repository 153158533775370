import { Component, OnInit } from "@angular/core";
import { ConfirmationService, MessageService } from "primeng/api";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import {
  ELicencaTipo,
  Licenca,
  Registro,
  RegistroDadosAlteracoes,
  RegistroDadosPrimeiroAcesso,
} from "src/app/models/registro";
import { RegistroService } from "src/app/services/registro.service";
import { GenericCadastro } from "src/app/utilities/generic-cadastro";
import { Endereco } from "src/app/models/abstract-model";
import { FormValidation } from "src/app/utilities/util/form-validation";
import { ActivatedRoute, Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { BreadcrumbService } from "src/app/app.breadcrumb.service";
import { clone } from "src/app/utilities/util/clone-utils";
import { AbstractService } from "src/app/services/abstract.service";
import { ParceiroService } from "src/app/services/parceiro.service";
import { ClassificacaoService } from "src/app/services/classificacao.service";
import { CadastroLicencaComponent } from "../cadastro-licenca/cadastro-licenca.component";
import { softwares } from "src/app/models/registro-produto";
import { CadastroLicencaHabilCobrancasComponent } from "../cadastro-licenca-habil-cobrancas/cadastro-licenca-habil-cobrancas.component";
import { CadastroLicencaLimberChefComponent } from "../cadastro-licenca-limber-chef/cadastro-licenca-limber-chef.component";
import { CadastroLicencaHabilFranqueadoraComponent } from "../cadastro-licenca-habil-franqueadora/cadastro-licenca-habil-franqueadora.component";
import { HabilFranqueadoraUsuarioService } from "src/app/services/habil-franqueadora-usuario.service";
import { HabilFranqueadoraUsuario } from "src/app/models/habil-franqueadora-usuario";
import { SearchParams } from "src/app/utilities/util/search/search-params";
import { ClienteHabilFranqueadoraUsuarioComponent } from "../cliente-habil-franqueadora-usuario/cliente-habil-franqueadora-usuario.component";
import { FormatUtils } from "src/app/utilities/util/format-utils";
import { DatabaseLocation } from "src/app/models/database-location";
import { DatabaseLocationService } from "src/app/services/database-location.service";
import { CadastroLicencaHabilMobilePdvComponent } from "../cadastro-licenca-habil-mobile-pdv/cadastro-licenca-habil-mobile-pdv.component";
import { CadastroLicencaHabilPlayComponent } from "../cadastro-licenca-habil-play/cadastro-licenca-habil-play.component";

@Component({
  selector: "habil-cadastro-cliente",
  templateUrl: "./cadastro-cliente.component.html",
  styleUrls: ["./cadastro-cliente.component.scss"],
  providers: [MessageService, DialogService],
})
export class CadastroClienteComponent extends GenericCadastro<Registro, RegistroService> implements OnInit {
  dadosPrimeiroAcesso: RegistroDadosPrimeiroAcesso[] = [];
  dadosAlteracoes: RegistroDadosAlteracoes[] = [];
  fonesAlteracoes: any[] = [];

  loadingDadosPrimeiroAcesso = false;

  usuariosFranqueadora: HabilFranqueadoraUsuario[] = [];

  dbLocation: DatabaseLocation = new DatabaseLocation();

  novaLicenca(): Licenca {
    return {
      software: "HABIL_10",
      dbToken: "",
      modulosLicenciados: [],
      licenceKey: "",
      timestamp: "",
      personalizada: false,
      usouDemo: false,
      tipo: "PAGO",
      id: 0,
      idInterno: 0,
      creationDate: undefined,
      idHabil: "",
      lastModifiedDate: undefined,
      createdBy: 0,
      lastModifiedBy: 0,
      deleted: false,
    };
  }

  tipoIndicacoes: any[] = [
    { value: "INDICACAO", label: "Indicação" },
    { value: "GOOGLE", label: "Google" },
    { value: "REDES_SOCIAIS", label: "Redes Sociais" },
    { value: "BLOG", label: "Blog" },
    { value: "EMAIL", label: "E-mail" },
    { value: "OUTRO", label: "Outros" }
  ];

  servidoresNuvem = [
    { label: "Nenhum", value: "" },
    { label: "Servidor 1", value: "https://app.habil.net.br" },
    { label: "Servidor 2", value: "https://srv02.habil.net.br" },
    { label: "Servidor 0 (especial)", value: "https://srv00.habil.net.br" }
  ];
  
  servidoresBd =  [
    { label: "Nenhum", value: "" },
    { label: "Servidor 1", value: "54.235.214.105:5432" },
    { label: "Servidor 2", value: "44.202.75.71:5432" },
    { label: "RDS 1", value: "database-1-instance-1.c3kzxyl2psqd.us-east-1.rds.amazonaws.com:5432" }
  ];

  newDate(d) {
    return new Date(d);
  }

  changeEndereco(endereco) {
    this.obj.endereco = { ...endereco };
  }

  getTipo(value: string) {
    if (value) {
      return ELicencaTipo.find((t) => t.value == value)?.label || "(não encontrado)";
    }
    return "(nenhum)"
  }

  getSoftware(value: string) {
    if (value) {
      return softwares.find((s) => s.value == value)?.label || "(não encontrado)";
    }
    return "(nenhum)"
  }

  newObject(): Registro {
    let endereco: Endereco = {
      logradouro: "",
      numero: "",
      complemento: "",
      bairro: "",
      cep: "",
      pais: "BRASIL",
      uf: "NENHUM",
      municipio: "",
    };
    let obj: Registro = {
      id: 0,
      nome: "",
      cnpj: "",
      email: "",
      cancelamento: null,
      endereco: { ...endereco },
      responsavel: "",
      telefones: [],
      licencas: [this.novaLicenca()],
      idInterno: 0,
      creationDate: new Date(),
      idHabil: "",
      lastModifiedDate: undefined,
      createdBy: 0,
      lastModifiedBy: 0,
      deleted: false,
    };
    return obj;
  }

  doValidate(): boolean {
    this.corrigeAtributos();
    if (this.dbLocation?.databaseIp) {
      this.databaseLocationService.save(this.dbLocation).subscribe((val) => {
        console.log(val);
      });
    }

    let validation: FormValidation = new FormValidation();

    validation.toValidate = [
      {
        condition: this.obj.nome != undefined && this.obj.nome.length > 0,
        message: "O nome é obrigatório!",
      },
      {
        condition: this.obj.cnpj != undefined && this.obj.cnpj.length > 0,
        message: "O CNPJ é obrigatório!",
      },
    ];

    if (!validation.isValid()) {
      validation.message().forEach((m) => {
        this.messageService.add({
          severity: "warn",
          summary: "Atenção",
          detail: m,
        });
      });
    }
    this.obj.cnpj = FormatUtils.clear(this.obj.cnpj);
    return validation.isValid();
  }

  liberaNovaInstalacao(licenca) {
    this.confirmationService.confirm({
      header: "Atenção",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Sim",
      rejectLabel: "Não",
      message:
        "Confirma a liberação da licença do software para uma nova instalação?",
      accept: () => {
        this._loading = true;
        this.service.zeraToken(this.obj.cnpj, licenca.software).subscribe(
          (ret) => {
            if (ret) {
              console.log(ret);
              this._loading = false;
              this.messageService.add({
                severity: "success",
                summary: "Liberação de nova instalação",
                detail: "Liberação efetuada com sucesso!",
              });
            }
          },
          (e) => {
            this.service.handleError(e);
          }
        );
      },
      reject: () => { },
    });
  }

  constructor(
    public service: RegistroService,
    public messageService: MessageService,
    public dialogService: DialogService,
    public route: ActivatedRoute,
    public router: Router,
    public titleService: Title,
    public breadcrumbService: BreadcrumbService,
    public parceiroService: ParceiroService,
    public classificacaoService: ClassificacaoService,
    public confirmationService: ConfirmationService,
    public usuarioFranqueadoraService: HabilFranqueadoraUsuarioService,
    private databaseLocationService: DatabaseLocationService
  ) {
    super();
    this.breadcrumbService.setItems([
      { label: "Clientes", routerLink: "/clientes/registro" },
      { label: "Cadastro" },
    ]);
  }

  editar(licenca: Licenca) {
    const editar = () => {
      let ref: DynamicDialogRef = null

      if (licenca.software == "HABIL_10") {
        ref = this.dialogService.open(
          CadastroLicencaComponent, {
          width: "90%",
          header: "Licença",
          data: {
            licenca: { ...licenca },
            registro: { ...this.obj },
          },
        });
      } else if (licenca.software == "HABIL_COBRANCAS") {
        ref = this.dialogService.open(
          CadastroLicencaHabilCobrancasComponent, {
          width: "90%",
          header: "Licença do Hábil Cobranças",
          data: {
            licenca: { ...licenca },
            registro: { ...this.obj },
          },
        });
      } else if (licenca.software == "LIMBER_CHEF") {
        ref = this.dialogService.open(
          CadastroLicencaLimberChefComponent, {
          width: "90%",
          header: "Licença do Limber Chef",
          data: {
            licenca: { ...licenca },
            registro: { ...this.obj },
          },
        });
      } else if (licenca.software == "HABIL_FRANQUEADORA") {
        ref = this.dialogService.open(
          CadastroLicencaHabilFranqueadoraComponent, {
          width: "90%",
          header: "Licença do Hábil Franqueadora",
          data: {
            licenca: { ...licenca },
            registro: { ...this.obj },
          },
        });
      } else if (licenca.software == "HABIL_MOBILE_PDV") {
        ref = this.dialogService.open(
          CadastroLicencaHabilMobilePdvComponent, {
          width: "90%",
          header: "Licença do Hábil Mobile PDV",
          data: {
            licenca: { ...licenca },
            registro: { ...this.obj },
          },
        });
      } else if (licenca.software == "HABIL_PLAY") {
        ref = this.dialogService.open(
          CadastroLicencaHabilPlayComponent, {
          width: "90%",
          header: "Licença do Hábil Play",
          data: {
            licenca: { ...licenca },
            registro: { ...this.obj },
          },
        });
      }

      ref.onClose.subscribe(
        registroLicenca => {

          this.service.findByIdLazyLoaded(this.obj.id).subscribe(
            novo => {
              this.obj = clone(novo);
              if (licenca.software == "HABIL_FRANQUEADORA") {
                if (!this.usuariosFranqueadora?.length) {
                  this.novoUsuarioHabilFranqueadora();
                }
              }
            }
          )
        }
      )
    };
    if (this.obj.licencas[0].id <= 0) {
      this._readonly = true;
      this._loading = true;
      if (this.doValidate()) {
        this.service.save(this.obj).subscribe(
          (saved) => {
            this.service.findByIdLazyLoaded(saved.id).subscribe(
              (obj) => {
                this.obj = clone(obj);
                editar();

                this._readonly = false;
                this._loading = false;
              },
              (err) => {
                this.service.handleError(err);
              }
            );
          },
          (e) => {
            this.service.handleError(e);
          }
        );
      }
    }
    else {
      editar();
    }
  }

  onFoneChange(event) {
    this.obj.telefones = event;
  }

  carregaDadosPrimeiroAcesso() {
    this.loadingDadosPrimeiroAcesso = true;
    this.service.getDadosPrimeiroAcesso(this.obj.cnpj).subscribe(
      (dados) => {
        if (dados) {
          this.dadosPrimeiroAcesso = [...dados];
        }
        this.loadingDadosPrimeiroAcesso = false;
      },
      (e) => {
        this.service.handleError(e);
        this.loadingDadosPrimeiroAcesso = false;
      }
    );
  }

  enviaChavePorEmail(licenca) {
    if (this.obj.email && this.obj.email != "") {
      this.confirmationService.confirm({
        header: "Atenção",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Sim",
        rejectLabel: "Não",
        message: `Confirma o envio da chave para ${this.obj.email}?`,
        accept: () => {
          this._loading = true;
          this.service
            .enviaChavePorEmail(this.obj.cnpj, licenca.software)
            .subscribe(
              (ret) => {
                if (ret) {
                  console.log(ret);
                  this._loading = false;
                  this.messageService.add({
                    severity: "success",
                    summary: "Informação",
                    detail: "E-mail enviado com sucesso!",
                  });
                }
              },
              (e) => {
                this.service.handleError(e);
              }
            );
        },
        reject: () => { },
      });
    }
  }

  carregaDadosAlteracoes() {
    this.loadingDadosPrimeiroAcesso = true;
    this.service.getDadosAlteracoes(this.obj.cnpj).subscribe(
      (dados) => {
        if (dados) {
          this.dadosAlteracoes = [];
          this.fonesAlteracoes = [];
          for (const d of dados) {
            let jaPresente = false;
            for (const dt of this.dadosAlteracoes) {
              if (d.email == dt.email || d.homePage == dt.homePage) {
                jaPresente = true;
                break;
              }
            }
            if (jaPresente) continue;
            this.dadosAlteracoes.push(d);
            if (d.telefones) {
              for (const f of d.telefones) {
                this.fonesAlteracoes.push(f);
              }
            }
          }
        }
        this.loadingDadosPrimeiroAcesso = false;
      },
      (e) => {
        this.service.handleError(e);
        this.loadingDadosPrimeiroAcesso = false;
      }
    );
  }

  afterLoadObj(): void {
    if (this.obj.cnpj) {
      this.databaseLocationService.findAll(0, 1, "", "", [new SearchParams("cnpj", ["tn" + this.obj.cnpj])])
        .subscribe({
          next: (val) => {
            if (val?.length) {
              this.dbLocation = val[0];
            } else {
              this.dbLocation.cnpj = "tn" + this.obj.cnpj;
            }
          }
        })
    }
    if (this.obj.licencas) {
      console.log(this.obj);
      console.log(this.obj.licencas);
      let lic: Licenca[] = [];
      let ids: number[] = [];
      for (let l of this.obj.licencas) {
        const index = ids.indexOf(l.id);
        if (index == -1) {
          lic.push(l);
          ids.push(l.id);
        }
      }
      this.obj.licencas = [...lic];
      if (this.existeLicenca("HABIL_FRANQUEADORA")) {
        this.buscarUsuariosFranqueadora();
      }
    }
  }

  buscarUsuariosFranqueadora() {
    this.usuarioFranqueadoraService.findAll(0, 10, "", undefined, [new SearchParams("cnpjFranqueadora", [this.obj.cnpj])]).subscribe({
      next: (lst) => {
        if (lst) {
          this.usuariosFranqueadora = [...lst];
        }
      }
    })
  }

  excluirUsuarioHabilFranqueadora(usr: HabilFranqueadoraUsuario) {
    this.confirmationService.confirm({
      message: 'Confirma a exclusão?',
      header: 'Atenção',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      accept: () => {
        this.usuarioFranqueadoraService.delete(usr.id)
          .subscribe(e => {
            setTimeout(() => {
              this.buscarUsuariosFranqueadora();
            }, 300);

          }, (e) => this.service.handleError(e));
      },
      reject: () => {

      }
    })
  }

  editarUsuarioHabilFranqueadora(usr: HabilFranqueadoraUsuario) {
    const ref = this.dialogService.open(ClienteHabilFranqueadoraUsuarioComponent, {
      header: "Usuário: " + usr.id,
      width: '800px',
      data: {
        cnpjFranqueadora: this.obj.cnpj,
        id: usr.id
      }
    });
    ref.onClose.subscribe({
      next: (val) => {
        this.buscarUsuariosFranqueadora()
      }
    })
  }

  novoUsuarioHabilFranqueadora() {
    const ref = this.dialogService.open(ClienteHabilFranqueadoraUsuarioComponent, {
      header: "Novo Usuário - Hábil Franqueadora",
      width: '800px',
      data: {
        cnpjFranqueadora: this.obj.cnpj
      }
    });
    ref.onClose.subscribe({
      next: (val) => {
        this.buscarUsuariosFranqueadora()
      }
    })
  }

  mostrarCancelamento(): boolean {
    return this.obj.classificacao?.cancelados
  }

  existeLicenca(tipo: string) {
    for (const l of this.obj.licencas) {
      if (l.software == tipo) {
        return !l.deleted;
      }
    }
    return false;
  }

  adicionaLicenca(tipo: string) {
    if (this.obj?.licencas == null) {
      this.obj.licencas = [];
    }

    for (const l of this.obj.licencas) {
      if (l.software == tipo) {
        l.deleted = false;
        this.messageService.add({
          summary: "Atenção",
          severity: "warn",
          detail: "Licença com este software já existe!"
        });
        return;
      }
    }
    switch (tipo) {
      case "HABIL_COBRANCAS": {

        const licenca: Licenca = this.novaLicenca();
        if (this.obj.licencas?.length > 0) {
          licenca.id = this.obj.licencas.length * -1;
        }
        licenca.software = tipo;
        licenca.tipo = "PAGO";
        this.editar(licenca);
        this.obj.licencas.push(licenca);
        break;
      }
      case "LIMBER_CHEF": {
        const licenca: Licenca = this.novaLicenca();
        if (this.obj.licencas?.length > 0) {
          licenca.id = this.obj.licencas.length * -1;
        }
        licenca.software = tipo;
        licenca.tipo = "PAGO";
        this.editar(licenca);
        this.obj.licencas.push(licenca);
        break;
      }
      case "HABIL_FRANQUEADORA": {
        const licenca: Licenca = this.novaLicenca();
        if (this.obj.licencas?.length > 0) {
          licenca.id = this.obj.licencas.length * -1;
        }
        licenca.software = tipo;
        licenca.tipo = "PAGO";
        this.editar(licenca);
        this.obj.licencas.push(licenca);
        break;
      }
      case "HABIL_MOBILE_PDV": {
        const licenca: Licenca = this.novaLicenca();
        if (this.obj.licencas?.length > 0) {
          licenca.id = this.obj.licencas.length * -1;
        }
        licenca.software = tipo;
        licenca.tipo = "PAGO";
        this.editar(licenca);
        this.obj.licencas.push(licenca);
        break;
      }
      case "HABIL_PLAY": {
        const licenca: Licenca = this.novaLicenca();
        if (this.obj.licencas?.length > 0) {
          licenca.id = this.obj.licencas.length * -1;
        }
        licenca.software = tipo;
        licenca.tipo = "PAGO";
        this.editar(licenca);
        this.obj.licencas.push(licenca);
        break;
      }
    }
  }

  ngOnInit(): void {
    if (this._isDialog) {
      this._loading = true;
      if (this._externalObj != null && this._externalObj != undefined) {
        this.obj = clone(this._externalObj);
        this.id = this.obj.id;
        this.idCampos = this.id == undefined ? "0" : this.id.toString();
        this._loading = false;
      } else {
        this.obj = this.newObject();
        this.idCampos = this.id == undefined ? "0" : this.id.toString();
        this.loadAuxObjects();
        this._loading = false;
      }
    } else {
      this.titleService.setTitle("Cliente - " + AbstractService.app);
      super.ngOnInit();
      this.dif = 250;
    }
  }

  doBeforeSave() { }
}
