<p-scrollPanel [style]="{ width: '100%', height: getScrollHeight() }">
  <div class="p-fluid p-grid" style="padding-right: 15px">
    <div class="p-field p-lg-5">
      <habil-label name="txtNome" label="Cliente">
        <input
          type="text"
          name="txtNome"
          pInputText
          [(ngModel)]="registro.nome"
        />
      </habil-label>
    </div>
    <div class="p-field p-lg-3">
      <habil-label name="txtCnpj" label="CPF / CNPJ">
        <input
          type="text"
          name="txtCnpj"
          pInputText
          [(ngModel)]="registro.cnpj"
        />
      </habil-label>
    </div>
    <div class="p-field p-lg-2">
      <habil-label name="ddSoftwares" label="Software">
        <p-dropdown
          name="ddSoftwares"
          optionValue="value"
          optionLabel="label"
          [disabled]="true"
          [(ngModel)]="licenca.software"
          [options]="softwares"
        >
        </p-dropdown>
      </habil-label>
    </div>
    <div class="p-field p-lg-2 p-col-align-center">
      <br />
      <p-checkbox
        [binary]="true"
        [(ngModel)]="obj.acessoGeral"
        label="Acesso geral"
      ></p-checkbox>
    </div>
    <div class="p-field p-lg-3">
      <habil-label name="txtIp" label="IP">
        <input type="text" name="txtIp" pInputText [(ngModel)]="obj.ip" />
      </habil-label>
    </div>
    <div class="p-field p-lg-2" *ngIf="obj.acessoGeral">
      <habil-label name="deVctoChave" label="Vencimento">
        <habil-date-edit name="deVctoChave" [(ngModel)]="obj.expDate">
        </habil-date-edit>
      </habil-label>
    </div>
    <div class="p-field p-lg-2" *ngIf="obj.acessoGeral">
      <habil-label name="inNroLicencas" label="Licenças">
        <p-inputNumber inputId="inNroLicencas" [(ngModel)]="obj.nroLicencas">
        </p-inputNumber>
      </habil-label>
    </div>
    <div class="p-field p-col-12">
      <p-panel header="Empresas">
        <p-table [value]="obj.empresas" responsiveLayout="scroll">
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 80px">
                <p-button
                  icon="pi pi-plus"
                  (onClick)="novaEmpresa()"
                ></p-button>
              </th>
              <th>CNPJ</th>
              <th>Descrição</th>
              <th *ngIf="!obj.acessoGeral">Vencimento</th>
              <th *ngIf="!obj.acessoGeral">Licenças</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-product>
            <tr>
              <td style="width: 80px">
                <p-button
                  icon="pi pi-trash"
                  (onClick)="apagaEmpresa(product)"
                ></p-button>
              </td>
              <td>
                <input pInputText type="text" [(ngModel)]="product.documento" />
              </td>
              <td>
                <input pInputText type="text" [(ngModel)]="product.nome" />
              </td>
              <td *ngIf="!obj.acessoGeral">
                <habil-date-edit [(ngModel)]="product.vcto"></habil-date-edit>
              </td>
              <td *ngIf="!obj.acessoGeral">
                <p-inputNumber [(ngModel)]="product.licencas"> </p-inputNumber>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </p-panel>
    </div>
    <div class="p-field p-col-12">
      <p-panel header="Módulos">
        <p-pickList
          sourceHeader="Módulos disponíveis"
          (onMoveToSource)="onChangeModulos()"
          (onMoveToTarget)="onChangeModulos()"
          (onMoveAllToTarget)="onChangeModulos()"
          (onMoveAllToSource)="onChangeModulos()"
          targetHeader="Módulos Licenciados"
          [responsive]="true"
          [sourceStyle]="{ height: '250px' }"
          [targetStyle]="{ height: '250px' }"
          [source]="modulosDisponiveis"
          [target]="licenca.modulosLicenciados"
          [dragdrop]="true"
          filterBy="descricaoModulo"
        >
          <ng-template let-modulo pTemplate="item">
            <div *ngIf="modulo">{{ modulo.descricaoModulo }}</div>
          </ng-template>
        </p-pickList>
      </p-panel>
    </div>
    <div class="p-field p-col-12">
      <p-panel
        header="Personalizar Rotinas"
        [toggleable]="true"
        [collapsed]="true"
      >
        <p-pickList
          sourceHeader="Rotinas disponíveis"
          targetHeader="Rotinas habilitadas"
          [responsive]="true"
          [sourceStyle]="{ height: '250px' }"
          [targetStyle]="{ height: '250px' }"
          [source]="rotinas"
          [target]="rotinasComDescricao"
          [dragdrop]="true"
          filterBy="label"
        >
          <ng-template let-rotina pTemplate="item">
            <div *ngIf="rotina?.label">{{ rotina.label }}</div>
            <div *ngIf="!rotina?.label && rotina?.value">
              {{ rotina.value }}
            </div>
            <div *ngIf="!rotina?.label && !rotina?.value">{{ rotina }}</div>
          </ng-template>
        </p-pickList>
      </p-panel>
    </div>
  </div>
</p-scrollPanel>
<hr />
<div class="p-grid">
  <div class="p-col-2">
    <button
      pButton
      type="button"
      style="width: 100%"
      class="p-button-outlined p-button-danger"
      label="Cancelar"
      icon="pi pi-times"
      (click)="cancelar()"
    ></button>
  </div>
  <div class="p-col"></div>
  <div class="p-col-2">
    <button
      pButton
      type="button"
      style="width: 100%"
      class="p-button-success"
      [loading]="loading"
      label="Salvar"
      icon="pi pi-check"
      (click)="salvar()"
    ></button>
  </div>
</div>
