import { Component } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { EModuloAdicional } from './models/registro-produto';
import { ProdutoService } from './services/produto.service';
import { IObserver, WebSocketHabil } from './utilities/util/web-socket-habil';

export const eventInNotify = {
    onclick: (ev: any): any => {
        console.log('click');
    }
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements IObserver {

    menuMode = 'static';

    lightMenu = false;

    theme = 'blue';

    inputStyle = 'outlined';

    ripple: boolean;

    constructor(private primengConfig: PrimeNGConfig, public produtoService: ProdutoService,
        public dialogService: DialogService,) {
    }
    update(dados: any, tipo: 'error' | 'importacao'): void {
        if (Notification.permission === "granted") {
            // Criar e exibir a notificação
            if (tipo == 'error') {
                const n = new Notification('Erro: ' + dados.cnpj, {
                    body: dados.controller + " - " + dados.ip
                    //icon: dados.icone // Opcional: ícone da notificação
                });
                n.onclick = eventInNotify.onclick;

            } else if (tipo == 'importacao') {
                const n = new Notification('Importação: ' + dados.cnpj, {
                    body: dados.dados
                    //icon: dados.icone // Opcional: ícone da notificação
                });
            }
          
        }
    }

    ngOnInit() {
        this.primengConfig.ripple = true;
        this.ripple = true;
        if (!EModuloAdicional.modulos) { // botei esse if porque lá no newrelic acusou que isso aqui está sendo chamado em looping
            this.produtoService.getModulosAdicionais().subscribe(modulosAdicionais => {
                if (modulosAdicionais) {
                    EModuloAdicional.modulos = [...modulosAdicionais];
                }
            });
        }
        if (Notification.permission === "default") {
            Notification.requestPermission().then(permission => {
                if (permission !== "granted") {
                    console.log("Permissão de notificação negada");
                }
            });
        }
        WebSocketHabil.getInstance().attach(this);
    }
}
