import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class LogsService {
    constructor(private http: HttpClient) {}

    private static token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJIQUJJTCIsIm5hbWUiOiJLT0lOT05JQSJ9.IiYLFfWgCnKkrQASae42K_ar7DAaew7US4TUVHW3tYg";
    public static setToken(token) {
        this.token = token;
    }

    private get token() {
        return LogsService.token;
    }

    getErrors(start = 0, length = 100, params: any[] = []): Observable<any[]> {
        const url = environment.apiLogs + "/errors?start=" + start + "&length=" + length;
        const h = new HttpHeaders({
            Accept: "application/json; charset=utf-8",
            "auth": this.token,
            "qb": 'true',
            'params': JSON.stringify(params)
        });
        return this.http.get<any[]>(url, { headers: h });
    }

    getImportacoes(start = 0, length = 100, params: any[] = []): Observable<any[]> {
        const url = environment.apiLogs + "/importacao?start=" + start + "&length=" + length;
        const h = new HttpHeaders({
            Accept: "application/json; charset=utf-8",
            "auth": this.token,
            "qb": 'true',
            'params': JSON.stringify(params)
        });
        return this.http.get<any[]>(url, { headers: h });
    }

}
