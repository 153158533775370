<p-confirmDialog header="Atenção" icon="pi pi-exclamation-triangle">
</p-confirmDialog>
<p-toast></p-toast>
<div class="p-field p-col-12 p-md-12 p-lg-12" *ngIf="_loading">
  <p-progressBar
    mode="indeterminate"
    [style]="{ height: '6px' }"
  ></p-progressBar>
</div>
<habil-grupo>
  <p-scrollPanel [style]="{ width: '100%', height: getScrollHeight() }">
    <p-accordion [multiple]="true">
      <p-accordionTab header="Dados Básicos" [selected]="true">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-4">
            <habil-label name="txtAssunto" label="Assunto">
              <input
                [readonly]="_readonly"
                type="text"
                name="txtAssunto"
                pInputText
                [(ngModel)]="obj.assunto"
              />
            </habil-label>
          </div>
          <div class="p-field p-col">
            <habil-label name="ddSoftwares" label="Software">
              <p-dropdown
                name="ddSoftwares"
                optionValue="value"
                optionLabel="label"
                [disabled]="true"
                [(ngModel)]="obj.software"
                [options]="softwares"
              >
              </p-dropdown>
            </habil-label>
          </div>
          <div class="p-field p-col">
            <habil-label name="ddTipo" label="Tipo">
              <p-dropdown
                (onChange)="onChangeTipo($event)"
                appendTo="body"
                name="ddTipo"
                optionValue="value"
                optionLabel="label"
                [disabled]="_readonly"
                [(ngModel)]="obj.tipo"
                [options]="tipos"
              >
              </p-dropdown>
            </habil-label>
          </div>
          <div class="p-field p-col">
            <habil-label name="de-aPartirDe" label="Disponível a partir de">
              <habil-date-edit name="de-aPartirDe" [(ngModel)]="obj.aPartirDe">
              </habil-date-edit>
            </habil-label>
          </div>
          <div class="p-field p-col">
            <habil-label name="de-validade" label="Validade">
              <habil-date-edit name="de-validade" [(ngModel)]="obj.validade">
              </habil-date-edit>
            </habil-label>
          </div>
        </div>
        <div *ngIf="obj.tipo == 'NEWS'">
          <div class="p-fluid p-formgrid p-grid">
            <div
              class="p-field p-col-12 p-md-3 p-lg-2 p-xl-2 p-col-align-end"
              *ngIf="!_readonly"
            >
              <p-fileUpload
                #fileUpload
                chooseLabel="Adicionar"
                customUpload="true"
                mode="basic"
                auto="true"
                (uploadHandler)="uploadFile($event, fileUpload)"
                [style]="{ width: '100%' }"
                [disabled]="_readonly"
              ></p-fileUpload>
            </div>
            <div
              class="p-field p-col-12 p-md-3 p-lg-2 p-xl-2 p-col-align-end"
              *ngIf="!_readonly"
            >
              <p-button
                label="Excluir"
                (click)="deleteFile()"
                icon="pi pi-trash"
                [style]="{ width: '100%' }"
              ></p-button>
            </div>

            <div class="p-field p-col-12 p-md-3 p-lg-2 p-xl-2 p-col-align-end">
              <p-button
                (onClick)="baixaArquivo()"
                icon="pi pi-download"
                label="Baixar"
                [style]="{ width: '100%' }"
              ></p-button>
            </div>
            <div class="p-field p-col-6"
                 *ngIf="!_readonly">
              <habil-label name="linkUrl" label="Url">
                <input
                  [readonly]="_readonly"
                  type="text"
                  name="url"
                  pInputText
                  [(ngModel)]="linkUrl"
                />
              </habil-label>
            </div>
          </div>
          <p-table
            [value]="obj.htmlInfo"
            dataKey="id"
            editMode="row"
            selectionMode="single"
            [(selection)]="selectedFile"
          >
            <ng-template pTemplate="colgroup">
              <colgroup>
                <col [hidden]="true" />
                <col [style]="{ width: '100%' }" />
              </colgroup>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th hidden="true">Código</th>
                <th>Nome do arquivo</th>
              </tr>
            </ng-template>
            <ng-template
              pTemplate="body"
              let-rowData
              let-editing="editing"
              let-ri="rowIndex"
            >
              <tr [pEditableRow]="rowData" [pSelectableRow]="rowData">
                <td hidden="true">{{ rowData.id }}</td>
                <td>{{ rowData.fileName }}</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <div *ngIf="obj.tipo == 'BANNER'">
          <div class="p-fluid p-grid">
            <div class="p-field p-col-12 p-lg-8">
              <habil-label name="txtHint" label="Hint">
                <input
                  type="text"
                  pInputText
                  name="txtHint"
                  [readonly]="_readonly"
                  [(ngModel)]="obj.bannerInfo.hint"
                />
              </habil-label>
            </div>
            <div class="p-field p-col">
              <habil-label name="ddPrior" label="Prioridade">
                <p-dropdown
                  appendTo="body"
                  name="ddPrior"
                  [readonly]="_readonly"
                  optionValue="value"
                  optionLabel="label"
                  [disabled]="_readonly"
                  [(ngModel)]="obj.bannerInfo.prioridade"
                  [options]="prioridades"
                >
                </p-dropdown>
              </habil-label>
            </div>
            <div class="p-field p-col">
              <habil-label name="inInterv" label="intervalo">
                <p-inputNumber
                  name="inInterv"
                  [readonly]="_readonly"
                  [(ngModel)]="obj.bannerInfo.intervalo"
                ></p-inputNumber>
              </habil-label>
            </div>
            <div class="p-field p-col-12 p-lg-8">
              <habil-label name="txtNmBanner" label="Nome do arquivo">
                <input
                  type="text"
                  pInputText
                  name="txtNmBanner"
                  [readonly]="true"
                  [(ngModel)]="obj.bannerInfo.bannerFileName"
                />
              </habil-label>
            </div>
            <div class="p-field p-col p-col-align-end">
              <p-fileUpload
                #fileUpload
                chooseLabel="Selecionar Imagem"
                customUpload="true"
                mode="basic"
                auto="true"
                (uploadHandler)="uploadFile($event, fileUpload)"
                [style]="{ width: '100%' }"
                [disabled]="_readonly"
              ></p-fileUpload>
            </div>
            <div class="p-field p-col-12 p-md-3 p-lg-2 p-xl-2 p-col-align-end">
              <p-button
                (onClick)="baixaArquivoBanner()"
                icon="pi pi-download"
                label="Baixar"
                [style]="{ width: '100%' }"
              ></p-button>
            </div>
          </div>
        </div>
        <div>
          <div class="p-fluid p-grid" *ngIf="obj.tipo == 'MENSAGEM'">
            <div class="p-field p-col-12">
              <p-editor
                [(ngModel)]="obj.mensagemInfo.textoMensagem"
                #editor
                [readonly]="_readonly"
                [style]="{ height: '300px' }"
              ></p-editor>
            </div>
          </div>
        </div>
        <div *ngIf="obj.tipo == 'NOVIDADES'">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-2">
              <habil-label name="lbbuild" label="Build">
                <input
                  pInputText
                  [readonly]="_readonly"
                  type="text"
                  [(ngModel)]="obj.novidadesInfo.build"
                />
              </habil-label>
            </div>
            <div class="p-field p-col-3 p-col-align-end">
              <p-fileUpload
                name="myfile[]"
                accept=".csv"
                [customUpload]="true"
                (uploadHandler)="uploadCsv($event)"
                mode="basic"
                [auto]="true"
                chooseLabel="Importar arquivo"
              ></p-fileUpload>
            </div>
            <div class="p-field p-col p-col-align-end">
              <p-fileUpload
                #fileUpload
                chooseLabel="Selecionar Imagem"
                customUpload="true"
                mode="basic"
                auto="true"
                (uploadHandler)="uploadFile($event, fileUpload)"
                [style]="{ width: '100%' }"
                [disabled]="_readonly"
              ></p-fileUpload>
            </div>
            <div class="p-field p-col p-col-align-end">
              <p-button
                (onClick)="baixarImagemNovidades()"
                icon="pi pi-download"
                label="Baixar"
                [style]="{ width: '100%' }"
              ></p-button>
            </div>
            <div class="p-field p-col-12">
              <habil-label name="lbalteracoes" label="Alterações">
                <p-table
                  [value]="obj.novidadesInfo.alteracoes"
                  [scrollable]="true"
                  [style]="{ width: '100%' }"
                  scrollHeight="250px"
                >
                  <ng-template pTemplate="header">
                    <tr>
                      <th
                        style="
                          width: 36px;
                          text-align-last: center;
                          padding: 0;
                        "
                      >
                        <button
                          pButton
                          pRipple
                          icon="pi pi-plus"
                          style="text-align-last: center;"
                          class="p-button-rounded p-button-text"
                          (click)="addNovidade()"
                        ></button>
                      </th>
                      <th style="width: 25%;">Tipo</th>
                      <th style="width: 50%;">Descrição</th>
                      <th style="width: 25%;">Rotina</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-reg>
                    <tr>
                      <td
                        style="
                          width: 36px;
                          text-align-last: center;
                          padding: 0;
                        "
                      >
                        <button
                          pButton
                          pRipple
                          icon="pi pi-trash"
                          style="text-align-last: center;"
                          class="p-button-rounded p-button-text"
                          (click)="delNovidade(reg)"
                        ></button>
                      </td>
                      <td style="width: 25%;">
                        <input pInputText [(ngModel)]="reg.tipo" />
                      </td>
                      <td style="width: 50%;">
                        <input pInputText [(ngModel)]="reg.descricao" />
                      </td>
                      <td style="width: 25%;">
                        <input pInputText [(ngModel)]="reg.rotina" />
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </habil-label>
            </div>
            <div class="p-field p-col-12">
              <habil-label name="lbmsg" label="Mensagem">
                <p-editor
                  [(ngModel)]="obj.novidadesInfo.mensagem"
                  [readonly]="_readonly"
                  [style]="{ height: '300px' }"
                ></p-editor>
              </habil-label>
            </div>
          </div>
        </div>
        <div *ngIf="obj.tipo == 'NOVIDADES_INICIO'">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-2">
              <habil-label name="lbbuild" label="Build">
                <input
                  pInputText
                  [readonly]="_readonly"
                  type="text"
                  [(ngModel)]="obj.novidadesInfo.build"
                />
              </habil-label>
            </div>
            <div class="p-field p-col-3 p-col-align-end">
              <habil-label name="lbRotina" label="Rotina">
                <p-dropdown
                  [options]="rotinas"
                  label="label"
                  value="value"
                  [autoDisplayFirst]="false"
                  [showClear]="true"
                  [filter]="true"
                  [(ngModel)]="obj.novidadesInfo.rotina"
                >
                </p-dropdown>
              </habil-label>
            </div>
            <div class="p-field p-col p-col-align-end">
              <p-fileUpload
                #fileUpload
                chooseLabel="Selecionar Imagem"
                customUpload="true"
                mode="basic"
                auto="true"
                (uploadHandler)="uploadFile($event, fileUpload)"
                [style]="{ width: '100%' }"
                [disabled]="_readonly"
              ></p-fileUpload>
            </div>
            <div class="p-field p-col p-col-align-end">
              <p-button
                (onClick)="baixarImagemNovidades()"
                icon="pi pi-download"
                label="Baixar"
                [style]="{ width: '100%' }"
              ></p-button>
            </div>
            <div class="p-field p-col-12"></div>
            <div class="p-field p-col-12">
              <habil-label name="lbmsg" label="Mensagem">
                <p-editor
                  [(ngModel)]="obj.novidadesInfo.mensagem"
                  [readonly]="_readonly"
                  [style]="{ height: '300px' }"
                ></p-editor>
              </habil-label>
            </div>
          </div>
        </div>
        <div *ngIf="obj.tipo == 'PESQUISA'">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-3">
              <habil-label name="lbtipopesquisa" label="Tipo da Pesquisa">
                <p-dropdown
                  [options]="pesquisaTipo"
                  label="label"
                  value="value"
                  [(ngModel)]="obj.pesquisaInfo.tipo"
                >
                </p-dropdown>
              </habil-label>
            </div>
            <div class="p-field p-col-9"></div>
          </div>
        </div>
      </p-accordionTab>
      <p-accordionTab header="Pra quem mandar" [selected]="true">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-10">
            <habil-label name="lbcnpjs" label="CNPJs (separados por vírgula)">
              <p-chips [(ngModel)]="obj.cnpjs" separator=","></p-chips>
            </habil-label>
          </div>
          <div class="p-field p-col-2 p-col-align-end">
            <p-checkbox
              label="Mostrar somente para usuários administradores"
              [binary]="true"
              [(ngModel)]="obj.somenteParaAdmins"
            ></p-checkbox>
          </div>
          <div class="p-field p-col-2">
            <habil-label name="lbnfe" label="NF-e">
              <p-dropdown
                optionValue="value"
                optionLabel="label"
                [disabled]="_readonly"
                [(ngModel)]="obj.nfe"
                [options]="emissoes"
              ></p-dropdown>
            </habil-label>
          </div>
          <div class="p-field p-col-2">
            <habil-label name="lbnfce" label="NFC-e">
              <p-dropdown
                optionValue="value"
                optionLabel="label"
                [disabled]="_readonly"
                [(ngModel)]="obj.nfce"
                [options]="emissoes"
              ></p-dropdown>
            </habil-label>
          </div>
          <div class="p-field p-col-2">
            <habil-label name="lbnfse" label="NFS">
              <p-dropdown
                optionValue="value"
                optionLabel="label"
                [disabled]="_readonly"
                [(ngModel)]="obj.nfse"
                [options]="emissoes"
              ></p-dropdown>
            </habil-label>
          </div>
          <div class="p-field p-col-6"></div>
          <div class="p-field p-col-6">
            <habil-label name="lbmodulos" label="Módulos">
              <p-listbox
                [options]="produtos"
                [(ngModel)]="obj.modulos"
                optionLabel="label"
                optionValue="value"
                [listStyle]="{ 'max-height': '250px' }"
                [checkbox]="true"
                [readonly]="_readonly"
                [multiple]="true"
              ></p-listbox>
            </habil-label>
          </div>
          <div class="p-field p-col-6">
            <habil-label name="lbufs" label="UFs (deixe desmarcado para todos)">
              <p-listbox
                [options]="ufs"
                [(ngModel)]="obj.ufs"
                optionLabel="label"
                optionValue="value"
                [checkbox]="true"
                [listStyle]="{ 'max-height': '250px' }"
                [multiple]="true"
                [readonly]="_readonly"
              ></p-listbox>
            </habil-label>
          </div>
        </div>
      </p-accordionTab>
    </p-accordion>
  </p-scrollPanel>
</habil-grupo>
<br />
<habil-botoes-cadastro
  (onError)="onErrorHandled()"
  [obj]="getObject.bind(this)"
  [dialog]="_isDialog"
  (onSaved)="onSave($event)"
  (onClosed)="onCancel()"
  [service]="service"
  [readonly]="_readonly"
  [validate]="doValidate.bind(this)"
  [doBeforeSave]="doBeforeSave.bind(this)"
  (onSaveAndNew)="onSaveAndNew($event)"
>
</habil-botoes-cadastro>
