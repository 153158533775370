<div class="p-grid p-fluid">
    <div class="p-col-12">
        <div class="card">
            <h5>MenuBar</h5>
            <p-menubar [model]="tieredItems">
                <ng-template pTemplate="end">
                    <span class="p-input-icon-right">
                        <input type="text" pInputText placeholder="Search">
                        <i class="pi pi-search"></i>
                    </span>
                </ng-template>
            </p-menubar>
        </div>
    </div>

    <div class="p-col-12">
        <div class="card">
            <h5>Breadcrumb</h5>
            <p-breadcrumb [model]="breadcrumbItems" [home]="{icon: 'pi pi-home'}"></p-breadcrumb>
        </div>
    </div>

    <div class="p-col-12 p-md-6">
        <div class="card">
            <h5>Steps</h5>
            <p-steps [model]="stepsItems" [activeIndex]="1"></p-steps>
        </div>
    </div>

    <div class="p-col-12 p-md-6">
        <div class="card">
            <h5>TabMenu</h5>
            <p-tabMenu [model]="tabMenuItems" [activeItem]="tabMenuItems[0]"></p-tabMenu>
        </div>
    </div>

    <div class="p-col-12 p-md-4">
        <div class="card">
            <h5>TieredMenu</h5>
            <p-tieredMenu [model]="tieredItems" [style]="{'width':'100%','margin-bottom':'20px'}"></p-tieredMenu>
        </div>

        <div class="card">
            <h5>MenuButton</h5>
            <p-menu #menu [popup]="true" [model]="menuItems" [style]="{'width':'250px'}"></p-menu>
            <button type="button" pButton icon="pi pi-chevron-down" label="Options" (click)="menu.toggle($event)" style="width:auto"></button>
        </div>
    </div>

    <div class="p-col-12 p-md-4">
        <div class="card">
            <h5>Plain Menu</h5>
            <p-menu [model]="items" [style]="{'width':'100%'}"></p-menu>
        </div>
    </div>

    <div class="p-col-12 p-md-4">
        <div class="card">
            <h5>ContextMenu</h5>
            Right click to display.

            <p-contextMenu [global]="true" [model]="menuItems"></p-contextMenu>
        </div>

        <div class="card">
            <h5>SlideMenu</h5>
            <p-slideMenu [model]="slideItems" [viewportHeight]="250" [menuWidth]="175"></p-slideMenu>
        </div>
    </div>

    <div class="p-col-12 p-md-6">
        <div class="card">
            <h5>MegaMenu - Horizontal</h5>
            <p-megaMenu [model]="megaMenuItems"></p-megaMenu>

            <h5>MegaMenu - Vertical</h5>
            <p-megaMenu [model]="megaMenuItems" orientation="vertical" [style]="{'width':'200px'}"></p-megaMenu>
        </div>
    </div>


    <div class="p-col-12 p-md-6">
        <div class="card">
            <h5>PanelMenu</h5>
            <p-panelMenu [model]="panelMenuItems"></p-panelMenu>
        </div>
    </div>
</div>
