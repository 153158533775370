import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CategoriaRelatorio } from '../models/registro-produto';
import { AbstractService } from './abstract.service';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class CategoriaRelatorioService extends AbstractService<CategoriaRelatorio> {

  titulo: string = 'Classificação';
  endpoint(): string {
    return "admin/categoria-relatorio";
  }
  rotina(): string {
    return "CATEGORIA_RELATORIO";
  }
  colunas(): any[] {
    return [
      { header: "Código", field: "id" },
      { header: "Nome", field: "descricao" },
    ];
  }
  novo(selection?: any) {
    AbstractService.readonly = false;
    this.router.navigate(['adm/categorias-relatorios/cadastro']);
  }
  abrir(obj: CategoriaRelatorio) {
    AbstractService.readonly = true;
    this.router.navigate(['adm/categorias-relatorios/cadastro'], { queryParams: { id: obj.id } });
  }
  edit(obj: CategoriaRelatorio) {
    AbstractService.readonly = false;
    this.router.navigate(['adm/categorias-relatorios/cadastro'], { queryParams: { id: obj.id } });
  }
  routeLista() {
    return 'adm/categorias-relatorios';
  }

  constructor(
    http: HttpClient,
    private router: Router,
    loginService: LoginService
  ) {
    super(http, loginService);
  }
}
