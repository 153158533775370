import { buildEventRangeKey } from "@fullcalendar/core";
import { Message } from "primeng/api";
import { AbstractModel } from "src/app/models/abstract-model";
import { FormatUtils } from "./format-utils";

export interface ValidationItem {
  condition: Boolean;
  message: string;
}

export class FormValidationItem implements ValidationItem {
  condition: Boolean;
  message: string;

  constructor( condition: Boolean, message: string) {
    this.condition = condition;
    this.message = message;
  }
}

export class StringValidationItem implements ValidationItem {
  condition: Boolean;
  message: string;
  
  constructor( s: string, message: string ) {
    this.condition = s != undefined && s != null;
    this.message = message;
  }
}

export class CpfCnpjValidationItem implements ValidationItem {
  condition: Boolean;
  message: string;

  constructor( cpfCnpj: string, message: string, naoPermiteVazio?: boolean ) {
    let len = FormatUtils.clear( cpfCnpj ).length;
    switch ( len ) {
      case 0: {
        this.condition = true;
        if ( naoPermiteVazio ) {
          this.condition = false; 
        }        
        break;        
      }
      case 11: 
      case 14: {
        this.condition = true;
        break;        
      }
      default: {
        this.condition = false;
        break;
      }
    }
    this.message = message;
  }
}

export class SearchValidationItem<T extends AbstractModel> implements ValidationItem {
  condition: Boolean;
  message: string;

  constructor( private obj: T, message: string  ){
    this.message = message;
    this.condition =( obj != undefined && obj != null );
  }
}


export class DateValidationItem implements ValidationItem {
  condition: Boolean;
  message: string;

  constructor( date: Date, message: string ) {
    this.condition = date != null && date != undefined;
    this.message = message;
  }
}

export class NumberValidationItem implements ValidationItem {
  condition: Boolean;
  message: string;

  constructor( n: number, message: string ) {
    this.condition = n > 0;
    this.message = message;
  }
}

export class FormValidation {

  toValidate: ValidationItem[] = [];

  public isValid(): boolean {
    let b: boolean = true;
    this.toValidate.forEach(
      item => {
        if (!item.condition) {
          b = false;
          return;
        }
      }
    )
    return b;
  }

  public messageToString(): string {
    let ret: string = "";
    this.message().forEach( 
      s => {
        ret+= s + "\n";
      }
    )
    return ret.toString();
  }

  public message(): string[] {
    let msgs: string[] = [];
    this.toValidate.forEach(
      item => {
        if (!item.condition) {
          msgs.push(item.message);
        }
      }
    );
    return msgs;
  }

  

  public add(validation?: ValidationItem, formValidation?: FormValidation): FormValidation {
    if ( validation ) {
      this.toValidate.push(validation);
    }
    if ( formValidation ) {
      formValidation.toValidate.forEach( 
        v => {
          this.toValidate.push( v );
        }
      )
    }
    return this;
  }

  public msgs(): Message[] {
    let arr = [];
    this.message().forEach( s => {
      arr.push({
        severity: "info",
        summary: "Informação",
        detail: s
      })
    });
    return arr;
  }

}
