import { Observable, ReplaySubject } from "rxjs";

export enum EMime {
  CSV
}

export class FileUtils {

  private static getMime( mime: EMime) {
    switch ( mime ) {
      case EMime.CSV: 
        return "text/csv";
    }
  }

  public static fileToBase64(file: File): Observable<any> {
    let obs = new ReplaySubject<any>(1);
    let reader = new FileReader();
    reader.onload = (ev) => {
      obs.next(reader.result);
    };
    reader.readAsDataURL(file);
    return obs;
  }

  public static downloadBas64( base64: string, mime: EMime, nomeArquivo: string ) {
    let a = document.createElement( "a" );
    a.href = "data:" + FileUtils.getMime( mime ) + ";base64," + base64;
    a.download = nomeArquivo;
    a.click();

  }

  public static downloadFile(blob: Blob, name: string) {
    var url = URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.download = name;
    anchor.href = url;
    anchor.click();
  }

  public static openBase64( file: string, extensao: string, mime: string, nomeArquivo: string = "" ) {
    if ( !extensao.startsWith( '.' ) ) {
      extensao = '.' + extensao;
    } 
    const byteArray = new Uint8Array(
      atob(file)
        .split("")
        .map((char) => char.charCodeAt(0))
    );
    const blob = new Blob([byteArray], { type: mime });
    if (extensao === ".pdf") {
      this.openFile(blob);
    } else {
      if (nomeArquivo == "") {
        this.downloadFile(blob, "_file" + extensao);
      } else {
        this.downloadFile(blob, nomeArquivo);
      }
    }
  }
  public static openFile(blob: Blob) {
    var fileUrl = URL.createObjectURL(blob);
    window.open(fileUrl, "_blank");
  }
}
