import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Servidor } from '../models/balanceamento';
import { AbstractService } from './abstract.service';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class ServidorService extends AbstractService<Servidor> {
  titulo: string = "Servidor";

  endpoint(): string {
    return "admin/servidor";
  }
  rotina(): string {
    return "SERVIDOR";
  }
  colunas(): any[] {
    return [
      { header: "Código", field: "id" },
      { header: "Nome", field: "nome" },
      { header: "URL", field: "url" }
    ]
  }
  private uri( ehLista?: boolean ): string {
    let ret = "/adm/servidor";
    if ( ehLista ) {
      return ret;
    }
    return ret + "/cadastro";
  }

  novo(selection?: any) {
    AbstractService.readonly = false;
    this.router.navigate( [ this.uri() ] )
  }
  abrir(obj: Servidor) {
    AbstractService.readonly = true;
    this.router.navigate( [ this.uri() ], { queryParams: {id: obj.id } } );
  }
  edit(obj: Servidor) {
    AbstractService.readonly = false;
    this.router.navigate( [ this.uri() ], { queryParams: {id: obj.id } } );
  }
  routeLista() {
    return this.uri( true );
  }

  constructor( public http: HttpClient, public loginService: LoginService, private router: Router ) {
    super( http, loginService );
   }
}
