<habil-grupo>
  <div class="p-fluid p-formgrid p-grid">    
    <div class="p-field p-col-12">
      <p-message severity="info" text="Cliente não encontrado! Cadastre-o se desejar." styleClass="mr-2"></p-message>
    </div>    
    <div class="p-field p-col-3">
      <habil-label name="lbcnpj" label="CPF/CNPJ">
        <input pInputText [value]="obj.cpfCnpj" />
      </habil-label>
    </div>
    <div class="p-field p-col-9">
      <habil-label name="lbnome" label="Nome">
        <input pInputText [value]="obj.nome" />
      </habil-label>
    </div>
    <div class="p-field p-col-4">
      <habil-label name="lbrazao" label="Razão Social">
        <input pInputText [value]="obj.razaoSocial" />
      </habil-label>
    </div>
    <div class="p-field p-col-4">
      <habil-label name="lbfone" label="Telefone">
        <input pInputText [value]="obj.telefone" />
      </habil-label>
    </div>
    <div class="p-field p-col-4">
      <habil-label name="lbemail" label="E-mail">
        <input pInputText [value]="obj.email" />
      </habil-label>
    </div>
  </div>
  <p-divider>Exceção</p-divider>
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-6">
      <habil-label name="lbatendente" label="Atendente">
        <input pInputText [(ngModel)]="excecao.atendente" />
      </habil-label>
    </div>
    <div class="p-field p-col-12">
      <habil-label name="lbobs" label="Observações">
        <textarea pInputTextarea [(ngModel)]="excecao.observacoes"></textarea>
      </habil-label>
    </div>
  </div>
</habil-grupo>
<div *ngIf="loading">
  <br />
  <p-progressBar
    mode="indeterminate"
    [style]="{ height: '6px' }"
  ></p-progressBar>
</div>
<br />
<habil-grupo>
  <div class="p-fluid p-grid">
    <div class="p-col-8"></div>
    <div class="p-col-2 p-col-align-end">
      <button
        pButton
        class="p-button-success"
        icon="pi pi-check"
        label="Salvar"
        (click)="salvar()"
      ></button>
    </div>
    <div class="p-col-2 p-col-align-end">
      <button
        pButton
        icon="pi pi-times"
        label="Cancelar"
        class="p-button-outlined p-button-danger"
        (click)="cancelar()"
        pRipple
      ></button>
    </div>
  </div>  
</habil-grupo>
