<div class="layout-topbar">
    <a href="#" id="topbar-menu-button" (click)="app.onMenuButtonClick($event)">
        <i class="pi pi-angle-left arrow"></i>
        <i class="pi pi-angle-up mobile"></i>
    </a>

    <!-- <div class="search-input">
        <i class="pi pi-search icon-search"></i>
        <input type="text"/>
    </div> -->

    <a href="/" class="logo-container">
        <img src="assets/img/habil-logo.png" alt="central-cliente" />
    </a>

    <a id="topbar-profile-menu-button" href="#" (click)="app.onTopbarMenuButtonClick($event)">
        <span>Jeniffer Reynolds</span>
        <img src="assets/layout/images/avatar.png" alt="ecuador-layout" class="topbar-profile" />
        <i class="pi pi-caret-down"></i>
    </a>

    <ul id="topbar-usermenu" class="fadeInDown animated" [ngClass]="{'usermenu-active': app.topbarMenuActive}">
        <li>
            <a href="#" (click)="app.onTopbarSubItemClick($event)">
                <i class="pi pi-fw pi-user"></i>
                <span class="topbar-item-name">Profile</span>
                <span class="topbar-submenuitem-badge">4</span>
            </a>
        </li>
        <li #settings [ngClass]="{'menuitem-active':app.activeTopbarItem === settings}">
            <a href="#" (click)="app.onTopbarItemClick($event, settings)">
                <i class="pi pi-fw pi-cog"></i>
                <span class="topbar-item-name">Settings</span>
                <span class="topbar-submenuitem-badge">2</span>
            </a>
            <ul>
                <li role="menuitem">
                    <a href="#" (click)="app.onTopbarSubItemClick($event)">
                        <i class="pi pi-fw pi-palette"></i>
                        <span>Change</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" (click)="app.onTopbarSubItemClick($event)">
                        <i class="pi pi-fw pi-star"></i>
                        <span>Favorites</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" (click)="app.onTopbarSubItemClick($event)">
                        <i class="pi pi-fw pi-lock"></i>
                        <span>Lock Screen</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" (click)="app.onTopbarSubItemClick($event)">
                        <i class="pi pi-fw pi-image"></i>
                        <span>Wallpaper</span>
                    </a>
                </li>
            </ul>
        </li>
        <li #messages [ngClass]="{'menuitem-active':app.activeTopbarItem === messages}">
            <a href="#" (click)="app.onTopbarItemClick($event, messages)">
                <i class="pi pi-fw pi-envelope"></i>
                <span class="topbar-item-name">Messages</span>
            </a>
            <ul>
                <li role="menuitem">
                    <a href="#" class="topbar-message" (click)="app.onTopbarSubItemClick($event)">
                        <img src="assets/layout/images/avatar-brooke.png" alt="ecuador-layout" width="25"/>
                        <span>Give me a call</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" class="topbar-message" (click)="app.onTopbarSubItemClick($event)">
                        <img src="assets/layout/images/avatar-tom.png" alt="ecuador-layout" width="25"/>
                        <span>Reports attached</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" class="topbar-message" (click)="app.onTopbarSubItemClick($event)">
                        <img src="assets/layout/images/avatar-maggie.png" alt="ecuador-layout" width="25"/>
                        <span>About your invoice</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" class="topbar-message" (click)="app.onTopbarSubItemClick($event)">
                        <img src="assets/layout/images/avatar-lucas.png" alt="ecuador-layout" width="25"/>
                        <span>Meeting today</span>
                    </a>
                </li>
            </ul>
        </li>
        <li #notifications [ngClass]="{'menuitem-active':app.activeTopbarItem === notifications}">
            <a href="#" (click)="app.onTopbarItemClick($event, notifications)">
                <i class="pi pi-fw pi-bell"></i>
                <span class="topbar-item-name">Notifications</span>
            </a>
            <ul>
                <li role="menuitem">
                    <a href="#" (click)="app.onTopbarSubItemClick($event)">
                        <i class="pi pi-fw pi-sliders-h"></i>
                        <span>Pending tasks</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" (click)="app.onTopbarSubItemClick($event)">
                        <i class="pi pi-fw pi-calendar"></i>
                        <span>Meeting today</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" (click)="app.onTopbarSubItemClick($event)">
                        <i class="pi pi-fw pi-download"></i>
                        <span>Download</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" (click)="app.onTopbarSubItemClick($event)">
                        <i class="pi pi-fw pi-ticket"></i>
                        <span>Book flight</span>
                    </a>
                </li>
            </ul>
        </li>
    </ul>

    <a href="#" id="right-panel-button" [ngClass]="{'menu-button-rotate': app.rightPanelActive}" (click)="app.onRightPanelButtonClick($event)">
        <i class="pi pi-angle-double-left"></i>
    </a>

</div>
