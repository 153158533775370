import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { AbstractService } from 'src/app/services/abstract.service';
import { ClassificacaoService } from 'src/app/services/classificacao.service';

@Component({
  selector: 'habil-classificacao',
  templateUrl: './classificacao.component.html',
  styleUrls: ['./classificacao.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class ClassificacaoComponent implements OnInit {

  constructor(private breadcrumbService: BreadcrumbService, public service: ClassificacaoService, titleService: Title) {
    this.breadcrumbService.setItems([{label: 'Classificações'}]);
    titleService.setTitle('Classificações - ' + AbstractService.app);
  }

  ngOnInit(): void {
  }

}
