import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { AutocompleteEditComponent } from './autocomplete-edit.component';
import {RippleModule} from 'primeng/ripple';




@NgModule({
  declarations: [
    AutocompleteEditComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        AutoCompleteModule,
        InputTextModule,
        ButtonModule,
        TooltipModule,
        RippleModule
    ],
  exports: [
    AutocompleteEditComponent
  ]
})
export class AutocompleteEditModule { }
