import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { MensagemComponent } from '../utilities/mensagem/mensagem.component';

@Injectable({
  providedIn: 'root'
})
export class MensagemService {

//  constructor( private dialog: DialogService ) { }

  erro( msg: string ) {
    console.log( msg );
    // this.dialog.open( 
    //   MensagemComponent, {
    //     header: "Erro",
    //     data: {
    //       texto: msg
    //     }
    //   }
    // )
  }

}

