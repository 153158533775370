import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PlanoSuporte } from '../models/plano-suporte';
import { AbstractService } from './abstract.service';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class PlanoSuporteService extends AbstractService<PlanoSuporte> {
  titulo: string = "Plano de Suporte";

  endpoint(): string {
    return "/admin/plano-suporte"
  }
  rotina(): string {
    return "PLANO_SUPORTE"
  }
  colunas(): any[] {
    return [
      { header: "Código", field: "id" },
      { header: "Descrição", field: "descricao" },
    ];

  }
  novo(selection?: any) {
    AbstractService.readonly = false;
    this.router.navigate( [ this.uri() ] )
  }
  abrir(obj: PlanoSuporte) {
    AbstractService.readonly = true;
    this.router.navigate( [ this.uri() ], { queryParams: { id: obj.id } } );
  }
  edit(obj: PlanoSuporte) {
    AbstractService.readonly = false;
    this.router.navigate( [ this.uri() ], { queryParams: { id: obj.id } } );
  }
  routeLista() {
    return this.uri( true ) 
  }

  private uri(ehLista?:boolean): string {
    let ret = "/adm/plano-suporte";
    if ( ehLista ) {
      return ret;
    }
    return ret + "/cadastro";
  }

  constructor(http: HttpClient, loginService: LoginService, private router: Router) { 
    super( http, loginService );
  }
}
