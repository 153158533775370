import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { AbstractService } from 'src/app/services/abstract.service';
import { CategoriaRelatorioService } from 'src/app/services/categoria-relatorio.service';

@Component({
  selector: 'habil-categoria-relatorio',
  templateUrl: './categoria-relatorio.component.html',
  styleUrls: ['./categoria-relatorio.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class CategoriaRelatorioComponent implements OnInit {

  constructor(private breadcrumbService: BreadcrumbService, public service: CategoriaRelatorioService, titleService: Title) {
    this.breadcrumbService.setItems([{label: 'Categorias de Relatório'}]);
    titleService.setTitle('Categorias - ' + AbstractService.app);
  }

  ngOnInit(): void {
  }

}
