import { formatDate } from "@angular/common";
import { DateUtils } from "../utilities/util/date-utils";
import { FormatUtils } from "../utilities/util/format-utils";

export class ChaveEmpresarialEmissao {
  cnpj: string;
  nfse: boolean;
  nfseVcto: Date;
  nfce: boolean;
  nfceVcto: Date;
  sat: boolean;
  satVcto: Date;
  app: boolean;
  appVcto: Date;
}

export class ConversorChaveEmpresarial {

  private getSolucao( solucao: string ): number {
    if ( solucao == "Sem Propaganda" ) {
      return 1;
    }
    if ( solucao == "Sem Propaganda +" ) {
      return 2;
    }
    if ( solucao == "Plus" ) {
      return 3;
    }
    if ( solucao == "Profissional" ) {
      return 4;
    }
    if ( solucao == "Compacto" ) {
      return 5;
    }
    if ( solucao == "Emissor de NF-e" ) {
      return 6;
    }
    return 0;
  }

  private cnpjStrToArr( cnpjs: string ): string[] {
    let ret: string[] = [];    
    if ( cnpjs ) {
      let aux: string[] = cnpjs.split( ">" )
      for ( let s of aux ) { 
        let cnpj: string = s.replace( "<", "" ).trim();
        if ( cnpj ) {
          ret.push( cnpj );
        }
      }
    }

    return ret;
  }

  public convert( textoDaChave: string, chaveDescriptografada: string ): ChaveEmpresarial {

    let a = new Map();
    chaveDescriptografada.split( "\n" ).forEach( s => {
      a.set( s.split( '=' )[ 0 ], s.split( '=' )[ 1 ] );
    })
    let ret = new ChaveEmpresarial();

    ret.nome = a.get('NOME');
    ret.razaoSocial = a.get( 'RAZAOSOCIAL' );
    ret.cpfCnpj = a.get(  'CNPJ' );
    ret.cpfCnpj = FormatUtils.clear(ret.cpfCnpj);
    ret.fone = a.get(  'FONE' );
    ret.uf = a.get( 'UF' );

    ret.nroLicencas = +a.get( 'USERS' );
    ret.versao = a.get( 'VERSAO' );
    ret.usaNFe = a.get( "UNFe" ) == "True";
    if ( a.get( 'NFeEx' ) ) {
      ret.outrosCnpjs = this.cnpjStrToArr( a.get( 'NFeEx' ) );      
    }
    ret.versaoNFe = a.get( "VNFE" );
    ret.solucao = this.getSolucao( a.get( "NOMESOLUCAO" ) );
    ret.usaCupom = a.get( "UCF" ) == "True";
    ret.usaContrato = a.get( "UALCON" ) == "True";
    ret.usaCompras = a.get( "UORDCOM" ) == "True";
    ret.usaComandas = a.get( "UCOMAN" ) == "True";
    ret.usaAlmoxarifado = a.get( "UALMOX" ) == "True";
    ret.usaMagento = a.get( "UMAGENTO" ) == "True";
    if ( a.get( "EXTRA" ) ) {
      let x: string = a.get( "EXTRA" );
      ret.usaGraficos = x.includes( "<G>" );
      ret.usaSpedFiscal = x.includes( "<S1>" );
      ret.usaSpedPisCofins = x.includes( "<S2>" );
    }
    if ( a.get( "VAL" ) ) {
      ret.comValidade = true;
      ret.validade = DateUtils.incDay( DateUtils.getDate( 1899, 12, 31 ), +a.get( "VAL" ) );
      if ( a.get( "DEMO" ) == "TRUE" ) {
        ret.chaveDemo = true;
      }
    }
    if ( a.get('APPLIC' ) ) {
      ret.nroLicencasApp = +a.get('APPLIC' );
    }

    ret.emissoes = [];
    let cnpjs: string[] = [];

    let todes: string[] = [ a.get('NFCeEx' ), a.get('NFSEx' ), a.get('SATEx' ), a.get('APPEx' ) ];
    for ( let aux of todes ) {
      this.cnpjStrToArr( aux ).forEach( 
        cnpj => {
          if ( cnpjs.indexOf( cnpj ) == -1 ) {
            cnpjs.push( cnpj );
          }
        }
      )
    }
    cnpjs.forEach( 
      cnpj => {
        let e: ChaveEmpresarialEmissao = new ChaveEmpresarialEmissao();
        e.app = a.get( "UAPP" ) == "True";
        e.nfce = a.get( "UNFCe" ) == "True";
        e.nfse = a.get( "UNFSe") == "True";
        e.sat = a.get( "USAT") == "True";
        e.cnpj = cnpj;

        ret.emissoes.push( e );
      }
    )
        
    return this.procuraVencimentoDosModulos( textoDaChave, ret );
  }
  
  private procuraVencimentoDosModulos( textoChave: string, chave: ChaveEmpresarial ): ChaveEmpresarial {
    // agora eu procuro A PORRA do vencimento dos módulos, pq olhar na chave e digitar é muito avançado pra certas pessoas
    let arr: string[] = textoChave.split( "\n" );
    for ( let i = 0; i < arr.length; i++ ) {      
      let s: string = arr[ i ];
      if ( s.startsWith( "Seu código de liberação para o módulo do " ) ) {
        let index = i + 1;
        let linha: string;
        do {
          linha = arr[ index ].trim();
          if ( linha  ) {
            let cnpj = FormatUtils.clear( linha.substring( linha.indexOf( "cnpj " ) + 5 ).substring( 0, 18 ) );
            let aux = linha.substring( linha.indexOf( "até " ) + 4 ).substring( 0, 10 ).split( "/" );
            let vcto: Date = DateUtils.getDate( +aux[ 2 ], +aux[ 1 ], +aux[ 0 ] );

            chave.emissoes.forEach( e => {
              if ( e.cnpj == cnpj ) {
                if ( linha.startsWith( "C" ) ) {
                  e.nfceVcto = vcto;
                }
                if ( linha.startsWith( "S" ) ) {
                  e.nfseVcto = vcto;
                }
                if ( linha.startsWith( "T" ) ) {
                  e.satVcto = vcto;
                }
                if ( linha.startsWith( "A" ) ) {
                  e.appVcto = vcto;
                }
                return;
              }
            })
          }
          index++;
        } while ( linha );
      }
    }

    return chave;
  }
}


export class ChaveEmpresarial {
  nome: string;
  razaoSocial: string;
  cpfCnpj: string;
  fone: string;
  email: string;
  versao: string;
  solucao: number;
  nroLicencas: number;
  comValidade: boolean;
  validade: Date;
  chaveDemo: boolean;
  usaNFe: boolean;
  versaoNFe: string;
  outrosCnpjs: string[] = [];
  nroLicencasApp: number;
  usaCupom: boolean;
  usaCompras: boolean;
  usaContrato: boolean;
  usaSpedFiscal: boolean;
  usaSpedPisCofins: boolean;
  usaGraficos: boolean;
  usaComandas: boolean;
  usaAlmoxarifado: boolean;
  usaMagento: boolean;
  uf: string = "";
  emissoes: ChaveEmpresarialEmissao[] = [];

  private GRATUITA = '01*02*03*04*05*06*07*08*09*0A*0B*0C*0D*0E*0F*0G#0H#0I#0J#0K*0L*0M#0N*0O*0P*0Q*0R*0S*0T*0U*0V*0W*0X*0Y*0Z*10*11#12*13*14*15*16*17#18#19*1A*1B*1C*1D*'+
    '1E*1F*1G*1H*1I*1J*1K*1L*1M*1N*1O*1P*1Q*1R*1S*1T*1U*1V*1W*1X*1Y*1Z*20*21*23*24*25*26*27*28*29*2A*2B*2C*2D*2E*2F*2G#2H#2I#2J#2K#2L#2M#2N#2O*2P*2Q#2R#'+
    '2S*2T#2U#2V*2W#2X#2Y#2Z#30#31#32#33#34#35#36#37#38#39#3A#3B#3C#3D*3E#3F*3G*3H#3I#3J#3K#3L#3M#3N#3O*3P*3Q#3R#3S*3T#3U#3V#3W#3X#3Y#3Z#40#41#42#43#44#'+
    '45*46#47#48#49#4A*4B#4C*4D*4E*4F*4G*4H#4I#4J#4K#4L#4M#4N#4O#4P#4Q#4Z#50#51#52#53#54#55#56#57#ZQ#ZR#ZS#ZT#ZU#ZV#ZW#ZX#ZY#ZZ*58#5B*5D*5E#5F#5G#5H#5K#'+
    '5L*5M#5N*5O#5P#';
;
  private SP = '01*02*03*04*05*06*07*08*09*0A*0B*0C*0D*0E*0F*0G#0H#0I#0J#0K*0L*0M#0N*0O*0P*0Q*0R*0S*0T*0U*0V*0W*0X*0Y*0Z*10*11#12*13*14*15*16*17*18*19*1A*1B*1C*1D*'+
    '1E*1F*1G*1H*1I*1J*1K*1L*1M*1N*1O*1P*1Q*1R*1S*1T*1U*1V*1W*1X*1Y*1Z*20*21*23*24*25*26*27*28*29*2A*2B*2C*2D*2E*2F*2G#2H#2I#2J#2K#2L#2M#2N#2O*2P*2Q#2R*'+
    '2S*2T*2U*2V*2W#2X#2Y#2Z#30#31#32#33#34#35#36#37#38#39#3A#3B#3C#3D*3E#3F*3G*3H*3I#3J#3K#3L#3M#3N#3O*3P*3Q#3R#3S*3T#3U#3V#3W#3X#3Y*3Z*40*41#42#43#44#'+
    '45*46#47#48#49#4A*4B#4C*4D*4E*4F*4G*4H#4I#4J#4K*4L*4M#4N*4O*4P#4Q#4Z*50*51*52*53*54#55#56#57#58#5B*5D*5E#5F#5G*5H*5K#5L*5M#5N*5O#5P*' +
    'ZQ#ZR#ZS*ZT*ZU#ZV#ZW*ZX#ZY#ZZ#';
  
  private SP_MAIS = '01*02*03*04*05*06*07*08*09*0A*0B*0C*0D*0E*0F*0G#0H#0I#0J#0K*0L*0M#0N*0O*0P*0Q*0R*0S*0T*0U*0V*0W*0X*0Y*0Z*10*11#12*13*14*15*16*17*18*19*1A*1B*1C*1D*'+
    '1E*1F*1G*1H*1I*1J*1K*1L*1M*1N*1O*1P*1Q*1R*1S*1T*1U*1V*1W*1X*1Y*1Z*20*21*23*24*25*26*27*28*29*2A*2B*2C*2D*2E*2F*2G*2H*2I*2J*2K*2L*2M*2N*2O*2P*2Q*2R*'+
    '2S*2T*2U*2V*2W#2X#2Y#2Z#30#31#32#33#34#35#36#37#38#39#3A#3B#3C#3D*3E#3F*3G*3H*3I#3J#3K#3L#3M#3N#3O*3P*3Q#3R#3S*3T*3U#3V#3W#3X#3Y*3Z*40*41#42#43#44#'+
    '45*46#47#48#49#4A*4B*4C*4D*4E*4F*4G*4H#4I#4J#4K*4L*4M#4N*4O*4P#4Q#4Z*50*51*52*53*54#55#56#57#58#5A*5B*5D*5E#5F#5G*5H*5K#5L*5M#5N*5O#5P*' +
    'ZQ#ZR#ZS*ZT*ZU#ZV*ZW*ZX#ZY#ZZ#';

  private PLUS = '01*02*03*04*05*06*07*08*09*0A*0B*0C*0D*0E*0F*0G*0H*0I*0J*0K*0L*0M#0N*0O*0P*0Q*0R*0S*0T*0U*0V*0W*0X*0Y*0Z*10*11*12*13*14*15*16*17*18*19*1A*1B*1C*1D*'+
    '1E*1F*1G*1H*1I*1J*1K*1L*1M*1N*1O*1P*1Q*1R*1S*1T*1U*1V*1W*1X*1Y*1Z*20*21*23*24*25*26*27*28*29*2A*2B*2C*2D*2E*2F*2G*2H*2I*2J*2K*2L*2M*2N*2O*2P*2Q*2R*'+
    '2S*2T*2U*2V*2W*2X*2Y*2Z*30*31*32*33*34*35*36*37*38*39*3A*3B*3C*3D*3E*3F*3G*3H*3I#3J#3K#3L#3M#3N#3O*3P*3Q*3R*3S*3T*3U#3V#3W#3X#3Y*3Z*40*41#42*43*44*'+
    '45*46*47*48#49#4A*4B*4C*4D*4E*4F*4G*4H*4I#4J#4K*4L*4M#4N*4O*4P#4Q*4S*4T*4U*4V*4W*4X*4Y*4Z*50*51*52*53*54*55*56*57*58#5A*5B*5D*5E*5F*5G*5H*5K*5L*5M*'+
    '5N*5O#5P*ZQ*ZR*ZS*ZT*ZU*ZV*ZW*ZX*ZY*ZZ#';

  private PROFISSIONAL = '01*02*03*04*05*06*07*08*09*0A*0B*0C*0D*0E*0F*0G*0H*0I*0J*0K*0L*0M*0N*0O*0P*0Q*0R*0S*0T*0U*0V*0W*0X*0Y*0Z*10*11*12*13*14*15*16*17*18*19*1A*1B*1C*1D*'+
    '1E*1F*1G*1H*1I*1J*1K*1L*1M*1N*1O*1P*1Q*1R*1S*1T*1U*1V*1W*1X*1Y*1Z*20*21*23*24*25*26*27*28*29*2A*2B*2C*2D*2E*2F*2G*2H*2I*2J*2K*2L*2M*2N*2O*2P*2Q*2R*'+
    '2S*2T*2U*2V*2W*2X*2Y*2Z*30*31*32*33*34*35*36*37*38*39*3A*3B*3C*3D*3E*3F*3G*3H*3I*3J*3K*3L*3M*3N*3O*3P*3Q*3R*3S*3T*3U*3V*3W*3X*3Y*3Z*40*41*42*43*44*'+
    '45*46*47*48*49*4A*4B*4C*4D*4E*4F*4G*4H*4I*4J*4K*4L*4M*4N*4O*4P*4Q*4S*4T*4U*4V*4W*4X*4Y*4Z*50*51*52*53*54*55*56*57*58*5B*5D*5E*5F*5G*5H*5K*5L*5M*5N*'+
    '5O*5P*ZQ*ZR*ZS*ZT*ZU*ZV*ZW*ZX*ZY*ZZ#5A*';
  
  private COMPACTA = '01*02*03*04*05*06*07*08*09*0A*0B*0C*0D*0E*0F*0G#0H#0I#0J#0K*0L*0M*0N*0O*0P#0Q#0R#0S#0T*0U#0V*0W*0X*0Y*0Z*10*11*12*13*14*15*16#17#18#19*1A*1B*1C*1D#'+
    '1E*1F*1G*1H*1I*1J*1K*1L*1M*1N*1O*1P*1Q*1R*1S*1T*1U*1V*1W*1X*1Y*1Z*20*21#23*24*25*26*27*28*29*2A*2B*2C*2D*2E*2F#2G#2H#2I#2J*2K*2L#2M#2N*2O*2P*2Q*2R*'+
    '2S*2T#2U#2V*2W#2X*2Y*2Z#30#31*32#33*34#35*36#37*38#39#3A#3B#3C*3D#3E*3F*3G*3H*3I*3J*3K*3L*3M#3N#3O#3P*3Q#3R#3S#3T#3U#3V#3W*3X#3Y#3Z*40#41#42*43*44#'+
    '45#46#47#48#49*4A*4B#4C#4D#4E#4F#4G#4H#4I*4J*4K#4L#4M*4N#4O#4P*4Q*4S*4T*4U*4V*4W*4X*4Y*4Z*50*51*52*53*54*55*56*57*58*5B*5D*5E#5F#5G*5H*5K*5L*5M#5N*'+
    '5O*5P*ZQ#ZR#ZS*ZT*ZU*ZV*ZW*ZX#ZY*ZZ#';

  private NFE = '01*02*03*04*05*06*07*08*09*0A*0B*0C*0D*0E*0F*0G#0H#0I#0J#0K*0L*0M#0N*0O*0P*0Q*0R*0S*0T*0U*0V*0W*0X*0Y*0Z*10*11*12*13*14*15*16*17#18#19*1A*1B*1C*1D*' +
    '1E*1F*1G*1H*1I*1J*1K*1L*1M*1N*1O*1P*1Q*1R*1S*1T*1U*1V*1W*1X*1Y*1Z*20*21*23*24*25*26*27*28*29*2A*2B*2C*2D*2E*2F*2G#2H#2I#2J#2K#2L#2M#2N#2O*2P*2Q#2R#' +
    '2S*2T#2U#2V*2W#2X#2Y#2Z#30#31#32#33*34#35*36#37#38#39#3A#3B#3C*3D*3E#3F*3G*3H#3I*3J#3K*3L*3M#3N#3O*3P*3Q#3R#3S*3T#3U#3V#3W*3X#3Y#3Z*40#41*42*43*44#' +
    '45*46#47#48*49#4A*4B#4C*4D*4E*4F*4G*4H#4I*4J*4K#4L#4M*4N#4O#4P*4Q#4S#4T#4U#4V#4W#4X#4Y#4Z#50#51#52#53#54#55#56#57#58*5B*5D*5E#5F#5G*5H*5K*5L*5M#5N*' +
    '5O#5P*ZQ#ZR#ZS*ZT#ZU#ZV#ZW#ZX#ZY#ZZ#';

;

  private poeModulo( nomeModulo: string, usa: boolean ): string {
    if ( usa ) {
      return nomeModulo + "=" + this.boolToStr( usa ) + "\n";
    }
    return "";
  }

  private pegaExtras(): string {
    let extras: string = "";
    if ( this.usaGraficos ) {
      extras += "<G>";
    }
    if ( this.usaSpedFiscal ) {
      extras += "<S1>";
    }
    if ( this.usaSpedPisCofins ) {
      extras += "<S2>";
    }
    return extras;
  }

  private calculaDataValidade(): string {
    // quando se converte uma data pra inteiro no delphi, pra desconverter basta somar o inteiro com a data base
    // no delphi a data base é 31/12/1899
    return DateUtils.daysBetween(  this.validade, DateUtils.getDate( 1899, 12, 31 ) ).toString() + "\n";
  }

  private getStringDaVersao(): string {
    switch ( this.solucao ) {
      case 1: return this.SP;
      case 2: return this.SP_MAIS;
      case 3: return this.PLUS;
      case 4: return this.PROFISSIONAL;
      case 5: return this.COMPACTA;
      case 6: return this.NFE;
      default: return this.GRATUITA;
    }   
  }

  private getNomeSolucao(): string {
    switch ( this.solucao ) {
      case 1: return "Sem Propaganda";
      case 2: return "Sem Propaganda +";
      case 3: return "Plus";
      case 4: return "Profissional";
      case 5: return "Compacto";
      case 6: return "Emissor de NF-e";
      default: return "Gratuito";
    }   
  }

  private resolveEmissoes(): string {
    let nfs: string = "False";
    let nfc: string = "False";
    let sat: string = "False";
    let app: string = "False";
    let appdocs: string = "";
    let nfcdocs: string = "";
    let nfsdocs: string = "";
    let satdocs: string = "";
    for ( let e of this.emissoes ) {
      if ( e.app ) {
        app = "True";
        appdocs += "<" + e.cnpj + ">";
      }
      if ( e.nfce ) {
        nfc = "True";
        nfcdocs += "<"+ e.cnpj + ">";
      }
      if ( e.sat ) {
        sat = "True";
        satdocs += "<" + e.cnpj + ">";
      }
      if ( e.nfse ) {
        nfs = "True";
        nfsdocs += "<" + e.cnpj + ">";
      }
    }
    return "UNFCe=" + nfc + "\n" +
      "UNFSe=" + nfs + "\n" +
      "USAT=" + sat + "\n" +
      "UAPP=" + app + "\n" +
      "NFCeEx=" + nfcdocs + "\n" +
      "NFSEx=" + nfsdocs + "\n" +
      "SATEx=" + satdocs + "\n" +
      "APPEx=" + appdocs + "\n";
  }

  private boolToStr( b: boolean ): string {
    if ( b ) {
      return "True";
    }
    return "False";
  }

  public getListEmissoes(): any[] {
    let list: any[] = [];
    this.emissoes.forEach( 
      e => {
        let fn = function( tipo: string, data: Date ) {
          list.push(
            {
              tipo: tipo,
              data: data,
              cnpj: e.cnpj
            }
          )
        }

        if ( e.app ) {
          fn( "APP", e.appVcto );
        }
        if ( e.nfce ) {
          fn( "NFCE", e.nfceVcto );
        }
        if ( e.nfse ) {
          fn( "NFS", e.nfseVcto );
        }
        if ( e.sat ) {
          fn( "SAT", e.satVcto );
        }
      }
    );
    return list;
  }

  public getDadosChave(): string {
    if ( this.usaNFe == undefined ) {
      this.usaNFe = false;
    }    
    let ret: string = 
      "NOME=" + this.nome + "\n" + 
      "RAZAOSOCIAL=" + this.razaoSocial + "\n" + 
      "CNPJ=" + this.cpfCnpj + "\n" + 
      "FONE=" + this.fone + "\n" + 
      "UF=" + this.uf + "\n" + 
      "CHAVE=" + formatDate( new Date(), "dd-MM-yyyy HHmmss", "pt-BR" ) + "\n" +
      "SISTEMA=EMP" + "\n" + 
      "USERS=" + this.nroLicencas + "\n"+
      "NOMESOLUCAO=" + this.getNomeSolucao() + "\n" +
      "VERSAO=" + this.versao + "\n" + 
      "MOD=" + this.getMod() + "\n" + 
      "APPLIC=" + this.nroLicencasApp + "\n" +
      this.poeModulo( "UCF", this.usaCupom ) +
      this.poeModulo( "UALCON", this.usaContrato ) +
      this.poeModulo( "UORDCOM", this.usaCompras ) +
      this.poeModulo( "UCOMAN", this.usaComandas ) +
      this.poeModulo( "UALMOX", this.usaAlmoxarifado ) +
      this.poeModulo( "UMAGENTO", this.usaMagento ) +
      "EXTRA=" + this.pegaExtras() + "\n" +
      "UNFe=" + this.boolToStr( this.usaNFe ) + "\n" + 
      "VNFE=" + this.versaoNFe + "\n";    
    if ( this.outrosCnpjs ) {
      let outros: string = "";
      for ( let s of this.outrosCnpjs ) {
        outros += "<" + s + '>';
      }
      ret += "NFeEx=" + outros + "\n";
    }
    ret += this.resolveEmissoes();
    if ( this.comValidade ) {
      ret += "DEMO=TRUE\n";
      if ( this.validade ) {
        ret += "VAL=" + this.calculaDataValidade();
      }
      if ( !this.chaveDemo ) {
        ret += "PARCELA=TRUE\n";
      }
    }
    ret += "ModX=" + this.getStringDaVersao();
    return ret.trim();
  } 

  private getMod(): string {
    let ret = "<0>";
    if ( this.solucao > 0 ) {
      ret += "<1>";
    }
    if ( this.solucao > 1 ) {
      ret += "<2>";
    }
    if ( this.solucao > 2 ) {
      ret += "<3>";
    }
    if ( this.solucao > 3 ) {
      ret += "<5>";
    }
    if ( this.solucao > 4 ) {
      ret += "<A>";
    }
    return ret;
  }  
}