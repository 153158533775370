import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AdminUsuario } from '../models/admin-usuario';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements CanActivate {

  constructor(
    private route: Router
  ) { }

  private mandaPraHome(): boolean {
    this.route.navigate( [ '/' ] );
    return false;
  }

  private naoTemAcesso(): boolean {
    this.route.navigate( [ 'nao-autorizado' ] );
    return false;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let token: string = AbstractService.getLocalStorage( "token" );
    if ( !token ) {
      return this.mandaPraHome();
    }     
    let usuario: AdminUsuario = AbstractService.getLocalStorage( "usuario" );
    if ( !usuario ) {
      return this.mandaPraHome();
    } 
    if ( usuario.sysAdmin ) { // admin pode tudo
      return true;
    }
    if ( !usuario.grupoAcesso ) { 
      // se não tem grupo definido e não é admin, não acessa nada
      return this.naoTemAcesso();
    }

    let grupo = route.data.grupo;
    if ( grupo ) {
      // se tem grupo, valida
      if ( usuario.grupoAcesso > grupo ) {
        return this.naoTemAcesso();
      }
    }
    return true;    
  }
}
