import { FormsModule } from '@angular/forms';

import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { GrupoComponent } from './grupo.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { RippleModule } from 'primeng/ripple';



@NgModule({
  declarations: [
    GrupoComponent
  ],
  imports: [
    CommonModule,
    ButtonModule,
    CardModule,
    FormsModule,
    ButtonModule,
    ScrollPanelModule,
    RippleModule
  ],
  exports: [
    GrupoComponent
  ]
})
export class GrupoModule { }
