import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EstimativaAcessoComponent } from './estimativa-acesso.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AccordionModule } from 'primeng/accordion';
import { ProgressBarModule } from 'primeng/progressbar';
import { RippleModule } from 'primeng/ripple';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { BaseListaModule } from 'src/app/utilities/base-lista/base-lista.module';
import { BotoesCadastroModule } from 'src/app/utilities/botoes-cadastro/botoes-cadastro.module';
import { GrupoModule } from 'src/app/utilities/grupo/grupo.module';
import { LabelModule } from 'src/app/utilities/label/label.module';
import { TableModule } from 'primeng/table';
import { CheckboxModule } from 'primeng/checkbox';
import { TagModule } from 'primeng/tag';
import { DropdownModule } from 'primeng/dropdown';
import { BadgeModule } from 'primeng/badge';



@NgModule({
  declarations: [
    EstimativaAcessoComponent
  ],
  imports: [
    CommonModule,
    BaseListaModule,
    FormsModule,
    CheckboxModule,
    BrowserModule,
    ConfirmDialogModule,
    GrupoModule,
    BotoesCadastroModule,
    DropdownModule,
    TooltipModule,
    AccordionModule,
    LabelModule,
    ProgressBarModule,
    BadgeModule,
    RippleModule,
    ToastModule,
    ScrollPanelModule,
    TableModule,
  ]
})
export class EstimativaAcessoModule { }
