import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AdminUsuarioModule } from 'src/app/pages/admin-usuario/admin-usuario.module';
import { ClassificacaoModule } from 'src/app/pages/classificacao/classificacao.module';
import { ParceiroModule } from 'src/app/pages/parceiro/parceiro.module';
import { ProdutoModule } from 'src/app/pages/produto/produto.module';
import { CadastroDialogComponent } from './cadastro-dialog.component';





@NgModule({
  declarations: [
    CadastroDialogComponent
  ],
  imports: [BrowserModule,
    CommonModule,
    FormsModule,
    AdminUsuarioModule,
    ParceiroModule,
    ProdutoModule,
    ClassificacaoModule
  ], 
  exports:[
    CadastroDialogComponent
  ],
})
export class CadastroDialogModule { }
