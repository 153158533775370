<p-messages severity="warn"
  *ngIf="_showCustom && msg">
  <ng-template pTemplate>
    <div class="p-m-2">{{msg['msg']}}</div>
    <a target="_blank"
      [href]="msg['ref']">{{msg['refMsg']}}</a>
  </ng-template>
</p-messages>
<p-toast></p-toast>
<div
  id="login-component"
  style="overflow:hidden;">
  <div class="p-card"
    style="width: 100%">
    <div class="p-d-flex"
      style="height: 100vh; text-align-last: center;flex-flow: column;place-content: center;">
      <div class="main">
        <div>
          <div class="p-d-none p-d-md-block">
            <div>
              <div
                style="margin-bottom: 10vh; display: flex;justify-content: center;"
                *ngIf="theme == 'deeporange'">
                <img
                  src="assets/img/habilbylimber.svg"
                  id="img"
                  style="max-width: 13vw; max-height: 12vh; object-fit: scale-down;"
                  alt="habil-logo" />
              </div>
              <div
                style="margin-bottom: 10vh; display: flex;justify-content: center;"
                *ngIf="theme != 'deeporange'">
                <img
                  src="assets/img/habilbylimber.svg"
                  id="img"
                  style="max-width: 13vw; max-height: 12vh; object-fit: scale-down;"
                  alt="habil-logo" />
              </div>
            </div>
          </div>
          <div class="p-d-block p-d-md-none">
            <div>
              <div
                style="margin-bottom: 10vh;display: flex;justify-content: center;"
                *ngIf="theme == 'deeporange'">
                <img
                  src="assets/img/H10Logotipo.png"
                  id="img"
                  width="300"
                  style="width: 50vw;"
                  alt="ecuador-layout" />
              </div>
              <div
                style="margin-bottom: 10vh;display: flex;justify-content: center;"
                *ngIf="theme != 'deeporange'">
                <img
                  src="assets/img/H10Dark.png"
                  id="img"
                  width="300"
                  style="width: 50vw;"
                  alt="ecuador-layout" />
              </div>
            </div>
          </div>
          <br>
          <div>
            <div>
              <div class="form-login">
                <!-- <div>
                  <span class="p-input-icon-left">
                    <i class="pi pi-id-card"></i>
                    <input type="text"
                      pInputText
                      style="height:32px;"
                      [(ngModel)]="cnpj"
                      placeholder="CPF/CNPJ"
                      [name]="cnpj"
                      (keyup.enter)="doLogin2()" />
                  </span>
                </div> -->
                <div>
                  <span class="p-input-icon-left">
                    <i class="pi pi-user"></i>
                    <input type="text"
                      pInputText
                      placeholder="Usuário"
                      id="usuario"
                      [(ngModel)]="login"
                      style="text-transform: uppercase;height:32px;"
                      (keyup.enter)="entrar()">
                  </span>
                </div>
                <div>
                  <span class="p-input-icon-left">
                    <i class="pi pi-key"></i>
                    <div>
                      <div>
                        <input [type]="_type"
                          pInputText
                          style="background: none;height:32px;width: 100% + 6px;"
                          id="password"
                          [(ngModel)]="password"
                          placeholder="SENHA"
                          (keyup.enter)="entrar()">
                      </div>
                      <div>
                        <button pButton
                          pRipple
                          type="button"
                          [icon]="_icon"
                          (click)="changeVisibility()"
                          class="p-button-rounded p-button-text"></button>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div *ngIf="_loading">
          <p-progressBar mode="indeterminate"
            [style]="{'height': '6px', 'width': '211px','display': 'inline-block'}">
          </p-progressBar>
        </div>
      </div>
      <div class="btn-login" style="align-self: center">
        <div
          class="p-col-12">
          <br>
          <p-button
            [style]="{'width': '214.5px'}"
            class="ui-button-secondary entrar-btn"
            [disabled]="_loading"
            ks
            label="Entrar"
            (click)="entrar()">
          </p-button>
        </div>
        <div class="p-col-12" style="margin-top: 0 !important;padding-top: 0;">
          <!-- <a pButton
            style="text-transform: none;"
            class="p-button-rounded button-experimente p-button-text"
            (click)="esqueciSenha()">
            Esqueceu sua senha?
          </a> -->
        </div>

        <br>


      </div>
    </div>
  </div>
  <div class="info-login p-d-none p-d-md-block"
    style="width:300vw;">
    <div class="inicio">
      <div
        class="intro-content cc-homepage p-d-flex p-flex-column"
        style=" margin-left: 20px;margin-right: 20px;">
        <div class="div-block-3 w-clearfix">
          <!-- <h1 class="h1">Experimente os benefícios
            de ter
            <span
              style="color: white;font-weight: 600;">mais
              controle sobre o seu negócio</span>
          </h1> -->
          <h1 class="h1">
            <span
              style="color: white;font-weight: 600;">Central do Cliente</span>
          </h1>
        </div>
        <div class="div-block-7">
          <div class="paragrafo-internas branco"
            style="font-size: 1.5em; max-width: 75rem;text-align: center;margin-top: 1.5em;">
            <!-- <p
              style="margin-top: 1.5em;font-weight: 200;margin-bottom: 4px;">
              Não tem uma conta?</p> -->
              <!-- <p
              style="margin-top: 1.5em;font-weight: 200;margin-bottom: 4px;">
              Uso interno</p> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
