import { SelectItem } from 'primeng/api/selectitem';

export class EUf {
    public static ufs: SelectItem[] = [
        { label: '-', value: 'NENHUM' },
        { label: 'Acre', value: 'AC' },
        { label: 'Alagoas', value: 'AL' },
        { label: 'Amazonas', value: 'AM' },
        { label: 'Amapá', value: 'AP' },
        { label: 'Bahia', value: 'BA' },
        { label: 'Ceará', value: 'CE' },
        { label: 'Distrito Federal', value: 'DF' },
        { label: 'Espírito Santo', value: 'ES' },
        { label: 'Goiás', value: 'GO' },
        { label: 'Maranhão', value: 'MA' },
        { label: 'Minas Gerais', value: 'MG' },
        { label: 'Mato Grosso do Sul', value: 'MS' },
        { label: 'Mato Grosso', value: 'MT' },
        { label: 'Pará', value: 'PA' },
        { label: 'Paraíba', value: 'PB' },
        { label: 'Pernambuco', value: 'PE' },
        { label: 'Piauí', value: 'PI' },
        { label: 'Paraná', value: 'PR' },
        { label: 'Rio de Janeiro', value: 'RJ' },
        { label: 'Rio Grande do Norte', value: 'RN' },
        { label: 'Rondônia', value: 'RO' },
        { label: 'Roraima', value: 'RR' },
        { label: 'Rio Grande do Sul', value: 'RS' },
        { label: 'Santa Catarina', value: 'SC' },
        { label: 'Sergipe', value: 'SE' },
        { label: 'São Paulo', value: 'SP' },
        { label: 'Tocantins', value: 'TO' },
        { label: 'Exterior', value: 'EX' }
  ]

  public static ufsSigla : SelectItem[] = [
    { label: '-', value: 'NENHUM' },
    { label: 'AC', value: 'AC' },
    { label: 'AL', value: 'AL' },
    { label: 'AM', value: 'AM' },
    { label: 'AP', value: 'AP' },
    { label: 'BA', value: 'BA' },
    { label: 'CE', value: 'CE' },
    { label: 'DF', value: 'DF' },
    { label: 'ES', value: 'ES' },
    { label: 'GO', value: 'GO' },
    { label: 'MA', value: 'MA' },
    { label: 'MG', value: 'MG' },
    { label: 'MS', value: 'MS' },
    { label: 'MT', value: 'MT' },
    { label: 'PA', value: 'PA' },
    { label: 'PB', value: 'PB' },
    { label: 'PE', value: 'PE' },
    { label: 'PI', value: 'PI' },
    { label: 'PR', value: 'PR' },
    { label: 'RJ', value: 'RJ' },
    { label: 'RN', value: 'RN' },
    { label: 'RO', value: 'RO' },
    { label: 'RR', value: 'RR' },
    { label: 'RS', value: 'RS' },
    { label: 'SC', value: 'SC' },
    { label: 'SE', value: 'SE' },
    { label: 'SP', value: 'SP' },
    { label: 'TO', value: 'TO' },
    { label: 'EX', value: 'EX' }
  ]
}
