import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ConsultaClienteDTO, Licenca, Registro, RegistroDadosAlteracoes, RegistroDadosPrimeiroAcesso, RegistroLicenca } from '../models/registro';
import { FormatUtils } from '../utilities/util/format-utils';
import { HttpRequest, UriBuilder } from '../utilities/util/http-request';
import { SearchParams } from '../utilities/util/search/search-params';
import { AbstractService } from './abstract.service';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class RegistroService extends AbstractService<Registro> {

  titulo: string = 'Registro';
  endpoint(): string {
    return "admin/registro";
  }
  rotina(): string {
    return "REGISTRO";
  }
  colunas(): any[] {
    return [
      { header: "Código", field: "id" },
      { header: "CNPJ", field: "cnpj" },
      { header: "Nome", field: "nome" },
      { header: "E-mail", field: "email" },
      { header: "Responsável", field: "responsavel" },
    ];
  }
  novo(selection?: any) {
    AbstractService.readonly = false;
    this.router.navigate(['clientes/registro/cadastro']);
  }
  abrir(obj: Registro) {
    AbstractService.readonly = true;
    this.router.navigate(['clientes/registro/cadastro'], { queryParams: { id: obj.id } });
  }
  edit(obj: Registro) {
    AbstractService.readonly = false;
    this.router.navigate(['clientes/registro/cadastro'], { queryParams: { id: obj.id } });
  }
  routeLista() {
    return '/clientes/registro';
  }

  getDadosPrimeiroAcesso(cnpj: string): Observable<RegistroDadosPrimeiroAcesso[]> {
    const url = this.baseUrl() + "/dados-primeiro-acesso?cnpj=" + cnpj;

    const h = new HttpHeaders({
      Accept: "application/json; charset=utf-8",
      "X-Auth-Token": AbstractService.token(),
    });
    return this.http.get<RegistroDadosPrimeiroAcesso[]>(url, { headers: h });
  }

  findByCnpj(cnpj: string): Observable<Registro[]> {
    return super.findAll(0, 0, "", undefined, [new SearchParams("cnpj", [cnpj])]);
  }

  getDadosAlteracoes(cnpj: string): Observable<RegistroDadosAlteracoes[]> {
    const url = this.baseUrl() + "/dados-alteracoes-informacoes?cnpj=" + cnpj;

    const h = new HttpHeaders({
      Accept: "application/json; charset=utf-8",
      "X-Auth-Token": AbstractService.token(),
    });
    return this.http.get<RegistroDadosAlteracoes[]>(url, { headers: h });
  }

  fazConsulta(tipoData: number, dataInicial, dataFinal, tipoLicenca, idClassificacao): Observable<ConsultaClienteDTO[]> {
    if (!idClassificacao) idClassificacao = 0;
    const url = this.baseUrl() +
      `/consulta?tipo-data=${tipoData}&data-inicial=${dataInicial}&data-final=${dataFinal}&tipo-licenca=${tipoLicenca}&id-classificacao=${idClassificacao}`;

    const h = new HttpHeaders({
      Accept: "application/json; charset=utf-8",
      "X-Auth-Token": AbstractService.token(),
    });
    return this.http.get<ConsultaClienteDTO[]>(url, { headers: h });
  }

  fazConsultaCnpjReq(cnpj: string): Observable<ConsultaClienteDTO> {
    const url = this.baseUrl() +
      `/consulta-cnpj?cnpj=${cnpj}`;

    const h = new HttpHeaders({
      Accept: "application/json; charset=utf-8",
      "X-Auth-Token": AbstractService.token(),
    });
    return this.http.get<ConsultaClienteDTO>(url, { headers: h });
  }

  fazConsultaCnpj(cnpj: string) {
    return new Promise<ConsultaClienteDTO>((resolve, reject) => {
      this.fazConsultaCnpjReq(cnpj).subscribe({
        next: (dados) => {
          if (!dados) {
            dados = { id: 0, cnpj, nome: "", email: "", classificacao: "", vencimento: new Date(0), apagado: false, cadastro: new Date(0), tipo: "Outros" };
          }
          resolve(dados);
        },
        error: (err) => {
          reject(err);
        }
      })
    })

  }

  getRegistroLicenca(id: number, software: string): Observable<HttpResponse<any>> {
    const url = this.baseUrl() + "/registro-licenca";
    const params = new URLSearchParams();
    params.append("id-registro", id.toString());
    params.append("software", software);

    const h = new HttpHeaders({
      Accept: "application/json; charset=utf-8",
      "Content-Type": "application/x-www-form-urlencoded",
      "X-Auth-Token": AbstractService.token(),
    });
    return this.http.post<RegistroLicenca>(url, params.toString(), { headers: h, observe: 'response' });
  }

  zeraToken(cnpj: string, software: string): Observable<HttpResponse<any>> {
    const url = this.baseUrl() + "/zera-token";
    const params = new URLSearchParams();
    params.append("cnpj", cnpj);
    params.append("software", software);

    const h = new HttpHeaders({
      Accept: "application/json; charset=utf-8",
      "Content-Type": "application/x-www-form-urlencoded",
      "X-Auth-Token": AbstractService.token(),
    });
    return this.http.post<any>(url, params.toString(), { headers: h, observe: 'response' });
  }

  enviaChavePorEmail(cnpj: string, software: string): Observable<HttpResponse<any>> {
    const url = this.baseUrl() + "/send-key";
    const params = new URLSearchParams();
    params.append("cnpj", cnpj);
    params.append("software", software);

    const h = new HttpHeaders({
      Accept: "application/json; charset=utf-8",
      "Content-Type": "application/x-www-form-urlencoded",
      "X-Auth-Token": AbstractService.token(),
    });
    return this.http.post<any>(url, params.toString(), { headers: h, observe: 'response' });
  }

  alteraRegistroLicenca(id: number, software: string, registroLicenca: RegistroLicenca): Observable<Licenca> {
    return new HttpRequest<Licenca>(this.http)
      .endpoint(this.endpoint())
      .uri(
        new UriBuilder()
          .subpath("registro-licenca/licenca")
          .build()
      )
      .param("id-registro", id.toString())
      .param("software", software)
      .param("registro-licenca", JSON.stringify(registroLicenca))
      .doPost();
  }

  saveRegistroLicenca(id: number, licenca: Licenca, registroLicenca: any): Observable<HttpResponse<any>> {
    const url = this.baseUrl() + "/registro-licenca/save";
    const params = new URLSearchParams();
    params.append("id-registro", id.toString());
    params.append("licenca", FormatUtils.toJson(licenca));
    params.append("registro-licenca", FormatUtils.toJson(registroLicenca));

    const h = new HttpHeaders({
      Accept: "application/json; charset=utf-8",
      "Content-Type": "application/x-www-form-urlencoded",
      "X-Auth-Token": AbstractService.token(),
    });
    return this.http.put<any>(url, params.toString(), { headers: h, observe: 'response' });
  }

  constructor(
    http: HttpClient,
    private router: Router,
    loginService: LoginService
  ) {
    super(http, loginService);
  }
}
