<div class="p-fluid p-formgrid p-grid">
  <div class="p-field p-col-12 p-col-align-end">
    <p-checkbox
      [binary]="true"
      (onBlur)="blur()"
      [(ngModel)]="_value.liberar"
      label="Deseja liberar suporte para esse cliente?"
    ></p-checkbox>
  </div>
  <div class="p-field p-col-1">
    <habil-label name="lbid" label="Código">
      <input
        pInputText
        [(ngModel)]="_value.atendimento.id"
        [readonly]="true"
        [disabled]="!_value.liberar"
        (onBlur)="blur()"
      />
    </habil-label>
  </div>
  <div class="p-field p-col-3">
    <habil-label name="lbcnpj" label="CPF/CNPJ">
      <input
        pInputText
        [(ngModel)]="_value.atendimento.cpfCnpj"
        [readonly]="true"
        [disabled]="!_value.liberar"
        (onBlur)="blur()"
      />
    </habil-label>
  </div>
  <div class="p-field p-col-4">
    <habil-label name="lbnome" label="Nome">
      <input
        pInputText
        [(ngModel)]="_value.atendimento.nome"
        [disabled]="!_value.liberar"
        (onBlur)="blur()"
      />
    </habil-label>
  </div>
  <div class="p-field p-col-4">
    <habil-label name="lbplanosuporte" label="Plano de Suporte">
      <habil-autocomplete-edit
        (onBlur)="blur()"
        [service]="planoSuporteService"
        [(ngModel)]="_value.atendimento.planoSuporte"
        name="searchPlano"
        field="descricao"
        dataKey="id"
        [disabled]="!_value.liberar"
      >
      </habil-autocomplete-edit>
    </habil-label>
  </div>
  <div class="p-field p-col-1">
    <habil-label name="lbinicio" label="Iniciar em">
      <habil-date-edit
        (onBlur)="blur()"
        [(ngModel)]="_value.atendimento.inicio"
        [disabled]="!_value.liberar"
      ></habil-date-edit>
    </habil-label>
  </div>
  <div class="p-field p-col-1">
    <habil-label name="vctooriginal" label="Vcto original">
      <habil-date-edit
        [(ngModel)]="_vctoAtual"
        [disabled]="true"
      ></habil-date-edit>
    </habil-label>
  </div>
  <div class="p-field p-col-1">
    <habil-label name="lbfim" label="Atender até">
      <habil-date-edit
        (onBlur)="blur()"
        [(ngModel)]="_value.atendimento.fim"
        [disabled]="!_value.liberar"
      ></habil-date-edit>
    </habil-label>
  </div>
  <div class="p-field p-col-1 p-col-align-end">
    <button pButton label="+15 dias" [disabled]="!_value.liberar" (click)="somaData(false)" ></button>
  </div>
  <div class="p-field p-col-1 p-col-align-end">
    <button pButton label="+30 dias" [disabled]="!_value.liberar" (click)="somaData(true)" ></button>
  </div>
  <div class="p-field p-col-3">
    <habil-label name="lbrazao" label="Razão Social">
      <input
        pInputText
        [(ngModel)]="_value.atendimento.razaoSocial"
        (onBlur)="blur()"
        [disabled]="!_value.liberar"
      />
    </habil-label>
  </div>
  <div class="p-field p-col-2">
    <habil-label name="lbemail" label="E-mail">
      <input
        pInputText
        [(ngModel)]="_value.atendimento.email"
        (onBlur)="blur()"
        [disabled]="!_value.liberar"
      />
    </habil-label>
  </div>
  <div class="p-field p-col-2">
    <habil-label name="lbfone" label="Telefone">
      <input
        pInputText
        [(ngModel)]="_value.atendimento.telefone"
        (onBlur)="blur()"
        [disabled]="!_value.liberar"
      />
    </habil-label>
  </div>
</div>
