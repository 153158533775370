<habil-grupo>
    <div class="p-fluid p-grid">
        <div class="p-col">
            <habil-label name="vcto" label="Tipo da Data">
                <p-dropdown name="vcto" [options]="tiposData" optionLabel="label" optionValue="value" [(ngModel)]="tipoData" [showClear]="false" [filter]="false"></p-dropdown>
            </habil-label>
        </div>
        <div class="p-col">
            <habil-label name="dtInicial" label="Data Inical">
                <habil-date-edit name="dtInicial" [(ngModel)]="dataInicial"></habil-date-edit>
            </habil-label>
        </div>
        <div class="p-col">
            <habil-label name="dtFinal" label="Data Final">
                <habil-date-edit name="dtFinal" [(ngModel)]="dataFinal"></habil-date-edit>
            </habil-label>
        </div>
        <!-- <div class="p-col">
            <habil-label name="licenca" label="Tipo da Licença">
                <p-dropdown name="licenca" [options]="tiposLicenca" optionLabel="label" optionValue="value" [(ngModel)]="tipoLicenca" [showClear]="false" [filter]="false"></p-dropdown>
            </habil-label>
        </div> -->
        <div class="p-col">
            <habil-label name="searchClass" label="Classificação">
                <habil-autocomplete-edit
                    [service]="classificacaoService"
                    [(ngModel)]="classificacao"
                    name="searchClass"
                    field="descricao"
                    dataKey="id">
                </habil-autocomplete-edit>
            </habil-label>
        </div>
        <div class="p-col p-col-align-end">
            <p-button label="Visualizar" [loading]="loading" (onClick)="busca()"></p-button>
        </div>
        <div class="p-col p-col-align-end">
            <p-button label="Exportar" (onClick)="dt.exportCSV()"></p-button>
        </div>
        <div class="p-col-12">
            <p>{{qtdeRegistros}}</p>
        </div>
        <div class="p-col-12">
            <p-table #dt [value]="list" [columns]="exportColumns" [loading]="loading" csvSeparator=";">
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="id">Código<p-sortIcon field="id"></p-sortIcon></th>
                        <th pSortableColumn="cnpj">CNPJ<p-sortIcon field="cnpj"></p-sortIcon></th>
                        <th pSortableColumn="nome">Nome<p-sortIcon field="nome"></p-sortIcon></th>
                        <th pSortableColumn="email">E-mail<p-sortIcon field="email"></p-sortIcon></th>
                        <th pSortableColumn="classificacao">Classificação<p-sortIcon field="classificacao"></p-sortIcon></th>
                        <th pSortableColumn="vencimento">Vencimento<p-sortIcon field="vencimento"></p-sortIcon></th>
                        <th pSortableColumn="cadastro">Cadastro<p-sortIcon field="cadastro"></p-sortIcon></th>
                        <th pSortableColumn="apagado" *ngIf="tipoData==1">Apagado<p-sortIcon field="apagado"></p-sortIcon></th>
                        <th pSortableColumn="tipo">Tipo da Licença<p-sortIcon field="tipo"></p-sortIcon></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-cliente>
                    <tr>
                        <td>{{cliente.id}}</td>
                        <td>{{cliente.cnpj}}</td>
                        <td>{{cliente.nome}}</td>
                        <td>{{cliente.email}}</td>
                        <td>{{cliente.classificacao}}</td>
                        <td>{{cliente.vencimento | date: 'dd/MM/yyy'}}</td>
                        <td>{{cliente.cadastro | date: 'dd/MM/yyy'}}</td>
                        <td *ngIf="tipoData==1">{{cliente.apagado ? "Sim" : "Não" }}</td>
                        <td>{{cliente.tipo}}</td>
    
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</habil-grupo>