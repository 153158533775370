import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Parceiro } from '../models/admin-usuario';
import { AbstractService } from './abstract.service';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class ParceiroService extends AbstractService<Parceiro> {

  titulo: string = 'Parceiro';
  endpoint(): string {
    return "admin/parceiros";
  }
  rotina(): string {
    return "PARCEIROS";
  }
  colunas(): any[] {
    return [
      { header: "Código", field: "id" },
      { header: "Nome", field: "nome" },
    ];
  }
  novo(selection?: any) {
    AbstractService.readonly = false;
    this.router.navigate(['adm/parceiros/cadastro']);
  }
  abrir(obj: Parceiro) {
    AbstractService.readonly = true;
    this.router.navigate(['adm/parceiros/cadastro'], { queryParams: { id: obj.id } });
  }
  edit(obj: Parceiro) {
    AbstractService.readonly = false;
    this.router.navigate(['adm/parceiros/cadastro'], { queryParams: { id: obj.id } });
  }
  routeLista() {
    return '/adm/parceiros';
  }

  constructor(
    http: HttpClient,
    private router: Router,
    loginService: LoginService
  ) {
    super(http, loginService);
  }
}
