<div class="p-fluid p-formgrid p-grid"
     style="max-height: 35px"
     [ngClass]="_insideButton ? 'inside-button' : ''">
  <div class="p-col">
    <p-autoComplete [(ngModel)]="value"
                    id='autoComplete'
                    autofocus
                    [autoHighlight]="true"
                    [name]="_name"
                    [field]="_field"
                    [suggestions]="_suggestions"
                    [showEmptyMessage]="_showEmpty"
                    [readonly]="_readonly"
                    [disabled]="_disabled"
                    [minLength]="1"
                    [dropdown]="_enableDropButton"
                    [style]="_style"
                    [dropdownIcon]="_dropdownButtonIcon"
                    dropdownMode="current"
                    [placeholder]="_placeholder"
                    [emptyMessage]="_empty"
                    appendTo="body"
                    styleClass="padding-btn"
                    dataKey="id"
                    (onClear)="onClear()"
                    (onSelect)="onSelect($event)"
                    (onBlur)="onBlur()"
                    (completeMethod)="search($event)"
                    [class]="_class">
      <ng-template pTemplate="item"
                   let-item>
        <div class="p-grid p-justify-between" [title]="item[_field]">
          <ng-container *ngFor="let f of _fields">
            <ng-container>
              <div style="overflow: hidden;text-overflow: ellipsis;-o-text-overflow: ellipsis;white-space: nowrap;width: 100%;"
                   *ngIf="f != 'id' && f != 'dataCadastro' &&
                    f != 'razaoSocial' && f != 'codigoArvore' && !(f == 'descricao' && _service.rotina() == 'CONTA_CAIXA') &&
                    f != 'codigoContabil' &&
                     f != 'codigo' &&
                     (f.toLowerCase().indexOf( 'cpf' ) < 0) &&
                      ( f.toLowerCase().indexOf( 'cnpj' ) < 0)  "
                   class="p-col">
                {{item[f]}}
              </div>
<!--              <div *ngIf="f == 'id' && !_hideId"-->
<!--                   class="p-col-1">-->
<!--                {{item[f]}}-->
<!--              </div>-->
              <div *ngIf="f == 'codigo'"
                   class="p-col-2">
                {{item[f]}}
              </div>
              <div *ngIf="f == 'descricao' && _service.rotina() == 'CONTA_CAIXA'"
                   class="p-col-2">
                {{ item[codigoContaCaixa]}} - {{item[f]}}
              </div>
              <div style="overflow: hidden;text-overflow: ellipsis;-o-text-overflow: ellipsis;white-space: nowrap;width: 100%;"
                   *ngIf="f == 'infoPessoa.nomePessoa'"
                   class="p-col">
                {{item['infoPessoa']?.nomePessoa}}
              </div>
              <div
                *ngIf="f == 'dataCadastro'"
                class="p-col">
                {{item['dataCadastro'] | date: 'dd/MM/yyyy'}}
              </div>
              <div
                *ngIf="f.toLowerCase().indexOf( 'cpf' ) >= 0 || f.toLowerCase().indexOf( 'cnpj' ) >= 0 "
                class="p-col" style="max-width: 150px">
                {{formatCpfCnpj( item[f] )}}
              </div>
            </ng-container>
          </ng-container>
        </div>
      </ng-template>
    </p-autoComplete>
  </div>
  <div class=" p-col-fixed p-col-align-top"
       style="width: 35px;"
       *ngIf="_showButton && (value != undefined && value != null)">
    <button pButton
            pRipple
            icon="pi pi-pencil"
            label=""
            tabindex="-1"
            [title]="getId()"
            style="height: 100%;"
            [disabled]="_readonly || _disabled"
            (click)="onEdit()">
    </button>
  </div>
  <div class="p-col-fixed p-col-align-top"
       style="width: 35px;padding-left: 1px; "
       *ngIf="_showButton && (value == undefined || value == null)">
    <button pButton
            pRipple
            title="Adicionar Novo"
            [tooltipPosition]="_tooltipBtnPosition"
            icon="pi pi-plus"
            style="height: 100%;"
            tabindex="-1"
            class="p-autocomplete-dropdown p-ripple p-button p-component p-button-icon-only ng-star-inserted"
            label=""
            [disabled]="_readonly || _disabled"
            (click)="onEdit()">
    </button>
  </div>
</div>
