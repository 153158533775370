<p-confirmDialog header="Atenção" icon="pi pi-exclamation-triangle">
</p-confirmDialog>
<p-toast></p-toast>
<div class="p-field p-col-12 p-md-12 p-lg-12" *ngIf="_loading">
  <p-progressBar
    mode="indeterminate"
    [style]="{'height': '6px'}"
  ></p-progressBar>
</div>
<habil-grupo>
  <p-scrollPanel [style]="{width: '100%', height: getScrollHeight()}">
    <p-accordion>
      <p-accordionTab header="Dados Básicos" [selected]="true">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-lg-8">
            <habil-label name="txtLogin" label="Login">
              <input
                style="text-transform: uppercase;"
                type="text"
                [readonly]="_readonly"
                name="txtLogin"
                pInputText
                [(ngModel)]="obj.login"
              />
            </habil-label>
          </div>
          <div class="p-field p-col-12 p-lg-2 p-col-align-center">
            <br />
            <p-checkbox
              [readonly]="_readonly"
              [(ngModel)]="obj.inativo"
              label="Inativo"
              [binary]="true"
            ></p-checkbox>
          </div>
          <div class="p-field p-col-12 p-lg-2 p-col-align-center">
            <br />
            <p-checkbox
              [readonly]="_readonly"
              [(ngModel)]="obj.sysAdmin"
              label="É Administrador"
              [binary]="true"
            ></p-checkbox>
          </div>
          <div class="p-field p-col-12 p-lg-6">
            <habil-label name="txtNome" label="Nome">
              <input
                [readonly]="_readonly"
                type="text"
                name="txtNome"
                pInputText
                [(ngModel)]="obj.nome"
              />
            </habil-label>
          </div>
          <div class="p-field p-col-12 p-lg-6">
            <habil-label name="txtEmail" label="E-mail">
              <input
                [readonly]="_readonly"
                type="text"
                name="txtEmail"
                pInputText
                [(ngModel)]="obj.email"
              />
            </habil-label>
          </div>
          <div class="p-field p-col-12 p-lg-6">
            <habil-label name="pwdSenha" label="Senha">
              <p-password
                [disabled]="_readonly"
                name="pwdSenha"
                [(ngModel)]="obj.senha"
                [toggleMask]="true"
                [feedback]="false"
              ></p-password>
            </habil-label>
          </div>
          <div class="p-field p-col-12 p-lg-6">
            <habil-label name="pwdSenhaConfirmar" label="Confirmar Senha">
              <p-password
                [disabled]="_readonly"
                name="pwdSenhaConfirmar"
                [(ngModel)]="confirmarSenha"
                [toggleMask]="true"
                [feedback]="false"
              ></p-password>
            </habil-label>
          </div>
          <div class="p-field p-col-12">
            <habil-label name="txtLembreteSenha" label="Lembrete de Senha">
              <input
                [readonly]="_readonly"
                type="text"
                name="txtLembreteSenha"
                pInputText
                [(ngModel)]="obj.lembreteSenha"
              />
            </habil-label>
          </div>
          <div class="p-field p-col-12">
            <habil-label
              name="searchParceiro"
              label="Somente mostrar clientes do parceiro"
            >
              <habil-autocomplete-edit
                [service]="parceiroService"
                [(ngModel)]="obj.parceiro"
                [readonly]="_readonly"
                name="searchParceiro"
                field="nome"
                dataKey="id"
              >
              </habil-autocomplete-edit>
            </habil-label>
          </div>
          <div class="p-field p-col-12">
            <habil-label name="cbGrupoAcesso" label="Grupo de Acesso">
              <p-dropdown
                [readonly]="_readonly"
                [disabled]="obj.sysAdmin"
                appendTo="body"
                [options]="gruposAcesso"
                [(ngModel)]="obj.grupoAcesso"
                optionLabel="label"
                optionValue="value"
              ></p-dropdown>
            </habil-label>
          </div>
        </div>
      </p-accordionTab>
    </p-accordion>
    <br>
    <p-accordion>
      <p-accordionTab header="Central de Relatórios" [selected]="true">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12">
            <p-checkbox
              [readonly]="_readonly"
              [(ngModel)]="acessoRelatorios.ver"
              label="Pode Visualizar"
              [binary]="true"
            ></p-checkbox>
          </div>
          <div class="p-field p-col-12">
            <p-checkbox
              [readonly]="_readonly"
              [(ngModel)]="acessoRelatorios.editar"
              label="Pode Editar"
              [binary]="true"
            ></p-checkbox>
          </div>
          <div class="p-field p-col-12">
            <p-checkbox
              [readonly]="_readonly"
              [(ngModel)]="acessoRelatorios.adicionar"
              label="Pode Adicionar"
              [binary]="true"
            ></p-checkbox>
          </div>
          <div class="p-field p-col-12">
            <p-checkbox
              [readonly]="_readonly"
              [(ngModel)]="acessoRelatorios.padrao"
              label="Acesso aos relatóros padrão"
              [binary]="true"
            ></p-checkbox>
          </div>
          <div class="p-field p-col-12">
            <p-checkbox
              [readonly]="_readonly"
              [(ngModel)]="acessoRelatorios.excluir"
              label="Pode excluir"
              [binary]="true"
            ></p-checkbox>
          </div>
        </div>
      </p-accordionTab>
    </p-accordion>
  </p-scrollPanel>
</habil-grupo>
<br />
<habil-botoes-cadastro
  (onError)="onErrorHandled()"
  [obj]="getObject.bind(this)"
  [dialog]="_isDialog"
  (onSaved)="onSave($event)"
  (onClosed)="onCancel()"
  [service]="service"
  [readonly]="_readonly"
  [validate]="doValidate.bind(this)"
  [doBeforeSave]="doBeforeSave.bind(this)"
  (onSaveAndNew)="onSaveAndNew($event)"
>
</habil-botoes-cadastro>
