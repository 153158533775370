<p-toast></p-toast>
<p-confirmDialog header="Atenção"
    icon="pi pi-exclamation-triangle"></p-confirmDialog>
<habil-grupo [dif]="0">
    <div class="p-grid p-fluid">
        <div class="sem-margem p-col-4 p-md-2">
            <button pButton
                pRipple
                style="width: 100%;"
                label="Novo"
                id="header-new"
                icon="pi pi-plus"
                title="Ctrl + Insert"
                class="p-button-secondary"
                (click)="novo()">
            </button>
        </div>
        <div class="p-col-4 p-lg-2">
            <p-dropdown [options]="tipos"
                [(ngModel)]="tipo"
                styleClass="combo-filtro-software"
                (onChange)="recarregarPesquisa()"
                optionValue="value"
                optionLabel="label"></p-dropdown>
        </div>
        <div class="p-col"></div>
        <div class="sem-margem p-col-10 p-md-9 p-lg-9 p-xl-10">
            <input pInputText
                class="p-inputtext-lg"
                [(ngModel)]="search"
                (input)="reset()"
                name="pesquisa"
                placeholder="Digite o valor para pesquisa..."
                [readonly]="false">
        </div>
        <div class="sem-margem p-col-12 p-md-3 p-lg-3 p-xl-2 p-col-align-center p-d-none p-d-md-block">
            <button pButton
                pRippler
                icon="pi pi-search"
                label="Pesquisar por código"
                [disabled]="_loading"
                (click)="searchId()"
                sel>
            </button>
        </div>
        <div class="sem-margem p-col p-col-align-center p-d-block p-d-md-none">
            <button pButton
                pRippler
                icon="pi pi-search"
                label=""
                (onClick)="searchId()"
                sel>
            </button>
        </div>
        <div class="sem-margem p-col-12">

            <p-table [scrollHeight]="getScrollHeight()"
                [columns]="cols"
                [value]="objs"
                [loading]="_loading"
                dataKey="id"
                selectionMode="none"
                responsiveLayout="scroll"
                [(selection)]="selection">
                <ng-template pTemplate="header"
                    let-columns>
                    <tr>
                        <th [pSortableColumn]="col.field"
                            *ngFor="let col of columns">
                            {{col.header}}<p-sortIcon [field]="col.field"></p-sortIcon>
                        </th>
                        <th style="width: 120px; text-align-last: end; padding: 0;">
                            <button pButton
                                pRipple
                                type="button"
                                icon="pi pi-refresh"
                                title="Atualizar Lista"
                                (click)="recarregarPesquisa()"
                                class="p-button-rounded p-button-text">
                            </button>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body"
                    let-rowData
                    let-columns="columns">
                    <tr [pSelectableRow]="rowData"
                        [ngClass]="{'hover-actions' : true}"
                        [style.height]="'41.1px'"
                        (dblclick)="acaoPadrao(rowData)">
                        <td *ngFor="let col of columns">
                            <span class="p-column-title">{{col.header}}</span>
                            <ng-container [ngSwitch]="col.type">
                                <ng-container *ngSwitchCase="'date'">
                                    {{getFieldValue(rowData, col) | date: 'dd/MM/yyyy'}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'datetime'">
                                    {{getFieldValue(rowData, col) | date: 'dd/MM/yyyy HH:mm'}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'number'">
                                    {{getFieldValue(rowData, col) | number: '1.2-5'}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'ternary'">
                                    {{getTernaryFieldValue(rowData, col)}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'enum'">
                                    {{col['handler'](getFieldValue(rowData, col))}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'checkbox'">
                                    <p-checkbox [disabled]="true"
                                        [(ngModel)]="rowData[col.field]"
                                        binary="true"
                                        [style.margin-left]="'21%'"></p-checkbox>
                                </ng-container>
                                <ng-container *ngSwitchCase="'cpfCnpj'">
                                    {{formatCpfCnpj(getFieldValue( rowData, col))}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'situacao'">
                                    {{(rowData.cancelado == 'true' || rowData.cancelado == true ?
                                    'Cancelado' : col['handler'](getFieldValue(rowData, col)))}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'tipoOrcamento'">
                                    {{(rowData.tipoOrcamento == 'ORCAMENTO' ? 'Orçamento' : 'Condicional')}}
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    {{getFieldValue( rowData, col)}}
                                </ng-container>
                            </ng-container>
                        </td>
                        <td style="width: 120px; padding: 0;">
                            <div class="p-d-flex p-flex-row action-buttons">
                                <!-- <p-slideMenu
                        #menuAcoes
                        [popup]="true"
                        [viewportHeight]="'180px'"
                        [menuWidth]="170"
                        [model]="items"
                        appendTo="body"
                        backLabel="Voltar"></p-slideMenu> -->
                                <p-slideMenu #menuAcoes
                                    [model]="subActions"
                                    appendTo="body"
                                    [popup]="true"></p-slideMenu>

                                <p-button icon="pi pi-folder-open"
                                    *ngIf="showAbrir"
                                    (click)="abrir(rowData)"
                                    [style]="{'padding': '1em', 'height': '44px'}">
                                </p-button>
                                <p-button icon="pi pi-pencil"
                                    [disabled]="!podeEditar"
                                    *ngIf="showEditar"
                                    (click)="edit(rowData)"
                                    [disabled]="!podeEditar"
                                    [style]="{'padding': '1em', 'height': '44px'}">
                                </p-button>
                                <p-button icon="pi pi-trash"
                                    [disabled]="!podeApagar"
                                    *ngIf="showExcluir"
                                    (click)="doDelete(rowData)"
                                    [disabled]="!podeApagar"
                                    [style]="{'padding': '1em', 'height': '44px'}">
                                </p-button>
                                <p-button icon="pi pi-ellipsis-v"
                                    *ngIf="showAcoes"
                                    (onClick)="menuAcoes.toggle($event); selectClick(rowData)"
                                    iconPos="left"
                                    [disabled]="subActions == undefined || subActions.length == 0"
                                    [style]="{'padding': '1em', 'height': '44px'}">
                                </p-button>
                            </div>
                        </td>

                    </tr>
                </ng-template>
            </p-table>


        </div>
        <div class="sem-margem p-col-12">
            <p-paginator [rows]="100"
                [first]="first"
                [totalRecords]="recordCount"
                [rowsPerPageOptions]="[10, 25, 50, 75, 100]"
                (onPageChange)="onPageChange($event)">
            </p-paginator>
        </div>
    </div>
</habil-grupo>