<p-toast></p-toast>
<p-dialog header="Resumo"
  [(visible)]="showResumo">
  <textarea name="resumoAtend"
    id="resumoAtend"
    pInputTextarea cols="90" rows="30"
    [value]="resumo"></textarea>
</p-dialog>
<habil-grupo header="Pesquisa">
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-10">
      <habil-label name="pesquisacnpj"
        label="Pesquisa por CNPJ, Nome ou Razão Social">
        <input pInputText
          [(ngModel)]="valor"
          (keydown.enter)="buscar()" />
      </habil-label>
    </div>
    <div class="p-field p-col-2 p-col-align-end">
      <button pButton
        icon="pi pi-search"
        label="Pesquisar"
        (click)="buscar()"></button>
    </div>
  </div>
</habil-grupo>
<habil-grupo header="Informações sobre o usuário">
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-3">
      <habil-label name="lbcnpj"
        label="CPF/CNPJ">
        <input pInputText
          [value]="obj.cpfCnpj"
          [readonly]="true" />
      </habil-label>

    </div>
    <div class="p-field p-col-3">
      <habil-label name="lbnome"
        label="Nome">
        <input pInputText
          [value]="obj.nome"
          [readonly]="true" />
      </habil-label>
    </div>
    <div class="p-field p-col-3">
      <habil-label name="lbrazao"
        label="Razão Social">
        <input pInputText
          [value]="obj.razaoSocial"
          [readonly]="true" />
      </habil-label>
    </div>
    <div class="p-field p-col-3">
      <habil-label name="lbfone"
        label="Telefone">
        <input pInputText
          [value]="obj.telefone"
          [readonly]="true" />
      </habil-label>
    </div>
    <div class="p-field p-col-3">
      <habil-label name="lbemail"
        label="E-mail">
        <input pInputText
          [value]="obj.email"
          [readonly]="true" />
      </habil-label>

    </div>
    <div class="p-field p-col-3">
      <habil-label name="lbinicio"
        label="Iniciar atendimento">
        <habil-date-edit [(ngModel)]="obj.inicio"
          [disabled]="true"></habil-date-edit>
      </habil-label>
    </div>
    <div class="p-field p-col-2">
      <habil-label name="lbfim"
        label="Atender até">
        <habil-date-edit [(ngModel)]="obj.fim"
          [disabled]="true"></habil-date-edit>
      </habil-label>
    </div>
    <div class="p-field p-col-1 p-col-align-end">
      <div *ngIf="fornecerSuporte() && !implantacao()">
        <p-tag value="FORNECER SUPORTE"
          severity="success"></p-tag>
      </div>
      <div *ngIf="fornecerSuporte() && implantacao()">
        <p-tag value="EM IMPLANTACAO"
          [style]="{ 'background': 'orange' }"></p-tag>
      </div>
      <div *ngIf="!fornecerSuporte()">
        <p-tag value="NÃO FORNECER SUPORTE"
          severity="danger"></p-tag>
      </div>
    </div>
    <div class="p-field p-col-3">
      <habil-label name="lbplano"
        label="Plano de Suporte">
        <input pInputText
          [value]="obj.planoSuporte != undefined ? obj.planoSuporte.descricao : 'nada'"
          [readonly]="true" />
      </habil-label>
    </div>
  </div>
</habil-grupo>
<habil-grupo header="Exceções">
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-fixed">
      <button pButton
        icon="pi pi-plus"
        label="Lançar exceção"
        (click)="lancarExcecao()"></button>
    </div>
    <div class="p-field p-col-10"></div>
    <div class="p-field p-col-12">
      <p-table [value]="excecoes">
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 20%;">Atendente</th>
            <th style="width: 10%;">Data</th>
            <th style="width: 70%;">Observações</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body"
          let-ex>
          <tr>
            <td>{{ ex.atendente }}</td>
            <td>{{ ex.data | date: 'dd/MM/yyyy HH:mm' }}</td>
            <td>{{ ex.observacoes }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

</habil-grupo>
<habil-grupo header="Atendimentos">
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12">
      <p-table [value]="atendimentos">
        <ng-template pTemplate="header">
          <tr>
            <th>Data</th>
            <th>Assunto</th>
            <th>Funcionário</th>
            <th>Tipo</th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body"
          let-ex>
          <tr>
            <td>{{ ex.dataCadastro | date: 'dd/MM/yyyy HH:mm' }}</td>
            <td>{{ ex.assunto }}</td>
            <td>{{ (ex.funcionario?.nome) }}</td>
            <td>{{ ex.tipo }}</td>
            <td><button pButton
                label="Ver resumo"
                (click)="verResumo(ex.resumo)"></button></td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

</habil-grupo>