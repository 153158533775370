import { Component, OnInit } from "@angular/core";
import { MessageService } from "primeng/api";
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";
// import { EAcessoRotina } from 'src/app/models/eacesso-rotina';
import {
  Licenca,
  LicencaModulos,
  Registro,
  RegistroLicenca,
  RegistroLicencaAdicional,
  RegistroLicencaEmissao,
} from "src/app/models/registro";
import {
  EModuloAdicional,
  RegistroProduto,
  softwares,
} from "src/app/models/registro-produto";
import { ProdutoService } from "src/app/services/produto.service";
import { RegistroService } from "src/app/services/registro.service";
import { clone } from "src/app/utilities/util/clone-utils";
import { DateUtils } from "src/app/utilities/util/date-utils";

@Component({
  selector: "habil-cadastro-licenca",
  templateUrl: "./cadastro-licenca.component.html",
  styleUrls: ["./cadastro-licenca.component.scss"],
  providers: [MessageService, DialogService],
})
export class CadastroLicencaComponent implements OnInit {
  obj: RegistroLicenca = {
    expDate: undefined,
    nroLicencas: 0,
    boletos: 0,
    acessos: [],
    emissoes: [],
    adicionais: [],
    categoriasRelatorio: "",
  };

  modulosAdicionais = EModuloAdicional.modulos;
  rotinas = [];
  rotinasComDescricao = [];

  modulosDisponiveis: LicencaModulos[] = [];
  todosOsModulos: RegistroProduto[] = [];
  softwares = softwares;

  licenca: Licenca = {
    software: "HABIL_10",
    dbToken: "",
    modulosLicenciados: [],
    licenceKey: "",
    timestamp: DateUtils.dateTimeToString(new Date()),
    personalizada: false,
    usouDemo: false,
    tipo: "",
    id: 0,
    idInterno: 0,
    creationDate: undefined,
    idHabil: "",
    lastModifiedDate: undefined,
    createdBy: 0,
    lastModifiedBy: 0,
    deleted: false,
  };

  registro: Registro = {
    nome: "",
    cnpj: "",
    email: "",
    cancelamento: null,
    endereco: undefined,
    responsavel: "",
    telefones: [],
    licencas: [],
    id: 0,
    idInterno: 0,
    creationDate: undefined,
    idHabil: "",
    lastModifiedDate: undefined,
    createdBy: 0,
    lastModifiedBy: 0,
    deleted: false,
  };

  constructor(
    private config: DynamicDialogConfig,
    private modal: DynamicDialogRef,
    public service: RegistroService,
    public moduloService: ProdutoService
  ) {}

  novaEmissao() {
    if (!this.obj.emissoes) this.obj.emissoes = [];
    let emissao: RegistroLicencaEmissao = {
      documento: "",
      nfe: false,
      vctoNfe: new Date(),
      qtdeDocsNfe: 0,
      nfce: false,
      vctoNfce: new Date(),
      qtdeDocsNfce: 0,
      nfs: false,
      vctoNfs: new Date(),
      qtdeDocsNfs: 0,
      sat: false,
      vctoSat: new Date(),
      qtdeDocsSat: 0,
    };
    this.obj.emissoes.push({ ...emissao });
  }

  apagaModulo(modulo) {
    this.obj.adicionais = this.obj.adicionais.filter((m) => m != modulo);
  }

  getScrollHeight(): string {
    let fullHeight = window.innerHeight;
    let availableHeight = fullHeight - 230;
    let scrollHeight = availableHeight > 100 ? availableHeight + "px" : "100px";

    return scrollHeight;
  }

  novoModulo() {
    if (!this.obj.adicionais) this.obj.adicionais = [];
    let adicional: RegistroLicencaAdicional = {
      documento: this.registro.cnpj,
      vcto: new Date(),
      modulo: this.modulosAdicionais[0]?.value,
      licencas: 1,
    };
    this.obj.adicionais.push({ ...adicional });
  }

  apagaEmissao(documento) {
    this.obj.emissoes = this.obj.emissoes.filter(
      (e) => e.documento != documento
    );
  }

  ngOnInit(): void {
    setTimeout(() => {
      if (!this.modulosAdicionais || this.modulosAdicionais.length == 0)
        this.modulosAdicionais = EModuloAdicional.modulos;
    }, 1000);
    if (this.config.data) {
      this.licenca = this.config.data.licenca;
      this.registro = this.config.data.registro;
      this.service
        .getRegistroLicenca(this.registro.id, this.licenca.software)
        .subscribe(
          (rl) => {
            if (rl?.body) {
              this.obj = clone(rl.body);
              this.moduloService.findAll(0, 0, "").subscribe((produtos) => {
                this.todosOsModulos = [...produtos];
                this.modulosDisponiveis = [];
                let inc = -1;
                if (produtos) {
                  for (let p of produtos) {
                    let jaUtilizado = false;
                    for (
                      let i = 0;
                      i < this.licenca.modulosLicenciados.length;
                      i++
                    ) {
                      if (p.id == this.licenca.modulosLicenciados[i].idModulo) {
                        this.licenca.modulosLicenciados[i].descricaoModulo =
                          p.nomeProduto;
                        jaUtilizado = true;
                        break;
                      }
                    }
                    if (jaUtilizado) continue;

                    const m: LicencaModulos = {
                      idModulo: p.id,
                      descricaoModulo: p.nomeProduto,
                      licenca: { id: this.licenca.id },
                      id: clone(inc),
                      idInterno: 0,
                      creationDate: undefined,
                      idHabil: "",
                      lastModifiedDate: undefined,
                      createdBy: 0,
                      lastModifiedBy: 0,
                      deleted: false,
                    };
                    inc--;
                    this.modulosDisponiveis.push(m);
                  }
                }
                this.licenca.modulosLicenciados =
                  this.licenca.modulosLicenciados.filter(
                    (m) => m.descricaoModulo
                  );
                this.atualizarRotinasPorModulo();
              });
            }
          },
          (e) => {
            this.service.handleError(e);
          }
        );
    }
  }

  onChangeModulos() {
    setTimeout(() => {
      this.preencherRotinasPorModulo();
    }, 500);
  }

  preencherRotinasPorModulo() {
    this.obj.acessos = [];
    for (const m of this.licenca.modulosLicenciados) {
      const modulo: RegistroProduto = this.todosOsModulos.find(
        (p) => p.id == m.idModulo
      );
      for (const rotina of modulo.acessos) {
        if (!this.obj.acessos.find((r) => r == rotina)) {
          this.obj.acessos.push(clone(rotina));
        }
      }
    }
    this.atualizarRotinasPorModulo();
  }

  private carregaRotinas(): Promise<void> {
    return new Promise((sim, nao) => {
      this.moduloService.getAcessos().subscribe({
        next: (acessos) => {
          this.rotinas = [];
          if (acessos) {
            acessos.forEach((a) => {
              this.rotinas.push({
                value: a.value,
                label: a.description,
              });
            });
          }
          sim();
        },
        error: (erro) => {
          this.moduloService.handleError(erro);
          nao();
        },
      });
    });
  }

  atualizarRotinasPorModulo() {
    this.rotinasComDescricao = [];
    //this.rotinas = [...EAcessoRotina]
    this.carregaRotinas().then(() => {
      this.rotinas.sort((a, b) => {
        if (a.label > b.label) {
          return 1;
        }
        if (a.label < b.label) {
          return -1;
        }
        return 0;
      });
      if (!this.obj.acessos) this.obj.acessos = [];
      for (let rotina of this.obj.acessos) {
        let r = this.rotinas.find((r) => r.value == rotina);
        if (!r) r = { label: rotina, value: rotina };
        this.rotinasComDescricao.push(r);
        this.rotinas = [...this.rotinas.filter((r) => r.value != rotina)];
      }
      this.rotinasComDescricao.sort((a, b) => {
        if (a.label > b.label) {
          return 1;
        }
        if (a.label < b.label) {
          return -1;
        }
        return 0;
      });
    });
  }

  loading = false;

  salvar() {
    this.obj.acessos = [];
    for (const rotina of this.rotinasComDescricao) {
      this.obj.acessos.push(rotina.value);
    }
    this.loading = true;
    this.service
      .saveRegistroLicenca(this.registro.id, this.licenca, this.obj)
      .subscribe(
        (ret) => {
          //this.service.alteraRegistroLicenca( this.registro.id, this.licenca.software, this.obj ).subscribe( ret => {
          this.loading = false;
          if (ret) {
            //this.modal.close(this.licenca);
            this.modal.close(ret);
          }
        },
        (e) => {
          this.service.handleError(e);
          this.loading = false;
        }
      );
  }

  cancelar() {
    this.modal.close();
  }
}
