
<style>
  .topbar-profile-badge {
    background-color: rgb(18, 0, 121);
    background: rgb(27, 37, 179);
  }

  #texto {
    position: absolute;
    margin-top: -30px;
  }

  .slim {
    place-content: center;
    display: flex;

  }

  .isNotSlim {
    justify-content: flex-end;
    margin-right: 8px;
    display: flex;
  }

  .slim a {
    transform: rotate(180deg);
    transition: all 0.6s ease-in;
  }

  .isNotSlim a {
    transform: rotate(0deg);
    transition: all 0.6s ease-in;
  }

  .animation-menu {
    transition: all 0.4s cubic-bezier(0.2, 0.77, 0.94, 0.97);
  }

  .imgSlim {
    max-width: 50px;
    transition: all 0.3s ease-in-out;
  }

  .imgNotSlim {
    max-width: 150px;
    transition: all 0.3s ease-in-out;
  }

  .img-user {
    display: flex;
    margin: 0 auto;

  }

  /* Works on Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #f7f7f7 #f7f7f7;
  }

  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  *::-webkit-scrollbar-track {
    background: #f7f7f7;
  }

  *::-webkit-scrollbar-thumb {
    border: 3px solid #e8e8e8;
    background-color: #e9e9e9;
    border-radius: 7px;
  }


  .zoomOut {
    zoom: 1;
  }

  .menu-perfil-btn i {
    font-size: 1.4em;
  }



  .reasonButton:hover {
    color: #ff8301 !important;
    transition: all 0.3s ease-in-out;
  }

  .news-count {
    position: absolute;
    left: 49%;
    top: 87.3vh;
    background: red;
    padding: 2px 4px;
    border-radius: 100%;
    color: white;
    font-size: 11px;
    font-weight: 600;
  }

  .news-count-6ix-length {
    position: absolute;
    left: 41%;
    top: 86.1vh;
    background: rgb(255 0 0);
    padding: 2px 4px;
    border-radius: 100%;
    color: rgb(255 255 255);
    font-size: 11px;
    font-weight: 600;
  }

  .autorizacoes-count {
    position: absolute;
    left: 57%;
    top: 85.8vh;
    background: red;
    padding: 2px 4px;
    border-radius: 100%;
    color: white;
    font-size: 11px;
    font-weight: 600;
  }



</style>

<!-- <div class="layout-sidebar" (click)="app.onMenuClick($event)">
    <div class="layout-menu-title">MAIN MENU</div>
    <div class="menu-scroll-content">
        <ul class="layout-menu">
            <li app-menuitem *ngFor="let item of model; let i = index;" [item]="item" [index]="i" [root]="true"></li>
        </ul>
    </div>
</div> -->

<p-dialog [header]="'Alterar senha de ' + user?.login" [(visible)]="showTrocarSenha" [style]="{width: '20vw'}">
  <p-toast></p-toast>
  <div class="p-fluid p-grid">
    <div class="p-field p-col-12">
      <habil-label name="txtAtual" label="Senha Atual">
        <input type="password" name="txtAtual" pInputText [(ngModel)]="senhaAtual">
      </habil-label>
    </div>
    <div class="p-field p-col-12">
      <habil-label name="txtNova" label="Nova Senha">
        <input type="password" name="txtNova" pInputText [(ngModel)]="novaSenha">
      </habil-label>
    </div>
    <div class="p-field p-col-12">
      <habil-label name="txtConf" label="Confirmar Nova Senha">
        <input type="password" name="txtConf" pInputText [(ngModel)]="confirmarNovaSenha">
      </habil-label>
    </div>
  </div>
  <ng-template pTemplate="footer">
      <p-button icon="pi pi-check" (click)="salvarSenha()" label="Ok" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>
<div class="layout-sidebar animation-menu" style="top: 0;" (click)="app.onMenuClick($event)">
    <!--<div class="layout-menu-title">MAIN MENU</div>-->
      <div class="menu-scroll-content menu-lateral">
        <!-- ICONE SHOW E HIDE MENU -->
        <div [ngClass]="{'slim' : app.isSlim(), 'isNotSlim': !app.isSlim()}">
          <a href="#"
            [title]="getMensagemHideShow()"
            id="topbar-menu-button" style="place-self: flex-end;font-size: calc(1.5vw + 1.5vh);"
            (click)="app.onMenuButtonClick($event)">
            <i class="pi pi-chevron-left"></i>
          </a>
        </div>
        <div class="p-d-block p-d-md-none" style="align-self: center;margin: 4vh 0" ></div>
        <div class="p-d-none p-d-md-block" style="align-self: center;margin: 1vh 0" (click)="!app.isSlim() ? null : usermenu.toggle($event)">
            <img *ngIf="showAvatar" [src]="getSrcImage()"
              id="imgAvatar"
              [ngStyle]="app.isSlim() ? {'max-width': '40px'} : {'max-width': '70px'}"
              alt="ecuador-layout"
              class="img-user" />
            <div style="text-align: center;margin-top: 1em;">
              <a id="topbar-profile-menu-button">
                <span *ngIf="!app.isSlim()">{{nomeUser}}</span>
              </a>
            </div>
            <div class="reasonButton" style="padding-bottom: 17px" (dblclick)="abreEmpresativa()" *ngIf="!app.isSlim()" [title]="empresa">
              {{empresa}}
            </div>
            <p-menu #usermenu [popup]="true" appendTo="body" [model]="menuUsuario">
            </p-menu>
        </div>
        <!-- OPCOES DO MENU -->
        <ul class="layout-menu" style="padding: 0;padding-top: 2px; flex: 0.8;margin-top: -3em;"
            [ngStyle]="!app.isSlim() ? {'overflow': 'auto'} : {}">
          <li app-menuitem *ngFor="let item of model; let i = index;" [item]="item" [index]="i" [root]="true"></li>
        </ul>
        <div style="display: flex;margin-top: 1em;align-self: center;margin-bottom: -4vh;" *ngIf="!app.isSlim()">
          <div
            *ngFor="let item of menuUsuario"
            [title]="item.label"
            class="menu-perfil-btn"
            style="min-width: 35px;"
            (click)="item.command()">
              <i [class]="item.icon" *ngIf="item.icon" style="margin-right: 8px; color: #7f7e7e;position: relative;"></i>
              <span
                [ngClass]="menuUsuario && menuUsuario.length < 6 ? 'news-count' : 'news-count-6ix-length'"
                [title]="'Notificações não lidas: ' + newsToRead"
                *ngIf="item.label == 'News' && newsToRead > 0">
                {{newsToRead}}
              </span>
              <span
                class="autorizacoes-count"
                [title]="'Notificações não lidas: ' + getCountAuthPendentes"
                *ngIf="item.label == 'Painel de Autorizações' && getCountAuthPendentes > 0">
                {{getCountAuthPendentes}}
              </span>
              <fa-icon
                [icon]="item.iconFA"
                *ngIf="item.iconFA"
                style="margin-right: 8px;color: #7f7e7e;DISPLAY: flex;place-content: flex-end;margin-top: 1px;zoom: 1.1;">
              </fa-icon>
          </div>
        </div>
        <!-- LOGO DO HABIL -->
        <div class="p-d-none p-d-md-flex" style="justify-content: center;margin: 3vh 0;" *ngIf="theme == 'deeporange'">
          <a target="_blank" href="https://www.habil.com.br">
            <img src="assets/img/habilbylimber.svg"
                alt="ecuador-layout"
                [ngStyle]="app.isSlim() ? {'max-width': '50px'} : {'max-width': '100px'}"
                [ngClass]="{'imgSlim' : app.isSlim(), 'imgNotSlim' : !app.isSlim()  }"
                style="
              width: 10vw;
              margin-top: 7px;"/>
            </a>
        </div>
        <div class="p-d-none p-d-md-flex" style="justify-content: center;display: flex;margin: 3vh 0;" *ngIf="theme != 'deeporange'">
          <a target="_blank" href="https://www.habil.com.br">
            <img src="assets/img/habilbylimber.svg"
                alt="ecuador-layout"
                [ngStyle]="app.isSlim() ? {'max-width': '50px'} : {'max-width': '100px'}"
                [ngClass]="{'imgSlim' : app.isSlim(), 'imgNotSlim' : !app.isSlim()  }"
                style="
              width: 10vw;
              margin-top: 7px;"/>
            </a>
        </div>
      </div>
  </div>