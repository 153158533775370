<p-confirmDialog header="Atenção"
    icon="pi pi-exclamation-triangle">
</p-confirmDialog>
<p-toast></p-toast>
<div class="p-field p-col-12 p-md-12 p-lg-12"
    *ngIf="_loading">
    <p-progressBar mode="indeterminate"
        [style]="{'height': '6px'}"></p-progressBar>
</div>
<habil-grupo>
    <p-scrollPanel [style]="{width: '100%', height: getScrollHeight()}">
        <p-accordion>
            <p-accordionTab header="Dados Básicos"
                [selected]="true">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-lg-10">
                        <habil-label name="txtNome"
                            label="Nome">
                            <input [readonly]="_readonly"
                                type="text"
                                name="txtNome"
                                pInputText
                                [(ngModel)]="obj.nomeProduto" />
                        </habil-label>
                    </div>
                    <div class="p-field p-col-12 p-lg-2">
                        <habil-label name="txtCodigo"
                            label="Código no Hábil 10">
                            <p-inputNumber [(ngModel)]="obj.idInterno"
                                title="Informe o código do produto no Hábil 10"
                                tooltipPosition="top"></p-inputNumber>
                        </habil-label>
                    </div>
                    <div class="p-field p-col-10">
                        <habil-label name="searchClass" label="Usar essa classificação ao lançar novo usuário">
                            <habil-autocomplete-edit
                                [service]="classificacaoService"
                                [(ngModel)]="obj.classificacao"
                                [readonly]="_readonly"
                                name="searchClass"
                                field="descricao"
                                dataKey="id">
                            </habil-autocomplete-edit>
                        </habil-label>
                    </div>
                    <div class="p-field p-col-12">
                        <p-pickList [disabled]="_readonly" sourceHeader="Rotinas disponíveis" targetHeader="Rotinas habilitadas" [responsive]="true" [sourceStyle]="{'height':'250px'}"
                         [targetStyle]="{'height':'250px'}"
                         [source]="rotinas" [target]="obj.acessosComDescricao" [dragdrop]="true" filterBy="label">
                            <ng-template let-rotina pTemplate="item">
                                <div *ngIf="rotina">{{rotina.label}}</div>
                            </ng-template>
                        </p-pickList>
                    </div>
                </div>
            </p-accordionTab>
        </p-accordion>
    </p-scrollPanel>
</habil-grupo>
<br>
<habil-botoes-cadastro (onError)="onErrorHandled()"
    [obj]="getObject.bind(this)"
    [dialog]="_isDialog"
    (onSaved)="onSave($event)"
    (onClosed)="onCancel()"
    [service]="service"
    [readonly]="_readonly"
    [validate]="doValidate.bind(this)"
    [doBeforeSave]="doBeforeSave.bind(this)"
    (onSaveAndNew)="onSaveAndNew($event)">
</habil-botoes-cadastro>