import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CfgComponent } from './cfg.component';
import { GrupoModule } from 'src/app/utilities/grupo/grupo.module';
import { ButtonModule } from 'primeng/button';
import { LabelModule } from 'src/app/utilities/label/label.module';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { PanelModule } from 'primeng/panel';
import { InputNumberModule } from 'primeng/inputnumber';
import { PasswordModule } from 'primeng/password';



@NgModule({
  declarations: [
    CfgComponent
  ],
  imports: [
    CommonModule,
    GrupoModule,
    InputTextModule,
    ButtonModule,
    LabelModule,
    FormsModule,
    ToastModule,
    PanelModule,
    InputNumberModule,
    PasswordModule
  ]
})
export class CfgModule { }
