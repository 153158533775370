<div class="p-grid p-fluid">
  <div class="p-col-6 p-lg-2">
    <label for="ddServidorAtual">Servidor atual</label>
    <p-dropdown id="ddServidorAtual" [(ngModel)]="servidorAtual" [options]="servidores"></p-dropdown>
  </div>
  <div class="p-col-6 p-lg-2 p-col-align-end">
    <button pButton icon="pi pi-search" label="Buscar dados..." (click)="buscar()"></button>
  </div>
</div>
<div class="p-grid p-fluid">
  <div class="p-col-12">
    <p-table [value]="list" styleClass="p-datatable-sm p-datatable-striped">
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="databaseInfo.cnpj">Banco de dados</th>
          <th pSortableColumn="nome">Nome</th>
          <th pSortableColumn="conectado">Status</th>
          <th pSortableColumn="databaseInfo.databaseIp">Servidor</th>
          <th pSortableColumn="vencimento">Vencimento</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-db>
        <tr>
          <td>{{ db.databaseInfo.cnpj }}</td>
          <td>{{ db.nome }}</td>
          <td>{{ (db.conectado ? 'Conectado' : 'Desconectado') }}</td>
          <td>{{ getServerName(db.databaseInfo.databaseIp) }}</td>
          <td>{{ db.vencimento | date: 'dd/MM/yyy' }}</td>
          <td><button (click)="removerConexao(db)" class="p-button-sm p-button-text" pButton
              label="Desconectar"></button></td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>