<!-- <p-toast></p-toast> -->
<p-messages></p-messages>
<div class="p-d-none p-d-md-block">
  <p-table [value]="fones" styleClass="p-datatable-responsive-demo" [(selection)]="selectedFone" selectionMode="single" (onEditComplete)="onRowEditSave(selectedFone)">
  <ng-template pTemplate="colgroup" let-columns>
    <col [style.width]="'80px'" />
    <col [style.width]="'80px'" />
    <col [style.width]="'80px'" />
    <col [style.width]="'40%'" />
    <col [style.width]="'60%'" />
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th style="text-align: center">Padrão</th>
      <th>Tipo</th>
      <th>DDD</th>
      <th>Fone</th>
      <th>Observações</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData>
    <tr [pSelectableRow]="rowData">
      <td style="text-align: center">
        <p-checkbox [binary]="true" [readonly]="_readonly" [(ngModel)]="rowData.padrao"></p-checkbox>
      </td>
      <td [pEditableColumn]="rowData" pEditableColumnField="tipo">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-dropdown [options]="tipos" [(ngModel)]="rowData.tipo"></p-dropdown>
          </ng-template>
          <ng-template pTemplate="output">
            {{rowData.tipo}}
          </ng-template>
        </p-cellEditor>
      </td>
      <td [pEditableColumn]="rowData" pEditableColumnField="ddd">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input pInputText [(ngModel)]="rowData.ddd">
          </ng-template>
          <ng-template pTemplate="output">
          {{rowData.ddd}}
        </ng-template>
        </p-cellEditor>

      </td>
      <td [pEditableColumn]="rowData" pEditableColumnField="numero">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input pInputText [(ngModel)]="rowData.numero">
          </ng-template>
          <ng-template pTemplate="output">
            {{rowData.numero}}
          </ng-template>
        </p-cellEditor>
      </td>
      <td [pEditableColumn]="rowData" pEditableColumnField="observacoes">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input pInputText type="text" [(ngModel)]="rowData.observacoes" />
          </ng-template>
          <ng-template pTemplate="output">
            {{rowData.observacoes}}
          </ng-template>
        </p-cellEditor>
      </td>
    </tr>
  </ng-template>
</p-table>
</div>
<div class="p-d-block p-d-md-none">
  <p-table [value]="fones" styleClass="p-datatable-responsive-demo" [(selection)]="selectedFone" selectionMode="single" (onEditComplete)="onRowEditSave(selectedFone)">
    <ng-template pTemplate="header">
      <tr>
        <th>Padrão</th>
        <th>Tipo</th>
        <th>DDD</th>
        <th>Fone</th>
        <th>Observações</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr [pSelectableRow]="rowData">
        <td>
          <span class="p-column-title">Padrão</span>
          <p-checkbox [binary]="true" [(ngModel)]="rowData.padrao"></p-checkbox>
        </td>
        <td [pEditableColumn]="rowData" pEditableColumnField="tipo">
          <span class="p-column-title">Tipo</span>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <p-dropdown [options]="tipos" [(ngModel)]="rowData.tipo"></p-dropdown>
            </ng-template>
            <ng-template pTemplate="output">
              {{rowData.tipo}}
            </ng-template>
          </p-cellEditor>
        </td>
        <td [pEditableColumn]="rowData" pEditableColumnField="ddd">
          <span class="p-column-title">DDD</span>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <ks-text-edit [(ngModel)]="rowData.ddd"></ks-text-edit>
            </ng-template>
            <ng-template pTemplate="output">
              {{rowData.ddd}}
            </ng-template>
          </p-cellEditor>

        </td>
        <td [pEditableColumn]="rowData" pEditableColumnField="numero">
          <span class="p-column-title">Número</span>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input pInputText [(ngModel)]="rowData.numero">
            </ng-template>
            <ng-template pTemplate="output">
              {{rowData.numero}}
            </ng-template>
          </p-cellEditor>
        </td>
        <td [pEditableColumn]="rowData" pEditableColumnField="observacoes">
          <span class="p-column-title">Obs</span>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input pInputText type="text" [(ngModel)]="rowData.observacoes" />
            </ng-template>
            <ng-template pTemplate="output">
              {{rowData.observacoes}}
            </ng-template>
          </p-cellEditor>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
<br />
<div style="display: inline-flex;">
    <div style="margin-inline-end: 2%;">
        <p-button styleClass="p-button-text" label="Adicionar" icon="pi pi-plus" *ngIf="!_readonly" (click)="novoFone()"></p-button>
    </div>
    <div>
        <p-button styleClass="p-button-text" label="Excluir" icon="pi pi-trash" *ngIf="!_readonly" (click)="deleteFone()"></p-button>
    </div>
</div>

<!-- <div class="grid-fones">
  <p-table [value]="fones" dataKey="id" editMode="row" selectionMode="single" [(selection)]="selectedFone">
    <ng-template pTemplate="colgroup">
      <colgroup>
        <col [hidden]="true">
        <col>
        <col style="text-overflow: ellipsis;">
        <col style="text-overflow: ellipsis;">
        <col style="text-overflow: ellipsis;">
        <col [style.width]="'50%'">
        <col *ngIf="!_readonly" style="width: 8em;">
      </colgroup>

    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th hidden="true">Código</th>
        <th>Padrão</th>
        <th>Tipo</th>
        <th>DDD</th>
        <th>Fone</th>
        <th>Observações</th>
        <th *ngIf="!_readonly"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
      <tr [pEditableRow]="rowData" [pSelectableRow]="rowData">
        <td hidden="true">{{rowData.id}}</td>
        <td style="text-align: center;">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <ks-checkbox name="edt-padrao" [(ngModel)]="rowData.padrao"></ks-checkbox>
            </ng-template>
            <ng-template pTemplate="output">
              <ks-checkbox name="padrao" [(ngModel)]="rowData.padrao" [readonly]="true"></ks-checkbox>
            </ng-template>
          </p-cellEditor>
        </td>
        <td>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <ks-text-edit [(ngModel)]="rowData.tipo" name="tipo" [readonly]="false"></ks-text-edit>
            </ng-template>
            <ng-template pTemplate="output">
              <ks-text-edit [(ngModel)]="rowData.tipo" name="tipo" [readonly]="true"></ks-text-edit>
            </ng-template>
          </p-cellEditor>
        </td>
        <td style="text-overflow: ellipsis;">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <ks-text-edit [(ngModel)]="rowData.ddd" name="ddd" [readonly]="false"></ks-text-edit>
            </ng-template>
            <ng-template pTemplate="output">
              <ks-text-edit [(ngModel)]="rowData.ddd" name="ddd" [readonly]="true"></ks-text-edit>
            </ng-template>
          </p-cellEditor>
        </td>
        <td style="text-overflow: ellipsis;">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <ks-text-edit [(ngModel)]="rowData.numero" name="numero" [readonly]="false"></ks-text-edit>
            </ng-template>
            <ng-template pTemplate="output">
              <ks-text-edit [(ngModel)]="rowData.numero" name="numero" [readonly]="true"></ks-text-edit>
            </ng-template>
          </p-cellEditor>
        </td>
        <td>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <ks-text-edit [(ngModel)]="rowData.observacoes" name="observacoes" [readonly]="false"></ks-text-edit>
            </ng-template>
            <ng-template pTemplate="output" style="text-overflow: ellipsis;">
              <ks-text-edit [(ngModel)]="rowData.observacoes" name="observacoes" [readonly]="true"></ks-text-edit>
            </ng-template>
          </p-cellEditor>
        </td>
        <td style="text-align: center;" *ngIf="!_readonly">
          <button *ngIf="!editing" pButton type="button" pInitEditableRow icon="pi pi-pencil" class="ui-button-info"
            (click)="onRowEditInit(rowData)"></button>
          <button *ngIf="editing" pButton type="button" pSaveEditableRow icon="pi pi-check" class="ui-button-success"
            style="margin-right: .5em" (click)="onRowEditSave(rowData)"></button>
          <button *ngIf="editing" pButton type="button" pCancelEditableRow icon="pi pi-times" class="ui-button-danger"
            (click)="onRowEditCancel(rowData, ri)"></button>
        </td>
      </tr>
    </ng-template> -->
