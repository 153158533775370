import { AbstractModel } from "./abstract-model";
import { Rotina } from "./eacesso-rotina";
import { Classificacao } from "./registro-produto";

export class HabilFranqueadoraProduto extends AbstractModel {
    nomeProduto: string = "";
    codigo: string = "";
    acessos: string[] = [];
    acessosComDescricao?: Rotina[];
    classificacao: Classificacao;
}


export class HabilFranqueadoraRegistroLicenca {
    expDate: Date = new Date();
    nroLicencas: number = 1;
    acessoGeral: boolean = true;
    acessos: string[] = [];
    //empresas: HabilFranqueadoraRegistroLicencaEmpresa[] = [];
    ip?: string;
}
