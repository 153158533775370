<habil-grupo>
  <p-accordion [multiple]="true">
    <p-accordionTab header="Liberação da Chave" [selected]="true">
      <div class="p-grid p-fluid">
        <div class="p-field p-col-12">
          <habil-label name="lbobs" label="Observações">
            <textarea pInputTextarea [(ngModel)]="liberacao.observacoes" [rows]="5"></textarea>
          </habil-label>
          </div>
        <div class="p-field p-col-12">
          <habil-label name="lbchave" label="Chave de registro">
            <textarea
              pInputTextarea
              [rows]="5"
              [(ngModel)]="liberacao.dadosCliente.chaveRegistro"
            ></textarea>
          </habil-label>
        </div>
        <div class="p-field p-col-6">
          <habil-label
            name="lbcodigoacesso"
            label="Código de Acesso"
            [visible]="liberacao.id == 0"
          >
            <input pInputText [(ngModel)]="codigoAcesso" />
          </habil-label>
        </div>
        <div class="p-field p-col-2">
          <habil-label name="lbinicial" label="Data Inicial">
            <habil-date-edit [(ngModel)]="dataI"></habil-date-edit>
          </habil-label>
        </div>
        <div class="p-field p-col-2">
          <habil-label name="lbfinal" label="Data Final">
            <habil-date-edit [(ngModel)]="dataF"></habil-date-edit>
          </habil-label>
        </div>
        <div class="p-field p-col-2 p-col-align-end">
          <button
            pButton
            icon="pi pi-bolt"
            class="p-button-success"
            (click)="gerarCodigoLiberacao()"
            label="Gerar"
          ></button>
        </div>
        <div class="p-field p-col-12">
          <habil-label name="lbliberacao" label="Código de Liberação">
            <div class="p-inputgroup">
              <input pInputText [(ngModel)]="codigoLiberacao" />
              <button
                #codLib
                type="button"
                pButton
                pRipple
                icon="pi pi-copy"
                title="Copiar o código de liberação"
                tooltipPosition="top"
                label="Copiar código"
                [cdkCopyToClipboard]="codigoLiberacao"
              ></button>
            </div>
          </habil-label>
        </div>
      </div>
    </p-accordionTab>
    <p-accordionTab header="Liberação de Suporte" [selected]="true">
      <habil-liberacao-atendimento
        [(ngModel)]="cadastraAtendimento"
        (onChange)="changeAtendimentoLiberacao($event)"
      ></habil-liberacao-atendimento>
    </p-accordionTab>
  </p-accordion>
</habil-grupo>
<div *ngIf="loading">
  <br />
  <p-progressBar
    mode="indeterminate"
    [style]="{ height: '6px' }"
  ></p-progressBar>
</div>
<br />
<habil-grupo>
  <div class="p-fluid p-grid">
    <div class="p-col"></div>
    <div class="p-col-2">
      <button
        pButton
        class="p-button-success"
        icon="pi pi-check"
        label="Finaliza"
        [disabled]="!permiteFinalizar()"
        (click)="finalizar()"
      ></button>
    </div>
    <div class="p-col-2">
      <button
        pButton
        icon="pi pi-check"
        label="Fechar"
        (click)="fechar()"
      ></button>
    </div>
  </div>
</habil-grupo>
