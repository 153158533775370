import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { AbstractService } from 'src/app/services/abstract.service';
import { AdminUsuarioService } from 'src/app/services/admin-usuario.service';

@Component({
  selector: 'habil-admin-usuario',
  templateUrl: './admin-usuario.component.html',
  styleUrls: ['./admin-usuario.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class AdminUsuarioComponent implements OnInit {

  constructor(private breadcrumbService: BreadcrumbService, public service: AdminUsuarioService, titleService: Title) {
    this.breadcrumbService.setItems([{label: 'Usuários'}]);
    titleService.setTitle('Usuários - ' + AbstractService.app);
  }

  ngOnInit(): void {
  }

}
