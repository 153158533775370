import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'habil-grupo',
  templateUrl: './grupo.component.html',
  styleUrls: ['./grupo.component.scss']
})
export class GrupoComponent implements OnInit {

  @Input('header')
  header: string;
  @Input('showCloseButton')
  showClose: boolean = false;
  @Output()
  onClick: EventEmitter<any> = new EventEmitter();

  @Input()
  style: string = '';

  @Input()
  dif = 0;


  constructor() { }

  ngOnInit() {
  }
  close() {
    this.onClick.emit();
  }

  getScrollHeight(): string {
    let fullHeight = window.innerHeight;
    let availableHeight = fullHeight - this.dif;
    let scrollHeight = availableHeight > 100 ? availableHeight + 'px' : '100px';

    return scrollHeight;
  }


}
