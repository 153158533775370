import { Component, OnInit, ViewChild } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { Editor } from "primeng/editor";
import { BreadcrumbService } from "src/app/app.breadcrumb.service";
import { Rotina } from "src/app/models/eacesso-rotina";
import {
  ETipoPesquisaNews,
  News,
  NewsBanner,
  NewsHtml,
  NewsMensagem,
  NewsNovidades,
  NewsNovidadesAlteracao,
  NewsPesquisa,
} from "src/app/models/news";
import { RegistroProduto } from "src/app/models/registro-produto";
import { AbstractService } from "src/app/services/abstract.service";
import { NewsService } from "src/app/services/news.service";
import { ProdutoService } from "src/app/services/produto.service";
import { EUf } from "src/app/utilities/endereco/euf.enum";
import { GenericCadastro } from "src/app/utilities/generic-cadastro";
import { clone } from "src/app/utilities/util/clone-utils";
import { DateUtils } from "src/app/utilities/util/date-utils";
import { FileUtils } from "src/app/utilities/util/file-utils";
import { FormValidation } from "src/app/utilities/util/form-validation";

@Component({
  selector: "habil-cadastro-news",
  templateUrl: "./cadastro-news.component.html",
  styleUrls: ["./cadastro-news.component.scss"],
  providers: [ConfirmationService, MessageService, DialogService],
})
export class CadastroNewsComponent
  extends GenericCadastro<News, NewsService>
  implements OnInit
{
  tipos = [
    { label: "News", value: "NEWS" },
    { label: "Banner", value: "BANNER" },
    { label: "Mensagem", value: "MENSAGEM" },
    { label: "Novidades", value: "NOVIDADES" },
    { label: "Novidades (tela inicial)", value: "NOVIDADES_INICIO" },
    { label: "Pesquisa", value: "PESQUISA" },
  ];
  prioridades = [
    { label: "Baixa", value: "BAIXA" },
    { label: "Média", value: "MEDIA" },
    { label: "Alta", value: "ALTA" },
  ];

  TAMANHO_MAXIMO: number = 5000000;
  produtos: any[];

  uploadedFiles: any[] = [];
  loading: boolean = false;
  selectedFile: any;
  rotinas: Rotina[] = [];

  ufs: any[] = EUf.ufsSigla.filter(
    (uf) => uf.value !== "NENHUM" && uf.value !== "EX"
  );
  pesquisaTipo: any[] = [
    { value: ETipoPesquisaNews.SIM_NAO, label: "Sim ou Não" },
    { value: ETipoPesquisaNews.NOTA, label: "Pedir Nota" },
  ];

  emissoes: any[] = [
    { label: "Ambos", value: "AMBOS" },
    { label: "Emite", value: "EMITE" },
    { label: "Não emite", value: "NAO_EMITE" },
  ];

  @ViewChild("editor")
  editor: Editor;
  linkUrl: string;

  newBanner: NewsBanner = {
    prioridade: "",
    intervalo: 0,
    hint: "",
    bannerFileName: "",
    bannerImg: "",
    bannerContentType: "",
    id: 0,
    idInterno: 0,
    creationDate: undefined,
    idHabil: "",
    lastModifiedDate: undefined,
    createdBy: 0,
    lastModifiedBy: 0,
    deleted: false,
  };

  newMsg: NewsMensagem = {
    textoMensagem: "<b>Mensagem</b>",
    id: 0,
    idInterno: 0,
    creationDate: undefined,
    idHabil: "",
    lastModifiedDate: undefined,
    createdBy: 0,
    lastModifiedBy: 0,
    deleted: false,
  };

  newNovidades: NewsNovidades = new NewsNovidades();

  newObject(): News {
    let obj: News = {
      deleted: false,
      id: 0,
      assunto: "",
      tipo: "NEWS",
      aPartirDe: new Date(),
      validade: DateUtils.endOfTheDay(new Date()),
      software: "HABIL_10",
      modulos: [],
      cnpjs: [],
      ufs: [],
      versao: "",
      versaoCompareType: "GREATER_THAN",
      nfe: "",
      nfce: "",
      nfse: "",
      cupom: "",
      somenteParaAdmins: false,
      novidadesInfo: null,
      bannerInfo: null,
      mensagemInfo: null,
      htmlInfo: [],
      pesquisaInfo: null,
      cancelado: false,
      read: false,
      idInterno: 0,
      creationDate: undefined,
      idHabil: "",
      lastModifiedDate: undefined,
      createdBy: 0,
      lastModifiedBy: 0,
    };
    return obj;
  }

  softwares = [
    { label: "Hábil 10", value: "HABIL_10" },
    { label: "Hábil Pessoal", value: "APP_HABIL_PESSOAL" },
  ];

  doValidate(): boolean {
    this.corrigeAtributos();
    this.getUrl();
    let validation: FormValidation = new FormValidation();
    validation.toValidate = [
      {
        condition: this.obj.assunto != undefined && this.obj.assunto.length > 0,
        message: "O assunto é obrigatório!",
      },
    ];
    if (!validation.isValid()) {
      validation.message().forEach((m) => {
        this.messageService.add({
          severity: "warn",
          summary: "Atenção",
          detail: m,
        });
      });
    }
    return validation.isValid();
  }

  constructor(
    public service: NewsService,
    public messageService: MessageService,
    public dialogService: DialogService,
    public route: ActivatedRoute,
    public router: Router,
    public confirmationService: ConfirmationService,
    public titleService: Title,
    public breadcrumbService: BreadcrumbService,
    private registroProdutoService: ProdutoService,
    private produtoService: ProdutoService
  ) {
    super();
    this.breadcrumbService.setItems([
      { label: "News", routerLink: "/news" },
      { label: "Cadastro" },
    ]);
    this.carregaAcessos();
  }

  private carregaAcessos(): Promise<void> {
    return new Promise((sim, nao) => {
      this.produtoService.getAcessos().subscribe({
        next: (acessos) => {
          this.rotinas = [];
          if (acessos) {
            acessos.forEach((a) => {
              this.rotinas.push({
                label: a.description,
                value: a.value,
                routeCadastro: "",
                routeLista: "",
                temImpressao: false,
              });
            });
          }
          sim();
        },
        error: (erro) => this.service.handleError(erro),
      });
    });
  }

  ngOnInit(): void {
    if (this._isDialog) {
      this._loading = true;
      if (this._externalObj != null && this._externalObj != undefined) {
        this.obj = clone(this._externalObj);
        this.id = this.obj.id;
        this.idCampos = this.id == undefined ? "0" : this.id.toString();
        this._loading = false;
      } else {
        this.obj = this.newObject();
        this.idCampos = this.id == undefined ? "0" : this.id.toString();
        this.loadAuxObjects();
        this._loading = false;
      }
    } else {
      this.titleService.setTitle("News - " + AbstractService.app);
      super.ngOnInit();
    }

    this.registroProdutoService.findAll(0, 0, "").subscribe((list) => {
      this.produtos = [];
      if (list) {
        list.forEach((p) => {
          this.produtos.push({ label: p.nomeProduto, value: p.id });
        });
      }
    });
  }

  onChangeTipo(event) {
    switch (event.value) {
      case "NEWS":
        this.obj.htmlInfo = [];
        this.obj.bannerInfo = null;
        this.obj.mensagemInfo = null;
        this.obj.novidadesInfo = null;
        this.obj.pesquisaInfo = null;
        break;
      case "BANNER":
        this.obj.htmlInfo = null;
        this.obj.bannerInfo = { ...this.newBanner };
        this.obj.novidadesInfo = null;
        this.obj.mensagemInfo = null;
        this.obj.pesquisaInfo = null;
        break;
      case "MENSAGEM":
        this.obj.htmlInfo = null;
        this.obj.bannerInfo = null;
        this.obj.novidadesInfo = null;
        this.obj.mensagemInfo = { ...this.newMsg };
        this.obj.pesquisaInfo = null;
        break;
      case "NOVIDADES":
      case "NOVIDADES_INICIO":
        this.obj.htmlInfo = null;
        this.obj.bannerInfo = null;
        this.obj.mensagemInfo = null;
        this.obj.novidadesInfo = { ...this.newNovidades };
        this.obj.pesquisaInfo = null;
        break;
      case "PESQUISA":
        this.obj.htmlInfo = null;
        this.obj.bannerInfo = null;
        this.obj.mensagemInfo = null;
        this.obj.novidadesInfo = null;
        this.obj.pesquisaInfo = new NewsPesquisa();
        break;
    }
  }

  doBeforeSave() {}

  onFileChange(event) {
    this.loading = true;
    let file: File;
    if (event != undefined) {
      file = event;
      console.log(file);
      
      this.getArquivo(file);
      this.uploadedFiles.push(file);
    }
  }

  convertBase64ToBlobData(
    base64Data: string,
    contentType: string = "image/png",
    sliceSize = 512
  ) {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  baixaArquivo() {
    const type = this.converteEnumParaConteudo(this.selectedFile.contentType);
    const blobData = this.convertBase64ToBlobData(
      this.selectedFile.content,
      type
    );

    const blob = new Blob([blobData], { type: type });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = this.selectedFile.fileName;
    link.click();
  }

  baixarImagemNovidades() {
    const type = this.converteEnumParaConteudo(
      this.obj.novidadesInfo.contentType
    );
    const blobData = this.convertBase64ToBlobData(
      this.obj.novidadesInfo.imagem,
      type
    );

    const blob = new Blob([blobData], { type: type });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "imagem.jpg";
    link.click();
  }

  baixaArquivoBanner() {
    const type = this.converteEnumParaConteudo(
      this.obj.bannerInfo.bannerContentType
    );
    const blobData = this.convertBase64ToBlobData(
      this.obj.bannerInfo.bannerImg,
      type
    );

    const blob = new Blob([blobData], { type: type });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = this.obj.bannerInfo.bannerFileName;
    link.click();
  }

  uploadFile(event, fileUpload) {
    let files: File[] = event.files;
    for (let file of files) {
      if (file.size < this.TAMANHO_MAXIMO) {
        this.uploadedFiles.push(file);
        this.getArquivo(file);
      } else {
        this.messageService.add({
          severity: "error",
          summary: "Atenção",
          detail:
            "Arquivo maior do que o permitido. Máximo permitido: " +
            this.TAMANHO_MAXIMO / 1000000 +
            "mb",
          sticky: true,
        });
      }
    }
    //console.log(this.anexoObjs)
    fileUpload.clear();
  }

  _tempId = 0;

  async getArquivo(file: File) {
    this._tempId--;
    const tipo = this.obj.tipo;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let content = "";
      let type = "";
      try {
        content = reader.result.toString().split(";base64,")[1];
        type = reader.result
          .toString()
          .split(";base64,")[0]
          .replace("data:", "");
        type = this.validaTipoConteudo(type);
      } finally {
        switch (tipo) {
          case "NEWS": {
            const html: NewsHtml = {
              content: clone(content),
              contentType: clone(type),
              fileName: clone(file.name),
              id: clone(this._tempId),
              idInterno: 0,
              creationDate: undefined,
              idHabil: "",
              lastModifiedDate: undefined,
              createdBy: 0,
              lastModifiedBy: 0,
              deleted: false,
            };
            this.obj.htmlInfo.push(html);
            break;
          }
          case "BANNER": {
            this.obj.bannerInfo.bannerImg = clone(content);
            this.obj.bannerInfo.bannerFileName = clone(file.name);
            this.obj.bannerInfo.bannerContentType = clone(type);
            break;
          }
          case "NOVIDADES":
          case "NOVIDADES_INICIO": {
            this.obj.novidadesInfo.imagem = clone(content);
            this.obj.novidadesInfo.contentType = clone(type);
          }
        }
      }
    };
  }
  async getUrl() {
    this._tempId--
    const html: NewsHtml = {
      content: '',
      contentType: '',
      fileName: clone(this.linkUrl), 
      id: clone(this._tempId),
      idInterno: 0,
      creationDate: undefined,
      idHabil: "",
      lastModifiedDate: undefined,
      createdBy: 0,
      lastModifiedBy: 0,
      deleted: false,
    };
    this.obj.htmlInfo.push(html);
  }

  // não julgue o que você vai ver nas próximas linhas... eu sei, poderia ser simplificado,
  // mas só percebi quando um dos métodos já estava pronto

  validaTipoConteudo(type): string {
    switch (type) {
      case "image/x-png":
        type = "IMG_PNG";
        break;
      case "image/pjpeg":
        type = "IMG_JPEG";
        break;
      case "image/bmp":
        type = "IMG_BMP";
        break;
      case "application/octet-stream":
        type = "OCTET_STREAM";
        break;
      case "application/xml":
        type = "XML";
        break;
      case "application/pdf":
        type = "PDF";
        break;
      case "image/gif":
        type = "IMG_GIF";
        break;
      case "application/x-shockwave-flash":
        type = "SWF";
        break;
      case "application/rtf":
        type = "RTF";
        break;
      case "text/html":
        type = "HTML";
        break;
    }
    return type;
  }
  converteEnumParaConteudo(type): string {
    switch (type) {
      case "IMG_PNG":
        type = "image/x-png";
        break;
      case "IMG_JPEG":
        type = "image/pjpeg";
        break;
      case "IMG_BMP":
        type = "image/bmp";
        break;
      case "OCTET_STREAM":
        type = "application/octet-stream";
        break;
      case "XML":
        type = "application/xml";
        break;
      case "PDF":
        type = "application/pdf";
        break;
      case "IMG_GIF":
        type = "image/gif";
        break;
      case "SWF":
        type = "application/x-shockwave-flash";
        break;
      case "RTF":
        type = "application/rtf";
        break;
      case "HTML":
        type = "text/html";
        break;
    }
    return type;
  }

  deleteFile() {
    if (this.selectedFile != undefined && this.selectedFile != null) {
      this.selectedFile.deleted = true;
      this.selectedFile.path = "";
      this.selectedFile = null;
    } else {
      this.messageService.add({
        severity: "info",
        summary: "Atenção",
        detail: "Selecione um item para excluir",
      });
    }
  }

  addNovidade() {
    this.obj.novidadesInfo.alteracoes.push(new NewsNovidadesAlteracao());
  }

  delNovidade(reg: NewsNovidadesAlteracao) {
    const index = this.obj.novidadesInfo.alteracoes.indexOf(reg);
    if (index > -1) {
      this.obj.novidadesInfo.alteracoes.splice(index, 1);
    }
  }

  uploadCsv(event) {
    this._loading = true;
    if (event.files) {
      FileUtils.fileToBase64(event.files[0]).subscribe(
        (base64) => {
          let sFile: string = base64;
          sFile = sFile.replace("data:text/csv;base64,", "");
          this.service.convertCsv(sFile).subscribe((data) => {
            if (data) {
              this.obj.novidadesInfo = data;
            }
            this._loading = false;
          });
          this._loading = false;
        },
        (error) => {
          this.service.handleError(error);
          this._loading = false;
        }
      );
    }
  }
}
