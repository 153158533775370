<div class="layout-wrapper" [ngClass]="{'layout-menu-horizontal': app.menuMode === 'horizontal',
                                        'layout-menu-overlay': app.menuMode === 'overlay',
                                        'layout-menu-static': app.menuMode === 'static',
                                        'layout-menu-slim': app.menuMode === 'slim',
                                        'layout-menu-light': true,
                                        'layout-menu-overlay-active': overlayMenuActive,
                                        'layout-menu-mobile-active': staticMenuMobileActive || overlayMenuActive,
                                        'layout-menu-static-inactive': staticMenuDesktopInactive,
                                        'p-input-filled': app.inputStyle === 'filled', 'p-ripple-disabled': !app.ripple}" (click)="onLayoutClick()">
    <!-- <div class="p-d-lg-none">
        <app-topbar></app-topbar>
    </div> -->
    

    <!-- <app-rightpanel></app-rightpanel> -->

    <app-menu></app-menu>

    <div class="layout-main">
        <!-- <div class="p-d-lg-none" style="height: 60px">
        </div> -->
        <div class="p-d-none p-d-md-block">
          <div id="acoes-rapidas" style="margin-bottom: 12px;"></div>
        </div>
        <app-breadcrumb></app-breadcrumb>

        <div class="layout-content">
            <router-outlet></router-outlet>
        </div>

        <!-- <app-footer></app-footer> -->
    </div>

    <!-- <app-config></app-config> -->
</div>
