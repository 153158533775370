import { HttpResponse } from "@angular/common/http";
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { DialogService } from 'primeng/dynamicdialog';
import { AbstractModel } from "src/app/models/abstract-model";
import { AbstractService } from "src/app/services/abstract.service";

@Component({
  selector: "habil-botoes-cadastro",
  templateUrl: "./botoes-cadastro.component.html",
  styleUrls: ["./botoes-cadastro.component.scss"],
  providers: [ConfirmationService, DialogService],
})
export class BotoesCadastroComponent<T extends AbstractModel> implements OnInit {
  @Input()
  service: AbstractService<T>;
  @Input()
  readonly: boolean = true;

  @Input()
  obj: Function;


  @Input('loading')
  _loading: boolean = false;

  @Input()
  validate: Function;

  @Input()
  onAfterSave: Function;

  @Input()
  limiteCredito: Function;

  @Input()
  contaPaga: boolean = false;

  @Input()
  btnFinal: boolean = false;

  @Input()
  isLctoFinanceiro: boolean = true;
  @Input()
  isFromCaixa: boolean = false;

  @Input('dialog')
  isDialog: boolean = false;

  @Input()
  doBeforeSave: Function = new Function();

  @Output()
  onSaveAndNew: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  onSaved: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  onError: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  onClosed: EventEmitter<T> = new EventEmitter<T>();

  _ignoraLimite = false;

  constructor(
    private router: Router,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ) { }

  ngOnInit() {

  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.ctrlKey) {
      if (event.shiftKey) {
        if (event.key === "S") {
          this.salvar();
        }
        if (event.key === "I") {
          this.salvar(true);
        }
      }
    } else {
      if (event.key == "Escape") {
        this.cancelar();
      }
    }
  }

  salvarBaixar(){
    this.salvar(false, true);
  }


  salvar(novo: boolean = false, baixar?: boolean) {
    if (this.readonly) return;

    if (this.service.rotina() == "PESSOA" && !this._ignoraLimite) {
      let promise: Promise<void> = this.limiteCredito(this.obj);
      promise.then(() => {
        this._ignoraLimite = true;
        this.salvar(novo, baixar);
      });
    } else {
      this.messageService.clear();
      if (this.doBeforeSave != undefined) {
        this.doBeforeSave();
      }

      this.onSaved.emit();
      if (this.validate()) {     
        let t: T = this.obj();
        this._loading = true;
        this.service.saveR(t).subscribe(
          (t: HttpResponse<T>) => {
            if (!baixar){
              this.onSaved.emit(t.body)
            }
            if (t.status == 200 || t.status == 201) {
              this._loading = false;
              if (this.onAfterSave != undefined) {

                let promise: Promise<void> = this.onAfterSave(t, baixar, novo);

                promise.then(() => {
                  if (!novo) {
                    this.router.navigate([this.service.routeLista()]);
                  } else {
                    this.onSaveAndNew.emit(true);

                  }
                });
              } else {
                if (!novo) {
                  this.router.navigate([this.service.routeLista()]);
                } else {
                  this.onSaveAndNew.emit(true);
                  this._ignoraLimite = false;
                }
              }
            } else {
              this._loading = false;
              this.service.handleError(t);
              let msg = "Ocorreu um erro ao processar os dados:\n" +
                t.status +
                " - " +
                t.statusText;
              this.messageService.add({ severity: 'error', summary: 'Erro', detail: msg });
            }
          },
          (e) => {
            this._loading = false;
            this.service.handleError(e);
            this.onError.emit(true);
          }
        );
      }
    }
  }
  salvarNovo() {
    this.salvar(true);
  }

  cancelar() {
    if (!this.readonly) {
      this.confirmationService.confirm({
        message: "Deseja cancelar as alterações no registro?",
        header: "Atenção",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Sim",
        rejectLabel: "Não",
        accept: () => {
          if (this.isDialog) {
            this.onClosed.emit();
            return;
          }
          this.router.navigate([this.service.routeLista()]);
        },
        reject: () => { },
      });
    } else {
      if (this.isDialog) {
        this.onClosed.emit();
        return;
      }
      this.router.navigate([this.service.routeLista()]);
    }
  }
}
