<habil-grupo>
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-6">
      <habil-label name="lbdata" label="Data">
        <habil-date-edit
          [(ngModel)]="obj.data"
          [showTime]="true"
        ></habil-date-edit>
      </habil-label>
    </div>
    <div class="p-field p-col-6">
      <habil-label name="lbatendente" label="Atendente">
        <input pInputText [(ngModel)]="obj.atendente" />
      </habil-label>
    </div>
    <div class="p-field p-col-12">
      <habil-label name="lbobs" label="Observações">
        <textarea pInputTextarea [(ngModel)]="obj.observacoes"></textarea>
      </habil-label>
    </div>
  </div>
</habil-grupo>
<br />
<habil-grupo>
  <div class="p-fluid p-grid">
    <div class="p-col-8"></div>
    <div class="p-col-2 p-col-align-end">
      <button
        pButton
        class="p-button-success"
        icon="pi pi-check"
        label="Salvar"
        (click)="salvar()"
      ></button>
    </div>
    <div class="p-col-2 p-col-align-end">
      <button
        pButton
        icon="pi pi-times"
        label="Cancelar"
        class="p-button-outlined p-button-danger"
        (click)="cancelar()"
        pRipple
      ></button>
    </div>
  </div>
</habil-grupo>
