import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { AdminUsuario } from './models/admin-usuario';
import { AdminUsuarioService } from './services/admin-usuario.service';
import { LoginService } from './services/login.service';
import { MessageService } from 'primeng/api';
import { AbstractService } from './services/abstract.service';
import { clone } from './utilities/util/clone-utils';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {
  model: any[] = [];

  events: string[] = [];
  opened: boolean = true;
  isAuthenticated: boolean = false;

  isOpen: boolean = false;

  public static avatarC: string;
  nomeUser: string = 'Admin';
  idUser: number;
  user: AdminUsuario;
  senhaAtual = '';
  novaSenha = '';
  confirmarNovaSenha = '';
  showEmpresaAtiva: boolean;
  showInfoSistema: boolean;
  showContagem: boolean = false;
  cols: any[];
  expiresIn: number;
  empresa: string;

  ano = new Date().getFullYear().toString();

  addIcon: string = 'pi pi-plus';

  _loading: boolean = false;
  menuUsuario: any[];

  public static _showAvatar: boolean = true;

  constructor(
    public app: AppMainComponent,
    public router: Router,
    public usuarioService: AdminUsuarioService,
    public loginService: LoginService,
    private messageService: MessageService
  ) {
  }

  get showAvatar() {
    return AppMenuComponent._showAvatar;
  }

  get theme() {
    return 'deeporange';
  }

  getMensagemHideShow(): string {
    if (this.app.isSlim()) {
      return 'Expandir Menu';
    } else if (!this.app.isSlim()) {
      return 'Diminuir Menu';
    }
    return '';
  }

  montaMenuUsuario() {
    this.menuUsuario = [
      // {
      //   label: 'Alterar tema',
      //   icon: this.theme == 'deeporange' ? 'pi pi-moon' : 'pi pi-sun',
      //   command: () => {
      //     //this.changeTheme();
      //   },
      // },
      {
        label: 'Trocar Senha',
        icon: 'pi pi-user-edit',
        command: () => {
          this.showTrocarSenha = true;
        },
      },
    ];

    /* red#7202
if (this.user.grupoAcesso.toString() == "1" || this.user.sysAdmin) {
  this.menuUsuario.push({
    label: "News",
    icon: "pi pi-bell",
    command: () => {
      this.router.navigate(["news"]);
    },
  });
}
*/

    this.menuUsuario.push({
      label: 'Desconectar',
      icon: 'pi pi-sign-out',
      command: () => {
        //this.desconectar();
        window.location.href = '/';
      },
    });
  }

  getSrcImage(): string {
    return (
      '../../../assets/img/avatar/' +
      (AppMenuComponent.avatarC != undefined
        ? AppMenuComponent.avatarC
        : 'avatar08.png')
    );
  }

  showTrocarSenha = false;
  loadingTrocarSenha = false;

  salvarSenha() {
    this.loadingTrocarSenha = true;
    this.loginService
      .validateUsername(this.user.login, this.senhaAtual)
      .subscribe(
        () => {
          if (this.novaSenha == this.confirmarNovaSenha) {
            this.user.senha = this.novaSenha;
            this.usuarioService.save(this.user).subscribe(() => {
              this.showTrocarSenha = false;
              this.loadingTrocarSenha = false;
              this.messageService.add({
                severity: 'success',
                summary: 'Sucesso',
                detail: 'Senha alterada com sucesso!',
              });
              this.senhaAtual = '';
              this.confirmarNovaSenha = '';
              this.novaSenha = '';
            });
          } else {
            this.loadingTrocarSenha = false;
            this.messageService.add({
              severity: 'warn',
              summary: 'Atenção',
              detail: 'Senhas não conferem!',
            });
          }
        },
        () => {
          this.loadingTrocarSenha = false;
          this.messageService.add({
            severity: 'warn',
            summary: 'Atenção',
            detail: 'Senha atual incorreta!',
          });
        }
      );
  }

  ngOnInit() {
    this.user = AbstractService.getLocalStorage('usuario');
    this.nomeUser = this.user.nome;
    this.montaMenuUsuario();

    let model = [
      {
        label: 'Dashboard',
        grupos: [1],
        icon: 'pi pi-fw pi-home',
        routerLink: ['/home'],
      },
      {
        label: 'Administração',
        grupos: [1],
        icon: 'pi pi-fw pi-star',
        routerLink: ['/adm'],
        items: [
          {
            label: 'Usuários',
            grupos: [1],
            routerLink: ['/adm/usuarios'],
          },
          {
            label: 'Produtos',
            grupos: [1],
            routerLink: ['/adm/produtos'],
          },
          { label: 'News', grupos: [1], routerLink: ['/news'] },
          {
            label: 'Parceiros',
            grupos: [1],
            routerLink: ['/adm/parceiros'],
          },
          {
            label: 'Log de Operações',
            grupos: [1],
            routerLink: ['/adm/log'],
          },
          {
            label: 'Estimativas de Acessos',
            grupos: [1],
            routerLink: ['/adm/estimativas'],
          },
          {
            label: 'Categorias de Relatórios',
            grupos: [1],
            routerLink: ['/adm/categorias-relatorios'],
          },
          {
            label: 'Planos de Suporte',
            grupos: [1],
            routerLink: ['/adm/plano-suporte'],
          },
          {
            label: 'Servidores Hábil 10',
            grupos: [1],
            routerLink: ['/adm/servidor'],
          },
          {
            label: 'Gerenciamento Nuvem',
            grupos: [1],
            routerLink: ['/adm/nuvem'],
          },
        ],
      },
      {
        label: 'Clientes',
        grupos: [1, 2],
        icon: 'pi pi-fw pi-id-card',
        routerLink: ['clientes'],
        items: [
          {
            label: 'Cadastro',
            grupos: [1, 2],
            routerLink: ['/clientes/registro'],
          },
          {
            label: 'Classificação',
            grupos: [1],
            routerLink: ['/clientes/classificacao'],
          },
          {
            label: 'Consulta',
            grupos: [1],
            routerLink: ['/clientes/consulta'],
          },
          {
            label: 'Contagem de Emissão',
            grupos: [1],
            routerLink: ['/clientes/contagem'],
          },
          {
            label: 'Liberações do Enterprise',
            grupos: [1],
            routerLink: ['/clientes/liberacoes-herp'],
          },
          {
            label: 'Liberações do Empresarial',
            grupos: [1],
            routerLink: ['/clientes/liberacoes-he'],
          }
        ],
      },
      {
        label: 'Pedidos/Liberações',
        grupos: [1],
        icon: 'pi pi-fw pi-ticket',
        routerLink: ['/liberacao'],
      },
      {
        label: 'Atendimentos',
        grupos: [1, 2, 3],
        icon: 'pi pi-fw pi-phone',
        routerLink: ['/atendimento'],
      },
      {
        label: 'Logs',
        grupos: [1, 2, 3],
        icon: 'pi pi-fw pi-list',
        routerLink: ['/logs'],
      },
      {
        label: 'Central de Relatórios',
        grupos: [1, 2, 3],
        icon: 'pi pi-fw pi-file',
        command: (): void => {
          localStorage.setItem('token', AbstractService.token());
          window.location.href = environment.reports;
        },
        routerLink: ['/reports'],
      },
      {
        label: 'Configurações',
        grupos: [1],
        icon: 'pi pi-fw pi-cog',
        routerLink: ['/cfg'],
      },
    ];
    const isAdm = this.user.sysAdmin;
    const grupo = parseInt(this.user.grupoAcesso.toString());
    this.model = this.validaGrupoAcesso(isAdm, grupo, model);
  }

  validaGrupoAcesso(isAdm, grupo, model) {
    let retorno = [];
    for (let itemMenu of model) {
      if (itemMenu.grupos.includes(grupo) || isAdm) {
        let itemInclusao = clone(itemMenu);
        if (itemInclusao.items && itemInclusao.items.length > 0) {
          itemInclusao.items = [];
          itemInclusao.items = this.validaGrupoAcesso(
            isAdm,
            grupo,
            itemMenu.items
          );
        }
        retorno.push(itemInclusao);
      }
    }
    return retorno;
  }
}
