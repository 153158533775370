<p-toast></p-toast>
<habil-grupo>
  <div class="p-fluid p-grid">
    <div class="p-col-10">
      <habil-label
          label="Pesquisa por CNPJ"
          name="pesquisaCnpj">
        <input
            (keydown.enter)="buscar()"
            [(ngModel)]="cnpj"
            pInputText>
      </habil-label>
    </div>
    <div class="p-col-2 p-col-align-end">
      <button
          (click)="buscar()"
          [loading]="loading"
          icon="pi pi-search"
          label="Pesquisar"
          pButton>
      </button>
    </div>
  </div>
</habil-grupo>
<br>
<habil-grupo>
  <div class="p-fluid p-grid">
    <!-- <div class="p-field p-col-12">
      <habil-label
          label="CNPJ"
          name="cnpj">
        <input
            [readonly]="true"
            [value]="liberacao.cnpj"
            pInputText>
      </habil-label>
    </div> -->
    <div class="p-field p-col-12">
      <habil-label
          label="Liberado em"
          name="data">
        <input
            [readonly]="true"
            [value]="liberacao.data | date: 'dd/MM/yyyy HH:mm:ss'"
            pInputText>
      </habil-label>
    </div>
    <div class="p-field p-col-12">
      <habil-label
          label="Vencimento"
          name="vcto">
        <input
            [readonly]="true"
            [value]="liberacao.vcto | date: 'dd/MM/yyyy'"
            pInputText>
      </habil-label>
    </div>
    <!-- <div class="p-field p-col-12">
      <habil-label
          label="Código de liberação"
          name="codigo">
        <input
            [readonly]="true"
            [value]="liberacao.codigo"
            pInputText>
      </habil-label>
    </div>
    <div class="p-field p-col-12">
      <habil-label
          label="Foi lido pelo usuário?"
          name="guia">
        <input
            [readonly]="true"
            [value]="getGuia()"
            pInputText>
      </habil-label>
    </div> -->
  </div>
</habil-grupo>
