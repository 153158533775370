import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { AbstractService } from 'src/app/services/abstract.service';
import { ParceiroService } from 'src/app/services/parceiro.service';

@Component({
  selector: 'habil-parceiro',
  templateUrl: './parceiro.component.html',
  styleUrls: ['./parceiro.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class ParceiroComponent implements OnInit {

  constructor(private breadcrumbService: BreadcrumbService, public service: ParceiroService, titleService: Title) {
    this.breadcrumbService.setItems([{label: 'Parceiros'}]);
    titleService.setTitle('Parceiros - ' + AbstractService.app);
  }

  ngOnInit(): void {
  }

}
