import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LiberacaoComponent } from './liberacao.component';
import { GrupoModule } from 'src/app/utilities/grupo/grupo.module';
import { TableModule } from 'primeng/table';
import { SkeletonModule } from 'primeng/skeleton';
import { LabelModule } from 'src/app/utilities/label/label.module';
import { DateEditModule } from 'src/app/utilities/date-edit/date-edit.module';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { LiberacaoEmpresarialComponent } from './liberacao-empresarial/liberacao-empresarial.component';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { CheckboxModule } from 'primeng/checkbox';
import { TooltipModule } from 'primeng/tooltip';
import { CalendarModule } from 'primeng/calendar';
import { PanelModule } from 'primeng/panel';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { AccordionModule } from 'primeng/accordion';
import { ChipsModule } from 'primeng/chips';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { BadgeModule } from 'primeng/badge';
import { DividerModule } from 'primeng/divider';
import { LiberacaoEnterpriseComponent } from './liberacao-enterprise/liberacao-enterprise.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import { LiberacaoH10Component } from './liberacao-h10/liberacao-h10.component';
import { PickListModule } from 'primeng/picklist';
import { TagModule } from 'primeng/tag';
import { ProgressBarModule } from 'primeng/progressbar';
import { LiberacaoAtendimentoComponent } from './liberacao-atendimento/liberacao-atendimento.component';
import { AutocompleteEditModule } from 'src/app/utilities/autocomplete-edit/autocomplete-edit.module';
import { LiberacaoSuporteComponent } from './liberacao-suporte/liberacao-suporte.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToolbarModule } from 'primeng/toolbar';

@NgModule({
  declarations: [
    LiberacaoComponent, 
    LiberacaoEmpresarialComponent,
    LiberacaoEnterpriseComponent,
    LiberacaoH10Component,
    LiberacaoAtendimentoComponent,
    LiberacaoSuporteComponent
  ],
  imports: [
    CommonModule,
    TableModule,
    FormsModule,    
    GrupoModule,
    SkeletonModule,
    LabelModule,
    DateEditModule,
    DropdownModule,
    InputTextModule,
    ButtonModule,
    ToastModule,
    DialogModule,
    ConfirmDialogModule,
    ToolbarModule,
    InputNumberModule,
    CheckboxModule,
    DynamicDialogModule,
    ToastModule,
    TooltipModule,
    CalendarModule,
    PanelModule,
    ScrollPanelModule,
    AccordionModule,
    ChipsModule,
    InputTextareaModule,
    BadgeModule,
    DividerModule,
    ClipboardModule,
    PickListModule,
    TagModule,
    ProgressBarModule,
    AutocompleteEditModule
  ]
})
export class LiberacaoModule { }
