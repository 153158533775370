import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { LoginService } from "./login.service";
import { AbstractService } from "./abstract.service";
import { Observable } from "rxjs";
import { HabilPlayProduto } from "../models/habil-play-produto";

@Injectable({
  providedIn: "root",
})
export class HabilPlayProdutoService extends AbstractService<HabilPlayProduto> {
  constructor(
    public http: HttpClient,
    private router: Router,
    public loginService: LoginService
  ) {
    super(http, loginService);
  }

  titulo: string = "Hábil Play";

  endpoint(): string {
    return "registro/habil-play-produto";
  }

  rotina(): string {
    return "HABIL_PLAY_PRODUTO";
  }

  colunas(): any[] {
    return [
      { header: "Código", field: "id" },
      { header: "Nome", field: "nomeProduto" },
    ];
  }

  corrigeAtributos(t: any): HabilPlayProduto {
    t.acessos = [];
    for (let acesso of t["acessosComDescricao"]) {
      t.acessos.push(acesso.value);
    }
    t["acessosComDescricao"] = [];
    return super.corrigeAtributos(t);
  }

  private getHeaders(): HttpHeaders {
    return new HttpHeaders({
      Accept: "application/json; charset=utf-8",
      "X-Auth-Token": AbstractService.token(),
    });
  }

  getModulosAdicionais(): Observable<any[]> {
    const url = this.baseUrl() + "/modulos-adicionais";

    return this.http.get<any[]>(url, { headers: this.getHeaders() });
  }

  getAcessos(): Observable<any> {
    const url = this.baseUrl() + "/acessos";

    return this.http.get<any[]>(url, { headers: this.getHeaders() });
  }

  novo(selection?: any) {
    AbstractService.readonly = false;
    this.router.navigate(["adm/produtos/cadastro-habil-play"]);
  }

  abrir(obj: HabilPlayProduto) {
    AbstractService.readonly = true;
    this.router.navigate([
      "adm/produtos/cadastro-habil-play",
      { query: { id: obj.id } },
    ]);
  }

  edit(obj: HabilPlayProduto) {
    AbstractService.readonly = false;
    this.router.navigate([
      "adm/produtos/cadastro-habil-play",
      { query: { id: obj.id } },
    ]);
  }

  routeLista() {
    return "adm/produtos";
  }
}
