import { DateUtils } from "../utilities/util/date-utils";
import { AbstractModel } from "./abstract-model";
import { PlanoSuporte } from "./plano-suporte";

export class Atendimento extends AbstractModel {
  cpfCnpj: string = "";
  nome: string = "";
  razaoSocial: string = "";
  email: string = "";
  primeiraLiberacao: Date;
  inicio: Date = new Date();
  fim: Date = DateUtils.incMonth( new Date(), 1 );
  telefone: string = "";
  planoSuporte: PlanoSuporte;
  excecoes: AtendimentoExcecao[] = [];  
}

export class AtendimentoExcecao extends AbstractModel {
  atendimento: Atendimento;
  atendente: string;
  data: Date = new Date();
  observacoes: string;
}