import { AbstractModel } from "./abstract-model";

export class Balanceamento extends AbstractModel {
  tenant: string;
  servidor: Servidor;
}

export class Servidor extends AbstractModel {
  nome: string;
  url: string;
}
