import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogOperacoesComponent } from './log-operacoes.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AccordionModule } from 'primeng/accordion';
import { ProgressBarModule } from 'primeng/progressbar';
import { RippleModule } from 'primeng/ripple';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { BaseListaModule } from 'src/app/utilities/base-lista/base-lista.module';
import { BotoesCadastroModule } from 'src/app/utilities/botoes-cadastro/botoes-cadastro.module';
import { GrupoModule } from 'src/app/utilities/grupo/grupo.module';
import { LabelModule } from 'src/app/utilities/label/label.module';
import { AutocompleteEditModule } from 'src/app/utilities/autocomplete-edit/autocomplete-edit.module';
import { DropdownModule } from 'primeng/dropdown';
import { DateEditModule } from 'src/app/utilities/date-edit/date-edit.module';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';



@NgModule({
  declarations: [
    LogOperacoesComponent
  ],
  imports: [
    CommonModule,
    BaseListaModule,
    FormsModule,
    BrowserModule,
    ConfirmDialogModule,
    GrupoModule,
    BotoesCadastroModule,
    TooltipModule,
    AccordionModule,
    LabelModule,
    ProgressBarModule,
    RippleModule,
    ToastModule,
    ScrollPanelModule,
    InputTextModule,
    AutocompleteEditModule,
    DropdownModule,
    DateEditModule,
    TableModule,
    DialogModule
  ]
})
export class LogOperacoesModule { }
