import { ContagemEmissaoView } from "./../../../models/registro";
import { ContagemEmissaoService } from "./../../../services/contagem-emisao.service";
import { Component, OnInit } from "@angular/core";
import { MessageService, ConfirmationService } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { AbstractService } from "src/app/services/abstract.service";
import { ClassificacaoService } from "src/app/services/classificacao.service";
import { BreadcrumbService } from "src/app/app.breadcrumb.service";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "habil-consulta-contagem-emissao",
  templateUrl: "./consulta-contagem-emissao.component.html",
  styleUrls: ["./consulta-contagem-emissao.component.scss"],
  providers: [MessageService, DialogService],
})
export class ConsultaContagemEmissaoComponent implements OnInit {
  constructor(
    public service: ContagemEmissaoService,
    public classificacaoService: ClassificacaoService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    titleService: Title
  ) {
    this.breadcrumbService.setItems([
      { label: "Consulta de Contagem de Emissões" },
    ]);
    titleService.setTitle("Contagem de Emissões - " + AbstractService.app);
  }

  loading = false;

  classificacoes = [];
  classificacoesTodas = [];
  mes = (new Date().getMonth() + 1).toLocaleString("pt-BR", {
    minimumIntegerDigits: 2,
  });

  meses = [
    { label: "Janeiro", value: "01" },
    { label: "Fevereiro", value: "02" },
    { label: "Março", value: "03" },
    { label: "Abril", value: "04" },
    { label: "Maio", value: "05" },
    { label: "Junho", value: "06" },
    { label: "Julho", value: "07" },
    { label: "Agosto", value: "08" },
    { label: "Setembro", value: "09" },
    { label: "Outubro", value: "10" },
    { label: "Novembro", value: "11" },
    { label: "Dezembro", value: "12" },
  ];

  ano = new Date().getFullYear();
  list: ContagemEmissaoView[] = [];

  exportColumns = [
    { header: "Código", field: "registro" },
    { header: "CNPJ", field: "cnpj" },
    { header: "Nome", field: "nome" },
    { header: "E-mail", field: "email" },
    { header: "Franquia", field: "contagem" },
    { header: "Contagem", field: "contagemAtual" },
    { header: "Excedente", field: "excedente" },
  ];
  totalFranquia: number = 0;
  totalEmissoes: number = 0;
  totalExcedido: number = 0;
  totalEmpresasEmissoras: number = 0;


  detalhes = [];
  display: boolean = false;

  showDialog(detalhes) {
    //this.display = true;
    this.detalhes = detalhes;
  }

  ngOnInit(): void {
    this.classificacaoService.findAll(0, 0, "").subscribe((c) => {
      this.classificacoesTodas = [...c];
    });
  }

  busca() {
    const buscar = () => {
      this.loading = true;
      let classificacoes = "";
      for (const c of classificacoes) {
        classificacoes += c + ",";
      }
      classificacoes += "*";
      classificacoes = classificacoes.replace(",*", "").replace("*", "");
      this.service.consulta(`${this.mes}${this.ano}`, classificacoes).subscribe(
        (lst) => {
          this.totalFranquia = 0;
          this.totalEmissoes = 0;
          this.totalExcedido = 0;
          this.totalEmpresasEmissoras = 0;

          if (lst) {
            for (const item of lst) {
              item.excedente = 0;
              const cont = parseInt(item.contagem.toString());
              const atual = parseInt(item.contagemAtual.toString());

              if (cont > atual) {
                item.excedente = cont - atual;
              }
              this.totalFranquia += atual;
              this.totalEmissoes += cont;
              this.totalExcedido += item.excedente;
              if (cont) {
                this.totalEmpresasEmissoras++;
              }
            }
          }
          this.loading = false;
          this.list = [...lst];
        },
        (e) => {
          this.loading = false;
          this.service.handleError(e);
        }
      );
    };

    this.confirmationService.confirm({
      header: "Atenção",
      message:
        //"Iniciar atualização manual? A atualização manual levará cerca de um minuto para ser completada.",
        "Iniciando atualização! Deseja notificar o usuário caso a contagem exceda o máximo?",
      acceptLabel: "Sim",
      rejectLabel: "Não",
      accept: () => {
        this.loading = true;
        this.service.atualizarAgoraMes(this.mes, this.ano, true).subscribe(
          () => {
            buscar();
          },
          (e) => {
            this.loading = false;
            this.service.handleError(e);
          }
        );
      },
      reject: () => {
        this.loading = true;
        this.service.atualizarAgoraMes(this.mes, this.ano, false).subscribe(
          () => {
            buscar();
          },
          (e) => {
            this.loading = false;
            this.service.handleError(e);
          }
        );
      },
    });
  }
}
