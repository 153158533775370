import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { Servidor } from 'src/app/models/balanceamento';
import { AbstractService } from 'src/app/services/abstract.service';
import { ServidorService } from 'src/app/services/servidor.service';
import { GenericCadastro } from 'src/app/utilities/generic-cadastro';
import { FormValidation, StringValidationItem } from 'src/app/utilities/util/form-validation';

@Component({
  selector: 'habil-cadastro-servidor',
  templateUrl: './cadastro-servidor.component.html',
  styleUrls: ['./cadastro-servidor.component.scss']
})
export class CadastroServidorComponent extends GenericCadastro<Servidor, ServidorService> implements OnInit {
  
  newObject(): Servidor {
    return new Servidor();
  }
  
  doValidate(): boolean {
    let valid: FormValidation = new FormValidation()
      .add( new StringValidationItem( this.obj.nome, "Nome obrigatório!" ) )
      .add( new StringValidationItem( this.obj.url, "URL obrigatória!" ) );
    if ( !valid.isValid() ) {
      this.messageService.addAll( valid.msgs() );
    }
    return valid.isValid();
  }

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private title: Title,
    public service: ServidorService,
    private messageService: MessageService,
    public confirmationService: ConfirmationService,
    public bcService: BreadcrumbService
  ) { 
    super();
    this.bcService.setItems( 
      [ 
        { 
          label: "Servidores", 
          routerLink: "/adm/servidor",          
        },
        {
          label: "Cadastro"
        }
      ]
    );
  }

  ngOnInit(): void {
    this._loading = false;
    this.title.setTitle( "Servidor" + AbstractService.app )
    super.ngOnInit();
  }

  doBeforeSave() {

  }

}
