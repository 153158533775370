<p-confirmDialog header="Atenção" icon="pi pi-exclamation-triangle">
</p-confirmDialog>
<p-toast></p-toast>
<div class="p-field p-col-12 p-md-12 p-lg-12" *ngIf="_loading">
  <p-progressBar
    mode="indeterminate"
    [style]="{ height: '6px' }"
  ></p-progressBar>
</div>
<habil-grupo>
  <p-scrollPanel [style]="{ width: '100%', height: getScrollHeight() }">
    <p-accordion>
      <p-accordionTab header="Dados Básicos" [selected]="true">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12">
            <habil-label name="txtNome" label="Nome">
              <input
                [readonly]="_readonly"
                type="text"
                name="txtNome"
                pInputText
                [(ngModel)]="obj.descricao"
              />
            </habil-label>
          </div>
        </div>
      </p-accordionTab>
    </p-accordion>
  </p-scrollPanel>
</habil-grupo>
<br />
<habil-botoes-cadastro
  (onError)="onErrorHandled()"
  [obj]="getObject.bind(this)"
  [dialog]="_isDialog"
  (onSaved)="onSave($event)"
  (onClosed)="onCancel()"
  [service]="service"
  [readonly]="_readonly"
  [validate]="doValidate.bind(this)"
  [doBeforeSave]="doBeforeSave.bind(this)"
  (onSaveAndNew)="onSaveAndNew($event)"
>
</habil-botoes-cadastro>
