import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'habil-mensagem',
  templateUrl: './mensagem.component.html',
  styleUrls: ['./mensagem.component.scss']
})
export class MensagemComponent implements OnInit {

  texto: string;

  constructor( private ref: DynamicDialogRef, private cfg: DynamicDialogConfig ) { 
    
  }

  ngOnInit(): void {
    this.texto = this.cfg.data.texto;
  }

  fechar() {
    this.ref.close();
  }

}
