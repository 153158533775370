import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { AbstractModel } from 'src/app/models/abstract-model';
import { AbstractService } from 'src/app/services/abstract.service';
import { FormatUtils } from '../util/format-utils';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'habil-base-lista',
  templateUrl: './base-lista.component.html',
  styleUrls: ['./base-lista.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class BaseListaComponent<T extends AbstractModel> implements OnInit {

  @Input('service')
  _service: AbstractService<T>;
  showSearch = true;
  search = "";
  _loading = false;
  selection: any;
  _selection = null;
  cols: any[] = [];
  objs: any[] = [];
  @Input()
  subActions: MenuItem[] = [];
  menuContexto: { label: string; icon: string; command: () => any; disabled: boolean; }[];
  first = 0;
  recordCount: number;
  loading: boolean;
  pSize: number = 100;
  index = 0;
  duplicar = false;
  @Input()
  showAbrir = true;
  @Input()
  showEditar = true;
  @Input()
  showExcluir = true;
  @Input()
  showAcoes = true;
  @Input()
  montaObjeto: Function = (objs) => {
    return objs;
  };

  private modelChanged: Subject<string> = new Subject<string>();
  private subscription: Subscription;

  items = [
    { 
        label: 'File',
        items: [{
                label: 'New', 
                icon: 'pi pi-fw pi-plus',
                items: [
                    {label: 'Project'},
                    {label: 'Other'},
                ]
            },
            {label: 'Open'},
            {label: 'Quit'}
        ]
    },
    {
        label: 'Edit',
        icon: 'pi pi-fw pi-pencil',
        items: [
            {label: 'Delete', icon: 'pi pi-fw pi-trash'},
            {label: 'Refresh', icon: 'pi pi-fw pi-refresh'}
        ]
    }
];

  acoes = [];

  _tratandoAcao = false;

  getScrollHeight(): string {
    let fullHeight = window.innerHeight;
    let availableHeight = fullHeight - 270;
    let scrollHeight = availableHeight > 100 ? availableHeight + 'px' : '100px';

    return scrollHeight;
  }

  formatCpfCnpj(cpfCnpj) {
    return FormatUtils.cpfCnpj(cpfCnpj);
  }

  selectClick(item) {
    this._selection = item;
  }

  onListChange(event) {
    this.objs = [...event];
  }
  
  pesquisaChange(event?) {
    this.modelChanged.next(undefined);
  }

  private constroiComponentesPesquisa() {
    this.subscription = this.modelChanged
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.reset();
      });
  }

  onPageChange(event) {
    this._loading = true;
    this.selection = undefined;
    if (event.page == null || event.page == undefined
    || event.rows == null  || event.rows == undefined) {
      event.page = 0;
      event.rows = 10;
    }

    const regex = /^[\d.-]+$/;
    let search = this.search;
    if (regex.test(this.search)) {
      search = this.search.replace(/[.-]/g, '');
    }


    this._service.findAll(event?.page * event?.rows, event?.rows ?? 10, search, '', [])
          .subscribe(objs => {
            if (objs != undefined && objs != null) {
              this.objs = [...objs];
              this.objs = this.montaObjeto(this.objs)
            } else {
              this.objs = [];
            }
            this._loading = false;
          }, (e) => {this._service.handleError(e)});
  }

  getFieldValue(rowData: any, col: any): any {
    let field: string = col.field;
    let result: any = rowData;
    if (field.indexOf('.') > 0) {
      let fields: string[] = field.split('.');
      for (let i = 0; i < fields.length; i++) {
        try {
          if (result && result[fields[i]] != null && result[fields[i]] != undefined) {
            result = result[fields[i]];
          }
          else {
            result = undefined;
          }
        }
        catch ( e ) { 
          result = undefined
        }
      }
    }
    else {
      result = rowData[col.field];
    }
    if (result != undefined && result != null) {
      if (col.type == 'date') {
        if (result != "") {
          let date = moment(result).toDate();
          return date;
        } else {
          return undefined;
        }
      } else {
        return result;
      }
    } else {
      return undefined;
    }
  }

  recarregarPesquisa() {
    this.reset();
  }
  
  constructor(private router: Router,
    private confirmationService: ConfirmationService,
    private messageService: MessageService) { }

  ngOnInit(): void {
    this.cols = [...this._service.colunas()];
    this.reset();
    this.constroiComponentesPesquisa();
  }

  reset() {
    if (this.search.includes('%')){
      this.messageService.add({severity: 'warn', summary: 'Atenção!', detail: 'Caractere inválido para busca: %'})
      return;
    }

    const regex = /^[\d.-]+$/;
    let search = this.search;
    if (regex.test(this.search)) {
      search = this.search.replace(/[.-]/g, '');
    }

    this._service.count(search, [])
        .subscribe(count => {
          this.recordCount = count;
          let pages = count / this.pSize;
          if (pages - Math.trunc(pages) > 0) {
            pages++;
          }
          this.onPageChange({ first: 0, rows: this.pSize, page: 0, pageCount: pages })
        }, (e) => this._service.handleError(e));
  }

  searchId() {
    try {
      if (!isNaN(parseInt(this.search))) {
        this._loading = true;
          this._service.findById(parseInt(this.search + "")).subscribe(obj => {
            if (obj) {
              this.recordCount = 1;
              this.objs = [];
              this.objs.push({...obj});
              this._loading = false;
            } else {
              this.recordCount = 0;
            }
          })
      }
    }catch (e) {
      console.log(e);
    }
  }

  get podeEditar() {
    if (!this.showEditar) return false;
    return true;
  }

  get podeApagar() {
    return true;
  }

  get podeAbrir() {
    
    return true;
  }

  novo() {
    this._service.novo();
  }

  
  abrir(item) {
    this._service.abrir(item);
  }

  edit(item) {
    this._service.edit(item);
  }

  acaoPadrao(item){
    if (this.podeEditar) {
      this._service.edit(item);
    } else {
      this._service.abrir(item);
    }
  }

  doDelete(item) {
    this.confirmationService.confirm({
      message: 'Confirma a exclusão?',
      header: 'Atenção',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      accept: () => {
        this._service.delete(item.id)
          .subscribe(e => {
            if (item.deleted != null && item.deleted != undefined ) { item.deleted = true;}
            this.reset();
          }, (e) => this._service.handleError(e));
      },
      reject: () => {

      }
    });
  }
}
