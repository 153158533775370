import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { BotoesCadastroComponent } from './botoes-cadastro.component';
import {TooltipModule} from 'primeng/tooltip';
import {RippleModule} from 'primeng/ripple';
import { GrupoModule } from '../grupo/grupo.module';



@NgModule({
  declarations: [
    BotoesCadastroComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    GrupoModule,
    ConfirmDialogModule,
    TooltipModule,
    RippleModule
  ],
  exports: [
    BotoesCadastroComponent
  ]
})
export class BotoesCadastroModule { }
