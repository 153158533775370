import { LabelModule } from './../../utilities/label/label.module';
import { DialogModule } from "primeng/dialog";
import { InputTextModule } from "primeng/inputtext";
import { TableModule } from "primeng/table";
import { ToastModule } from "primeng/toast";
import { MessagesModule } from "primeng/messages";
import { ButtonModule } from "primeng/button";
import { FormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NuvemGerenciamentoComponent } from "./nuvem-gerenciamento/nuvem-gerenciamento.component";
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { GerenciamentoServidorComponent } from './gerenciamento-servidor/gerenciamento-servidor.component';
import { DropdownModule } from 'primeng/dropdown';

@NgModule({
  declarations: [NuvemGerenciamentoComponent, GerenciamentoServidorComponent],
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    MessagesModule,
    ToastModule,
    TableModule,
    DropdownModule,
    InputTextModule,
    DialogModule,
    LabelModule,
    ConfirmDialogModule,
  ],
})
export class NuvemModule {}
