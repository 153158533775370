import { Component, OnInit, Type } from '@angular/core';
import { ConfirmationService, LazyLoadEvent, MessageService, SelectItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ELiberacaoStatus, ELiberacaoTipo, Liberacao, LiberacaoFiltroDTO } from 'src/app/models/liberacao';
import { LiberacaoService } from 'src/app/services/liberacao.service';
import { FormValidation } from 'src/app/utilities/util/form-validation';
import { LiberacaoEnterpriseComponent } from './liberacao-enterprise/liberacao-enterprise.component';
import { LiberacaoEmpresarialComponent } from './liberacao-empresarial/liberacao-empresarial.component';
import { MensagemService } from 'src/app/services/mensagem.service';
import { LiberacaoH10Component } from './liberacao-h10/liberacao-h10.component';
import { LiberacaoSuporteComponent } from './liberacao-suporte/liberacao-suporte.component';

@Component({
  selector: 'habil-liberacao',
  templateUrl: './liberacao.component.html',
  providers: [ DialogService ],
  styleUrls: ['./liberacao.component.scss']
})
export class LiberacaoComponent implements OnInit {

  list: Liberacao[] = [];
  filtro: LiberacaoFiltroDTO = new LiberacaoFiltroDTO(); 
  tamanhoPagina = 10;
  count: number = 0;
  loading: boolean = false;

  listastatus = [
    { label: "Todos", value: null },
    { label: "Pendente", value: ELiberacaoStatus.PENDENTE },
    { label: "Finalizado", value: ELiberacaoStatus.FINALIZADO},
    { label: "Cancelado", value: ELiberacaoStatus.CANCELADO },
  ]
  listatipos = [
    { label: "Todos", value: null },
    { label: "Suporte Clube", value: ELiberacaoTipo.SUPORTE_CLUBE },
    { label: "Suporte Premium", value: ELiberacaoTipo.SUPORTE_PREMIUM },
    { label: "Hábil Empresarial", value: ELiberacaoTipo.EMPRESARIAL },
    { label: "Hábil Enterprise", value: ELiberacaoTipo.HERP },
    { label: "Hábil 10", value: ELiberacaoTipo.H10 }          
  ]


  constructor(
    public service: LiberacaoService,
    public message: MessageService,
    public dialog: DialogService,
    public mensagem: MensagemService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
    this.reset();
  }

  reset() {
    if ( this.validaFiltro() ) {
      this.list = [];
      this.service.countParaLista( this.filtro ).subscribe( 
        count => {          
          this.count = count;
          if ( count > this.tamanhoPagina ) {
            this.loadRegs( { first: 0, rows: this.tamanhoPagina } )
          }
          else {
            this.loadRegs( { first: 0, rows: count } );
          }
        },
        error => {
          this.service.handleError( error );
        }
      )
    }
  }

  private validaFiltro(): boolean {
    let v : FormValidation = new FormValidation().add( 
      {
        condition: this.filtro.dataInicial != undefined,
        message: "Data inicial obrigatória!"
      }
    )
    .add(
      {
        condition: this.filtro.dataFinal != undefined,
        message: "Data final obrigatória!"
      }
    );
    if ( !v.isValid() ) {
      v.message().forEach(
        s => {
          this.message.add(
            {
              severity: "info",
              summary: s
            }
          );
        }
      );
    }
    return v.isValid();
  }

  getStatusLabel( value: ELiberacaoStatus ): string {
    let ret: string = value;
    this.listastatus.forEach(
      a => {
        if ( a.value == value ) {
          ret = a.label;
          return;
        }
      }
    );
    return ret;
  }

  naoPermiteLiberacao( reg: Liberacao ): boolean {
    return reg.status == ELiberacaoStatus.CANCELADO;
  }

  getTipoLabel( value: ELiberacaoTipo ): string {
    let ret: string = value;
    this.listatipos.forEach(
      e => {
        if ( e.value == value ) {
          ret = e.label;
          return;
        }
      }
    );
    return ret;
  }

  filtrar() {    
    this.reset()
  }

  getScrollHeight(): string {
    let fullHeight = window.innerHeight;
    let availableHeight = fullHeight - 450;
    let scrollHeight = availableHeight > 100 ? availableHeight + "px" : "100px";
    return scrollHeight;
  }

  loadRegs(event: LazyLoadEvent) {
    this.loading = true;
    this.service.findParaLista( event.first, event.rows, this.filtro )
      .subscribe(        
        data => {
          if ( data ) {
            this.list = [ ...data ];
          }
          this.loading = false;
          
        },
        error => {
          this.service.handleError( error );
        }
      )
  }

  geraLiberacaoEnterprise() {
    this.liberar( new Liberacao( ELiberacaoTipo.HERP ) );
  }

  geraChaveH10() {
    this.liberar( new Liberacao( ELiberacaoTipo.H10 ) );
  }

  geraChaveEmpresarial() {
    this.liberar( new Liberacao( ELiberacaoTipo.EMPRESARIAL ) );
  }

  geraSuporte() {
    this.liberar( new Liberacao( ELiberacaoTipo.SUPORTE_CLUBE ) );
  }

  finalizar( liberacao: Liberacao ) {
    this.confirmationService.confirm(
      {
        message: 'Finalizar sem gerar follow-up?',
        header: 'Confirme',
        icon: 'pi pi-exclamation-triangle',
        
        accept: () => {
          liberacao.status = ELiberacaoStatus.FINALIZADO;
          this.service.save( liberacao ).subscribe(
            (data) => {
              this.localizaEAualizaLiberacao( liberacao, data );
            }
          )
        },
        reject: (type) => {
          // faz nada
        }
      }
    )  
  }

  liberar( liberacao: Liberacao ) {
    if (!liberacao) {
      liberacao = new Liberacao();
    }
    let comp: Type<any>;
    switch ( liberacao.tipo ) {
      case ELiberacaoTipo.EMPRESARIAL: {
        comp = LiberacaoEmpresarialComponent;        
        break;
      }
      case ELiberacaoTipo.HERP: {
        comp = LiberacaoEnterpriseComponent;
        break;
      }
      case ELiberacaoTipo.H10: {
        comp = LiberacaoH10Component;
        break;
      }
      case ELiberacaoTipo.SUPORTE_CLUBE:
      case ELiberacaoTipo.SUPORTE_PREMIUM: {
        comp = LiberacaoSuporteComponent;
        break;
      }
      default: {
        this.mensagem.erro( "arriba" );
      }
    }
    this.dialog.open( 
      comp, {
        header: this.getTipoLabel( liberacao.tipo ),
        width: '90%',
        data: {
          liberacao: liberacao
        }
      }
    )
    .onClose.subscribe( 
      data => {
        this.localizaEAualizaLiberacao( liberacao, data );
      }
    );
  }

  private localizaEAualizaLiberacao( liberacao: Liberacao, data: Liberacao ) {
    if ( data ) {
      const index = this.list.indexOf( liberacao );
      if ( index > -1 ) {
        let arr = [ ... this.list ];
        arr.splice( index, 1, data );
        this.list = [ ...arr ];
      }
    }
  }

}
 