import { formatDate } from "@angular/common";
import { DateUtils } from "./date-utils";

export class FormatUtils {
  public static toJson(obj) {
    var replacer = function(key, value) {

      if (this[key] instanceof Date) {
          return DateUtils.dateTimeToString(this[key]);
      }
      
      return value;
    }
    if (obj?.dataBaixa) {
      obj.dataBaixa = new Date(obj.dataBaixa);
    }
    return JSON.stringify(obj, replacer);
  }
  
  public static number(n: number, pInt: number, pDec: number): string {
    return Intl.NumberFormat('pt-Br', { minimumIntegerDigits: pInt, minimumFractionDigits: pDec }).format(n);

  }

  
  public static cpfCnpj(inStr: string): string {
    if (inStr == undefined) {
      return "";
    }
    if (inStr.length <= 11) {
      return this.cpf(this.clear(inStr));
    } else {
      return this.cnpj(this.clear(inStr));
    }
  }

  public static cnpj(inStr: string): string {
    if (inStr == undefined) {
      return "";
    }
    let outStr: string = this.clear(inStr).trim();
    if (outStr.length > 14) {
      outStr = outStr.substring(0, 14);
    }
    outStr = outStr.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    return outStr;
  }

  public static cpf(inStr: string): string {
    if (inStr == undefined) {
      return "";
    }
    let outStr: string = this.clear(inStr).trim();
    if (outStr.length > 11) {
      outStr = outStr.substring(0, 11);
    }

    outStr = outStr.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    return outStr;
  }

  public static clear(inStr: string, chars?: string): string {
    let out: string = inStr;
    if (out == undefined) {
      return "";
    }
    if (chars == undefined) {
      chars = '~`*()!@#$%^&*-+|?/\\<>:;",.[]{}- ';
    }

    for (let s in chars.split("")) {
      while (out.indexOf(chars[s]) > 0) {
        out = out.replace(chars[s], "");
      }
    }
    return out;
  }

  private static replace(texto: any, what, dest): string {
    if (texto == undefined) {
      return '';
    }
    while (texto.indexOf(what) >= 0) {
      texto = texto.replaceAll(what, dest);
    }
    return texto;
  }

  public static formataTextoVariaveis(texto: string, vars: any): string {
    let t: string = texto;
    if (vars.data != undefined) {
      t = FormatUtils.replace(t, '</DataAtual>', vars.data);
    } else {
      t = FormatUtils.replace(t, '</DataAtual>', new Date().toDateString);
    }
    if (vars.totalNota != undefined) {
      t = FormatUtils.replace(t, '</TotalNota>', vars.totalNota);
    }
    if (vars.aliquotaPerc != undefined) {
      t = FormatUtils.replace(t, "</Aliquota%>", vars.aliquotaPerc);
    }
    if (vars.aliquota != undefined) {
      t = FormatUtils.replace(t, "</Aliquota$>", vars.aliquota);
    }
    if (vars.baseST != undefined) {
      t = FormatUtils.replace(t, "</BaseSubst>", vars.baseST);
    }
    if (vars.valorST != undefined) {
      t = FormatUtils.replace(t, "</ValorSubst>", vars.valorST);
    }
    if (vars.baseICMS != undefined) {
      t = FormatUtils.replace(t, "</BaseICMS>", vars.baseICMS);
    }
    if (vars.valorICMS != undefined) {
      t = FormatUtils.replace(t, "</ValorIcms>", vars.valorICMS);
    }
    if (vars.icms_st != undefined) {
      t = FormatUtils.replace(t, "</SomaICMS+Subst>", vars.icms_st);
    }
    if (vars.totalServicos != undefined) {
      t = FormatUtils.replace(t, "</TotalServicos>", vars.totalServicos);
    }
    if (vars.totalProdutos != undefined) {
      t = FormatUtils.replace(t, "</TotalProdutos>", vars.totalProdutos);
    }
    if (vars.pedeTexto != undefined) {
      t = FormatUtils.replace(t, "</PedeTexto>", vars.pedeTexto);
    }
    if (vars.pedeValor != undefined) {
      t = FormatUtils.replace(t, "</PedeValor>", vars.pedeValor);
    }
    if (vars.pedeData != undefined) {
      t = FormatUtils.replace(t, "</PedeData>", vars.pedeData);
    }
    return t;
  }

  public static date(data : Date): string{
    return formatDate(data, "dd-MM-yyyy", "pt-BR");
  }
}
