import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Atendimento } from '../models/atendimento';
import { Liberacao, LiberacaoFiltroDTO, LiberacaoUltimoCodigo } from '../models/liberacao';
import { ChaveEmpresarial } from '../models/liberacao-chave-empresarial';
import { DateUtils } from '../utilities/util/date-utils';
import { HttpRequest, UriBuilder } from '../utilities/util/http-request';
import { AbstractService } from './abstract.service';
import { LoginService } from './login.service';
import { FollowUp } from '../models/follow-up';

export const tokenHE: string = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.' +
  'eyJzdWIiOiI0MDAyODkyMiIsIm5hbWUiOiJIYWJpbENTIn0.' +
  'XdsdLTe56CKjMSpVQyMsJbfKd_qpYekEVO3weKplROU';

@Injectable({
  providedIn: 'root'
})

export class LiberacaoService extends AbstractService<Liberacao> {

  titulo: string = 'Pedidos / Liberações';

  constructor(http: HttpClient, private router: Router, loginService: LoginService) {
    super(http, loginService);
  }

  endpoint(): string {
    return 'admin/liberacao';
  }

  rotina(): string {
    return 'LIBERACAO';
  }

  colunas(): any[] {
    return [];
  }

  novo(selection?: any): void {
    AbstractService.readonly = false;
    this.router.navigate([this.uri()]);
  }

  abrir(obj: Liberacao): void {
    AbstractService.readonly = true;
    this.router.navigate([this.uri()], { queryParams: { id: obj.id } });
  }

  edit(obj: Liberacao): void {
    AbstractService.readonly = false;
    this.router.navigate([this.uri()], { queryParams: { id: obj.id } });
  }

  routeLista(): string {
    return this.uri(true);
  }

  corrigeAtributos(t: Liberacao): Liberacao {
    t = super.corrigeAtributos(t);
    if (!t.emissaoFiscal) {
      t.emissaoFiscal = [];
    }
    if (!t.modulosAdicionais) {
      t.modulosAdicionais = [];
    }
    if (!t.produtos) {
      t.produtos = [];
    }
    return t;
  }

  lerChaveEmpresarial(chave: string): Observable<any> {
    return new HttpRequest<any>(this.http)
      .endpoint(this.endpoint())
      .uri(
        new UriBuilder()
          .subpath('empresarial/ler')
          .build()
      )
      .param('chave', chave)
      .doPost();
  }

  pegaUltimaChave(cnpj: string): Observable<any> {
    return new HttpRequest<any>(this.http)
      .endpoint(this.endpoint())
      .uri(
        new UriBuilder()
          .subpath('empresarial/ultima-chave')
          .build()
      )
      .param('cnpj', cnpj)
      .doGet();
  }

  consultaLiberacaoHerp(cnpj: string): Observable<LiberacaoUltimoCodigo> {
    return new HttpRequest<LiberacaoUltimoCodigo>(this.http)
      .endpoint(this.endpoint())
      .uri(
        new UriBuilder()
          .subpath('enterprise/consulta-liberacao')
          .subpath(cnpj.replace('/\D/g', ''))
          .build()
      )
      .doGet();
  }

  consultaLiberacaoHe(cnpj: string = '00000000000000'): Observable<any> {
    const url: string = `https://he.habil.net.br/registros/central?cnpj=${cnpj}`;
    return this.http.get<any>(url, { headers: { 'auth': tokenHE } });
  }

  geraContraSenhaEnterprise(chave: string, codigoAcesso: string, dataInicial: Date, dataFinal: Date): Observable<any> {
    return new HttpRequest<any>(this.http)
      .endpoint(this.endpoint())
      .uri(
        new UriBuilder()
          .subpath('enterprise/contra-senha')
          .build()
      )
      .param('chave', chave)
      .param('codigo-acesso', codigoAcesso)
      .param('data-inicial', DateUtils.dateToString(dataInicial))
      .param('data-final', DateUtils.dateToString(dataFinal))
      .doPost();
  }

  gravaLiberacao(liberacao: Liberacao, codigoLiberacao: string, dataInicial: Date, dataFinal: Date, atendimento: Atendimento): Observable<any> {
    this.corrigeAtributos(liberacao);
    let att: string = '';
    if (atendimento) {
      if (!atendimento.excecoes) {
        atendimento.excecoes = [];
      }
      att = JSON.stringify(atendimento);
    }
    const ret = new HttpRequest<any>(this.http)
      .endpoint(this.endpoint())
      .uri(
        new UriBuilder()
          .subpath('enterprise/gravar-liberacao')
          .build()
      )
      .param('liberacao', JSON.stringify(liberacao))
      .param('codigo-liberacao', codigoLiberacao)
      .param('data-inicial', DateUtils.dateToString(dataInicial))
      .param('data-final', DateUtils.dateToString(dataFinal));
    if (att) {
      ret.param('atendimento', att);
    }
    return ret.doPost();
  }

  enviaPorEmail(email: string, assunto: string, texto: string): Observable<void> {
    return new HttpRequest<void>(this.http)
      .endpoint(this.endpoint())
      .uri(
        new UriBuilder()
          .subpath('envia-por-email')
          .build()
      )
      .param('texto', texto)
      .param('email', email)
      .param('assunto', assunto)
      .doPost();
  }

  geraChaveEmpresarial(chave: ChaveEmpresarial): Observable<any> {
    return new HttpRequest<any>(this.http)
      .endpoint(this.endpoint())
      .uri(
        new UriBuilder()
          .subpath('empresarial')
          .build()
      )
      .param('chave', chave.getDadosChave())
      .param('emissoes', JSON.stringify(chave.getListEmissoes()))
      .doPost();
  }

  finaliza(liberacao: Liberacao, resumo: string, atendimento?: Atendimento, salvaUltimaChave?: boolean): Observable<Liberacao> {
    this.corrigeAtributos(liberacao);
    let req = new HttpRequest<any>(this.http)
      .endpoint(this.endpoint())
      .uri(
        new UriBuilder()
          .subpath('finaliza')
          .build()
      )
      .param('liberacao', JSON.stringify(liberacao))
      .param('resumo', resumo);
    if (atendimento) {
      if (!atendimento.excecoes) {
        atendimento.excecoes = [];
      }
      req.param('atendimento', JSON.stringify(atendimento));
    }
    if (salvaUltimaChave) {
      req.param('salvaUltimaChave', salvaUltimaChave.toString());
    }
    return req.doPost();
  }

  countParaLista(filtro: LiberacaoFiltroDTO): Observable<number> {
    return new HttpRequest<number>(this.http)
      .endpoint(this.endpoint())
      .uri(
        new UriBuilder()
          .subpath('record-count-lista')
          .build()
      )
      .param('filtro', JSON.stringify(filtro))
      .doGet();
  }

  findParaLista(start: number, length: number, filtro: LiberacaoFiltroDTO): Observable<Liberacao[]> {
    return new HttpRequest<Liberacao[]>(this.http)
      .endpoint(this.endpoint())
      .uri(
        new UriBuilder()
          .subpath('lista')
          .subpath(start.toString())
          .subpath(length.toString())
          .build()
      )
      .param('filtro', JSON.stringify(filtro))
      .doGet();
  }

  getFollowUps(dataInicial: Date, dataFinal: Date, cnpj: string): Observable<FollowUp[]> {
    return new HttpRequest<FollowUp[]>(this.http)
      .endpoint(this.endpoint())
      .uri(
        new UriBuilder()
          .subpath('follow-ups')
          .build()
      )
      .param('dataInicial', DateUtils.dateToString(dataInicial))
      .param('dataFinal', DateUtils.dateToString(dataFinal))
      .param('cnpj', cnpj)
      .doGet();
  }

  saveCSRegistroEmpresarial(cnpj: string, chave: string, validade: any) {
    const url = `https://he.habil.net.br/registros/central`;
    const regex = chave.split("*****")

    const body = {
      "cnpj": cnpj,
      "chave": `***** ${(regex[1] as any).replaceAll("\n", "")} *****`,
      "dataVencimento": validade
    }

    return this.http.post<any>(url, body, { headers: { 'auth': tokenHE } })
  }

  private uri(ehlista?: boolean): string {
    const ret = "/liberacao";
    if (ehlista) {
      return ret;
    }
    return ret + '/cadastro';
  }

}


