import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/app.breadcrumb.service";
import { Rotina } from "src/app/models/eacesso-rotina";
import { HabilMobilePDVProduto } from "src/app/models/habil-mobile-pdv-produto";
import { AbstractService } from "src/app/services/abstract.service";
import { ClassificacaoService } from "src/app/services/classificacao.service";
import { HabilMobilePdvProdutoService } from "src/app/services/habil-mobile-pdv-produto.service";
import { GenericCadastro } from "src/app/utilities/generic-cadastro";
import { clone } from "src/app/utilities/util/clone-utils";
import { FormValidation } from "src/app/utilities/util/form-validation";

@Component({
  selector: "habil-cadastro-produto-habil-mobile-pdv",
  templateUrl: "./cadastro-produto-habil-mobile-pdv.component.html",
  styleUrls: ["./cadastro-produto-habil-mobile-pdv.component.scss"],
})
export class CadastroProdutoHabilMobilePdvComponent
  extends GenericCadastro<HabilMobilePDVProduto, HabilMobilePdvProdutoService>
  implements OnInit
{
  rotinas: Rotina[] = [];
  carregado = false;

  constructor(
    public service: HabilMobilePdvProdutoService,
    public route: ActivatedRoute,
    public router: Router,
    public classificacaoService: ClassificacaoService,
    public confirmationService: ConfirmationService,
    public titleService: Title,
    public messageService: MessageService,
    public breadcrumbService: BreadcrumbService
  ) {
    super();
    this.breadcrumbService.setItems([
      { label: "Produtos", routerLink: "/adm/produtos" },
      { label: "Cadastro - Hábil Mobile PDV" },
    ]);
  }

  ngOnInit(): void {
    if (this._isDialog) {
      this._loading = true;
      if (this._externalObj != null && this._externalObj != undefined) {
        this.obj = clone(this._externalObj);
        this.id = this.obj.id;
        this.idCampos = this.id == undefined ? "0" : this.id.toString();
        this._loading = false;
      } else {
        this.obj = this.newObject();
        this.idCampos = this.id == undefined ? "0" : this.id.toString();
        this.loadAuxObjects();
        this._loading = false;
      }
    } else {
      this.titleService.setTitle("Produto - " + AbstractService.app);
      super.ngOnInit();
    }
  }

  newObject(): HabilMobilePDVProduto {
    const obj: HabilMobilePDVProduto = new HabilMobilePDVProduto();
    return obj;
  }

  doValidate(): boolean {
    this.corrigeAtributos();

    const validation: FormValidation = new FormValidation();
    validation.toValidate = [
      {
        condition:
          this.obj.nomeProduto != undefined && this.obj.nomeProduto.length > 0,
        message: "O nome é obrigatório!",
      },
    ];
    if (!validation.isValid()) {
      validation.message().forEach((m) => {
        this.messageService.add({
          severity: "warn",
          summary: "Atenção",
          detail: m,
        });
      });
    }
    return validation.isValid();
  }

  doBeforeSave() {}

  private carregaAcessos(): Promise<void> {
    return new Promise((sim, nao) => {
      this.service.getAcessos().subscribe({
        next: (acessos) => {
          this.rotinas = [];
          if (acessos) {
            acessos.forEach((a) => {
              this.rotinas.push({
                label: a.description,
                value: a.value,
                routeCadastro: "",
                routeLista: "",
                temImpressao: false,
              });
            });
          }
          sim();
        },
        error: (erro) => this.service.handleError(erro),
      });
    });
  }

  stopLoading(): void {
    this._loading = false;
    if (!this.carregado) {
      this.obj.acessosComDescricao = [];
      this.carregaAcessos().then(() => {
        this.rotinas.sort((a, b) => {
          if (a.label > b.label) {
            return 1;
          }
          if (a.label < b.label) {
            return -1;
          }
          return 0;
        });
        for (const rotina of this.obj.acessos) {
          let acesso = this.rotinas.find((r) => r.value == rotina);
          if (!acesso)
            acesso = {
              label: rotina,
              value: rotina,
              temImpressao: null,
              routeCadastro: null,
              routeLista: null,
            };
          this.obj.acessosComDescricao.push(acesso);
          this.rotinas = [...this.rotinas.filter((r) => r.value != rotina)];
        }
        this.obj.acessosComDescricao.sort((a, b) => {
          if (a.label > b.label) {
            return 1;
          }
          if (a.label < b.label) {
            return -1;
          }
          return 0;
        });
        this.carregado = true;
      });
    }
  }
}
