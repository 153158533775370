<h4>Reusable CSS widgets for your applications.</h4>

<div class="p-grid">
	<div class="p-col-12">
		<div class="card">
			<h4>Overview Boxes</h4>
			<div class="p-grid">
                <div class="p-col-12 p-md-6 p-lg-3">
                    <div class="widget-overview-box sales">
                        <img src="assets/layout/images/icon-sales.png" alt="ecuador-layout" class="image-icon" />
                        <div class="overview-title">SALES</div>
                        <div class="overview-numbers">
                            $ 32,700
                        </div>
                        <div class="overview-subinfo p-grid">
                            <div class="p-col-6 progressbar">
                                <div class="progress sale"></div>
                            </div>
                            <span class="p-col-6">23% more</span>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-md-6 p-lg-3">
                    <div class="widget-overview-box views">
                        <img src="assets/layout/images/icon-views.png" alt="ecuador-layout" style="margin-top:10px" />
                        <div class="overview-title">VIEWS</div>
                        <div class="overview-numbers">
                            21,009
                        </div>
                        <div class="overview-subinfo p-grid">
                            <div class="p-col-6 progressbar">
                                <div class="progress view"></div>
                            </div>
                            <span class="p-col-6">7% more</span>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-md-6 p-lg-3">
                    <div class="widget-overview-box users">
                        <img src="assets/layout/images/icon-users.png" alt="ecuador-layout" />
                        <div class="overview-title">USERS</div>
                        <div class="overview-numbers">
                            3,270
                        </div>
                        <div class="overview-subinfo user">
                            <i class="pi pi-circle-on"></i>
                            <span>Same</span>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-md-6 p-lg-3">
                    <div class="widget-overview-box checkins">
                        <img src="assets/layout/images/icon-checkin.png" alt="ecuador-layout" class="image-icon" />
                        <div class="overview-title">CHECK-INS</div>
                        <div class="overview-numbers">
                            704
                        </div>
                        <div class="overview-subinfo checkin">
                            <i class="pi pi-circle-on"></i>
                            <span>Same</span>
                        </div>
                    </div>
                </div>
			</div>
		</div>
	</div>

    <div class="p-col-12 p-md-12 p-lg-12 p-xl-6">
        <div class="widget-statistics card">
            <h4>Statistics</h4>
            <div class="p-grid">
                <div class="p-col-12 p-md-6 p-lg-6 p-xl-6 stats-left">
                    <div>
                        <i class="pi pi-arrow-up"></i>
                        <span>50%</span>
                        <i class="pi pi-dollar"></i>
                        <span>75%</span>
                        <i class="pi pi-replay"></i>
                        <span>25%</span>
                    </div>
                    <div class="knob-container">
                        <div class="knob">81%</div>
                    </div>
                    <div class="details">
                        <span>See Details</span>
                        <i class="pi pi-chevron-right"></i>
                    </div>
                </div>
                <div class="p-col-12 p-md-6 p-lg-6 p-xl-6 stats-right">
                    <i class="pi pi-arrow-up"></i>
                    <span class="icon-label">Raised</span>
                    <span>$ 85,397</span>
                    <div class="statsbar">
                        <div class="statsbar-value" style="width: 50%"></div>
                    </div>
                    <i class="pi pi-dollar"></i>
                    <span class="icon-label">Current Period Price</span>
                    <span>$ 194</span>
                    <div class="statsbar">
                        <div class="statsbar-value" style="width: 75%"></div>
                    </div>
                    <i class="pi pi-replay"></i>
                    <span class="icon-label">Remaining</span>
                    <span>16:01:53</span>
                    <div class="statsbar">
                        <div class="statsbar-value" style="width: 25%"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="p-col-12 p-md-12 p-lg-12 p-xl-6">
        <div class="card widget-global-sales">
            <h4>Global Sales</h4>
            <table>
                <thead>
                <tr>
                    <th></th>
                    <th class="country">Country</th>
                    <th>Total</th>
                    <th>Percentage</th>
                    <th>Change</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>1</td>
                    <td class="country">
                        <img src="assets/layout/images/flag-usa.png" alt="ecuador-layout" />
                        <span>USA</span>
                    </td>
                    <td>91,382 $</td>
                    <td>71%</td>
                    <td>
                        <i class="pi pi-caret-up up"></i>
                    </td>
                </tr>
                <tr>
                    <td>2</td>
                    <td class="country">
                        <img src="assets/layout/images/flag-brasil.png" alt="ecuador-layout" />
                        <span>Brasil</span>
                    </td>
                    <td>1,902 $</td>
                    <td>9%</td>
                    <td>
                        <i class="pi pi-caret-down down"></i>
                    </td>
                </tr>
                <tr>
                    <td>3</td>
                    <td class="country">
                        <img src="assets/layout/images/flag-india.png" alt="ecuador-layout" />
                        <span>India</span>
                    </td>
                    <td>3,418 $</td>
                    <td>19%</td>
                    <td>
                        <i class="pi pi-caret-down down"></i>
                    </td>
                </tr>
                <tr>
                    <td>4</td>
                    <td class="country">
                        <img src="assets/layout/images/flag-germany.png" alt="ecuador-layout" />
                        <span>Germany</span>
                    </td>
                    <td>74,293 $</td>
                    <td>62%</td>
                    <td>
                        <i class="pi pi-caret-up up"></i>
                    </td>
                </tr>
                <tr>
                    <td>5</td>
                    <td class="country">
                        <img src="assets/layout/images/flag-spain.png" alt="ecuador-layout" />
                        <span>Spain</span>
                    </td>
                    <td>32,849 $</td>
                    <td>28%</td>
                    <td>
                        <i class="pi pi-caret-down down"></i>
                    </td>
                </tr>
                <tr>
                    <td>6</td>
                    <td class="country">
                        <img src="assets/layout/images/flag-france.png" alt="ecuador-layout" />
                        <span>France</span>
                    </td>
                    <td>40,619 $</td>
                    <td>34%</td>
                    <td>
                        <i class="pi pi-caret-up up"></i>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>


    <div class="p-col-12 p-md-12 p-lg-4">
        <div class="widget-profile card">
            <div class="bg-image">
            </div>
            <div class="profile-info">
                <img src="assets/layout/images/avatar-jeniffer.png" alt="ecuador-layout" class="profile-image" />

                <span class="name">Jeniffer Reynolds</span>
                <div>
                    <i class="pi pi-briefcase"></i>
                    <span class="job">Human Resource Manager</span>
                </div>
                <div>
                    <i class="pi pi-map-marker"></i>
                    <span class="location">Seattle</span>
                </div>
                <div>
                    <div class="profile-numbers">
                        <span class="header">Views</span>
                        <span class="number">381</span>
                    </div>
                    <div class="profile-numbers">
                        <span class="header">Followers</span>
                        <span class="number">492</span>
                    </div>
                    <div class="profile-numbers">
                        <span class="header">Likes</span>
                        <span class="number">1,395</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="widget-timeline card">
            <h4>Account Timeline</h4>
            <ul>
                <li>
                    <div class="date-indicator">
                        <span class="date">today</span>
                        <span class="time">08:32</span>
                    </div>
                    <div class="activity-link"></div>
                    <i class="pi pi-fw pi-circle-on success"></i>
                    <div class="timeline-subject">
                        <span class="timeline-header">Login to system</span>
                        <span class="timeline-subheader">automatic login</span>
                    </div>
                    <i class="pi pi-desktop device success"></i>
                </li>
                <li>
                    <div class="date-indicator">
                        <span class="date">today</span>
                        <span class="time">00:07</span>
                    </div>
                    <div class="activity-link"></div>
                    <i class="pi pi-fw pi-circle-on success"></i>
                    <div class="timeline-subject">
                        <span class="timeline-header">Login to system</span>
                        <span class="timeline-subheader">automatic login</span>
                    </div>
                    <i class="pi pi-tablet device success"></i>
                </li>
                <li>
                    <div class="date-indicator">
                        <span class="date">1 d</span>
                        <span class="time">13:37</span>
                    </div>
                    <div class="activity-link"></div>
                    <i class="pi pi-fw pi-circle-on error"></i>
                    <div class="timeline-subject">
                        <span class="timeline-header">Usename error</span>
                        <span class="timeline-subheader">changed username</span>
                    </div>
                    <i class="pi pi-desktop device error"></i>
                </li>
                <li>
                    <div class="date-indicator">
                        <span class="date">1 d</span>
                        <span class="time">08:30</span>
                    </div>
                    <div class="activity-link"></div>
                    <i class="pi pi-fw pi-circle-on success"></i>
                    <div class="timeline-subject">
                        <span class="timeline-header">Login to system</span>
                        <span class="timeline-subheader">manual login</span>
                    </div>
                    <i class="pi pi-desktop device success"></i>
                </li>
                <li>
                    <div class="date-indicator">
                        <span class="date">2 d</span>
                        <span class="time">13:41</span>
                    </div>
                    <div class="activity-link"></div>
                    <i class="pi pi-fw pi-circle-on success"></i>
                    <div class="timeline-subject">
                        <span class="timeline-header">Login to system</span>
                        <span class="timeline-subheader">automatic login</span>
                    </div>
                    <i class="pi pi-desktop device success"></i>
                </li>
                <li>
                    <div class="date-indicator">
                        <span class="date">2 d</span>
                        <span class="time">09:03</span>
                    </div>
                    <div class="activity-link"></div>
                    <i class="pi pi-fw pi-circle-on error"></i>
                    <div class="timeline-subject">
                        <span class="timeline-header">Incorrect password</span>
                        <span class="timeline-subheader">access denied</span>
                    </div>
                    <i class="pi pi-mobile device error"></i>
                </li>
                <li>
                    <div class="date-indicator">
                        <span class="date">3 d</span>
                        <span class="time">23:48</span>
                    </div>
                    <div class="activity-link"></div>
                    <i class="pi pi-fw pi-circle-on success"></i>
                    <div class="timeline-subject">
                        <span class="timeline-header">Login to system</span>
                        <span class="timeline-subheader">retina scan</span>
                    </div>
                    <i class="pi pi-mobile device success"></i>
                </li>
                <li>
                    <div class="date-indicator">
                        <span class="date">3 d</span>
                        <span class="time">13:33</span>
                    </div>
                    <div class="activity-link"></div>
                    <i class="pi pi-fw pi-circle-on code"></i>
                    <div class="timeline-subject">
                        <span class="timeline-header">Exceptional error</span>
                        <span class="timeline-subheader">Code 2ff26982284</span>
                    </div>
                    <i class="pi pi-desktop device code"></i>
                </li>
                <li>
                    <div class="date-indicator">
                        <span class="date">13 d</span>
                        <span class="time">08:39</span>
                    </div>
                    <div class="activity-link"></div>
                    <i class="pi pi-fw pi-circle-on success"></i>
                    <div class="timeline-subject">
                        <span class="timeline-header">Login to system</span>
                        <span class="timeline-subheader">automatic login</span>
                    </div>
                    <i class="pi pi-desktop device success"></i>
                </li>
            </ul>
        </div>
    </div>

    <div class="p-col-12 p-md-12 p-lg-8">
        <div class="widget-inbox card">
            <h4>Inbox</h4>
            <ul>
                <li>
                    <div class="image-container">
                        <img src="assets/layout/images/avatar-maggie.png" alt="ecuador-layout" />
                    </div>
                    <div class="separator"></div>
                    <div class="message-box">
                        <span class="messager">Maggie Walker</span>
                        <span class="time">23 mins</span>
                        <span class="message">It is a long established fact that a reader will be distracted by the readable content of a page
                                when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less
                                normal distribu…</span>
                    </div>
                    <div class="message-status">
                        <i class="pi pi-circle-on"></i>
                        <span>562</span>
                    </div>
                </li>
                <li>
                    <div class="image-container">
                        <img src="assets/layout/images/avatar-brooke.png" alt="ecuador-layout" />
                    </div>
                    <div class="separator"></div>
                    <div class="message-box">
                        <span class="messager">Brooke Wright</span>
                        <span class="time">5 hours</span>
                        <span class="message">It is a long established fact that a reader will be distracted by the readable content of a page
                                when looking at its layout.</span>
                    </div>
                    <div class="message-status">
                        <i class="pi pi-circle-on"></i>
                        <span>149</span>
                    </div>
                </li>
                <li>
                    <div class="image-container">
                        <img src="assets/layout/images/avatar-tom.png" alt="ecuador-layout" />
                    </div>
                    <div class="separator"></div>
                    <div class="message-box">
                        <span class="messager">Tom Baker</span>
                        <span class="time">19 hours</span>
                        <span class="message">It is a long established fact that a reader will be distracted by the readable content.</span>
                    </div>
                    <div class="message-status">
                        <i class="pi pi-circle-on unread"></i>
                        <span>74</span>
                    </div>
                </li>
                <li>
                    <div class="image-container">
                        <img src="assets/layout/images/avatar-lucas.png" alt="ecuador-layout" />
                    </div>
                    <div class="separator"></div>
                    <div class="message-box">
                        <span class="messager">Lucas Fox</span>
                        <span class="time">1 day</span>
                        <span class="message">It is a long established fact that a reader will be distracted by the readable contentof a page
                                when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less
                                normal.
                            </span>
                    </div>
                    <div class="message-status">
                        <i class="pi pi-circle-on unread"></i>
                        <span>250</span>
                    </div>
                </li>
            </ul>
        </div>

        <div class="widget-projects card">
            <h4>Projects</h4>
            <ul>
                <li>
                    <div class="project-info">
                        <span class="project-name">Morpheus</span>
                        <span class="project-firm">PrimeFaces</span>
                    </div>
                    <div class="person-container">
                        <div class="persons">
                            <img src="assets/layout/images/faces-morpheus.png" alt="ecuador-layout" />
                        </div>
                        <span>24 people interested</span>
                    </div>
                    <div class="button">
                        <button type="button" label="See Details" pButton></button>
                    </div>
                </li>
                <li>
                    <div class="project-info">
                        <span class="project-name">Ultima</span>
                        <span class="project-firm">PrimeFaces</span>
                    </div>
                    <div class="person-container">
                        <div class="persons">
                            <img src="assets/layout/images/faces-ultima.png" alt="ecuador-layout" />
                        </div>
                        <span>32 people interested</span>
                    </div>
                    <div class="button">
                        <button type="button" label="See Details" pButton></button>
                    </div>
                </li>
                <li>
                    <div class="project-info">
                        <span class="project-name">Poseidon</span>
                        <span class="project-firm">PrimeFaces</span>
                    </div>
                    <div class="person-container">
                        <div class="persons">
                            <img src="assets/layout/images/faces-poseidon.png" alt="ecuador-layout" />
                        </div>
                        <span>37 people interested</span>
                    </div>
                    <div class="button">
                        <button pButton type="button" label="See Details"></button>
                    </div>
                </li>
            </ul>
        </div>

        <div class="p-grid">
            <div class="p-col-12 p-md-12 p-lg-6">
                <div class="widget-contacts card">
                    <h4>Contacts</h4>
                    <ul>
                        <li>
                            <img src="assets/layout/images/avatar-brooke.png" alt="ecuador-layout" />
                            <div class="separator"></div>
                            <div class="team-box">
                                <span class="team-member">Brooke Wright</span>
                                <div>
                                    <i class="pi pi-envelope"></i>
                                    <span class="team-member-mail">brooke@test.com</span>
                                </div>
                                <div>
                                    <i class="pi pi-map-marker"></i>
                                    <span class="team-member-address">153 Cold Valley Road</span>
                                </div>
                            </div>
                        </li>
                        <li>
                            <img src="assets/layout/images/avatar-maggie.png" alt="ecuador-layout" />
                            <div class="separator"></div>
                            <div class="team-box">
                                <span class="team-member">Maggie Walker</span>
                                <div>
                                    <i class="pi pi-envelope"></i>
                                    <span class="team-member-mail">maggie@test.com</span>
                                </div>
                                <div>
                                    <i class="pi pi-map-marker"></i>
                                    <span class="team-member-address">5698 Anderson Street</span>
                                </div>
                            </div>
                        </li>
                        <li>
                            <img src="assets/layout/images/avatar-tom.png" alt="ecuador-layout" />
                            <div class="separator"></div>
                            <div class="team-box">
                                <span class="team-member">Tom Baker</span>
                                <div>
                                    <i class="pi pi-envelope"></i>
                                    <span class="team-member-mail">tom@example.com</span>
                                </div>
                                <div>
                                    <i class="pi pi-map-marker"></i>
                                    <span class="team-member-address">2304 Billy Bill Lane</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="p-col-12 p-md-12 p-lg-6">
                <div class="widget-resolution-center card">
                    <h4>Resolution Center</h4>
                    <div class="p-grid p-fluid">
                        <div class="p-col-12">
                            <input type="text" pInputText placeholder="Name">
                        </div>
                        <div class="p-col-12">
                            <input type="text" pInputText placeholder="Email">
                        </div>
                        <div class="p-col-12">
                            <textarea type="text" pInputTextarea placeholder="Message"></textarea>
                        </div>
                        <div class="p-col-12">
                            <button type="button" label="Send" pButton></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
