import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ConfiguracaoSistema } from 'src/app/models/cfg';
import { AbstractService } from 'src/app/services/abstract.service';
import { LoginService } from 'src/app/services/login.service';
import { PlanoSuporteService } from 'src/app/services/plano-suporte.service';
import { clone } from 'src/app/utilities/util/clone-utils';

@Component({
  selector: 'habil-cfg',
  templateUrl: './cfg.component.html',
  styleUrls: ['./cfg.component.scss'],
  providers: [ConfirmationService, MessageService]
  
})
export class CfgComponent implements OnInit {

  obj: ConfiguracaoSistema;
  loading = true;

  constructor(
      private breadcrumbService: BreadcrumbService, 
      public service: LoginService, 
      public planoSuporteService: PlanoSuporteService,
      titleService: Title, 
      public messageService: MessageService) {
    this.breadcrumbService.setItems([{label: 'Configurações'}]);
    titleService.setTitle('Configurações - ' + AbstractService.app);
  }

  ngOnInit(): void {
    this.service.getGlobal().subscribe(cfg => {
      this.obj = clone(cfg);
      this.loading = false;
    }, e => {
      console.error(e);
      this.messageService.add({severity: 'error', summary: 'Erro', detail: e?.error});
      this.loading = false;
    });
  }

  saveCfg() {
    this.service.setGlobal(this.obj).subscribe(ret => {
      this.messageService.add({severity: 'success', summary: 'Sucesso!', detail: 'Configuração salva com sucesso!'})
    }, e => {
      this.messageService.add({severity: 'error', summary: 'Erro', detail: e?.error});
    })
  }

}
