<habil-grupo>
  <p-accordion [multiple]="true">
    <p-accordionTab header="Última Chave Gerada" [selected]="sanfonaUltimaChave">
      <div class="p-fluid p-grid">
        <div class="p-field p-col-12">
          <textarea
            pInputTextarea
            [(ngModel)]="ultimaChave"
            rows="30"
            cols="100"
          ></textarea>
        </div>
        <div class="p-field p-col-2">
          <button
            pButton
            icon="pi pi-arrow-circle-down"
            label="Usar informações dessa chave"
            (click)="descriptografarChave()"
          ></button>
        </div>
      </div>
    </p-accordionTab>
    <p-accordionTab header="Dados do Cliente" [selected]="!gerouChave">
      <div class="p-grid p-fluid">
        <div class="p-col-3 p-field">
          <habil-label name="lbnome" label="Nome Fantasia">
            <input pInputText [(ngModel)]="chave.nome" />
          </habil-label>
        </div>
        <div class="p-col-3 p-field">
          <habil-label name="lbrazao" label="Razão Social">
            <input pInputText [(ngModel)]="chave.razaoSocial" />
          </habil-label>
        </div>
        <div class="p-col-2 p-field">
          <habil-label name="lbemail" label="E-mail">
            <input pInputText [(ngModel)]="liberacao.dadosCliente.email" />
          </habil-label>
        </div>
        <div class="p-col-1 p-field">
          <habil-label name="lbuf" label="UF">
            <p-dropdown [options]="ufs" [(ngModel)]="chave.uf"></p-dropdown>
          </habil-label>
        </div>
        <div class="p-col-2 p-field">
          <habil-label name="lbcnpj" label="CPF/CNPJ">
            <input pInputText [(ngModel)]="chave.cpfCnpj" />
          </habil-label>
        </div>
        <div class="p-col-1 p-field">
          <habil-label name="lbfone" label="Fone">
            <input pInputText [(ngModel)]="chave.fone" />
          </habil-label>
        </div>
        <div class="p-col-12 p-field">
          <habil-label name="lbresumo" label="Resumo do Pedido">
            <p-table [value]="liberacao.produtos">
              <ng-template pTemplate="header">
                <tr>
                  <th>Descrição</th>
                  <th>Qtde</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-p>
                <tr>
                  <td>{{ p.descricao }}</td>
                  <td>{{ p.qtde }}</td>
                </tr>
              </ng-template>
            </p-table>
          </habil-label>
        </div>
        <div class="p-col-12 p-field">
          <habil-label name="lbobs" label="Observações">
            <textarea pInputTextarea [(ngModel)]="liberacao.observacoes" [rows]="10"></textarea>
          </habil-label>
        </div>
      </div>
      <p-divider>Opções da Chave</p-divider>
      <div class="p-grid p-fluid">
        <div class="p-field p-col-1">
          <habil-label name="lbversao" label="Versão">
            <p-dropdown
              [options]="versao"
              [(ngModel)]="chave.versao"
              optionLabel="value"
              optionValue="value"
            ></p-dropdown>
          </habil-label>
        </div>
        <div class="p-field p-col-9">
          <habil-label name="lbsolucao" label="Solução">
            <p-dropdown
              [options]="solucao"
              [(ngModel)]="chave.solucao"
              optionLabel="label"
              optionValue="value"
            ></p-dropdown>
          </habil-label>
        </div>
        <div class="p-field p-col-2">
          <habil-label name="lblicencas" label="Licenças">
            <p-inputNumber
              mode="decimal"
              [useGrouping]="false"
              [(ngModel)]="chave.nroLicencas"
            ></p-inputNumber>
          </habil-label>
        </div>
        <div class="p-field p-col-1 p-col-align-end">
          <p-checkbox
            [binary]="true"
            label="Possui validade?"
            [(ngModel)]="chave.comValidade"
          ></p-checkbox>
        </div>
        <div class="p-field p-col-3">
          <habil-label name="lbvalidade" label="Validade">
            <div
              class="p-fluid p-formgrid p-grid"
              style="max-height: 35px"
              [ngClass]="'inside-button'"
            >
              <div class="p-col">
                <p-calendar
                  dateFormat="dd/mm/yy"
                  appendTo="body"
                  [disabled]="!this.chave.comValidade"
                  [(ngModel)]="this.chave.validade"
                ></p-calendar>
              </div>
              <div class="p-col-fixed p-col-align-top" style="width: 36px">
                <button
                  type="button"
                  pButton
                  pRipple
                  title="+ 30 dias"
                  icon="pi pi-plus"
                  style="height: 100%"
                  tabindex="-1"
                  tooltipPosition="top"
                  class="p-autocomplete-dropdown p-ripple p-button p-component p-button-icon-only ng-star-inserted"
                  (click)="incValidade(true)"
                ></button>
              </div>
              <div class="p-col-fixed p-col-align-top" style="width: 36px">
                <button
                  type="button"
                  pButton
                  pRipple
                  title="+ mês"
                  tooltipPosition="top"
                  style="height: 100%"
                  tabindex="-1"
                  class="p-autocomplete-dropdown p-ripple p-button p-component p-button-icon-only ng-star-inserted"
                  icon="pi pi-calendar-plus"
                  (click)="incValidade(false)"
                ></button>
              </div>
            </div>
          </habil-label>
        </div>
        <div class="p-field p-col-6">
          <habil-label name="lbtipochave" label="Tipo da Chave">
            <p-dropdown
              [options]="tipoChave"
              [(ngModel)]="chave.chaveDemo"
              optionLabel="label"
              optionValue="value"
              [disabled]="!chave.comValidade"
            ></p-dropdown>
          </habil-label>
        </div>
        <div class="p-field p-col-2">
          <habil-label name="lblicencasapp" label="Licenças para o App">
            <p-inputNumber
              mode="decimal"
              [useGrouping]="false"
              [(ngModel)]="chave.nroLicencasApp"
            ></p-inputNumber>
          </habil-label>
        </div>
      </div>
      <p-divider>Módulos</p-divider>
      <div class="p-grid p-fluid">
        <div class="p-field p-col-12">
          <div class="p-fluid p-grid">
            <div class="p-field p-col-1 p-col-align-end">
              <p-checkbox
                [binary]="true"
                label="Usa NFe"
                [(ngModel)]="chave.usaNFe"
              ></p-checkbox>
            </div>
            <div class="p-field p-col-1">
              <habil-label name="lbversaonfe" label="Versão NF-e">
                <p-dropdown
                  [options]="versaoNFe"
                  optionLabel="value"
                  optionValue="value"
                  [(ngModel)]="chave.versaoNFe"
                  [disabled]="false"
                ></p-dropdown>
              </habil-label>
            </div>
            <div class="p-field p-col-10">
              <habil-label
                name="lbcnpjnfe"
                label="Outros CNPJs (separados por vírgula)"
              >
                <p-chips
                  separator=","
                  [(ngModel)]="chave.outrosCnpjs"
                ></p-chips>
              </habil-label>
            </div>
            <div class="p-field p-col-1">
              <p-checkbox
                [binary]="true"
                [(ngModel)]="chave.usaCupom"
                label="Cupom fiscal"
              ></p-checkbox>
            </div>
            <div class="p-field p-col-1">
              <p-checkbox
                [binary]="true"
                [(ngModel)]="chave.usaCompras"
                label="Compras"
              ></p-checkbox>
            </div>
            <div class="p-field p-col-1">
              <p-checkbox
                [binary]="true"
                [(ngModel)]="chave.usaContrato"
                label="Contratos"
              ></p-checkbox>
            </div>
            <div class="p-field p-col-1">
              <p-checkbox
                [binary]="true"
                [(ngModel)]="chave.usaGraficos"
                label="Gráficos"
              ></p-checkbox>
            </div>
            <div class="p-field p-col-1">
              <p-checkbox
                [binary]="true"
                [(ngModel)]="chave.usaComandas"
                label="Comandas"
              ></p-checkbox>
            </div>
            <div class="p-field p-col-1">
              <p-checkbox
                [binary]="true"
                [(ngModel)]="chave.usaMagento"
                label="Magento"
              ></p-checkbox>
            </div>
            <div class="p-field p-col-2">
              <p-checkbox
                [binary]="true"
                [(ngModel)]="chave.usaAlmoxarifado"
                label="Almoxarifado"
              ></p-checkbox>
            </div>
            <div class="p-field p-col-2">
              <p-checkbox
                [binary]="true"
                [(ngModel)]="chave.usaSpedFiscal"
                label="SPED Fiscal"
              ></p-checkbox>
            </div>
            <div class="p-field p-col-2">
              <p-checkbox
                [binary]="true"
                [(ngModel)]="chave.usaSpedPisCofins"
                label="SPED Pis/Cofins"
              ></p-checkbox>
            </div>
            <div class="p-field p-col-12">
              <p-table
                [value]="chave.emissoes"
                [scrollable]="true"
                [style]="{ width: '100%' }"
              >
                <ng-template pTemplate="header"> 
                  <tr>
                    <th
                      style="width: 36px; text-align-last: center; padding: 0"
                    >
                      <button
                        pButton
                        pRipple
                        icon="pi pi-plus"
                        style="text-align-last: center"
                        class="p-button-rounded p-button-text"
                        (click)="addEmissao()"
                      ></button>
                    </th>
                    <th style="width: 32%">CNPJ</th>
                    <th style="width: 5%">NFS-e</th>
                    <th style="width: 12%">Validade NFS-e</th>
                    <th style="width: 5%">NFC-e</th>
                    <th style="width: 12%">Validade NFC-e</th>
                    <th style="width: 5%">SAT</th>
                    <th style="width: 12%">Validade SAT</th>
                    <th style="width: 5%">APP</th>
                    <th style="width: 12%">Validade APP</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-reg>
                  <tr>
                    <td
                      style="width: 36px; text-align-last: center; padding: 0"
                    >
                      <button
                        pButton
                        pRipple
                        icon="pi pi-trash"
                        style="text-align-last: center"
                        class="p-button-rounded p-button-text"
                        (click)="delEmissao(reg)"
                      ></button>
                    </td>
                    <td style="width: 32%">
                      <input pInputText [(ngModel)]="reg.cnpj" />
                    </td>
                    <td style="width: 5%">
                      <p-checkbox
                        [binary]="true"
                        [(ngModel)]="reg.nfse"
                      ></p-checkbox>
                    </td>
                    <td style="width: 12%">
                      <habil-date-edit
                        [(ngModel)]="reg.nfseVcto"
                      ></habil-date-edit>
                    </td>
                    <td style="width: 5%">
                      <p-checkbox
                        [binary]="true"
                        [(ngModel)]="reg.nfce"
                      ></p-checkbox>
                    </td>
                    <td style="width: 12%">
                      <habil-date-edit
                        [(ngModel)]="reg.nfceVcto"
                      ></habil-date-edit>
                    </td>
                    <td style="width: 5%">
                      <p-checkbox
                        [binary]="true"
                        [(ngModel)]="reg.sat"
                      ></p-checkbox>
                    </td>
                    <td style="width: 12%">
                      <habil-date-edit
                        [(ngModel)]="reg.satVcto"
                      ></habil-date-edit>
                    </td>
                    <td style="width: 5%">
                      <p-checkbox
                        [binary]="true"
                        [(ngModel)]="reg.app"
                      ></p-checkbox>
                    </td>
                    <td style="width: 12%">
                      <habil-date-edit
                        [(ngModel)]="reg.appVcto"
                      ></habil-date-edit>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
        <div class="p-field p-col-6"></div>
        <div class="p-field p-col-4">
          <!-- <habil-liberacao-atendimento [(ngModel)]="cadastraAtendimento"></habil-liberacao-atendimento>  -->
        </div>
        <div class="p-field p-col-2">
          <button
            pButton
            class="p-button-success"
            icon="pi pi-bolt"
            label="Gerar chave"
            (click)="gerar()"
          ></button>
        </div>
      </div>
    </p-accordionTab>
    <p-accordionTab header="Chave de registro" [selected]="gerouChave">
      <div class="p-fluid p-grid">
        <div class="p-field p-col-12">
          <textarea
            pInputTextarea
            [(ngModel)]="chaveGerada"
            rows="30"
            cols="100"
          ></textarea>
        </div>
        <div class="p-field p-col-2">
          <button
            pButton
            icon="pi pi-copy"
            label="Copiar chave"
            [cdkCopyToClipboard]="chaveGerada"
          ></button>
        </div>
      </div>
    </p-accordionTab>
    <p-accordionTab header="Liberação de Suporte" [selected]="gerouChave">
      <habil-liberacao-atendimento
        [(ngModel)]="cadastraAtendimento"
        (onChange)="changeAtendimentoLiberacao($event)"
      ></habil-liberacao-atendimento>
    </p-accordionTab>
  </p-accordion>
</habil-grupo>
<div *ngIf="loading">
  <br />
  <p-progressBar
    mode="indeterminate"
    [style]="{ height: '6px' }"
  ></p-progressBar>
</div>
<br />
<!-- [disabled]="!permiteFinalizar()" -->
<habil-grupo>
  <div class="p-fluid p-grid">
    <div class="p-col-3"></div>
    <div class="p-col-3 p-col-align-end"></div>
    <div class="p-col-2 p-col-align-end">
      <button
        pButton
        class="p-button-success"
        icon="pi pi-check"
        label="Finalizar"
        
        (click)="finalizar()"
      ></button>
    </div>
    <div class="p-col-2 p-col-align-end">
      <button
        pButton
        class="p-button-success"
        icon="pi pi-check"
        label="Finalizar e enviar chave por e-mail"
        [disabled]="!permiteFinalizar()"
        (click)="finalizar(true)"
      ></button>
    </div>
    <div class="p-col-2 p-col-align-end">
      <button
        pButton
        icon="pi pi-check"
        label="Fechar"
        (click)="fechar()"
      ></button>
    </div>
  </div>
</habil-grupo>
