import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/app.breadcrumb.service";
import { EstimativaAcesso } from "src/app/models/estimativa-acesso";
import { AbstractService } from "src/app/services/abstract.service";
import { EstimativaAcessoService } from "src/app/services/estimativa-acesso.service";

@Component({
  selector: "habil-estimativa-acesso",
  templateUrl: "./estimativa-acesso.component.html",
  styleUrls: ["./estimativa-acesso.component.scss"],
  providers: [MessageService],
})
export class EstimativaAcessoComponent implements OnInit {
  mostrarRamo: boolean = false;
  origem: string = null;
  origens = [
    { value:"Desktop", label:"Desktop"},
    { value:"Web", label:"Web"},
    { value:"Desktop-Web",label:"Desktop do Web (encapsulado)"},
  ];
  categoria: string = null;
  categorias = [
    {value:"Função", label:"Funcionalidade" },
    {value:"Relatório", label:"Relatório" },
  ];
  mostrarRamoColuna: boolean;

  constructor(
    public service: EstimativaAcessoService,
    private breadcrumbService: BreadcrumbService,
    titleService: Title,
    public messageService: MessageService
  ) {
    this.breadcrumbService.setItems([{ label: "Estimativa de Acessos" }]);
    titleService.setTitle("Estimativa de Acessos - " + AbstractService.app);
  }

  list: EstimativaAcesso[] = [];
  exportColumns = [
    { header: "Ramo de Atividade", field: "ramoAtividade" },
    { header: "Rotina", field: "rotina" },
    { header: "Origem", field: "origem" },
    { header: "Categoria", field: "categoria" },
    { header: "Nro. de Acessos", field: "qtde" },
    { header: "Tempo (seg)", field: "tempo" },
  ];

  loading = false;

  private preencheLista(list: EstimativaAcesso[]) { 
    this.list = [];
    if ( list ) {
      list.forEach((e) => {
        let inclui = true;
        if ( this.origem ) {
          if ( e.origem != this.origem ) {
            inclui = false;
          }
        }
        if ( this.categoria ) {
          if ( e.categoria != this.categoria ) {
            inclui = false;
          }
        }

        if ( inclui ) {
          const est: EstimativaAcesso = new EstimativaAcesso();

          est.ramoAtividade = e.ramoAtividade;
          est.qtde = +e.qtde;
          est.rotina = e.rotina;
          est.tempo = +e.tempo;
          est.categoria = e.categoria == "Função" ? "Funcionalidade" : e.categoria;
          if (!est.categoria) {
            est.categoria = "(sem informação)";
          }
          est.origem = e.origem;
          if ( est.origem == "Desktop-Web") {
            est.origem = "Desktop do Web (encapsulado)";
          }
          this.list.push(est);
        }
      });
    }
  }

  private formataSegundos(segundos: number): string {
      let ret: string = "";
      if ( segundos >= 3600 ) {
          const horas = Math.trunc(segundos / 3600);
          ret += horas + "h"
          segundos -= (horas*3600);
      }
      if ( segundos >= 60 ) {
          const minutos = Math.trunc( segundos / 60 );
          ret += minutos + "m";
          segundos -= (minutos*60);
      }
      if ( segundos > 0 ) {
          ret += segundos + "s";
      }    
      return ret;  
  }

  carregaDados() {
    this.loading = true;
    this.mostrarRamoColuna = this.mostrarRamo;
    if (!this.mostrarRamo) {
      this.service.porTotal().subscribe(
        (list) => {
          this.preencheLista(list);
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.service.handleError(error);
        }
      );
    } else {
      this.service.findAll(0, 0, "").subscribe(
        (lst) => {
          this.preencheLista( lst );
          this.loading = false;
        },
        (e) => {
          this.loading = false;
          this.service.handleError(e);
        }
      );
    }
  }

  getSeverity(a: EstimativaAcesso): string {
    switch (a.categoria) {
      case "Funcionalidade":
        return "success";
      case "Relatório":
        return "info";
    }
    return "danger";
  }

  getTempo(reg: EstimativaAcesso): number {
    if (!+reg.tempo) {
      return Math.trunc(reg.qtde * 1.1);
    }
    return reg.tempo;
  }

  ngOnInit(): void {}
}
