import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Classificacao } from '../models/registro-produto';
import { AbstractService } from './abstract.service';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class ClassificacaoService extends AbstractService<Classificacao> {

  titulo: string = 'Classificação';
  endpoint(): string {
    return "admin/classificacao";
  }
  rotina(): string {
    return "CLASSIFICACOES";
  }
  colunas(): any[] {
    return [
      { header: "Código", field: "id" },
      { header: "Nome", field: "descricao" },
    ];
  }
  novo(selection?: any) {
    AbstractService.readonly = false;
    this.router.navigate(['clientes/classificacao/cadastro']);
  }
  abrir(obj: Classificacao) {
    AbstractService.readonly = true;
    this.router.navigate(['clientes/classificacao/cadastro'], { queryParams: { id: obj.id } });
  }
  edit(obj: Classificacao) {
    AbstractService.readonly = false;
    this.router.navigate(['clientes/classificacao/cadastro'], { queryParams: { id: obj.id } });
  }
  routeLista() {
    return '/clientes/classificacao';
  }

  constructor(
    http: HttpClient,
    private router: Router,
    loginService: LoginService
  ) {
    super(http, loginService);
  }
}
