<div class="card card-w-title" [style]="style">
  <div class="p-grid p-justify-between"
       *ngIf="showClose || ( header != undefined && header.trim() != '' ) ">
    <div class="p-col-10">
      <h3>{{header}}</h3>
    </div>
    <div class="btn-close" *ngIf="showClose">
      <button pButton pRipple type="button" (click)="close()"></button>
    </div>
  </div>
  <div *ngIf="dif > 0">
    <p-scrollPanel [style]="{width: '100%', height: getScrollHeight()}">
      Ola
      <div>
        <ng-content></ng-content>
      </div>
    </p-scrollPanel>
  </div>
  <div *ngIf="dif == 0">
    <ng-content></ng-content>
  </div>
  
</div>
