import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServidorComponent } from './servidor/servidor.component';
import { CadastroServidorComponent } from './cadastro-servidor/cadastro-servidor.component';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressBarModule } from 'primeng/progressbar';
import { RippleModule } from 'primeng/ripple';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { BotoesCadastroModule } from 'src/app/utilities/botoes-cadastro/botoes-cadastro.module';
import { GrupoModule } from 'src/app/utilities/grupo/grupo.module';
import { LabelModule } from 'src/app/utilities/label/label.module';
import { BaseListaModule } from 'src/app/utilities/base-lista/base-lista.module';
import { BalanceamentoComponent } from './balanceamento.component';



@NgModule({
  declarations: [
    CadastroServidorComponent,
    BalanceamentoComponent,
    ServidorComponent
  ],
  imports: [
    CommonModule,
    BaseListaModule,
    FormsModule,
    BrowserModule,
    ConfirmDialogModule,
    GrupoModule,
    BotoesCadastroModule,
    TooltipModule,
    AccordionModule,
    LabelModule,
    ProgressBarModule,
    RippleModule,
    ToastModule,
    ScrollPanelModule,
    InputTextModule,

  ]
})
export class BalanceamentoModule { }
