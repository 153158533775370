<p-panel [header]="_titulo" *ngIf="_titulo != '' && value != undefined">
  <ng-content></ng-content>

  <div class="p-fluid p-formgrid p-grid" >
    <div class="p-field p-col-12 p-md-6 p-lg-2">
      <habil-label name="cep" label="CEP">
        <div title="Informe um cep válido para que os dados de endereço sejam preenchidos automaticamente" tooltipPosition="top">
          <p-inputMask [(ngModel)]="value.cep" [name]="_prefix + 'cep'" mask="99999-999" (onBlur)="exitCep()" [readonly]="_readonly"></p-inputMask>
        </div>
      </habil-label>
    </div>
    <div class="p-field p-col-12 p-md-6 p-lg-4">
      <habil-label name="endereco" label="Endereço">
        <input pInputText [(ngModel)]="value.logradouro" [name]="_prefix + 'endereco'" [readonly]="_readonly" (blur)="blur()">
      </habil-label>
    </div>
    <div class="p-field p-col-12 p-md-4 p-lg-2">
      <habil-label name="numero" label="Número">
        <input pInputText [(ngModel)]="value.numero" [name]="_prefix + 'numero'" [readonly]="_readonly" (blur)="blur()">
      </habil-label>
    </div>
    <div class="p-field p-col-12 p-md-8 p-lg-4">
      <habil-label name="complemento" label="Complemento">
        <input pInputText [(ngModel)]="value.complemento" [name]="_prefix + 'complemento'" [readonly]="_readonly" (blur)="blur()">
      </habil-label>
    </div>
  </div>
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-md-6 p-lg-4">
      <habil-label name="bairro" label="Bairro">
        <input pInputText [(ngModel)]="value.bairro" [name]="_prefix + 'bairro'" [readonly]="_readonly" (blur)="blur()">
      </habil-label>
    </div>

    <div class="p-field p-col-12 p-md-6 p-lg-4" *ngIf="showUf()">
      <habil-label name="uf" label="UF">
        <p-dropdown appendTo="body"  [options]="ufs" [name]="_prefix + 'uf'"
                     [(ngModel)]="value.uf"
                     (onChange)="exitUf()"
                     [readonly]="_readonly">
        </p-dropdown>
      </habil-label>
    </div>
    <div class="p-field p-col-12 p-md-6 p-lg-4" *ngIf="!showPais()">
      <habil-label name="municipio" label="Cidade">
        <p-dropdown appendTo="body" [editable]="true" [options]="_selMunicipios"
                     [(ngModel)]="value.municipio" [name]="_prefix + 'municipio'" (onBlur)="blur()" [readonly]="_readonly">
        </p-dropdown>
      </habil-label>
    </div>
    <div class="p-field p-col-12 p-md-6 p-lg-4" *ngIf="showPais()">
      <habil-label name="pais" label="País">
        <p-dropdown appendTo="body"  [options]="_paises"
                     [(ngModel)]="value.pais"
                     [name]="_prefix + 'pais'"
                     optionLabel="nome" optionValue="nome" (onBlur)="blur()"
                     [readonly]="_readonly">
        </p-dropdown>
      </habil-label>
    </div>

  </div>

</p-panel>

<div *ngIf="_titulo == '' && value != undefined">
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-md-6 p-lg-2">
      <habil-label name="cep" label="CEP">
        <div title="Informe um cep válido para que os dados de endereço sejam preenchidos automaticamente" tooltipPosition="top">
          <p-inputMask [(ngModel)]="value.cep" mask="99999-999" (onBlur)="exitCep()" [readonly]="_readonly"></p-inputMask>
        </div>
      </habil-label>
    </div>
    <div class="p-field p-col-12 p-md-6 p-lg-4">
      <habil-label name="endereco" label="Endereço">
        <input pInputText [(ngModel)]="value.logradouro" [name]="_prefix + 'endereco'" [readonly]="_readonly" (blur)="blur()">
      </habil-label>
    </div>
    <div class="p-field p-col-12 p-md-4 p-lg-2">
      <habil-label name="numero" label="Número">
        <input pInputText [(ngModel)]="value.numero" [name]="_prefix + 'numero'" [readonly]="_readonly" (blur)="blur()">
      </habil-label>
    </div>
    <div class="p-field p-col-12 p-md-8 p-lg-4">
      <habil-label name="complemento" label="Complemento">
        <input pInputText [(ngModel)]="value.complemento" [name]="_prefix + 'complemento'" [readonly]="_readonly" (blur)="blur()">
      </habil-label>
    </div>
  </div>
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-md-6 p-lg-4">
      <habil-label name="bairro" label="Bairro">
        <input pInputText [(ngModel)]="value.bairro" [name]="_prefix + 'bairro'" [readonly]="_readonly" (blur)="blur()">
      </habil-label>
    </div>

    <div class="p-field p-col-12 p-md-6 p-lg-4" *ngIf="showUf()">
      <habil-label name="uf" label="UF">
        <p-dropdown appendTo="body"  [options]="ufs" [name]="_prefix + 'uf'" [(ngModel)]="value.uf" (onChange)="exitUf()" [readonly]="_readonly"></p-dropdown>
      </habil-label>
    </div>
    <div class="p-field p-col" *ngIf="!showPais()">
      <habil-label name="municipio" label="Cidade">
        <p-dropdown appendTo="body" [editable]="true" [options]="_selMunicipios" 
                     [(ngModel)]="value.municipio" [name]="_prefix + 'municipio'" (onBlur)="blur()" [readonly]="_readonly">
        </p-dropdown>
      </habil-label>
    </div>
    <div class="p-field p-col-fixed p-col-align-end" style="width: 45px" *ngIf="_showContent">
      <ng-content></ng-content>
    </div>
    <div class="p-field p-col-12 p-md-6 p-lg-4" *ngIf="showPais()">
      <habil-label name="pais" label="País">
        <p-dropdown appendTo="body"  [options]="_paises" [(ngModel)]="value.pais"
                     [name]="_prefix + 'pais'"
                     optionLabel="nome" optionValue="nome" (onBlur)="blur()" [readonly]="_readonly">
        </p-dropdown>
      </habil-label>
    </div>

  </div>
</div>
