<habil-grupo>
  <div class="p-fluid p-grid">
    <div class="p-col-2">
      <habil-label name="origem" label="Origem">
        <p-dropdown
          placeholder="Todas as origens"
          [showClear]="true"
          [(ngModel)]="origem"
          [options]="origens"
        >
        </p-dropdown>
      </habil-label>
    </div>
    <div class="p-col-2">
      <habil-label name="categoria" label="Categoria">
        <p-dropdown
          placeholder="Todas as categorias"
          [showClear]="true"
          [(ngModel)]="categoria"
          [options]="categorias"
        >
        </p-dropdown>
      </habil-label>
    </div>
    <div class="p-col-fixed p-col-align-center">
      <habil-label>
        <br>
        <p-checkbox
          name="mostraramo"
          [binary]="true"
          [(ngModel)]="mostrarRamo"
          label="Mostrar ramo de atividade"
        ></p-checkbox>
      </habil-label>
    </div>
    <div class="p-col-fixed p-col-align-end">
      <button
        pButton
        icon="pi pi-cloud-download"
        [loading]="loading"
        (click)="carregaDados()"
        label="Carregar Dados"
      ></button>
    </div>
    <div class="p-col-fixed p-col-align-end">
      <button
        pButton
        icon="pi pi-file-excel"
        (click)="dt.exportCSV()"
        label="Exportar"
        [disabled]="!list || list.length == 0"
      ></button>
    </div>
    <div class="p-col-12">
      <small>Dados desde 19/01/2023</small>
    </div>
    <div class="p-col-12">
      <p-table #dt [value]="list" [loading]="loading" [columns]="exportColumns" csvSeparator=";">
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="ramoAtividade" *ngIf="mostrarRamoColuna">
              Ramo de Atividade<p-sortIcon field="ramoAtividade"></p-sortIcon>
            </th>
            <th pSortableColumn="rotina">
              Rotina<p-sortIcon field="rotina"></p-sortIcon>
            </th>
            <th pSortableColumn="origem">
              Origem<p-sortIcon field="origem"></p-sortIcon>
            </th>
            <th pSortableColumn="qtde">
              Nro. de Acessos<p-sortIcon field="qtde"></p-sortIcon>
            </th>
            <th pSortableColumn="tempo">
              Tempo<p-sortIcon field="tempo"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-est>
          <tr>
            <td *ngIf="mostrarRamoColuna">{{ est.ramoAtividade }}</td>
            <td>
              <p-badge
              [value]="est.categoria"
              [severity]="getSeverity(est)"
              ></p-badge>
              {{ est.rotina }}
            </td>
            <td>{{ est.origem }}</td>
            <td>{{ est.qtde }}</td>
            <td>{{ formataSegundos( getTempo(est) ) }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</habil-grupo>
