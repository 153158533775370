import { Component, ElementRef, EventEmitter, forwardRef, OnInit, Output, Renderer2 } from '@angular/core';
import { DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Atendimento } from 'src/app/models/atendimento';
import { AtendimentoService } from 'src/app/services/atendimento.service';
import { PlanoSuporteService } from 'src/app/services/plano-suporte.service';
import { DateUtils } from 'src/app/utilities/util/date-utils';

@Component({
  selector: 'habil-liberacao-atendimento',
  templateUrl: './liberacao-atendimento.component.html',
  styleUrls: ['./liberacao-atendimento.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LiberacaoAtendimentoComponent ),
      multi: true
    }
  ]
})
export class LiberacaoAtendimentoComponent extends DefaultValueAccessor {

  _value: LiberacaoAtendimento = new LiberacaoAtendimento();
  _vctoAtual: Date;

  constructor( 
    renderer: Renderer2, 
    _elementRef: ElementRef,
    public planoSuporteService: PlanoSuporteService,
    private atendimentoService: AtendimentoService
  ) { 
    super(renderer, _elementRef, false );
  }

  onChange: ( obj: any ) => void;
  onTouched: () => void;
  
  @Output( 'onChange' )
  _onChange: EventEmitter<any> = new EventEmitter<any>();

  private setaVctoAtual( lib: LiberacaoAtendimento ) {
    console.log( lib )
    if ( !this._vctoAtual ) {
      if ( lib?.atendimento?.id ) {
        this.atendimentoService.findById( lib.atendimento.id ).subscribe(
          att => { 
            this._vctoAtual = DateUtils.corrigeCampoData( att.fim );
            const hoje = DateUtils.dateToString( new Date() );
            if ( DateUtils.dateToString( this._vctoAtual ) > hoje ) {
              const novoFim = DateUtils.incDay( this._vctoAtual, 30 );
              this.value.atendimento.fim = novoFim;
            }
          }
        ) 
      }
      //this._vctoAtual = lib?.atendimento?.fim;
    }
  }

  somaData( eh30Dias: boolean ) {
    let data = this.value?.atendimento?.fim;
    if ( !data ) {
      data = new Date();
    }
    const dias = eh30Dias ? 30 : 15;
    data = DateUtils.incDay( data, dias );
    this.value.atendimento.fim = data;
  }

  get value(): LiberacaoAtendimento {
    return this._value;
  }

  set value( value: LiberacaoAtendimento ) {    
    this.setaVctoAtual( value );
    this._value = value;
  }

  writeValue(value: LiberacaoAtendimento): void {
    this.setaVctoAtual( value )
    this._value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;    
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  blur() {
    if ( this._value ) {
      this._onChange.emit( this._value );
    }
  }

}

export class LiberacaoAtendimento {
  atendimento: Atendimento = new Atendimento();
  liberar: boolean = false;
}