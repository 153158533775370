<div class="layout-footer">
    <div class="p-grid">
        <div class="p-col-12 p-md-12 p-lg-4 logo-container-outer">
            <a href="/" class="logo-container">
                <img src="assets/layout/images/ecuador-orange-logo.png" alt="ecuador-layout" />
                <span class="app-name">Premium PrimeFaces Application Template</span>
            </a>
        </div>
        <div class="p-col-12 p-md-12 p-lg-8" style="text-align:center">
            <div id="footer-menu">
                <div class="p-grid">
                    <div class="p-col-12 p-md-6 p-lg-3 footer-box">
                        <span>OVERVIEW</span>
                        <a href="#primefaces">Why PrimeFaces</a>
                        <a href="#prime">Who Uses PrimeFaces</a>
                        <a href="#testimonials">Testimonials</a>
                        <a href="#licence">License</a>
                    </div>
                    <div class="p-col-12 p-md-6 p-lg-3 footer-box">
                        <span>DEMOS</span>
                        <a href="#faces">PrimeFaces</a>
                        <a href="#ng">PrimeNG</a>
                        <a href="#react">PrimeReact</a>
                        <a href="#ui">PrimeUI</a>
                    </div>
                    <div class="p-col-12 p-md-6 p-lg-3 footer-box">
                        <span>SUPPORT</span>
                        <a href="#options">Support Options</a>
                        <a href="#pro">PrimeFaces PRO</a>
                        <a href="#elite">PrimeFaces Elite</a>
                        <a href="#forum">Forum</a>
                    </div>
                    <div class="p-col-12 p-md-6 p-lg-3 footer-box">
                        <span>SOCIAL</span>
                        <a href="#twitter" class="icon-link"><i class="pi pi-twitter icon"></i></a>
                        <a href="#facebook" class="icon-link"><i class="pi pi-facebook icon"></i></a>
                        <a href="#github" class="icon-link"><i class="pi pi-github icon"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
