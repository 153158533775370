<habil-grupo>
  <p-accordion [multiple]="true">
    <p-accordionTab header="Dados do Cliente" [selected]="true">
      <div class="p-grid p-fluid">
        <div class="p-field p-col-5">
          <habil-label name="lbnome" label="Nome do Cliente">
            <input pInputText [(ngModel)]="liberacao.dadosCliente.nome" [readonly]="cliente.id>0" />
          </habil-label>
        </div>
        <div class="p-field p-col-4">
          <habil-label name="lbemail" label="E-mail">
            <input pInputText [(ngModel)]="liberacao.dadosCliente.email" [readonly]="cliente.id>0" />
          </habil-label>
        </div>
        <div class="p-field p-col-2">
          <habil-label name="lbcnpj" label="CPF/CNPJ">
            <input pInputText [(ngModel)]="liberacao.dadosCliente.cpfCnpj" [readonly]="cliente.id>0" />
          </habil-label>
        </div>        
        <div class="p-field p-col-1 p-col-align-end" *ngIf="cliente.id==0">
          <p-tag icon="pi pi-user" value="Usuário novo"></p-tag>
        </div>
        <div class="p-field p-col-12">
          <habil-label name="lbresumo" label="Resumo do Pedido">
            <p-table [value]="liberacao.produtos">
              <ng-template pTemplate="header">
                <tr>
                  <th>Descrição</th>
                  <th>Qtde</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-p>
                <tr>
                  <td>{{ p.descricao }}</td>
                  <td>{{ p.qtde }}</td>
                </tr>
              </ng-template>
            </p-table>
          </habil-label>
        </div>        
        <div class="p-field p-col-12">
          <habil-label name="lbobs" label="Observações">
            <textarea pInputTextarea [(ngModel)]="liberacao.observacoes" [rows]="10"></textarea>
          </habil-label>
        </div>
        <div class="p-field p-col-12">
          <div class="p-grid p-fluid" *ngIf="cliente.id>0">
            <div class="p-field p-col-12">
              <p-divider>Dados da chave atual</p-divider>
            </div>
            <div class="p-field p-col-2">
              <h5>ID do Cliente: <b>{{cliente.id}}</b></h5>          
            </div>
            <div class="p-field p-col-2">
              <h5>Número de licenças: <b>{{licenca.nroLicencas}}</b></h5>          
            </div>
            <div class="p-field p-col-2">
              <h5>Vencimento: <b>{{licenca.expDate | date: 'dd/MM/yyyy'}}</b></h5>
            </div>
            <div class="p-field p-col-6"></div>
            <div class="p-field p-col-4">
              <habil-label name="lbmodulos" label="Módulos Licenciados">
                <p-table [value]="modulos">
                  <ng-template pTemplate="header">
                    <tr>
                      <th>Módulo</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-e>
                    <tr>
                      <td>{{ e.label }}</td>
                    </tr>
                  </ng-template>
                </p-table>
              </habil-label>
            </div>
            <div class="p-field p-col-4">
              <habil-label name="lbemissoesatuais" label="Emissões">
                <p-table [value]="emissoes">
                  <ng-template pTemplate="header">
                    <tr>
                      <th>CNPJ</th>
                      <th>Tipo</th>
                      <th>Vencimento</th>
                      <th>Qtde Docs</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-e>
                    <tr>
                      <td>{{ e.cnpj }}</td>
                      <td>{{ e.tipo }}</td>
                      <td>{{ e.vcto | date: 'dd/MM/yyyy' }}</td>
                      <td>{{ e.qtde }}</td>
                    </tr>
                  </ng-template>
                </p-table>
              </habil-label>
            </div>
            <div class="p-field p-col-4">
              <habil-label name="lbadicionais" label="Adicionais">
                <p-table [value]="licenca.adicionais">
                  <ng-template pTemplate="header">
                    <tr>
                      <th>CNPJ</th>
                      <th>Módulo</th>
                      <th>Vencimento</th>
                      <th>Licenças</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-e>
                    <tr>
                      <td>{{ e.documento }}</td>
                      <td>{{ e.modulo }}</td>
                      <td>{{ e.vcto | date: 'dd/MM/yyyy' }}</td>
                      <td>{{ e.licencas }}</td>
                    </tr>
                  </ng-template>
                </p-table>
              </habil-label>
            </div>
          </div>
        </div>
      </div>           
    </p-accordionTab>
    <p-accordionTab header="Dados do Pedido" [selected]="true">
      <div class="p-grid p-fluid">
        <div class="p-field p-col-2">
          <habil-label label="Número de licenças" name="lbqtdelic">
            <p-inputNumber [(ngModel)]="liberacao.qtdeUsuarios"></p-inputNumber>
          </habil-label>
        </div>
        <div class="p-field p-col-2">
          <habil-label label="Novo Vencimento" name="lbvcto">
            <habil-date-edit [(ngModel)]="vcto"></habil-date-edit>
          </habil-label>
        </div>
        <div class="p-field p-col-8"></div>
        <div class="p-field p-col-12">
          <p-pickList
            [source]="produtosDisponiveis"
            [target]="produtosSelecionados"
            [dragdrop]="true"
            sourceHeader="Disponíveis"
            targetHeader="Selecionados"
            filterBy="nomeProduto"
            [responsive]="true"
            sourceFilterPlaceholder="Busca por nome"
            targetFilterPlaceholder="Busca por nome"
            [sourceStyle]="{ height: '20rem' }"
            [targetStyle]="{ height: '20rem' }"
            [showSourceControls]="false"
            [showTargetControls]="false"
          >
            <ng-template let-prod pTemplate="item">
              <div>
                {{ prod.nomeProduto }}
              </div>
            </ng-template>
          </p-pickList>
        </div>
      </div>
    </p-accordionTab>
    <p-accordionTab header="Emissões fiscais" [selected]="true">
      <div class="p-grid p-fluid">
        <div class="p-field p-col-12">
          <p-table [value]="licencaEmissao" responsiveLayout="scroll">
            <ng-template pTemplate="header">
              <tr>
                <th
                  rowspan="2"
                  style="width: 36px; text-align-last: center; padding: 0">
                  <button
                    pButton
                    pRipple
                    icon="pi pi-plus"
                    style="text-align-last: center"
                    class="p-button-rounded p-button-text"
                    (click)="addEmissao()"
                  ></button>
                </th>
                <th rowspan="2">CNPJ</th>
                <th colspan="3">NF-e</th>
                <th colspan="3">NFC-e</th>
                <th colspan="3">NFS</th>
                <th colspan="3">SAT</th>
              </tr>
              <tr>
                <th>Usa</th>
                <th>Vencimento</th>
                <th>Qtde</th>
                <th>Usa</th>
                <th>Vencimento</th>
                <th>Qtde</th>
                <th>Usa</th>
                <th>Vencimento</th>
                <th>Qtde</th>
                <th>Usa</th>
                <th>Vencimento</th>
                <th>Qtde</th>
              </tr>
            </ng-template>
            <br />
            <ng-template pTemplate="body" let-e>
              <tr>
                <td style="width: 36px; text-align-last: center; padding: 0">
                  <button
                    pButton
                    pRipple
                    icon="pi pi-trash"
                    style="text-align-last: center"
                    class="p-button-rounded p-button-text"
                    (click)="delEmissao(e)"
                  ></button>
                </td>
                <td>
                  <input pInputText [(ngModel)]="e.documento" />
                </td>
                <td>
                  <p-checkbox [binary]="true" [(ngModel)]="e.nfe"></p-checkbox>
                </td>
                <td>
                  <habil-date-edit [(ngModel)]="e.vctoNfe"></habil-date-edit>
                </td>
                <td>
                  <p-inputNumber [(ngModel)]="e.qtdeDocsNfe"></p-inputNumber>
                </td>
                <td>
                  <p-checkbox [binary]="true" [(ngModel)]="e.nfce"></p-checkbox>
                </td>
                <td>
                  <habil-date-edit [(ngModel)]="e.vctoNfce"></habil-date-edit>
                </td>
                <td>
                  <p-inputNumber [(ngModel)]="e.qtdeDocsNfce"></p-inputNumber>
                </td>
                <td>
                  <p-checkbox [binary]="true" [(ngModel)]="e.nfs"></p-checkbox>
                </td>
                <td>
                  <habil-date-edit [(ngModel)]="e.vctoNfs"></habil-date-edit>
                </td>
                <td>
                  <p-inputNumber [(ngModel)]="e.qtdeDocsNfs"></p-inputNumber>
                </td>
                <td>
                  <p-checkbox [binary]="true" [(ngModel)]="e.sat"></p-checkbox>
                </td>
                <td>
                  <habil-date-edit [(ngModel)]="e.vctoSat"></habil-date-edit>
                </td>
                <td>
                  <p-inputNumber [(ngModel)]="e.qtdeDocsSat"></p-inputNumber>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </p-accordionTab>
    <p-accordionTab header="Módulos Adicionais" [selected]="true">
      <div class="p-grid p-fluid">
        <div class="p-field p-col-12">
          <p-table [value]="licencaAdicionais" responsiveLayout="scroll">
            <ng-template pTemplate="header">
              <tr>
                <th
                  rowspan="2"
                  style="width: 36px; text-align-last: center; padding: 0"
                >
                  <button
                    pButton
                    pRipple
                    icon="pi pi-plus"
                    style="text-align-last: center"
                    class="p-button-rounded p-button-text"
                    (click)="addAdicional()"
                  ></button>
                </th>
                <th>CPF/CNPJ</th>
                <th>Módulo</th>
                <th>Vencimento</th>
                <th>Qtde Licenças</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-a>
              <tr>
                <td style="width: 36px; text-align-last: center; padding: 0">
                  <button
                    pButton
                    pRipple
                    icon="pi pi-trash"
                    style="text-align-last: center"
                    class="p-button-rounded p-button-text"
                    (click)="delAdicional(a)"
                  ></button>
                </td>
                <td>
                  <input pInputText [(ngModel)]="a.documento" />
                </td>
                <td>
                  <p-dropdown [(ngModel)]="a.modulo" optionValue="value" optionLabel="label" [options]="modulosAdicionais" ></p-dropdown>
                </td>
                <td>
                  <habil-date-edit [(ngModel)]="a.vcto"></habil-date-edit>
                </td>
                <td>
                  <p-inputNumber [(ngModel)]="a.licencas"></p-inputNumber>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </p-accordionTab>
    <p-accordionTab header="Liberação de Suporte" [selected]="true">
      <habil-liberacao-atendimento
        [(ngModel)]="cadastraAtendimento"
        (onChange)="changeAtendimentoLiberacao($event)"
      ></habil-liberacao-atendimento>
    </p-accordionTab>
  </p-accordion>
</habil-grupo>
<div *ngIf="loading">
  <br />
  <p-progressBar
    mode="indeterminate"
    [style]="{ height: '6px' }"
  ></p-progressBar>
</div>
<br />
<habil-grupo>
  <div class="p-fluid p-grid">
    <div class="p-col"></div>
    <div class="p-col-2">
      <button
        pButton
        class="p-button-success"
        icon="pi pi-check"
        label="Finalizar"
        [disabled]="!permiteFinalizar()"
        (click)="finalizar()"
      ></button>
    </div>
    <div class="p-col-2">
      <button
        pButton
        icon="pi pi-check"
        label="Fechar"
        (click)="fechar()"
      ></button>
    </div>
  </div>
</habil-grupo>
