import { Component, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { Licenca, LicencaModulos, Registro } from "src/app/models/registro";
import { softwares } from "src/app/models/registro-produto";
import { RegistroService } from "src/app/services/registro.service";
import { clone } from "src/app/utilities/util/clone-utils";
import { DateUtils } from "src/app/utilities/util/date-utils";
import { HabilMobilePdvProdutoService } from "src/app/services/habil-mobile-pdv-produto.service";
import {
  HabilMobilePDVProduto,
  HabilMobilePDVRegistroLicenca,
  HabilMobilePDVRegistroLicencaEmpresa,
} from "src/app/models/habil-mobile-pdv-produto";

@Component({
  selector: "habil-cadastro-licenca-habil-mobile-pdv",
  templateUrl: "./cadastro-licenca-habil-mobile-pdv.component.html",
  styleUrls: ["./cadastro-licenca-habil-mobile-pdv.component.scss"],
})
export class CadastroLicencaHabilMobilePdvComponent implements OnInit {
  constructor(
    private config: DynamicDialogConfig,
    private modal: DynamicDialogRef,
    public service: RegistroService,
    public moduloService: HabilMobilePdvProdutoService
  ) {}

  obj: HabilMobilePDVRegistroLicenca = new HabilMobilePDVRegistroLicenca();

  licenca: Licenca = {
    software: "HABIL_MOBILE_PDV",
    dbToken: "",
    modulosLicenciados: [],
    licenceKey: "",
    timestamp: DateUtils.dateTimeToString(new Date()),
    personalizada: false,
    usouDemo: false,
    tipo: "",
    id: 0,
    idInterno: 0,
    creationDate: undefined,
    idHabil: "",
    lastModifiedDate: undefined,
    createdBy: 0,
    lastModifiedBy: 0,
    deleted: false,
  };

  registro: Registro = {
    nome: "",
    cnpj: "",
    email: "",
    cancelamento: null,
    endereco: undefined,
    responsavel: "",
    telefones: [],
    licencas: [],
    id: 0,
    idInterno: 0,
    creationDate: undefined,
    idHabil: "",
    lastModifiedDate: undefined,
    createdBy: 0,
    lastModifiedBy: 0,
    deleted: false,
  };

  softwares = softwares;
  loading = false;

  modulosDisponiveis: LicencaModulos[] = [];
  todosOsModulos: HabilMobilePDVProduto[] = [];
  rotinas = [];
  rotinasComDescricao = [];

  apagaEmpresa(empresa: any): void {
    this.obj.empresas = this.obj.empresas.filter(
      (e) => e.documento != empresa.documento
    );
  }

  novaEmpresa(): void {
    const empresa: HabilMobilePDVRegistroLicencaEmpresa =
      new HabilMobilePDVRegistroLicencaEmpresa();
    empresa.documento = this.registro.cnpj;
    empresa.nome = this.registro.nome;
    empresa.licencas = 1;
    empresa.vcto = new Date();
    if (!this.obj.empresas) {
      this.obj.empresas = [];
    }
    this.obj.empresas?.push(empresa);
  }

  ngOnInit(): void {
    if (this.config.data) {
      this.licenca = this.config.data.licenca;
      this.registro = this.config.data.registro;
      this.service
        .getRegistroLicenca(this.registro.id, this.licenca.software)
        .subscribe(
          (rl) => {
            if (rl?.body) {
              this.obj = clone(rl.body);
              if (!this.obj.empresas?.length) {
                this.novaEmpresa();
              }
              this.moduloService.findAll(0, 0, "").subscribe((produtos) => {
                this.todosOsModulos = [...produtos];
                this.modulosDisponiveis = [];
                let inc = -1;
                if (produtos) {
                  for (let p of produtos) {
                    let jaUtilizado = false;
                    for (
                      let i = 0;
                      i < this.licenca.modulosLicenciados.length;
                      i++
                    ) {
                      if (p.id == this.licenca.modulosLicenciados[i].idModulo) {
                        this.licenca.modulosLicenciados[i].descricaoModulo =
                          p.nomeProduto;
                        jaUtilizado = true;
                        break;
                      }
                    }
                    if (jaUtilizado) continue;

                    const m: LicencaModulos = {
                      idModulo: p.id,
                      descricaoModulo: p.nomeProduto,
                      licenca: { id: this.licenca.id },
                      id: clone(inc),
                      idInterno: 0,
                      creationDate: undefined,
                      idHabil: "",
                      lastModifiedDate: undefined,
                      createdBy: 0,
                      lastModifiedBy: 0,
                      deleted: false,
                    };
                    inc--;
                    this.modulosDisponiveis.push(m);
                  }
                }
                this.licenca.modulosLicenciados =
                  this.licenca.modulosLicenciados.filter(
                    (m) => m.descricaoModulo
                  );
                this.atualizarRotinasPorModulo();
              });
            }
          },
          (e) => {
            this.service.handleError(e);
          }
        );
    }
  }

  onChangeModulos(): void {
    setTimeout(() => {
      this.preencherRotinasPorModulo();
    }, 500);
  }

  preencherRotinasPorModulo(): void {
    this.obj.acessos = [];
    for (const m of this.licenca.modulosLicenciados) {
      const modulo: HabilMobilePDVProduto = this.todosOsModulos.find(
        (p) => p.id == m.idModulo
      );
      console.log(modulo.acessos);
      for (const rotina of modulo.acessos) {
        if (!this.obj.acessos.find((r) => r == rotina)) {
          this.obj.acessos.push(clone(rotina));
        }
      }
    }
    this.atualizarRotinasPorModulo();
  }

  private carregaRotinas(): Promise<void> {
    return new Promise((sim, nao) => {
      this.moduloService.getAcessos().subscribe({
        next: (acessos) => {
          this.rotinas = [];
          if (acessos) {
            acessos.forEach((a) => {
              this.rotinas.push({
                value: a.value,
                label: a.description,
              });
            });
          }
          sim();
        },
        error: (erro) => {
          this.moduloService.handleError(erro);
          nao();
        },
      });
    });
  }

  atualizarRotinasPorModulo(): void {
    this.rotinasComDescricao = [];
    this.carregaRotinas().then(() => {
      console.log(this.rotinas);
      this.rotinas.sort((a, b) => {
        if (a.label > b.label) {
          return 1;
        }
        if (a.label < b.label) {
          return -1;
        }
        return 0;
      });
      if (!this.obj.acessos) this.obj.acessos = [];
      for (let rotina of this.obj.acessos) {
        let r = this.rotinas.find((r) => r.value == rotina);
        if (!r) r = { label: rotina, value: rotina };
        this.rotinasComDescricao.push(r);
        this.rotinas = [...this.rotinas.filter((r) => r.value != rotina)];
      }
      this.rotinasComDescricao.sort((a, b) => {
        if (a.label > b.label) {
          return 1;
        }
        if (a.label < b.label) {
          return -1;
        }
        return 0;
      });
    });
  }

  getScrollHeight(): string {
    let fullHeight = window.innerHeight;
    let availableHeight = fullHeight - 230;
    let scrollHeight = availableHeight > 100 ? availableHeight + "px" : "100px";

    return scrollHeight;
  }

  salvar(): void {
    this.obj.acessos = [];
    for (const rotina of this.rotinasComDescricao) {
      this.obj.acessos.push(rotina.value);
    }
    this.loading = true;
    this.service
      .saveRegistroLicenca(this.registro.id, this.licenca, this.obj)
      .subscribe(
        (ret) => {
          this.loading = false;
          if (ret) {
            this.modal.close(ret);
          }
        },
        (e) => {
          this.service.handleError(e);
          this.loading = false;
        }
      );
  }

  cancelar(): void {
    this.modal.close();
  }
}
