import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AdminUsuario, RetornoLogin } from '../models/admin-usuario';
import { ConfiguracaoSistema } from '../models/cfg';
import { ServerErrorComponent } from '../pages/server-error/server-error.component';
import { clone } from '../utilities/util/clone-utils';
import { FormatUtils } from '../utilities/util/format-utils';
import { HttpRequest } from '../utilities/util/http-request';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  public static usuarioLogado: AdminUsuario;

  isAuthenticated = new Subject<boolean>();
  expireIn = new BehaviorSubject<any>(null);
  user = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpClient,
    public dialogService: DialogService
  ) { }

  public trataExcecoes(e) {
    if (e == null) return;
    if (!(e['stack'] && e['stack'].toString().includes('TypeError: Cannot read properties of undefined'))){
      const tela = this.dialogService.open(ServerErrorComponent, {
        header: 'Erro interno',
        closeOnEscape: false,
        data: {
          erro: clone(e)
        }
      });
    }

  }

  validateUsername(usuario: string, senha: string): Observable<HttpResponse<RetornoLogin>> {
    const url = environment.api + '/auth/login';
    const params = new URLSearchParams();
    params.append('login', usuario.toString().toUpperCase());
    params.append('senha', senha);
    const h = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/json; charset=utf-8'
    });
    return this.http.post<RetornoLogin>(url, params.toString(), { headers: h, observe: 'response' });
  }

  public getGlobal(): Observable<any> {
    let url: string = environment.api + '/configuracao/global';
    const h = new HttpHeaders({
      'Accept': 'application/json; charset=utf-8',
      'X-Auth-Token': AbstractService.token()
    });
    return this.http.get<any>(url, { headers: h });
  }

  public setGlobal(cfg: ConfiguracaoSistema): Observable<ConfiguracaoSistema> {
    let url: string = environment.api + '/configuracao/global';
    const h = new HttpHeaders({
      'Accept': 'application/json; charset=utf-8',
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Auth-Token': AbstractService.token(),
    })
    const params = new URLSearchParams();
    params.append('obj', FormatUtils.toJson(cfg));
    return this.http.put<ConfiguracaoSistema>(url, params.toString(), { headers: h });
  }
  
}
