import { Component, OnInit } from '@angular/core';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {ConfirmationService, MessageService} from 'primeng/api';
import { AppComponent } from 'src/app/app.component';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'ks-server-error',
  templateUrl: './server-error.component.html',
  styleUrls: ['./server-error.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class ServerErrorComponent implements OnInit {

  constructor(private config: DynamicDialogConfig,
              private modal: DynamicDialogRef,
              private loginService: LoginService,
              private dialogService: DialogService) { }

  textoErro = 'Ocorreu um erro não especificado!'
  showDeslogar = true;
  deslogarSemEnviarReq = false;

  ngOnInit(): void {
    if (this.config?.data?.erro) {
      const erro = this.config?.data?.erro;
      if (erro?.status == 0 ) {
        this.textoErro = "Não foi possível conectar com o servidor. Por favor tente novamente mais tarde";
        return;
      }
      if (erro?.status == 500) {
        // Erro interno no Servidor
        if (erro?.error) {
          if (erro.error?.detailMessage) {
            this.textoErro = "Mensagem: " + erro.error.detailMessage;
            this.textoErro += "<br>" + erro.error?.cause?.detailMessage;
            if (this.textoErro.includes('varying(255)')){
              this.textoErro += "<br><b>DICA: possivelmente há neste cadastro algum campo que foi preenchido com " +
                "<br>mais caracteres do que o limite de 255. Verifique e tente novamente.</b>"
            }
          } else {
            try {
              this.textoErro = JSON.stringify(erro.error);
            } catch (e) {}
          }
        }
      } else if (erro.status == 401) {
        this.textoErro = "401 - Não autorizado";
        this.textoErro += "<br>" + this.validaString(erro.error?.cause?.detailMessage);
      } else if (erro.status == 402) {
        this.textoErro = "402 - Licença Expirada!";
        this.textoErro += "<br> Entre em contato com o departamento de vendas!";
      } else if (erro.status == 402) {
        this.textoErro = "424 - Erro de Versão. ";
        this.textoErro += "<br> A versão atual está diferente da versão do servidor. É necessário efetuar a atualização antes de prosseguir.";
      } else if (erro.status == 403) {
        this.textoErro = "403 - Sem permissão ";
        this.textoErro += "<br> Sem permissão para acessar esse recurso";
      } else if (erro.status == 409) {
        this.textoErro = "409 - Desconectado ";
        this.textoErro += "<br> Seu usuário foi conectado em outra estação! <br>Por favor o desconecte e tente novamente.";
        this.deslogarSemEnviarReq = true;
      } else if (erro.status == 412) {
        this.textoErro = "412 - Falha na pré-condição<br>";
        this.textoErro += this.validaString(erro.error);
      } else if (erro.status == 423) {
        this.textoErro = "423 - Acesso bloqueado ";
        this.textoErro += "<br> O Número de usuários logados ultrapassou o número de licenças do sistema!";
      } else {
        this.textoErro = JSON.stringify(erro);
      }
    }
  }

  validaString(str) {
    if (str == null) {
      return '';
    } else {
      return str;
    }
  }

  continuar() {
    this.modal.close();
    
  }

  sairDoHabil() {
    try {
      this.dialogService.dialogComponentRefMap.clear();
    } catch (e) {}
    try {
      this.modal.close();
    } catch (e) {}
  }

}
