import { Component, ElementRef, EventEmitter, forwardRef, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Subject } from 'rxjs';
import { AbstractModel } from 'src/app/models/abstract-model';
import { AbstractService } from 'src/app/services/abstract.service';
import { CadastroDialogComponent } from '../cadastro-dialog/cadastro-dialog.component';
import { clone } from '../util/clone-utils';
import { FormatUtils } from '../util/format-utils';
import { SearchParams } from '../util/search/search-params';

@Component({
  selector: 'habil-autocomplete-edit',
  templateUrl: './autocomplete-edit.component.html',
  styleUrls: ['./autocomplete-edit.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteEditComponent),
      multi: true,
    }, DialogService, MessageService, ConfirmationService,
  ]
})
export class AutocompleteEditComponent<T extends AbstractModel> extends DefaultValueAccessor {

  @Input('service')
  _service: AbstractService<T>;

  @Input('name')
  _name: string;

  @Input('readonly')
  _readonly: boolean = false;

  @Input('placeholder')
  _placeholder: string = '';

  @Input('field')
  _field: string = 'id';

  @Input('style')
  _style: string;

  @Input('index')
  _index: number;

  @Input('hide-id')
  _hideId: boolean = false;

  @Input('showButton')
  _showButton: boolean = true;

  @Input('insideButton')
  _insideButton: boolean = true;

  @Input('tooltipBtnPosition')
  _tooltipBtnPosition: string = 'top';

  @Input('enableDropButton')
  _enableDropButton: boolean = false;

  @Input('dropdownButtonIcon')
  _dropdownButtonIcon: string = 'pi pi-chevron-down';

  @Input('search-params')
  _getSearchParams: Function;

  @Output('onBlur')
  _onBlur: EventEmitter<void> = new EventEmitter();

  @Input('editDialogOptions')
  _editDialogOptions: any;

  @Output('onChange')
  _onChange: EventEmitter<T> = new EventEmitter();

  @Input('observableObject')
  @Output('observableObject')
  observableObject: Subject<any>;

  @ViewChild('autoComplete') autocomplete: ElementRef;

  constructor(public dialogService: DialogService, _renderer: Renderer2, _elementRef: ElementRef) {
    super(_renderer, _elementRef, true);
  }

  @Input('disabled')
  _disabled: boolean = false;

  _suggestions: T[] = [];

  _fields: string[] = [];
  _i: number = 0;

  val: T;

  id: number = 0;

  _searchAfter = false;

  _showEmpty = true;

  isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  _empty = 'Sem resultados'

  _class = "ng-invalid ng-dirty";

  tempValue;
  _query = '';

  titulo = '';

  codigoContaCaixa: string = 'codigoArvore';


  search(event) {
    try {
      this._class = "";
      this._showEmpty = true;
      this._suggestions = [];
      let q: string = event.query;
      this._query = event.query;
      this.tempValue = null;
      if (q.startsWith("+")) {
        this._suggestions = [];
        this._searchAfter = true;
        this.id = parseInt(q.replace("+", ""));
        this._empty = 'Busca pelo código';
        this._class = "ng-invalid ng-dirty"
        if (this.isNumber(this.id)) {
          let sp: SearchParams[] = [];
          if (this._getSearchParams != undefined && this._getSearchParams != null) {
            sp = this._getSearchParams();
          }
          sp.push({ paramName: 'id', paramValue: [this.id.toString()] })
          this._service.findAll(0,50,'', sp)
            .subscribe(t => {
              if (typeof t == 'string') {
                this.value = undefined;
                this._class = "ng-invalid ng-dirty"
              } else {
                if (t != undefined && t[0]?.id != undefined) {
                  this._class = "";
                  this._suggestions = [{ ...t[0] }]
                  this.tempValue = { ...t };
                  this._searchAfter = false;
                } else {
                  this._class = "ng-invalid ng-dirty"
                }
              }
              this._onChange.emit(this.value);
            }, e => {
              this._service.handleError(e);
            });
        }

      } else {

        this._searchAfter = false;
        this._empty = 'Carregando...'
        this._class = ""
        let sp: SearchParams[] = [];
        if (this._getSearchParams != undefined) {
          sp = this._getSearchParams();
        }

        this._service.findAll(0,50,
          event?.query.toLowerCase(),
          sp
        ).subscribe(resp => {
          let textoInformacao = ' resultados encontrados'
          this._suggestions = [];
          if (!resp) {
            this._empty = 'Sem resultados'
            console.clear();
          }
          if (resp && resp.length == 1) textoInformacao = ' resultado encontrado'
          const length = (resp ? resp.length : 0);
          this._empty = `${length}${textoInformacao}`;

          this._suggestions = clone(resp);

        }, error => {
          this._empty = 'Sem resultados'
          console.clear();
          console.log(error);
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  ngOnInit() {
    this.titulo = this._service.titulo;
    this._service.getSearchFields().subscribe(
      obj => {
        this._fields = [...obj.fields];
      },
      error => {
        console.log(error)
      }
    );
    setTimeout(() => {
      let doc: HTMLElement = document.getElementsByClassName('padding-btn')[0] as HTMLElement;
      doc.firstElementChild['style'].paddingRight = '30px';
    }, 200);
  }

  getId(): string {
    let v: any = this.value;
    let toolTip = ""
    if (this._service && this._service.rotina() == "CFOP") {
      toolTip = v.codigo;
    } else if (this._service && this._service.rotina() == "CONTA_CAIXA") {
      toolTip = v.codigoArvore;
    } else {
      toolTip = v.id.toString();
    }
    return toolTip;
  }



  onEdit() {
    this.buscaRegistro(this.val?.id).then(()=>{
      const tela = this.dialogService.open(CadastroDialogComponent, {
        width: '90%',
        //height: '100%',
        header: 'Cadastro - ' + this.titulo,
        closeOnEscape: true,
        closable: false,
        contentStyle: { height: '95%' },
        data: {
          obj: this.value,
          rotina: this._service.rotina(),
          options: this._editDialogOptions,
        }
      });

      tela.onClose.subscribe(data => {
        if (data != undefined) {
          this.value = data.body;
          /* RM3613 */
          if (this._index != undefined && this.observableObject != undefined) {
            this.value['index'] = this._index;
            this.observableObject.next(this.value);
          }
          this._onChange.emit(this.value);
        }
      });
    });


  }

  buscaRegistro(id): Promise<any>{
    return new Promise<any>((resolve) => {
      try {
        if (id) {
          this._service.findByIdLazyLoaded(id).subscribe(obj => {

            this.value = clone(obj);
            resolve(null);
          }, error => resolve(null));
        } else {
          resolve(null);
        }
      } catch (e) {
        resolve(null);
      }
    });
  }

  set value(val: T) {
    try {
      if (typeof val == 'string' || (val != undefined && val != null && val.id == undefined)) {
        let v = val.toString();
        if (v != null && v != undefined && v != '') {
          this._query = v;
        } else {
          this._query = '';
        }
      } else {
        this.val = val;
        this._class = "";
      }
      this.onChange(this.val);
      this.onTouched();
    } catch (e) {
      this._class = "ng-invalid ng-dirty";
    }

  }

  get value() {
    if (typeof this.val == 'string') {
      //this.val = undefined;

    }
    return this.val;
  }

  onChange: (_: any) => void;
  onTouched: () => void;

  writeValue(value: T): void {
    if (typeof value == 'string') {
      this.value = null;
    } else {
      this.value = value;
    }
    if(value != undefined && value.id == undefined){
      this.value = null;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  onBlur() {

    if (typeof this.value == 'string') {
      this.value = undefined;
      this._class = "ng-invalid ng-dirty";
    }
    let q: string = this._query;
    if (q.length > 0 && q.startsWith("+") || (this._service.rotina() == "CFOP")) {
      if (this._service.rotina() == "CFOP") {
        q = q.replace('.', '');
      }
      if (this.isNumber(q.replace("+", ""))) {
        this.id = parseInt(q.replace("+", ""));
      }
    }
    if (this.tempValue != undefined  && this.value != undefined) {
      this.value = clone(this.tempValue)
    }
    if (this.id > 0 && (this.value == undefined || this._service.rotina() == 'CFOP') && this._query !=  '') {
      if (this._service.rotina() == "CFOP"){
        let sp: SearchParams[] = [];
        if (this._getSearchParams != undefined) {
          sp = this._getSearchParams();
        }

        this._service.findAll(0,50,
          this.id.toString(),
          sp
        ).subscribe(resp => {
          if (resp != undefined && resp.length > 0) {
            this._class = "";
            this.value = resp[0];
          } else {
            this._class = "ng-invalid ng-dirty";
          }
        }, error => {
          console.clear();
          console.log(error);
        });
      } else {
        let sp: SearchParams[] = [];
        if (this._getSearchParams != undefined && this._getSearchParams != null) {
          sp = this._getSearchParams();
        }
        sp.push({ paramName: 'id', paramValue: [this.id.toString()] })
        this._service.findAll(0,50,'', sp)
          .subscribe(t => {
            if (typeof t == 'string') {
              this.value = undefined;
              this._class = "ng-invalid ng-dirty"
            } else {
              this.value = { ...t[0] };
              this._onChange.emit(this.value);
              if (t[0].id != undefined) {
                this._class = ""
              } else {
                this._class = "ng-invalid ng-dirty"
              }
            }
            this._onChange.emit(this.value);
          });
      }

    }else{
      setTimeout(()=>{
        if (this.value?.id == undefined){
          this.value = null;
          this._onChange.emit(this.value);
          this._query = '';

          this._suggestions = [];


          this._class = "ng-invalid ng-dirty"

        }
      },1000)


    }
    this._onBlur.emit();
  }

  onClear() {
    this.value = undefined;
    this._onChange.emit( this.value );
  }

  doRenderHeader(): boolean {
    this._i++;
    return this._i <= 1;
  }

  formatCpfCnpj(doc: string): string {
    return FormatUtils.cpfCnpj(doc);
  }

  requestFocus() {
    document.getElementById("autoComplete").focus();
  }

  onSelect(event) {
    this._service.findByIdLazyLoaded(event.id)
      .subscribe(t => {
        if (typeof t == 'string') {
          this.value = undefined;
        } else {
          this.value = { ...t };
        }
        this._onChange.emit(this.value);
      })
  }

}
