<div class="exception-body">
    <div class="exception-topbar">
        <a href="#"  class="logo">
            <img src="assets/layout/images/ecuador-logo.png" alt="ecuador-layout"/>
        </a>
    </div>
    <div class="exception-panel">
        <div class="top">
            <img src="assets/layout/images/curvy-left.svg" alt="ecuador-layout" class="left"/>
            <img src="assets/layout/images/error.svg" alt="ecuador-layout" class="main"/>
            <img src="assets/layout/images/curvy-right.svg" alt="ecuador-layout" class="right"/>
            <span>500</span>
            <div class="seperator"></div>
        </div>
        <div class="bottom">
            <span class="exception-panel-header">Error Occured</span>
            <span class="exception-panel-subheader">Something went wrong.</span>
            <button type="button" pButton label="Turn to home page" [routerLink]="['/']"></button>
        </div>
    </div>
</div>
