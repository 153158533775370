import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Atendimento } from 'src/app/models/atendimento';

@Component({
  selector: 'habil-atendimento-buscar',
  templateUrl: './atendimento-buscar.component.html',
  styleUrls: ['./atendimento-buscar.component.scss']
})
export class AtendimentoBuscarComponent implements OnInit {

  list: Atendimento[];
  obj: Atendimento;

  constructor(
    private ref: DynamicDialogRef, 
    private config: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
    this.list = this.config.data;
  }

  selecionar() {
    this.ref.close( this.obj );
  }

  cancelar() {
    this.ref.close();
  }

}
