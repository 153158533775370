import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { MessageService } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { BreadcrumbService } from "src/app/app.breadcrumb.service";
import { ConsultaClienteDTO } from "src/app/models/registro";
import { AbstractService } from "src/app/services/abstract.service";
import { ClassificacaoService } from "src/app/services/classificacao.service";
import { MensagemService } from "src/app/services/mensagem.service";
import { RegistroService } from "src/app/services/registro.service";
import { DateUtils } from "src/app/utilities/util/date-utils";

@Component({
  selector: "habil-consulta-cliente",
  templateUrl: "./consulta-cliente.component.html",
  styleUrls: ["./consulta-cliente.component.scss"],
  providers: [MessageService, DialogService],
})
export class ConsultaClienteComponent implements OnInit {
  
  list: ConsultaClienteDTO[] = [];
  tipoData = 0;
  tiposData = [
    { label: "Vencimento", value: 0 },
    { label: "Cadastro", value: 1 },
  ];
  tipoLicenca = 0;
  tiposLicenca = [
    { label: "Todas", value: 0 },
    { label: "Demo", value: 1 },
    { label: "Free", value: 2 },
  ];
  dataInicial = new Date();
  dataFinal = new Date();
  classificacao = null;
  exportColumns = [
    { header: "Código", field: "id" },
    { header: "CNPJ", field: "cnpj" },
    { header: "Nome", field: "nome" },
    { header: "E-mail", field: "email" },
    { header: "Classificação", field: "classificacao" },
    { header: "Vencimento", field: "vencimento" },
    { header: "Cadastro", field: "cadastro" },
    { header: "Tipo da Licença", field: "tipo" },
  ];
  qtdeRegistros: string = "Nenhum registro visualizado";
  loading: boolean = false;

  constructor(
    public service: RegistroService,
    public classificacaoService: ClassificacaoService,
    private breadcrumbService: BreadcrumbService,
    titleService: Title
  ) {
    this.breadcrumbService.setItems([{ label: "Consulta de Clientes" }]);
    titleService.setTitle("Consulta de Clientes - " + AbstractService.app);
  }



  busca() {
    this.loading = true;
    this.service
      .fazConsulta(
        this.tipoData,
        DateUtils.dateToString(this.dataInicial),
        DateUtils.dateToString(this.dataFinal),
        this.tipoLicenca,
        this.classificacao?.id
      )
      .subscribe(
        (lst) => {
          this.list = [];
          if (lst) {
            this.list = [...lst];
          }

          switch ( this.list.length ) {
            case 0: {
              this.qtdeRegistros = "Nenhum registro visualizado"
              break;
            }
            case 1: {
              this.qtdeRegistros = "1 registro visualizado";
              break;
            }
            default: {
              this.qtdeRegistros = `${this.list.length} registros visualizados`;
            }
          }

          this.loading = false;
        },
        (e) => {
          this.service.handleError(e);
          this.loading = false;
        }
      );
  }

  ngOnInit(): void {}
}
