<div class="p-fluid p-formgrid p-grid" style="max-height: 35px" [ngClass]="'inside-button'">
  <div class="p-col">
    <p-calendar
      #dateKs
      [(ngModel)]="value"
      [name]="name"
      [placeholder]="_placeholder"
      [showIcon]="false"
      [showOnFocus]="false"
      appendTo="body"
      (keydown)="keyUp($event)"
      [keepInvalid]="true"
      [showButtonBar]="true"
      [showTime]="_showTime"
      [minDate]="_minDate"
      dateFormat="dd/mm/yy"
      [disabled]="_disabled"
      (onInput)="doOnChange($event)"
      (onSelect)="doOnChange($event)"
      (onBlur)="onBlur($event)">
    </p-calendar>
  </div>
  <div class="p-col-fixed p-col-align-top" style="width: 36px; " *ngIf="!_disabled">
    <button
      pButton
      pRipple
      title="Abrir calendário"
      tooltipPosition="top"
      icon="pi pi-calendar"
      style="height: 100%;"
      tabindex="-1"
      class="p-autocomplete-dropdown p-ripple p-button p-component p-button-icon-only ng-star-inserted"
      label=""
      [disabled]="_disabled"
      (click)="dateKs.toggle()">
    </button>
  </div>

</div>

