import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { EstimativaAcesso } from '../models/estimativa-acesso';
import { HttpRequest } from '../utilities/util/http-request';
import { AbstractService } from './abstract.service';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class EstimativaAcessoService extends AbstractService<EstimativaAcesso> {

  titulo: string = 'Estimativa de Acessos';
  endpoint(): string {
    return "admin/estimativa-acessos";
  }
  rotina(): string {
    return "ESTIMATIVA_ACESSOS";
  }
  colunas(): any[] {
    return [
      { header: "Código", field: "id" },
      { header: "Nome", field: "descricao" },
    ];
  }

  porTotal(): Observable<EstimativaAcesso[]> {
    return new HttpRequest<EstimativaAcesso[]>(this.http)
      .endpoint( this.endpoint() )
      .uri( "/total-acesso" )
      .doGet()
  }

  novo(selection?: any) {
    AbstractService.readonly = false;
    this.router.navigate(['adm/estimativas']);
  }
  abrir(obj: EstimativaAcesso) {
    AbstractService.readonly = true;
    this.router.navigate(['adm/estimativas'], { queryParams: { id: obj.id } });
  }
  edit(obj: EstimativaAcesso) {
    AbstractService.readonly = false;
    this.router.navigate(['adm/estimativas'], { queryParams: { id: obj.id } });
  }
  routeLista() {
    return 'adm/estimativas';
  }

  constructor(
    http: HttpClient,
    private router: Router,
    loginService: LoginService
  ) {
    super(http, loginService);
  }
}
