import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class NuvemManagerService {
    constructor(private http: HttpClient) {}

    private static token = "";
    public static setToken(token) {
        this.token = token;
    }

    private get token() {
        return NuvemManagerService.token;
    }

    login(token: string): Observable<string> {
        const url = environment.devManager + "/login";
        const params = new URLSearchParams();
        params.append("token", token.toString());
        const h = new HttpHeaders({
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "text/plain; charset=utf-8",
        });
        return this.http.post(url, params.toString(), {
            headers: h,
            responseType: "text",
        });
    }

    getDatabases(): Observable<any[]> {
        const url = environment.devManager + "/databases";
        const h = new HttpHeaders({
            Accept: "application/json; charset=utf-8",
            "X-Auth-Token": this.token,
        });
        return this.http.get<any[]>(url, { headers: h });
    }

    getConnections(): Observable<string[]> {
        const url = environment.apiNuvem + "/server-up/tn/view";
        const h = new HttpHeaders({
            Accept: "application/json; charset=utf-8",
            "X-Auth-Token": environment.production ? TOKEN_H10_PROD : TOKEN_H10_DEV,
        });
        return this.http.get<string[]>(url, { headers: h });
    }

    upgrade(name: string): Observable<any> {
        const url = environment.devManager + "/upgrade";
        const params = new URLSearchParams();
        params.append("name", name);
        const h = new HttpHeaders({
            Accept: "application/json; charset=utf-8",
            "Content-Type": "application/x-www-form-urlencoded",
            "X-Auth-Token": this.token,
        });
        return this.http.post<any>(url, params.toString(), { headers: h });
    }

    drop(name: string): Observable<any> {
        const url = environment.devManager + "/drop";
        const params = new URLSearchParams();
        params.append("name", name);
        const h = new HttpHeaders({
            Accept: "application/json; charset=utf-8",
            "Content-Type": "application/x-www-form-urlencoded",
            "X-Auth-Token": this.token,
        });
        return this.http.post<any>(url, params.toString(), { headers: h });
    }

    create(name: string, urlFile: string): Observable<any> {
        const url = environment.devManager + "/create";
        const params = new URLSearchParams();
        params.append("name", name);
        params.append("url", urlFile);
        const h = new HttpHeaders({
            Accept: "application/json; charset=utf-8",
            "Content-Type": "application/x-www-form-urlencoded",
            "X-Auth-Token": this.token,
        });
        return this.http.post<any>(url, params.toString(), { headers: h });
    }
}

const TOKEN_H10_PROD =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJub21lTWFxdWluYSI6ImQyZmUzMTNlLWMwNGQtNDJiMy1hZGMxLWIyYzg2ZDg3NGU0MSIsInN1YiI6IjEwOTI3Njk1OTUyIiwibWFjQWRkcmVzcyI6ImQyZmUzMTNlLWMwNGQtNDJiMy1hZGMxLWIyYzg2ZDg3NGU0MSIsInVzZXJfaWQiOjEsImlzcyI6IktTSDEwIiwib3JpZ2VtIjoiUkVUQUdVQVJEQSJ9.ggUIRZ1zYaiQQKiCBYIyyAvWshsDJ0Ob2sbJPqJu0os";

const TOKEN_H10_DEV =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJub21lTWFxdWluYSI6IjUzZGMxZGIwLTU4NzQtNDk3YS05OGZiLWIxNGM0MTgzZjE2MyIsInN1YiI6IjI0MDU0MDUzMDAwMTUzIiwibWFjQWRkcmVzcyI6IjUzZGMxZGIwLTU4NzQtNDk3YS05OGZiLWIxNGM0MTgzZjE2MyIsInVzZXJfaWQiOjUsImlzcyI6IktTSDEwIiwib3JpZ2VtIjoiUkVUQUdVQVJEQSJ9.CZFtUV6DXMDvHMP6zzmVuVeC2Py00A1QUalbCqiC2tE";
