import { DatePipe, formatDate } from "@angular/common";
import moment from 'moment';

export class DateUtils {
  constructor(public datePipe: DatePipe) { }

  public static ptBr = {
    dateFormat: 'dd/mm/yyyy',
    firstDayOfeWeek: 1,
    dayNames: [
      "Domingo",
      "Segunda-Feira",
      "Terça-Feira",
      "Quarta-Feira",
      "Quinta-Feira",
      "Sexta-Feira",
      "Sábado",
    ],
    dayNamesShort: ["Dom", "Deg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
    dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
    monthNames: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
    monthNamesShort: [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ],
    today: "Hoje",
    clear: "Limpar",
  };


  public static dateToString(date: Date, somar: number = 0): string {
    if (date.toString().includes("/")) {
      let d: string = date.toString();
      let dt = '';
      if (d.split('/').length == 3) {
        dt = dt + d.split('/')[2] + '-';
        dt = dt + d.split('/')[1] + '-';
        dt = dt + d.split('/')[0] + " 00:00:00"
      }
      let data = new Date(dt);
      return formatDate(new Date(data.getFullYear(),
        data.getMonth(), data.getDate() + somar),
        "yyyy-MM-dd", "pt-BR")
    }
    if (date.toString().split('-').length < 3) {
      return formatDate(new Date(date.getFullYear(),
          date.getMonth(), date.getDate() + somar),
        "yyyy-MM-dd", "pt-BR");
    }else{
      return moment(date).format("yyyy-MM-DD");
    }


  }
  public static dateViewToString(date: Date): string {
    return formatDate(date, "dd/MM/yyy 'às' HH:mm", "pt-BR");
  }

  public static dateViewSimpleString(date: Date): string {
    return formatDate(date, "dd/MM/yyy HH:mm", "pt-BR");
  }

  public static dateTimeToString(date: Date, somar: number = 0): string {
    try {
      if (date.toString().includes("/")) {
        let d: string = date.toString();
        let dt = '';
        if (d.split('/').length == 3) {
          dt = dt + d.split('/')[2].split(' ')[0] + '-';
          dt = dt + d.split('/')[1] + '-';
          dt = dt + d.split('/')[0] ;
          if (d.includes(' ') && d.split(' ').length == 2){
            dt = dt + ' ' + d.split(' ')[1];
            if (dt.length == 16) {
              dt = dt + ':00';
            }else {
              dt = dt + ' 00:00:00';
            }
          }
        }
        let data = new Date(dt);
        return formatDate(new Date(data.getFullYear(),
            data.getMonth(), data.getDate() + somar, data.getHours(), data.getMinutes(), data.getSeconds()),
          "yyyy-MM-dd HH:mm:ss.SSS", "pt-BR")
      }
      date = new Date(date.toString());
      return formatDate(new Date(date.getFullYear(),
          date.getMonth(), date.getDate() + somar, date.getHours(), date.getMinutes(), date.getSeconds()),
        "yyyy-MM-dd HH:mm:ss.SSS", "pt-BR");
    } catch (e) {
      return moment(date).format("yyyy-MM-dd H:mm");
    }

  }

  public static corrigeCampoData(date, somar = 0) {
    if ( date == null || date == undefined ) {
      return null;
    }
    let dataConvertida: string = this.dateToString(date, somar);
    return this.stringToDate( dataConvertida + ' 03:00:00' );
  }

  public static timeToString(date: Date): string {
    return formatDate(date, "HH:mm:ss", "pt-BR");
  }

  public static dateBoletoToString(date: Date): string {
    return formatDate(date, "dd-MM-yyyy-HH-mm", "pt-BR");
  }

  public static dateToStringWihoutHH(date: Date): string {
    return formatDate(date, "dd/MM/yyyy", "pt-BR");
  }

  public static stringToDate(str: string): Date {
    return new Date(str);
  }

  public static isBetween(compared: Date, d1: Date, d2: Date): boolean {
    return compared >= d1 && compared <= d2;
  }

  public static incMonth(date: Date, n: number): Date {
    let d = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
    d.setMonth(date.getMonth() + n);
    return d;
  }

  public static incDay(date: Date, n: number): Date {
    let d = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
    d.setDate(d.getDate() + n);
    return d;
  }

  public static daysBetween(d1: Date, d2: Date): number {
    let diff = (this.startOfTheDay(d1).getTime() - this.startOfTheDay(d2).getTime()) / (1000 * 3600 * 24);
    return Math.ceil(diff);
  }

  public static startOfTheDay(date: Date): Date {
    let d = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
    d.setDate(d.getDate());
    d.setHours(0, 0, 0, 0);
    return d;
  }
  
  public static getDate( ano: number, mes: number, dia: number ) {
    // mês indexa de 0, por isso o -1
    return new Date( ano, mes - 1, dia );
  }
  
  public static endOfTheDay(d: Date): Date {
    d.setHours(23, 59, 59, 999);
    return d;
  }
  public static daysOfWeek(d: Date): String {
    let day;
    switch (d.getDay()) {
      case 0:
        day = "Sunday";
        break;
      case 1:
        day = "Monday";
        break;
      case 2:
        day = "Tuesday";
        break;
      case 3:
        day = "Wednesday";
        break;
      case 4:
        day = "Thursday";
        break;
      case 5:
        day = "Friday";
        break;
      case 6:
        day = "Saturday";
    }
    return day;
  }


}
