import { Directive, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService } from "primeng/api/";
import { AbstractModel } from "../models/abstract-model";
import { AbstractService } from "../services/abstract.service";
import { clone } from "./util/clone-utils";
import { DateUtils } from "./util/date-utils";
import { FormatUtils } from "./util/format-utils";
import { SearchParams } from "./util/search/search-params";

@Directive()
export abstract class GenericCadastro<
    T extends AbstractModel,
    U extends AbstractService<T>
> implements OnInit
{
    @Input()
    obj: T = this.newObject();

    public isCentroCusto: boolean = false;
    public centrosPai: any[];
    @Input("readonly")
    public _readonly: boolean = true;
    public id: number = 0;
    public titulo: any;
    public service: U;
    public route: ActivatedRoute;
    public router: Router;
    public confirmationService: ConfirmationService;

    @Input("dialog")
    _isDialog: boolean = false;

    @Input("value")
    _externalObj: any;

    _loading: boolean = true;
    awaitObjInitLoad: boolean = false;

    @Output()
    saving: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    cancel: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    objInitLoaded: EventEmitter<any> = new EventEmitter<any>();

    onSave(event) {
        if (this._isDialog && this.doValidate()) {
            let ret = { obj: this.obj, uc: event };
            this.saving.emit(ret);
        }
    }

    contasContrato: any[] = [];
    saldoDevedor: number = 0;

    public corrigeAtributos() {
        let t: any = this.obj;
        t = JSON.parse(FormatUtils.toJson(t).replace('":""', '":null'));
        if (t.cadastro) {
            t.cadastro = DateUtils.dateTimeToString(t.cadastro);
        }
        if (t.previsaoValidade) {
            t.previsaoValidade = DateUtils.dateTimeToString(t.previsaoValidade);
        }
        if (t.validade ) {
            const tipoEhNumber = typeof t.validade == "number";
            if ( !tipoEhNumber ) {
                t.validade = DateUtils.dateTimeToString(t.validade);
            }
        }
        this.obj = clone<T>(t);
    }

    onCancel() {
        if (this._isDialog) {
            this.cancel.emit();
        }
    }
    public idCampos: string;

    abstract newObject(): T;
    abstract doValidate(): boolean;

    public dif = 330;

    loadAuxObjects() {
        let obj: any = clone(this.obj);


        if (obj.dataInicio) {
            obj.dataInicio = DateUtils.dateToString(obj.dataInicio);
        }

        if (obj.dataValidade) {
            obj.dataValidade = DateUtils.dateToString(obj.dataValidade);
        }

        if (obj.proximoContatoData) {
            obj.proximoContatoData = new Date(obj.proximoContatoData);
        }
        if (obj.proximoContatoHora) {
            try {
                obj.proximoContatoHora = obj.proximoContatoHora
                    .toString()
                    .split(" ")[1];
                this.obj = clone(obj);
            } catch (e) {
                console.log(e);
            }
        }
        if (obj.proximoContatoHora == undefined) {
            obj.proximoContatoHora = "00:00:00";
        }
        this._loading = false;
    }

    getScrollHeight(): string {
        let fullHeight = window.innerHeight;
        let availableHeight = fullHeight - this.dif;
        let scrollHeight =
            availableHeight > 100 ? availableHeight + "px" : "100px";

        return scrollHeight;
    }

    validaDuplicacao(obj): T {
        return clone(obj);
    }

    stopLoading() {
        this._loading = false;
    }

    afterLoadObj() {}

    ngOnInit() {
        this._readonly = AbstractService.readonly;
        this.dif = 190;

        this.route.queryParams.subscribe((p) => {
            this.id = p.id;
            if (this.id > 0) {
                if (this.service.rotina() == "ITEM") {
                    this.idCampos =
                        this.id == undefined ? "0" : this.id.toString();
                    return;
                }
                this.service.findByIdLazyLoaded(this.id).subscribe(
                    (o) => {
                        if (o.detalhes) {
                            for (let d of o.detalhes) {
                                if (d.descontos) {
                                    if (d.descontos.descontoVistaInicio) {
                                        d.descontos.descontoVistaInicio =
                                            DateUtils.stringToDate(
                                                d.descontos.descontoVistaInicio
                                            );
                                    }
                                    if (d.descontos.descontoVistaTermino) {
                                        d.descontos.descontoVistaTermino =
                                            DateUtils.stringToDate(
                                                d.descontos.descontoVistaTermino
                                            );
                                    }
                                    if (d.descontos.descontoPrazoInicio) {
                                        d.descontos.descontoPrazoInicio =
                                            DateUtils.stringToDate(
                                                d.descontos.descontoPrazoInicio
                                            );
                                    }
                                    if (d.descontos.descontoPrazoTermino) {
                                        d.descontos.descontoPrazoTermino =
                                            DateUtils.stringToDate(
                                                d.descontos.descontoPrazoTermino
                                            );
                                    }
                                }
                            }
                        }
                        if (o.abertura) {
                            o.abertura = DateUtils.stringToDate(o.abertura);
                        }
                        if (o.dadosCliente?.clienteDesde) {
                            o.dadosCliente.clienteDesde =
                                DateUtils.stringToDate(
                                    o.dadosCliente.clienteDesde
                                );
                        }
                        if (o.data) {
                            o.data = DateUtils.stringToDate(o.data);
                        }
                        // if (o.licencas && o.licencas.length > 0){
                        //   o.licencas.map(l => {
                        //     l.timestamp = new Date(l.timestamp)
                        //   })
                        // }
                        if (o.bomPara) {
                            o.bomPara = DateUtils.stringToDate(o.bomPara);
                        }
                        if (o.dataSolicitacao) {
                            o.dataSolicitacao = DateUtils.stringToDate(
                                o.dataSolicitacao
                            );
                        }
                        if (o.dataVencimento) {
                            o.dataVencimento = DateUtils.stringToDate(
                                o.dataVencimento
                            );
                        }
                        if (o.dataPrevisao) {
                            o.dataPrevisao = DateUtils.stringToDate(
                                o.dataPrevisao
                            );
                        }
                        if (o.concluidaEm) {
                            o.concluidaEm = DateUtils.stringToDate(
                                o.concluidaEm
                            );
                        }
                        if (o.emissao) {
                            o.emissao = DateUtils.stringToDate(o.emissao);
                        }
                        if (o.dataInicio) {
                            o.dataInicio = DateUtils.stringToDate(o.dataInicio);
                        }
                        if (o.dataValidade) {
                            o.dataValidade = DateUtils.stringToDate(
                                o.dataValidade
                            );
                        }
                        if (o.inicioViagem) {
                            o.inicioViagem = DateUtils.stringToDate(
                                o.inicioViagem
                            );
                        }
                        if (o.dataCadastro) {
                            o.dataCadastro = DateUtils.stringToDate(
                                o.dataCadastro
                            );
                        }
                        if (o.cadastro) {
                            o.cadastro = DateUtils.stringToDate(o.cadastro);
                        }
                        if (o.dataInicial) {
                            o.dataInicial = DateUtils.stringToDate(
                                o.dataInicial
                            );
                        }
                        if (o.dataFinal) {
                            o.dataFinal = DateUtils.stringToDate(o.dataFinal);
                        }
                        if (o.proximoReajuste) {
                            o.proximoReajuste = DateUtils.stringToDate(
                                o.proximoReajuste
                            );
                        }

                        if (o.ultimoReajuste) {
                            o.ultimoReajuste = DateUtils.stringToDate(
                                o.ultimoReajuste
                            );
                        }

                        if (o.dataNotificacao) {
                            o.dataNotificacao = DateUtils.stringToDate(
                                o.dataNotificacao
                            );
                        }
                        if (o.inicio) {
                            o.inicio = DateUtils.stringToDate(o.inicio);
                        }
                        if (o.termino) {
                            o.termino = DateUtils.stringToDate(o.termino);
                        }

                        if (p._duplicar === "true") {
                            this.obj = this.validaDuplicacao(
                                Object.assign({}, o)
                            );
                            this.obj.id = 0;
                        } else {
                            this.obj = clone(o);
                        }

                        this.carregaInformacoesContrato(p);

                        this.loadAuxObjects();
                        if (this.awaitObjInitLoad) {
                            this.objInitLoaded.emit(this.obj);
                        }
                        this.stopLoading();
                        if (this.isCentroCusto) {
                            this.service
                                .findAll(
                                    0,
                                    0,
                                    "",
                                    "",
                                    this.getSearchParamsCentroPai()
                                )
                                .subscribe((cp) => {
                                    this.centrosPai = cp;
                                    console.log(cp);
                                });
                        }
                        this.afterLoadObj();
                    },
                    (e) => this.service.handleError(e)
                );
            } else {
                this.obj = this.newObject();
                this.loadAuxObjects();
                this.stopLoading();
            }
        });
        this.idCampos = this.id == undefined ? "0" : this.id.toString();
    }

    onErrorHandled() {
        this._loading = false;
    }

    carregaInformacoesContrato(params?) {
        let o = clone<any>(this.obj);

        if (this.service.rotina() == "CONTRATOS") {
            if (params.renovar) {
                o.id = 0;
                this.id = 0;
                this.idCampos = "0";
                if (o.dataInicio && o.dataValidade) {
                    let dif = o.dataValidade.getTime() - o.dataInicio.getTime();
                    o.dataInicio = new Date();
                    o.dataValidade = new Date(new Date().getTime() + dif);
                } else {
                    o.dataInicio = new Date();
                }
                o.referencia = 0;
                o.rescindido = false;
                this.obj = clone(o);
            } else {
                if (o.rescindido) {
                    this._readonly = true;
                }
            }
        }
    }

    getObject(): T {
        return this.obj;
    }

    setObject(obj: T) {
        this.obj = obj;
    }

    onSaveAndNew(novo: boolean) {
        if (novo) {
            this.obj = this.newObject();
            this.loadAuxObjects();
            this._loading = false;
        }
    }

    onSaved() {}

    cancelar() {
        if (!this._readonly) {
            this.confirmationService.confirm({
                message: "Deseja cancelar as alterações no registro?",
                header: "Atenção",
                icon: "pi pi-exclamation-triangle",
                acceptLabel: "Sim",
                rejectLabel: "Não",
                accept: () => {
                    this.router.navigate([this.service.routeLista()]);
                },
                reject: () => {},
            });
        } else {
            this.router.navigate([this.service.routeLista()]);
        }
    }

    getSearchParamsCentroPai(): SearchParams[] {
        return [
            {
                paramName: "pai.id",
                paramValue: [this.obj.id.toString()],
            },
        ];
    }
}
