import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Atendimento } from 'src/app/models/atendimento';
import { ELiberacaoEmissaoFiscalTipo, Liberacao } from 'src/app/models/liberacao';
import { ChaveEmpresarial, ChaveEmpresarialEmissao, ConversorChaveEmpresarial } from 'src/app/models/liberacao-chave-empresarial';
import { AbstractService } from 'src/app/services/abstract.service';
import { AtendimentoService } from 'src/app/services/atendimento.service';
import { LiberacaoService } from 'src/app/services/liberacao.service';
import { MensagemService } from 'src/app/services/mensagem.service';
import { EUf } from 'src/app/utilities/endereco/euf.enum';
import { DateUtils } from 'src/app/utilities/util/date-utils';
import { DateValidationItem, FormValidation, FormValidationItem, SearchValidationItem, StringValidationItem } from 'src/app/utilities/util/form-validation';
import { LiberacaoAtendimento } from '../liberacao-atendimento/liberacao-atendimento.component';

@Component({
  selector: 'habil-liberacao-empresarial',
  templateUrl: './liberacao-empresarial.component.html',
  styleUrls: ['./liberacao-empresarial.component.scss']
})
export class LiberacaoEmpresarialComponent implements OnInit {

  loading: boolean = false; 
  liberacao: Liberacao;
  sanfonaUltimaChave: boolean = false;
  ultimaChave: string = '';
  chave: ChaveEmpresarial = new ChaveEmpresarial();
  chaveGerada: string;
  gerouChave: boolean = false;
  ufs = EUf.ufsSigla.filter( uf => uf.value != 'NENHUM' && uf.value != "EX" );
  cadastraAtendimento: LiberacaoAtendimento = new LiberacaoAtendimento();

  versao = [
    { value: "9.7" },
    { value: "9.6" },
    { value: "9.5" },
    { value: "9.0" },
    { value: "8.5" },
    { value: "8.0" },
    { value: "7.6" },
    { value: "7.5.1" },
    { value: "7.5" },
    { value: "7.4.5" },
    { value: "7.4.1" },
    { value: "7.4" },
    { value: "7.3.5" },
    { value: "7.3" },
    { value: "7.2.5" },
    { value: "7.2" },
    { value: "7.0" }
  ];
  versaoNFe = [
    { value: "4.15" },
    { value: "4.14" },
    { value: "4.13" },
    { value: "4.12" },
    { value: "4.11" },
    { value: "4.10" },
    { value: "4.0" },
    { value: "3.13" },
    { value: "3.12" },
    { value: "3.11" },
    { value: "3.1" },
    { value: "3.0" },
  ];
  solucao = [
    { value: 0, label: "Gratuito" },
    { value: 1, label: "Sem Propaganda" },
    { value: 2, label: "Sem Propaganda+" },
    { value: 3, label: "PLUS" },
    { value: 4, label: "Profissional" },
    { value: 5, label: "Compacto" },
    { value: 6, label: "Emissor de NF-e" }
  ];
  tipoChave = [
    { value: true, label: "Demonstração" },
    { value: false, label: "Compra parcelada" },
  ]

  constructor(
    public ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private service: LiberacaoService,
    private message: MessageService,
    private msg: MensagemService,
    private atendimentoService: AtendimentoService
  ) { }

  ngOnInit(): void {
    console.log( this.cadastraAtendimento )
    this.liberacao = this.config.data.liberacao;
    this.criaChave();
    this.atendimentoService.localizaPelaLiberacao( this.liberacao ).subscribe( at => {
      this.cadastraAtendimento = {
        atendimento: at,
        liberar: false
      }
      //this.cadastraAtendimento.atendimento = at;
    });
  }  

  private validaGerarChave(): boolean {
    this.chave.validade = DateUtils.corrigeCampoData( this.chave.validade );
    let v: FormValidation = new FormValidation()
      .add( new FormValidationItem( this.chave.nroLicencas > 0, "Número de licenças inválido" ) )
      .add( new StringValidationItem( this.chave.nome, "Nome fantasia obrigatório" ) )
      .add( new StringValidationItem( this.chave.cpfCnpj, "CPF/CNPJ obrigatório" ) ); 
    if ( this.chave.comValidade ) {
      v.add( new DateValidationItem( this.chave.validade, "Validade inválida" ) )
    }
    this.chave.emissoes.forEach( 
      ( e : ChaveEmpresarialEmissao ) => {
        v.add( new StringValidationItem( e.cnpj, "CNPJ inválido para emissão" ) )
        if ( e.nfce ) {
          if (!e.nfceVcto) {
            e.nfceVcto = new Date(2090, 11, 30);
          }
          v.add( new DateValidationItem( e.nfceVcto, "Vencimento inválido para NFC-e" ) );
        }
        if ( e.nfse ) {
          if (!e.nfseVcto) {
            e.nfseVcto = new Date(2090, 11, 30);
          }
          v.add( new DateValidationItem( e.nfseVcto, "Vencimento inválido para NFS" ) );
        }
        if ( e.sat ) {
          if (!e.satVcto) {
            e.satVcto = new Date(2090, 11, 30);
          }
          v.add( new DateValidationItem( e.satVcto, "Vencimento inválido para SAT" ) );
        }
        if ( e.app ) {
          if (!e.appVcto) {
            e.appVcto = new Date(2090, 11, 30);
          }
          v.add( new DateValidationItem( e.appVcto, "Vencimento inválido para APP" ) );
        }
      }
    )
    if ( !v.isValid() ) {
      v.message().forEach( s => {
        this.message.add( {
          severity: "info",
          detail: s
        })
      } )
    }
    return v.isValid();
  }

  gerar() {
    if ( this.validaGerarChave() ) {
      this.gerouChave = false;
      this.service.geraChaveEmpresarial( this.chave ).subscribe( 
        data => {
          let s: string = data.value;
          if ( s.startsWith( "ERRO=" ) ) {
            this.service.handleError( s )            
          }
          else {
            this.chaveGerada = this.pegaTextoPadrao( data.value );
            this.gerouChave = true;
          }          
        },
        error => {
          this.chaveGerada = error;
        }
      )
    }
  }

  fechar() {
    this.ref.close()
  }

  private enviaChavePorEmailSeNecessario(enviaPorEmail: boolean): Promise<void> {
    return new Promise((sim, nao) => {
      if ( !enviaPorEmail ) {
        sim()
      }
      else {
        this.service.enviaPorEmail( this.liberacao.dadosCliente.email, "Hábil - Chave de Registro", this.chaveGerada ).subscribe({
          next: () => {
            this.message.add({
              severity: "success",
              detail: "E-mail enviado com sucesso!"
            })
            sim();
          },
          error: (error) => {
            this.message.add({
              severity: "error",
              sticky: true,
              detail: error.error.message,
              summary: "Erro ao enviar email"
            })
            nao();
          }
        })
      }
    });
  }

  private getAtendimento(): Atendimento {
    if ( this.cadastraAtendimento.liberar ) {
      this.cadastraAtendimento.atendimento.primeiraLiberacao = this.liberacao.primeiraLiberacao;
      return this.cadastraAtendimento.atendimento;
    }
    return null;
  }

  finalizar( enviaPorEmail?: boolean ) {
    if (!this.permiteFinalizar()) {
      this.service.saveCSRegistroEmpresarial(this.chave.cpfCnpj, this.chaveGerada, this.chave.comValidade ? this.chave.validade : null).subscribe(() => {
        this.loading = false;
        this.ref.close();
      });
      return;
    }
    if ( this.fazValidacao() ) {
      this.loading = true;
      this.liberacao.idUsuario = AbstractService.getLocalStorage('usuario').id;
      if ( !this.liberacao.emissaoFiscal ) {
        this.liberacao.emissaoFiscal = []
      }
      if ( !this.liberacao.modulosAdicionais ) {
        this.liberacao.modulosAdicionais = []
      }
      this.service.finaliza( this.liberacao, this.chaveGerada, this.getAtendimento(), true ).subscribe(
        data => {

          this.service.saveCSRegistroEmpresarial(this.chave.cpfCnpj?.trim(), this.chaveGerada, this.chave.comValidade ? this.chave.validade : null).subscribe(() => {});

          this.enviaChavePorEmailSeNecessario( enviaPorEmail ).then( () => {
            this.loading = false;
            this.ref.close( data );
          })
        },  
        error => {
          console.log( error );
          if ( error.status == 400 ) { // erro de validação
            this.message.add({
              severity: "error",
              detail: error.error.value
            })
          }
          else {
            this.service.handleError( error );
          }
          this.loading = false;
        }
      )
    }
  }

  changeAtendimentoLiberacao( cadastraAtendimento: LiberacaoAtendimento ) {
    this.cadastraAtendimento = cadastraAtendimento;
  }

  fazValidacao(): boolean {    
    let v: FormValidation = new FormValidation()
      .add( new StringValidationItem( this.chaveGerada, "Chave de registro vazia!" ) );
    if ( this.cadastraAtendimento.liberar ) {
      v
        .add( new DateValidationItem( this.cadastraAtendimento.atendimento.inicio, "Data de início do suporte obrigatória!" ) )
        .add( new DateValidationItem( this.cadastraAtendimento.atendimento.fim, "Data de fim do suporte obrigatória!" ) )
        .add( new SearchValidationItem( this.cadastraAtendimento.atendimento.planoSuporte, "Plano de Suporte obrigatório!" ) )
    }
    if ( !v.isValid() ) {
      this.message.addAll( v.msgs() );
    }
    console.log( v );
    return v.isValid();
  }

  permiteFinalizar(): boolean {
    return (this.liberacao.id > 0) && ( this.chaveGerada != undefined );
  }

  addEmissao() {
    this.chave.emissoes.push(
      function( cnpj: string ): ChaveEmpresarialEmissao {
        let r: ChaveEmpresarialEmissao = new ChaveEmpresarialEmissao();
        r.cnpj = cnpj;
        return r;
      }( this.chave.cpfCnpj )
    )
  }

  delEmissao( emissao: ChaveEmpresarialEmissao ) {
    const index = this.chave.emissoes.indexOf( emissao, 0 );
    if ( index > -1 ) {
      this.chave.emissoes.splice( index, 1 );
    }
  }

  private localizaUltimaChave(): Promise<ChaveEmpresarial> {
    return new Promise<any>( (accept ) => {
      this.service.pegaUltimaChave( this.liberacao.dadosCliente.cpfCnpj ).subscribe(
        data => {
          if ( data ) {
            this.ultimaChave = data.chave;
            accept( new ConversorChaveEmpresarial().convert( data.chave, data.value ) );
          }
          else {
            accept( new ChaveEmpresarial() );
          }
        },
        () => {
          accept( new ChaveEmpresarial() );
        }
      )
    });
  }

  private criaChave() {
    this.localizaUltimaChave().then( 
      data => { 
        this.chave = data;
        this.chave.nome = this.liberacao.dadosCliente.nome;
        this.chave.razaoSocial = this.liberacao.dadosCliente.razaoSocial;
        this.chave.cpfCnpj = this.liberacao.dadosCliente.cpfCnpj;
        this.chave.fone = this.liberacao.dadosCliente.fone;
        this.chave.email = this.liberacao.dadosCliente.email;  
        this.chave.nroLicencas = this.liberacao.qtdeUsuarios;
        
        if (!this.liberacao.modulosAdicionais) {
          this.liberacao.modulosAdicionais = [];
        }
        if (!this.liberacao.emissaoFiscal){
          this.liberacao.emissaoFiscal = [];
        }
    
        this.liberacao.modulosAdicionais.forEach( 
          m => {
            if ( m.modulo.toLowerCase() == "magento" ) {
              this.chave.usaMagento = true;
            }
          }
        )
    
        this.liberacao.emissaoFiscal.forEach(
          e => {
            let emissao: ChaveEmpresarialEmissao = new ChaveEmpresarialEmissao();
            switch ( e.tipo ) {
              case ELiberacaoEmissaoFiscalTipo.NFE: {
                this.chave.usaNFe = true;
                if ( e.cnpj != this.chave.cpfCnpj ) {
                  this.chave.outrosCnpjs.push( e.cnpj );
                }            
                break;
              }
              case ELiberacaoEmissaoFiscalTipo.NFCE: {
                emissao.nfce = true;
                break;
              }
              case ELiberacaoEmissaoFiscalTipo.NFS: {
                emissao.nfse = true;
                break;
              }
              case ELiberacaoEmissaoFiscalTipo.SAT: {
                emissao.sat = true;
                break;
              }        
            }
            emissao.cnpj = e.cnpj;
            this.chave.emissoes.push( emissao );        
          }
        )
      } 
    );
  }

  descriptografarChave() {
    this.sanfonaUltimaChave = true;
    this.service.lerChaveEmpresarial( this.ultimaChave ).subscribe( 
      data => {
        if ( data ) {
          this.chave = new ConversorChaveEmpresarial().convert( this.ultimaChave, data.value );
          this.sanfonaUltimaChave = false;
        }
      },
      error => {
        this.service.handleError( error );
      }
    )
  }

  incValidade( mais30dias:boolean ) {    
    if ( !this.chave.validade ) {
      this.chave.validade = new Date();
    }
    if ( mais30dias ) {
      this.chave.validade = DateUtils.incDay( this.chave.validade, 30 );
    }
    else {
      this.chave.validade = DateUtils.incMonth( this.chave.validade, 1 );
    }
  }

  private getSolucaoLabel(): string {
    let ret: string;
    this.solucao.forEach( 
      s => {
        if ( this.chave.solucao == s.value ) {
          ret = s.label;
          return;
        }
      }
    )
    return ret;
  }

  private pegaTextoPadrao( chave: string ): string {
    let s: string = "" +
      "Prezado(a) Cliente...\n" +
      "\n" +
      "** É MUITO IMPORTANTE QUE VOCÊ LEIA TODO ESTE EMAIL !!! **\n" +
      "\n" + 
      "Agradecemos por ter adquirido o Hábil Empresarial.\n" +
      "Esperamos que nosso software seja útil para você...\n" +
      "\n" +
      "A sua CHAVE DE REGISTRO da versão " + this.chave.versao + " do Hábil Empresarial " + this.getSolucaoLabel() + " com o CNPJ " + this.chave.cpfCnpj + " é a seguinte :\n" +
      "---------------------------------------------------------------\n" +
      chave.trim() +
      "\n\n" + 
      "Para registrar o Hábil Empresarial e liberar as opções da solução adquirida siga os passos abaixo:\n" + 
      "- Execute o Hábil Empresarial.\n" + 
      "- Dentro do Hábil Empresarial, escolha o menu Opções/Registre sua Cópia\n" + 
      "- Copie as informações acima, iniciando na letra  \"I\" de Início e terminando  na letra  \"M\"  de Fim.\n" + 
      "- Cole as informações que você copiou no espaço correspondente na tela de registro;\n" + 
      "- Tenha certeza de estar copiando corretamente as informações mostradas acima;\n" + 
      "- Se estiver tudo certo o Hábil apresentará a mensagem \"Registro completado com êxito\".\n" + 
      "- Caso ocorra algum problema com o registro entre em contato com o Depto Comercial.\n" + 
      "\n" + 
      "Se preferir ver as instruções com imagens acesse:\n" + 
      "\n" +
      "https://habil.movidesk.com/kb/article/100301/registrando-o-habil?menuId=10860-31490-100301&ticketId=&q=\n" + 
      "\n" + 
      "---------------------------------------------------------------\n" + 
      "Obs: Lembramos que a Chave de Registro acima só pode ser utilizada no estabelecimento\n" + 
      "comercial ou pessoa física que a adquiriu, restrito ao número de computadores conforme\n" + 
      "o número de licenças adquiridas.\n" + 
      "\n" + 
      "Para ver as vídeo aulas e mais informações de como usar o Hábil Empresarial acesse:   https://habil.movidesk.com/kb/article/75358/base-de-conhecimento-habil-empresarial\n" + 
      "É muito importante que você mantenha uma cópia do instalador da versão do Hábil Empresarial que você adquiriu, para a qual foi gerada a chave de registro.  Isso é importante se você precisar reinstalar/registrar a sua versão utilizando a mesma chave de registro enviada para você.\n" + 
      "\n" + 
      "A Hábil Softwares oferece suporte  e alterações corretivas somente para a última versão do Hábil Empresarial, disponível no site https://habil.com.br/ .\n" + 
      "Se tiver dúvidas acesse https://habil.movidesk.com/  e faça seu cadastro, caso ainda não tenha,  e cadastre seus tickets de dúvida.\n" + 
      "\n" +  
      "Lembre-se de visitar a seção do Infohábil  para  ver as novidades. ( https://habil.com.br/novidades/ )\n" + 
      "\n" + 
      "Agradecemos por sua aquisição!\n" + 
      "Seu apoio é muito importante pra nós! Obrigado!\n" + 
      "\n" + 
      "Atenciosamente...\n" + 
      "The Hábil Team\n" + 
      "Hábil Software - A casa do Hábil!\n";
    return s;
  }  
}
