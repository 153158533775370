import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { PanelModule } from 'primeng/panel';
import { TooltipModule } from 'primeng/tooltip';
import { LabelModule } from './../label/label.module';
import { EnderecoComponent } from './endereco.component';



@NgModule({
  declarations: [
    EnderecoComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    PanelModule,
    LabelModule,
    TooltipModule,
    InputMaskModule,
    InputTextModule,
    DropdownModule
  ],
  exports: [
    EnderecoComponent
  ]
})
export class EnderecoModule { }
