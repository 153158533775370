import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, MessageService, SelectItem } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { BreadcrumbService } from "src/app/app.breadcrumb.service";
import {
    AdminUsuario,
    GRUPOS_ACESSO,
    Parceiro,
} from "src/app/models/admin-usuario";
import { AbstractService } from "src/app/services/abstract.service";
import { AdminUsuarioService } from "src/app/services/admin-usuario.service";
import { ParceiroService } from "src/app/services/parceiro.service";
import { GenericCadastro } from "src/app/utilities/generic-cadastro";
import { clone } from "src/app/utilities/util/clone-utils";
import { FormValidation } from "src/app/utilities/util/form-validation";

@Component({
    selector: "habil-cadastro-admin-usuario",
    templateUrl: "./cadastro-admin-usuario.component.html",
    styleUrls: ["./cadastro-admin-usuario.component.scss"],
    providers: [MessageService, DialogService],
})
export class CadastroAdminUsuarioComponent
    extends GenericCadastro<AdminUsuario, AdminUsuarioService>
    implements OnInit
{
    obj: AdminUsuario = this.newObject();
    confirmarSenha = "";

    acessoRelatorios = {
        ver: false, //V
        editar: false, //E
        adicionar: false, //I
        padrao: false, //P
        excluir: false, //D
    };

    gruposAcesso: SelectItem[] = GRUPOS_ACESSO;
    newObject(): AdminUsuario {
        let obj: AdminUsuario = {
            id: 0,
            login: "",
            senha: "",
            nome: "",
            sysAdmin: false,
            email: "",
            lembreteSenha: "",
            parceiro: undefined,
            grupoAcesso: 0,
            idInterno: 0,
            creationDate: undefined,
            idHabil: "",
            lastModifiedDate: undefined,
            createdBy: 0,
            lastModifiedBy: 0,
            deleted: false,
            acessoRelatorios: "",
            inativo: false,
        };
        return obj;
    }

    afterLoadObj(): void {
        this.acessoRelatorios.ver = this.obj.acessoRelatorios?.includes(".V.");
        this.acessoRelatorios.editar =
            this.obj.acessoRelatorios?.includes(".E.");
        this.acessoRelatorios.adicionar =
            this.obj.acessoRelatorios?.includes(".I.");
        this.acessoRelatorios.padrao =
            this.obj.acessoRelatorios?.includes(".P.");
        this.acessoRelatorios.excluir =
            this.obj.acessoRelatorios?.includes(".D.");
    }

    salvarAcessos(): void {
        this.obj.acessoRelatorios = "";
        if (this.acessoRelatorios.ver) {
            this.obj.acessoRelatorios += ".V.";
        }
        if (this.acessoRelatorios.editar) {
            this.obj.acessoRelatorios += ".E.";
        }
        if (this.acessoRelatorios.adicionar) {
            this.obj.acessoRelatorios += ".I.";
        }
        if (this.acessoRelatorios.padrao) {
            this.obj.acessoRelatorios += ".P.";
        }
        if (this.acessoRelatorios.excluir) {
            this.obj.acessoRelatorios += ".D.";
        }
    }

    doValidate(): boolean {
        this.salvarAcessos();
        this.corrigeAtributos();
        let validation: FormValidation = new FormValidation();
        validation.toValidate = [
            {
                condition:
                    this.obj.nome != undefined && this.obj.nome.length > 0,
                message: "O nome é obrigatório!",
            },
            {
                condition:
                    this.obj.login != undefined && this.obj.login.length > 0,
                message: "O login é obrigatório!",
            },
            {
                condition:
                    (this.obj.id != null && this.obj.id > 0) ||
                    (this.obj.senha != undefined && this.obj.senha.length > 0),
                message: "A senha é obrigatória!",
            },
            {
                condition:
                    (this.obj.id != null && this.obj.id > 0) ||
                    this.obj.senha == this.confirmarSenha,
                message: "As senhas não conferem!",
            },
        ];
        if (!validation.isValid()) {
            validation.message().forEach((m) => {
                this.messageService.add({
                    severity: "warn",
                    summary: "Atenção",
                    detail: m,
                });
            });
        } else {
            this.obj.login = this.obj.login.toUpperCase();
        }
        return validation.isValid();
    }

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public confirmationService: ConfirmationService,
        public service: AdminUsuarioService,
        public messageService: MessageService,
        public dialogService: DialogService,
        public titleService: Title,
        public breadcrumbService: BreadcrumbService,
        public parceiroService: ParceiroService
    ) {
        super();
        this.breadcrumbService.setItems([
            { label: "Usuários", routerLink: "/adm/usuarios" },
            { label: "Cadastro" },
        ]);
    }

    ngOnInit(): void {
        if (this._isDialog) {
            this._loading = true;
            if (this._externalObj != null && this._externalObj != undefined) {
                this.obj = clone(this._externalObj);
                this.id = this.obj.id;
                this.idCampos = this.id == undefined ? "0" : this.id.toString();
                this._loading = false;
            } else {
                this.obj = this.newObject();
                this.idCampos = this.id == undefined ? "0" : this.id.toString();
                this.loadAuxObjects();
                this._loading = false;
            }
        } else {
            this.titleService.setTitle("Usuário - " + AbstractService.app);
            super.ngOnInit();
        }
    }

    doBeforeSave() {}
}
