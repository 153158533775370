export class AbstractModel {
    id: number;
    idInterno: number;
    creationDate: Date;
    idHabil: string;
    lastModifiedDate: Date;
    createdBy: number;
    lastModifiedBy: number;
    deleted: boolean;
}

export class Endereco {
    logradouro: string;
    numero: string;
    complemento: string;
    bairro: string;
    cep: string;
    pais: string;
    uf: string;
    municipio: string;
}

export class AbstractTelefone extends AbstractModel {
    padrao: boolean;
    tipo: string;
    ddd: string;
    numero: string;
    ramal: string;
    observacoes: string;
}