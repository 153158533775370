
import { Component, OnInit, Input, ChangeDetectorRef, Output, EventEmitter, forwardRef, Renderer2, ElementRef } from '@angular/core';
import { EnderecoService } from './endereco.service';
import { DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {TooltipModule} from 'primeng/tooltip';
import { Endereco } from 'src/app/models/abstract-model';
import { FormatUtils } from '../util/format-utils';
import { EUf } from './euf.enum';
import { Municipio } from './municipio';
import { AbstractService } from 'src/app/services/abstract.service';


@Component({
  selector: 'habil-endereco',
  templateUrl: './endereco.component.html',
  styleUrls: ['./endereco.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EnderecoComponent),
      multi: true
    },
  ]
})
export class EnderecoComponent extends DefaultValueAccessor {

  constructor(
    _renderer: Renderer2,
    _elementRef: ElementRef,
    private enderecoService: EnderecoService,
    private changeDetector: ChangeDetectorRef
  ) {
    super(_renderer, _elementRef, false);
  }

  onChange: (_: any) => void;
  onTouched: () => void;

  @Output('onChange')
  _onChange: EventEmitter<Endereco> = new EventEmitter<Endereco>();

  _endereco: Endereco = {
    logradouro: '',
    numero: '',
    complemento: '',
    bairro: '',
    cep: '',
    pais: '',
    uf: '',
    municipio: ''
  };

  get newEndereco() {
    return {
      logradouro: '',
      numero: '',
      complemento: '',
      bairro: '',
      cep: '',
      pais: '',
      uf: '',
      municipio: ''
    };
  }

  @Input('titulo')
  _titulo: string = 'Endereço';
  @Input('readonly')
  _readonly: boolean = false;
  @Input('prefix')
  _prefix: string = '';
  _disabled: boolean = false;

  @Input('showContent')
  _showContent = false;

  ufs = EUf.ufs.filter(uf => uf.value != 'NENHUM');
  _municipios: Municipio[] = [];
  _selMunicipios: Municipio[] = [];
  static _paises: Municipio[] = [];

  get _paises() {
    return EnderecoComponent._paises;
  }

  ngOnInit() {
    // this.spinnerService.show(undefined, {
    //   bdColor: "rgba(1,1,0,0.45)",
    //   size : "large",
    //   color: "#E67600",
    //   type: "cube-transition",
    //   fullScreen:  true,
    // });

    this._municipios = [...AbstractService.municipios];
    if (this._municipios == undefined || this._municipios == null || (this._municipios.length <= 0)) {
      this.enderecoService.buscaTodosMunicipios()
        .subscribe(m => {
          this._municipios = [...m];
          AbstractService.municipios = [...m];
          if (this.value.uf != undefined) {
            this._selMunicipios = [...m.filter(mun => mun.uf == this.value.uf)];
          }
          //this.spinnerService.hide();
        });
    } else {
      //this.spinnerService.hide();
    }


  }

  set value(end: Endereco) {
    this._endereco = !end ? undefined : end;
    if (this._endereco != undefined) {
      if (this._endereco.uf != undefined && (this._selMunicipios == undefined || this._selMunicipios.length == 0)) {
        this._selMunicipios = [...this._municipios.filter(m => m.uf == this._endereco.uf)];
        this._endereco = { ...this._endereco };
      }
      this.exitUf()
    }
    this.onChange(this._endereco);

    this.onTouched();
  }

  get value() {
    return this._endereco;
  }

  writeValue(value: Endereco): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  showUf(): boolean {
    return true;
    //return this.value.uf != 'EX';
  }

  showPais(): boolean {
    return this.value.uf == 'EX';
  }

  blur() {
    if (this.value != undefined) {
      this.value.cep = FormatUtils.clear(this.value.cep, '-');
      this.value = { ...this.value };
    }

  }

  exitCep() {
    if (this._readonly || this.value?.uf == 'EX') {
      return;
    }
    if (this.value != undefined) {
      this._selMunicipios = [];
      let cep = this.value.cep;
      this.value = {...this.newEndereco}
      if (cep != undefined && cep != '') {
        this.value.cep = cep;
        this.enderecoService.buscaCep(cep)
          .subscribe(e => {
            this._selMunicipios = [...this._municipios.filter(m => m.uf == e.uf)];
            setTimeout(() => {
              this.value = { ...e };
            }, 10);
          });
      }
    }

  }

  exitUf() {
    this._selMunicipios = [...this._municipios.filter(m => m.uf == this.value.uf)];
    if (this.value.uf == 'EX' && this._paises.length == 0){
      //this.spinnerService.show();
      this.enderecoService.buscaTodosPaises().subscribe(paises => {
        EnderecoComponent._paises = [...paises];
        //this.spinnerService.hide();
      }, error => {
        //.spinnerService.hide();
      });

    }
  }
}


