import { AbstractModel } from "./abstract-model";

export class DatabaseLocation extends AbstractModel {
  nome?: string;
  cnpj: string;
  databaseIp: string;
}

export const APP_1 = "http://ec2-34-193-51-106.compute-1.amazonaws.com";
export const APP_2 = "http://ec2-54-158-193-111.compute-1.amazonaws.com";

export const DATABASE_IP_LIST = [
  { label: "BD 1", value: "54.235.214.105:5432" },
  { label: "BD 2", value: "44.202.75.71:5432" }
];

export const SERVER_LIST = [
  { label: "APP 1", value: APP_1 },
  { label: "APP 2", value: APP_2 }
];
