import { Subject } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { clone } from '../util/clone-utils';
import { DateUtils } from '../util/date-utils';
import { AdminUsuarioService } from 'src/app/services/admin-usuario.service';
import { ParceiroService } from 'src/app/services/parceiro.service';
import { ProdutoService } from 'src/app/services/produto.service';
import { ClassificacaoService } from 'src/app/services/classificacao.service';

@Component({
  selector: 'ks-cadastro-dialog',
  templateUrl: './cadastro-dialog.component.html',
  styleUrls: ['./cadastro-dialog.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class CadastroDialogComponent implements OnInit {
  constructor(
    private config: DynamicDialogConfig,
    private modal: DynamicDialogRef,
    private messageService: MessageService,
    public usuarioService: AdminUsuarioService,
    public parceiroService: ParceiroService,
    public produtoService: ProdutoService,
    public classificacaoService: ClassificacaoService
  ) { }

  obj: any;
  rotina: string = "PESSOA";
  inicializado: boolean = false;
  _readonly: boolean = false;

  _dialogEditOptions: any;
  subject: Subject<any> = new Subject<any>();
  _adiar: boolean = false;

  ngOnInit(): void {
    if (this.config.data) {
      if (this.config.data.obj != null) {
        let o = clone(this.config.data.obj);
        if (o.abertura) {
          o.abertura = DateUtils.stringToDate(o.abertura);
        }
        if (o.dadosCliente?.clienteDesde) {
          o.dadosCliente.clienteDesde = DateUtils.stringToDate(o.dadosCliente.clienteDesde);
        }
        if (o.data) {
          o.data = DateUtils.stringToDate(o.data);
        }
        if (o.bomPara){
          o.bomPara = DateUtils.stringToDate(o.bomPara);
        }
        if (o.dataSolicitacao){
          o.dataSolicitacao = DateUtils.stringToDate(o.dataSolicitacao);
        }
        if (o.dataVencimento){
          o.dataVencimento = DateUtils.stringToDate(o.dataVencimento);
        }
        if (o.dataPrevisao){
          o.dataPrevisao = DateUtils.stringToDate(o.dataPrevisao);
        }
        if (o.concluidaEm) {
          o.concluidaEm = DateUtils.stringToDate(o.concluidaEm);
        }
        if (o.emissao) {
          o.emissao = DateUtils.stringToDate(o.emissao);
        }
        if (o.dataInicio) {
          o.dataInicio = DateUtils.stringToDate(o.dataInicio);
        }
        if (o.dataValidade) {
          o.dataValidade = DateUtils.stringToDate(o.dataValidade);
        }
        if (o.inicioViagem) {
          o.inicioViagem = DateUtils.stringToDate(o.inicioViagem);
        }
        if (o.dataCadastro){
          o.dataCadastro = DateUtils.stringToDate(o.dataCadastro);
        }
        if (o.dataInicial){
          o.dataInicial = DateUtils.stringToDate(o.dataInicial);
        }
        if (o.dataFinal){
          o.dataFinal = DateUtils.stringToDate(o.dataFinal);
        }
        if (o.proximoReajuste) {
          o.proximoReajuste = DateUtils.stringToDate(o.proximoReajuste)
        }

        if (o.ultimoReajuste) {
          o.ultimoReajuste = DateUtils.stringToDate(o.ultimoReajuste)
        }

        if (o.dataNotificacao){
          o.dataNotificacao = DateUtils.stringToDate(o.dataNotificacao);
        }
        if (o.inicio) {
          o.inicio = DateUtils.stringToDate(o.inicio);
        }
        if (o.termino) {
          o.termino = DateUtils.stringToDate(o.termino);
        }
        this.obj = clone(o);
      }
      if (this.config.data.adiar) {
        this._adiar = this.config.data.adiar;
      }
      this._dialogEditOptions = this.config?.data.options ? this.config?.data.options : undefined;
      this.rotina = this.config.data?.rotina;
      console.log(this.config.data)
      this.inicializado = true;
    }
  }
  service() {
    let service;
    switch (this.rotina.toLowerCase()) {
      case "usuario":
        return this.usuarioService;
      case "parceiros":
        return this.parceiroService;
      case "produtos":
        return this.produtoService;
      case "classificacoes":
        return this.classificacaoService;
    }
    return service;
 
  }


  onSave(event) {
    this.messageService.clear();
      let t = event.obj;
      this.service().saveR(t).subscribe(
        (t: HttpResponse<any>) => {

          if (t.status == 200 || t.status == 201) {
            this.modal.close(t);
          } else {
            this.service().handleError(t);
            let msg = "Ocorreu um erro ao processar os dados:\n" +
              t.status +
              " - " +
              t.statusText;
            this.messageService.add({ severity: 'error', summary: 'Erro', detail: msg });
          }
        },
        (e) => {
          this.service().handleError(e);
          let msg =
            "Ocorreu um erro ao processar os dados:\n" +
            e.status +
            " - " +
            e.error;
          this.messageService.add({
            severity: "error",
            summary: "Erro",
            detail: msg,
          });
        }
      );

  }

  onCancel() {
    this.modal.close();
  }

}
