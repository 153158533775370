import { AbstractModel } from "./abstract-model";
import { Registro } from "./registro";

export interface News extends AbstractModel {
  assunto: string;
  tipo: string;
  aPartirDe: Date;
  validade: Date;
  software: string;
  modulos: number[];
  cnpjs: string[];
  ufs: string[];
  versao: string;
  versaoCompareType: string;
  nfe: string;
  nfce: string;
  nfse: string;
  cupom: string;
  somenteParaAdmins: boolean;
  bannerInfo: NewsBanner;
  mensagemInfo: NewsMensagem;
  htmlInfo: NewsHtml[];
  pesquisaInfo: NewsPesquisa;
  cancelado: boolean;
  read: boolean;
  novidadesInfo: NewsNovidades;
}

export enum ETipoAlteracao {
  BUG = "BUG",
  IMPLEMENTACAO = "IMPLEMENTACAO"
}

export class NewsNovidadesAlteracao extends AbstractModel {
  tipo: ETipoAlteracao;
  descricao: string;
  rotina: string;
}

export class NewsNovidades extends AbstractModel {
  build: string = "";
  mensagem: string = "";
  imagem: string;
  contentType: string;
  rotina: string = "";
  alteracoes: NewsNovidadesAlteracao[] = [];
}

export class NewsPesquisa extends AbstractModel {
  tipo: ETipoPesquisaNews;
}

export interface NewsBanner extends AbstractModel {
  news?;
  prioridade: string;
  intervalo: number;
  hint: string;
  bannerFileName: string;
  bannerImg: string;
  bannerContentType: string;
}

export interface NewsMensagem extends AbstractModel {
  news?;
  textoMensagem: string;
}

export interface NewsHtml extends AbstractModel {
  news?;
  content: string;
  contentType: string;
  fileName: string;
}

export interface NewsLogs extends AbstractModel {
  tipoLog: string;
  registro: Registro;
  news: News;
  dhOperacao: Date;
}

export enum ESearchCompareType {
  GREATER_THAN,
  LOWER_THAN,
  EQUAL,
  LIKE,
  BETWEEN,
  GREATER_THAN_OR_EQUAL_TO,
  LOWER_THAN_OR_EQUAL_TO,
  NULL,
  NOT_NULL,
  ENUM_IN,
  NOT_EQUAL,
  LIST_CONTAINS
}

export enum ETipoPesquisaNews {
  SIM_NAO="SIM_NAO",
  NOTA="NOTA"
}
