import { formatDate } from "@angular/common";
import { SelectItem } from "primeng/api";
import { DateUtils } from "../utilities/util/date-utils";
import { AbstractModel } from "./abstract-model";

export enum ELiberacaoStatus {
  PENDENTE = "PENDENTE",
  FINALIZADO = "FINALIZADO",
  CANCELADO = "CANCELADO"
}

export enum ELiberacaoTipo {  
  SUPORTE_CLUBE = "SUPORTE_CLUBE",
  SUPORTE_PREMIUM =  "SUPORTE_PREMIUM",
  EMPRESARIAL = "EMPRESARIAL",
  HERP = "HERP",
  H10 ="H10"     
}

export enum ELiberacaoEmissaoFiscalTipo {
  NFE = "NFE",
  NFCE = "NFCE",
  NFS = "NFS",
  SAT = "SAT"
}

export const LiberacaoEmissaoFiscalTipo: SelectItem[] = [
  { label: "NF-e", value: ELiberacaoEmissaoFiscalTipo.NFE },
  { label: "NFC-e", value: ELiberacaoEmissaoFiscalTipo.NFCE },
  { label: "NFS", value: ELiberacaoEmissaoFiscalTipo.NFS },
  { label: "SAT", value: ELiberacaoEmissaoFiscalTipo.SAT },
]

export class Liberacao extends AbstractModel {
  data: Date;
  tipo: ELiberacaoTipo;
  status: ELiberacaoStatus;
  descricao: string;
  idUsuario: number;
  dadosCliente: LiberacaoDadosCliente = new LiberacaoDadosCliente();
  emissaoFiscal: LiberacaoEmissaoFiscal[] = [];
  modulosAdicionais: LiberacaoModuloAdicional[] = [];
  qtdeUsuarios: number;
  qtdeEmpresas: number;
  produtos: LiberacaoProduto[] = [];
  observacoes: string;
  primeiraLiberacao: Date;
  
  constructor( tipo?: ELiberacaoTipo ) {
    super();
    this.tipo = tipo;
  }

}

export class LiberacaoProduto {
  idItem: number;
  descricao: string;
  qtde: number;
}

export class LiberacaoDadosCliente {
  nome: string;
  razaoSocial: string;
  cpfCnpj: string;
  fone: string;
  email: string;
  chaveRegistro: string;
}

export class LiberacaoModuloAdicional extends AbstractModel {
  liberacao: Liberacao;
  modulo: string;
  cnpj: string;
  qtdeUsuarios: number;
}

export class LiberacaoEmissaoFiscal extends AbstractModel {
  liberacao: Liberacao;
  tipo: ELiberacaoEmissaoFiscalTipo;
  qtde: number;
  cnpj: string;
}

export class LiberacaoUltimoCodigo extends AbstractModel {
  data: Date;
  vcto: Date;
  codigo: string;
  guia: string;
  cnpj: string;
}

export class LiberacaoFiltroDTO {
  dataInicial: Date = DateUtils.incMonth( new Date(), -1 );
  dataFinal: Date = new Date();
  status: ELiberacaoStatus = ELiberacaoStatus.PENDENTE; 
  tipo: ELiberacaoTipo;
  nome: string;
  cpfCnpj: string;
}
