import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { PlanoSuporte } from 'src/app/models/plano-suporte';
import { AbstractService } from 'src/app/services/abstract.service';
import { PlanoSuporteService } from 'src/app/services/plano-suporte.service';
import { GenericCadastro } from 'src/app/utilities/generic-cadastro';
import { FormValidation, StringValidationItem } from 'src/app/utilities/util/form-validation';

@Component({
  selector: 'habil-cadastro-plano-suporte',
  templateUrl: './cadastro-plano-suporte.component.html',
  styleUrls: ['./cadastro-plano-suporte.component.scss']
})
export class CadastroPlanoSuporteComponent extends GenericCadastro<PlanoSuporte, PlanoSuporteService> implements OnInit {
  
  newObject(): PlanoSuporte {
    return new PlanoSuporte();
  }
  doValidate(): boolean {
    let v: FormValidation = new FormValidation()
      .add( 
        new StringValidationItem( this.obj.descricao, "Descrição obrigatória!" )
      );
    if (!v.isValid() ) {
      this.messageService.addAll( v.msgs() );
    }
    return v.isValid();
  }

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public confirmationService: ConfirmationService,
    public service: PlanoSuporteService,
    public messageService: MessageService,
    public titleService: Title,
    public bcService: BreadcrumbService
  ) { 
    super();
    this.bcService.setItems( 
      [ 
        { 
          label: "Planos de Suporte", 
          routerLink: "/adm/plano-suporte",          
        },
        {
          label: "Cadastro"
        }
      ]
    );
  }

  ngOnInit(): void {
    this._loading = false;
    this.titleService.setTitle( "Planos de Suporte" + AbstractService.app );
    super.ngOnInit()
  }

  doBeforeSave() {}
}
