import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { AbstractService } from 'src/app/services/abstract.service';
import { RegistroService } from 'src/app/services/registro.service';

@Component({
  selector: 'habil-cliente',
  templateUrl: './cliente.component.html',
  styleUrls: ['./cliente.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class ClienteComponent implements OnInit {

  constructor(private breadcrumbService: BreadcrumbService, public service: RegistroService, titleService: Title) {
    this.breadcrumbService.setItems([{label: 'Clientes'}]);
    titleService.setTitle('Clientes - ' + AbstractService.app);
  }

  ngOnInit(): void {
  }
}
