import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { RegistroProduto } from '../models/registro-produto';
import { AbstractService } from './abstract.service';
import { LoginService } from './login.service';
import { LimberChefProduto } from '../models/limber-chef-produto';

@Injectable({
  providedIn: 'root'
})
export class LimberChefProdutoService extends AbstractService<LimberChefProduto> {

  titulo: string = 'Soluções Hábil Cobranças';
  endpoint(): string {
    return "registro/limber-chef-produto";
  }
  rotina(): string {
    return "LIMBER_CHEF_PRODUTOS";
  }
  colunas(): any[] {
    return [
      { header: "Código", field: "id" },
      { header: "Nome", field: "nomeProduto" },
    ];
  }

  corrigeAtributos(t) {
    t.acessos = [];
    for (let acesso of t['acessosComDescricao']) {
      t.acessos.push(acesso.value);
    }
    t['acessosComDescricao'] = [];
    return super.corrigeAtributos(t);
  }

  private getHeaders(): HttpHeaders {
    return     new HttpHeaders({
      Accept: "application/json; charset=utf-8",
      "X-Auth-Token": AbstractService.token(),
    });

  }

  getModulosAdicionais(): Observable<any[]> {
    const url = this.baseUrl() + "/modulos-adicionais";

    return this.http.get<any[]>(url, { headers: this.getHeaders() });
  }

  getAcessos(): Observable<any> {
    const url = this.baseUrl() + "/acessos";

    return this.http.get<any[]>( url, {headers: this.getHeaders() });
  }

  novo(selection?: any) {
    AbstractService.readonly = false;
    this.router.navigate(['adm/produtos/cadastro-limber-chef']);
  }
  abrir(obj: RegistroProduto) {
    AbstractService.readonly = true;
    this.router.navigate(['adm/produtos/cadastro-limber-chef'], { queryParams: { id: obj.id } });
  }
  edit(obj: RegistroProduto) {
    AbstractService.readonly = false;
    this.router.navigate(['adm/produtos/cadastro-limber-chef'], { queryParams: { id: obj.id } });
  }
  routeLista() {
    return '/adm/produtos';
  }

  constructor(
    public http: HttpClient,
    private router: Router,
    public loginService: LoginService
  ) {
    super(http, loginService);
  }
}
