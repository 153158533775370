<habil-grupo>
  <p-accordion [multiple]="true">
    <p-accordionTab
      header="Procurar cliente"
      [selected]="procuraAberto"
      [disabled]="procuraDisable"
    >
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-3">
          <habil-label name="pesquisaCnpj" label="Procurar por CPF/CNPJ">
            <input pInputText [(ngModel)]="cnpj" (keydown.enter)="fazPesquisa()" />
          </habil-label>
        </div>
        <div class="p-field p-col-3 p-col-align-end">
          <button pButton icon="pi pi-search" label="Pesquisar" (click)="fazPesquisa()" ></button>
        </div>
        <div class="p-field p-col-6"></div>
      </div>
    </p-accordionTab>
    <p-accordionTab
      header="Lançar atendimento"
      [selected]="lancaAberto"
      [disabled]="lancaDisable"
    >
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-1">
          <habil-label name="lbid" label="Código">
            <input pInputText [(ngModel)]="obj.id" [readonly]="true" />
          </habil-label>
        </div>
        <div class="p-field p-col-3">
          <habil-label name="lbcnpj" label="CPF/CNPJ">
            <input pInputText [(ngModel)]="obj.cpfCnpj" [readonly]="true" />
          </habil-label>
        </div>
        <div class="p-field p-col-4">
          <habil-label name="lbnome" label="Nome">
            <input pInputText [(ngModel)]="obj.nome" />
          </habil-label>
        </div>
        <div class="p-field p-col-4">
          <habil-label name="lbplanosuporte" label="Plano de Suporte">
            <habil-autocomplete-edit
              [service]="planoSuporteService"
              [(ngModel)]="obj.planoSuporte"
              name="searchPlano"
              field="descricao"
              dataKey="id"
            >
            </habil-autocomplete-edit>
          </habil-label>
        </div>
        <div class="p-field p-col-3">
          <habil-label name="lbrazao" label="Razão Social">
            <input pInputText [(ngModel)]="obj.razaoSocial" />
          </habil-label>
        </div>
        <div class="p-field p-col-3">
          <habil-label name="lbemail" label="E-mail">
            <input pInputText [(ngModel)]="obj.email" />
          </habil-label>
        </div>
        <div class="p-field p-col-1">
          <habil-label name="lbfone" label="Telefone">
            <input pInputText [(ngModel)]="obj.telefone" />
          </habil-label>
        </div>
        <div class="p-field p-col-1">
          <habil-label name="lbinicio" label="Iniciar em">
            <habil-date-edit [(ngModel)]="obj.inicio"></habil-date-edit>
          </habil-label>
        </div>
        <div class="p-field p-col-1">
          <habil-label name="lboriginal" label="Vcto original">
            <habil-date-edit [disabled]="true" [(ngModel)]="vctoOriginal"></habil-date-edit>
          </habil-label>
        </div>
        <div class="p-field p-col-1">
          <b>
            <habil-label name="lbfim" label="Atender até">
              <habil-date-edit [(ngModel)]="obj.fim"></habil-date-edit>
            </habil-label>
          </b>
        </div>
        <div class="p-field p-col-1 p-col-align-end">
          <button pButton label="+15 dias" (click)="maisDiasClick(false)"></button>
        </div>
        <div class="p-field p-col-1 p-col-align-end">
          <button pButton label="+30 dias" (click)="maisDiasClick(true)"></button>
        </div>
      </div>
    </p-accordionTab>
  </p-accordion>
</habil-grupo>
<div *ngIf="loading">
  <br />
  <p-progressBar
    mode="indeterminate"
    [style]="{ height: '6px' }"
  ></p-progressBar>
</div>
<br />
<habil-grupo>
  <div class="p-fluid p-grid">
    <div class="p-col"></div>
    <div class="p-col-2">
      <button
        pButton
        class="p-button-success"
        icon="pi pi-check"
        label="Finaliza"
        (click)="finalizar()"
      ></button>
    </div>
    <div class="p-col-2">
      <button
        pButton
        icon="pi pi-check"
        label="Fechar"
        (click)="fechar()"
      ></button>
    </div>
  </div>
</habil-grupo>
