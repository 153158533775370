import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Atendimento, AtendimentoExcecao } from '../models/atendimento';
import { Liberacao } from '../models/liberacao';
import { HttpRequest, UriBuilder } from '../utilities/util/http-request';
import { AbstractService } from './abstract.service';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class AtendimentoService extends AbstractService<Atendimento> {

  titulo: string = "Atendimentos";

  salvaExcecao( idatendimento: number, excecao: AtendimentoExcecao ): Observable<any> {
    return new HttpRequest( this.http )
      .endpoint( this.endpoint() )
      .uri( 
        new UriBuilder()
          .subpath( "salva-excecao" )
          .subpath( idatendimento.toString() )
          .build()
      )
      .param( "excecao", JSON.stringify( excecao ) )
      .doPost()
  }

  getExcecoes( idatendimento: number, start: number, length: number ): Observable<AtendimentoExcecao[]> {
    return new HttpRequest<AtendimentoExcecao[]>( this.http )
      .endpoint( this.endpoint() )
      .uri( 
        new UriBuilder()
          .subpath( "excecoes" )
          .subpath( start.toString() )
          .subpath( length.toString() )
          .build()
      )
      .param( "id-atendimento", idatendimento.toString() )
      .doGet()
  }

  localizaPelaLiberacao( liberacao: Liberacao ): Observable<Atendimento> {
    return new HttpRequest<Atendimento>( this.http )
      .endpoint( this.endpoint() )
      .uri(
        new UriBuilder()
          .subpath( "localizar-pela-liberacao" )
          .build()
      )
      .param( "liberacao", JSON.stringify( liberacao ) )
      .doGet()
  }

  localizar( valor: string): Observable<Atendimento[]> {
    return new HttpRequest<Atendimento[]>( this.http )
      .endpoint( this.endpoint() )
      .uri( 
        new UriBuilder()
          .subpath( 'localizar' )
          .build()
      )
      .param( "valor", valor )
      .doGet();
  }

  endpoint(): string {
    return "/admin/atendimento";
  }
  rotina(): string {
    return "ATENDIMENTO";
  }
  colunas(): any[] {
    return [];
  }
  novo(selection?: any) {
    AbstractService.readonly = false;
    this.router.navigate( [ this.uri() ] );    
    
  }
  abrir(obj: Atendimento) {
    AbstractService.readonly = true;
    this.router.navigate( [ this.uri() ], { queryParams: { id: obj.id } } );
  }
  edit(obj: Atendimento) {
    AbstractService.readonly = false;
    this.router.navigate( [ this.uri() ], { queryParams: { id: obj.id } } );
  }
  routeLista() {
    return this.uri( true );
  }

  private uri( ehLista?: boolean ) {
    let ret = "/atendimento";
    if (ehLista) {
      return ret;
    }
    return ret + "/cadastro";
  }

  constructor( http: HttpClient, loginService: LoginService, private router: Router ) {
    super( http, loginService );
  }

  corrigeAtributos(t: Atendimento) {
    t = super.corrigeAtributos( t );
    if ( !t.excecoes ) {
      t.excecoes = [];
    }
    return t;
  }
}
