import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminUsuarioComponent } from './admin-usuario.component';
import { BaseListaModule } from 'src/app/utilities/base-lista/base-lista.module';
import { CadastroAdminUsuarioComponent } from './cadastro-admin-usuario/cadastro-admin-usuario.component';
import { FormsModule } from '@angular/forms';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { GrupoModule } from 'src/app/utilities/grupo/grupo.module';
import { BotoesCadastroModule } from 'src/app/utilities/botoes-cadastro/botoes-cadastro.module';
import { TooltipModule } from 'primeng/tooltip';
import { AccordionModule } from 'primeng/accordion';
import { LabelModule } from 'src/app/utilities/label/label.module';
import { ProgressBarModule } from 'primeng/progressbar';
import { RippleModule } from 'primeng/ripple';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { PasswordModule } from 'primeng/password';
import { AutocompleteEditModule } from 'src/app/utilities/autocomplete-edit/autocomplete-edit.module';
import { BrowserModule } from '@angular/platform-browser';
import { DropdownModule } from 'primeng/dropdown';
import { ToastModule } from 'primeng/toast';


@NgModule({
  declarations: [AdminUsuarioComponent, CadastroAdminUsuarioComponent],
  imports: [
    CommonModule,
    BaseListaModule,
    FormsModule,
    BrowserModule,
    ConfirmDialogModule,
    GrupoModule,
    BotoesCadastroModule,
    TooltipModule,
    AccordionModule,
    LabelModule,
    ProgressBarModule,
    RippleModule,
    ScrollPanelModule,
    InputTextModule,
    CheckboxModule,
    PasswordModule,
    AutocompleteEditModule,
    DropdownModule,
    ToastModule
  ],
  exports: [AdminUsuarioComponent, CadastroAdminUsuarioComponent]
})
export class AdminUsuarioModule { }
