import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { APP_1, DatabaseLocation, SERVER_LIST } from 'src/app/models/database-location';
import { DatabaseLocationService } from 'src/app/services/database-location.service';
import { HabilServerService } from 'src/app/services/habil-server.service';
import { RegistroService } from 'src/app/services/registro.service';
import { clone } from 'src/app/utilities/util/clone-utils';

export interface TenantDatabase {
  databaseInfo: DatabaseLocation;
  nome: string;
  conectado: boolean;
  ultimaEntrada: Date;
  vencimento: Date;
}

@Component({
  selector: 'habil-gerenciamento-servidor',
  templateUrl: './gerenciamento-servidor.component.html',
  styleUrls: ['./gerenciamento-servidor.component.scss']
})
export class GerenciamentoServidorComponent implements OnInit {

  servidorAtual: string = APP_1;
  servidores = SERVER_LIST;

  list: TenantDatabase[] = [];
  loading = false;

  constructor(private service: DatabaseLocationService,
    private habilService: HabilServerService,
    private registroService: RegistroService,
    private msg: MessageService) { }

  ngOnInit(): void {
    this.buscar()
  }

  getServerName(server: string) {
    return this.servidores.find((s) => s.value == server)?.label || server
  }

  async buscar() {
    this.loading = true;
    this.list = [];
    const conectados = await this.habilService.showDatabases(this.servidorAtual).toPromise();
    this.service.findAll(0, 0, "").subscribe({
      next: async (dl) => {
        const dlFiltered = [];
        const list: TenantDatabase[] = [];
        dl = dl.sort((a, b) => a.id - b.id);
        for (const d of dl) {
          if (!dlFiltered.find((df) => df.cnpj == d.cnpj)) {
            dlFiltered.push(d);
          }
        }
        const dlSorted: DatabaseLocation[] = dlFiltered.sort((a, b) => a.cnpj - b.cnpj);
        for (const d of dlSorted) {
          const dados = await this.registroService.fazConsultaCnpj(d.cnpj.replace("tn", ""));
          list.push({
            databaseInfo: clone(d),
            nome: dados.nome,
            conectado: !!conectados.find((c) => c == d.cnpj),
            ultimaEntrada: undefined,
            vencimento: new Date(dados.vencimento)
          });
        }

        this.list = [...list];
        console.log(list);
        this.loading = false;
      }, error: (err) => {
        this.loading = false;
      }
    })
  }

  removerConexao(db: TenantDatabase) {
    this.habilService.removeDatabase(this.servidorAtual, db.databaseInfo?.cnpj).subscribe({
      next: (val) => {
        this.msg.add({
          severity: "info",
          summary: db.databaseInfo?.cnpj + ": conexão removida"
        })
        this.buscar();
      }
    });
  }

}
