import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { HabilFranqueadoraUsuario } from 'src/app/models/habil-franqueadora-usuario';
import { HabilFranqueadoraUsuarioService } from 'src/app/services/habil-franqueadora-usuario.service';

@Component({
  selector: 'habil-cliente-habil-franqueadora-usuario',
  templateUrl: './cliente-habil-franqueadora-usuario.component.html',
  styleUrls: ['./cliente-habil-franqueadora-usuario.component.scss']
})
export class ClienteHabilFranqueadoraUsuarioComponent implements OnInit {

  obj: HabilFranqueadoraUsuario = {
    login: '',
    senha: '',
    nome: '',
    telefone: '',
    lembreteSenha: '',
    inativo: false,
    cnpjFranqueadora: '',
    id: 0,
    idInterno: 0,
    creationDate: undefined,
    idHabil: '',
    lastModifiedDate: undefined,
    createdBy: 0,
    lastModifiedBy: 0,
    deleted: false
  }

  constructor(private service: HabilFranqueadoraUsuarioService,
    private modal: DynamicDialogRef,
    private conf: DynamicDialogConfig) { }

  ngOnInit(): void {
    const data = this.conf.data;
    this.obj.cnpjFranqueadora = data.cnpjFranqueadora;
    if (data?.id) {
      this.service.findById(data.id).subscribe({
        next: (val) => {
          this.obj = {...val};
        }
      })
    }
  }

  cancelar() {
    this.modal.close();
  }

  salvar() {
    this.service.save(this.obj).subscribe({
      next: (saved) => {
        this.modal.close();
      }
    })
  }

}
