<p-confirmDialog
  header="Atenção"
  icon="pi pi-exclamation-triangle"
></p-confirmDialog>

<p-dialog [(visible)]="showRestaurarBanco">
  <div class="p-fluid p-grid">
    <div class="p-col-12">
      <habil-label label="Nome do banco (inicia com tn)" name="nomeBanco">
        <input pInputText [(ngModel)]="nomeTn" name="nomeBanco" />
      </habil-label>
    </div>
    <div class="p-col-12">
      <habil-label label="URL do backup" name="urlBackup">
        <input pInputText [(ngModel)]="backupUrl" name="urlBackup" />
      </habil-label>
    </div>
    <div class="p-col"></div>
    <div class="p-col">
      <p-button
        icon="pi pi-upload"
        label="Restaurar banco"
        [loading]="loading"
        (onClick)="restaurar()"
      ></p-button>
    </div>
  </div>
</p-dialog>

<div class="p-fluid p-grid" *ngIf="enabled">
  <div class="p-col">
    <p-button
      label="Buscar bancos de dados"
      styleClass="p-button-secondary"
      icon="pi pi-search"
      [loading]="loading"
      (onClick)="getDatabases()"
    ></p-button>
  </div>
  <div class="p-col">
    <p-button
      label="Buscar dados de todos"
      styleClass="p-button-secondary"
      icon="pi pi-search"
      [loading]="loading"
      (onClick)="getDadosTodosUsuarios()"
    ></p-button>
  </div>
  <div class="p-col">
    <p-button
      label="Upgrade conectados"
      styleClass="p-button-secondary"
      [loading]="loading"
      icon="pi pi-refresh"
      (onClick)="upgradeTodosBancosConectados()"
    ></p-button>
  </div>
  <div class="p-col">
    <p-button
      label="Upgrade todos os bancos"
      styleClass="p-button-secondary"
      [loading]="loading"
      icon="pi pi-refresh"
      (onClick)="upgradeTodosBancos()"
    ></p-button>
  </div>
  <div class="p-col">
    <p-button
      label="Restaurar banco"
      styleClass="p-button-secondary"
      [loading]="loading"
      icon="pi pi-upload"
      (onClick)="showRestaurarBanco = true"
    ></p-button>
  </div>
  <div class="p-col-12" *ngIf="loading"><i>Aguarde...</i></div>
  <div class="p-col-12">
    <b>{{getQtdeBd()}}</b> bancos no servidor |
    <b>{{getQtdeConectado()}}</b> tenants conectados |
    <b>{{getQtdeSemRegistro()}}</b> sem registro na central
  </div>
  <div class="p-col-12">
    <p-table [value]="users" scrollHeight="flex" selectionMode="single">
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="tn">Tenant</th>
          <th pSortableColumn="status">Status</th>
          <th pSortableColumn="nome">Nome</th>
          <th pSortableColumn="email">E-mail</th>
          <th pSortableColumn="ultimaEntrada">Última entrada</th>
          <th pSortableColumn="expDate">Validade</th>
          <th pSortableColumn="servidor">Servidor</th>
          <th style="width: 120px;"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-u>
        <tr>
          <td>{{u.tn}}</td>
          <td>{{u.status}}</td>
          <td>{{u.nome}}</td>
          <td>{{u.email}}</td>
          <td>{{u.ultimaEntrada | date: 'dd/MM/yyy HH:mm'}}</td>
          <td>{{u.expDate | date: 'dd/MM/yyy'}}</td>
          <td>{{u.servidor}}</td>
          <td>
            <button
              pButton
              class="p-button-text"
              title="Buscar dados"
              icon="pi pi-search"
              [loading]="u.loading"
              (click)="getDadosUsuario(u)"
            ></button>
            <button
              pButton
              title="Upgrade do banco de dados"
              class="p-button-text"
              icon="pi pi-refresh"
              [loading]="u.loading"
              (click)="upgradeBanco(u)"
            ></button>
            <button
              pButton
              title="Apagar banco de dados permanentemente"
              class="p-button-text"
              icon="pi pi-trash"
              [loading]="u.loading"
              (click)="dropBanco(u)"
            ></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
<div class="p-fluid grid" *ngIf="!enabled">
  <div class="col-12">Não disponível para não-desenvolvedor</div>
</div>
