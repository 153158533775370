import { SelectItem } from "primeng/api";
import { AbstractModel, Endereco } from "./abstract-model";

export interface AdminUsuario extends AbstractModel {
    login: string;
    senha: string;
    nome: string;
    sysAdmin: boolean;
    email: string;
    lembreteSenha: string;
    parceiro: Parceiro;
    grupoAcesso: number;
    acessoRelatorios;
    inativo: boolean;
}

export interface Parceiro extends AbstractModel {
    nome: string;
    cpfCnpj: string;
    telefone: string;
    email: string;
    endereco: Endereco;
}

export interface RetornoLogin {
    token: any;
    usuario: AdminUsuario;
}

export interface AdminLog extends AbstractModel {
    usuario: AdminUsuario;
    date: Date;
    operacao: string;
    idDest: number;
    descricao: string;
}

export const GRUPOS_ACESSO: SelectItem[] = [
    { label: "Supervisor de Suporte", value: 2 },
    { label: "Atendente", value: 3 },
];
