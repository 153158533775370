import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { AbstractService } from 'src/app/services/abstract.service';
import { ServidorService } from 'src/app/services/servidor.service';

@Component({
  selector: 'habil-servidor',
  templateUrl: './servidor.component.html',
  styleUrls: ['./servidor.component.scss']
})
export class ServidorComponent implements OnInit {

  constructor( bcservice: BreadcrumbService, public service: ServidorService, title: Title) {
    bcservice.setItems( [ { label: "Servidor" } ] );
    title.setTitle( "Servidor - " + AbstractService.app );
  }

  ngOnInit(): void {
  }

}
