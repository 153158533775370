import { AbstractModel } from "./abstract-model";
import { Rotina } from "./eacesso-rotina";

export interface RegistroProduto extends AbstractModel {
  nomeProduto: string;
  codigo: string;
  acessos: string[];
  ehGratuito: boolean;
  ehDemo: boolean;
  validade: number;
  tipoFrontend: string;
  categoriasRelatorio: string;
  classificacao: Classificacao;
}

export interface RegistroProduto extends AbstractModel {
  nomeProduto: string;
  codigo: string;
  acessosComDescricao: Rotina[];
  ehGratuito: boolean;
  ehDemo: boolean;
  validade: number;
  tipoFrontend: string;
  categoriasRelatorio: string;
  classificacao: Classificacao;
  empresas?: number;
}

export interface Classificacao extends AbstractModel {
  descricao: string;
  cancelados: boolean;
}

export interface CategoriaRelatorio extends AbstractModel {
  descricao: string;
}

export interface CategoriaRelatorio extends AbstractModel {
  descricao: string;
  habilitado?: number;
}

export class EModuloAdicional {
  public static modulos = [
    { description: "Hábil Cobranças", value: "PLATAFORMA_COBRANCAS" },
    { description: "Nuvem Shop", value: "NUVEM_SHOP" },
    { description: "Magento", value: "MAGENTO" },
    { description: "Ideris", value: "IDERIS" }
  ];
}

export const softwares = [
  { label: "Hábil 10", value: "HABIL_10" },
  // { label: "Hábil Pessoal", value: "APP_HABIL_PESSOAL" },
  { label: "Hábil Cobranças", value: "HABIL_COBRANCAS" },
  { label: "Limber Chef", value: "LIMBER_CHEF" },
  { label: "Hábil Franqueadora", value: "HABIL_FRANQUEADORA" },
  { label: "Hábil Mobile PDV", value: "HABIL_MOBILE_PDV" },
];