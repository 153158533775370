import { Registro, RegistroLicenca } from "./../../../models/registro";
import { RegistroService } from "./../../../services/registro.service";
import { AbstractService } from "src/app/services/abstract.service";
import { NuvemManagerService } from "./../../../services/nuvem-manager.service";
import { Component, OnInit } from "@angular/core";
import { FormatUtils } from "src/app/utilities/util/format-utils";
import { ConfirmationService, MessageService } from "primeng/api";

class UserDatabase {
  tn;
  loading = false;
  status;
  registro: Registro;
  licenca: RegistroLicenca;
  email: string;
  nome: string;
  ultimaEntrada;
  servidor: string;
  expDate: Date;
}

@Component({
  selector: "habil-nuvem-gerenciamento",
  templateUrl: "./nuvem-gerenciamento.component.html",
  styleUrls: ["./nuvem-gerenciamento.component.scss"],
})
export class NuvemGerenciamentoComponent implements OnInit {
  enabled = false;
  loading = false;
  users: UserDatabase[] = [];
  cancelarUpgrades = false;
  showCancelarUpgrades = false;
  showRestaurarBanco = false;

  nomeTn = "";
  backupUrl = "";
  constructor(
    public service: NuvemManagerService,
    public regService: RegistroService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {
    this.service.login(AbstractService.token()).subscribe((ret) => {
      if (ret) {
        this.enabled = true;
        this.users = [];
      }
    });
  }

  getQtdeBd() {
    return this.users?.length;
  }

  getQtdeConectado() {
    return this.users?.filter((u) => u.status == "Conectado")?.length;
  }

  getQtdeSemRegistro() {
    return this.users?.filter((u) => !u.registro)?.length;
  }

  restaurar() {
    this.showRestaurarBanco = false;
    this.loading = true;
    this.service.create(this.nomeTn, this.backupUrl).subscribe({
      next: (value) => {
        this.loading = false;
        console.log(value);
      },
      error: (err) => {
        this.loading = false;
      },
    });
  }

  async getDatabases() {
    this.loading = true;
    this.users = [];
    this.service.getDatabases().subscribe({
      next: (lst) => {
        this.loading = false;
        if (lst) {
          for (const item of lst) {
            if ((item as string).startsWith("tn") || item == "userbase") {
              const usr = new UserDatabase();
              usr.tn = item;
              usr.status = "";
              this.users.push(usr);
            }
          }
        }
        this.service.getConnections().subscribe({
          next: async (value) => {
            if (value) {
              for (const v of value) {
                const i = this.users.findIndex((usr) => usr.tn == v);
                if (i >= 0) {
                  this.users[i].status = "Conectado";
                }
              }
              // await this.getDadosTodosUsuarios();
            }
          },
        });
      },
      error: (err) => {
        console.error(err);
        this.loading = false;
      },
    });
  }

  upgradeBanco(u) {
    this.upgradeBancoPromise(u).then(() => {});
  }

  upgradeBancoPromise(u) {
    return new Promise<void>((resolve) => {
      u.loading = true;
      this.service.upgrade(u.tn).subscribe({
        next(value) {
          u.loading = false;
          console.log(value);
          resolve();
        },
        error(err) {
          u.loading = false;
          console.error(err);
          resolve();
        },
      });
    });
  }

  geraSQLDropAllSemRegistro() {
    for (const tn of this.users) {
      if (!tn.registro && tn.tn != "userbase") {
        console.log("DROP DATABASE " + tn.tn + ";");
      }
    }
  }

  dropBanco(u) {
    this.confirmationService.confirm({
      message: "Apagar banco de dados? Essa operação não pode ser desfeita!!",
      header: "Atenção",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Sim",
      rejectLabel: "Não",
      accept: () => {
        //this.loading = true;
        this.service.drop(u.tn).subscribe({
          next: (value) => {
            this.loading = false;
            console.log(value);
            this.users = this.users.filter((obj) => obj.tn != u.tn);
            //this.getDatabases();
          },
          error(err) {
            this.loading = false;
            console.error(err);
          },
        });
      },
      reject: () => {},
    });
  }

  getDadosUsuario(u: UserDatabase) {
    this.getDadosUsuarioPromise(u).then(() => {});
  }

  async upgradeTodosBancos() {
    this.loading = true;
    this.showCancelarUpgrades = true;
    for (const u of this.users) {
      if (this.cancelarUpgrades) {
        this.cancelarUpgrades = false;
        this.showCancelarUpgrades = false;
        break;
      }
      await this.upgradeBancoPromise(u);
    }
    this.showCancelarUpgrades = false;
  }

  async upgradeTodosBancosConectados() {
    this.loading = true;
    this.showCancelarUpgrades = true;
    for (const u of this.users) {
      if (this.cancelarUpgrades) {
        this.cancelarUpgrades = false;
        this.showCancelarUpgrades = false;
        break;
      }
      if (u.status == "Conectado") {
        await this.upgradeBancoPromise(u);
      }
    }
    this.showCancelarUpgrades = false;
  }

  async getDadosTodosUsuarios() {
    this.loading = true;
    for (const u of this.users) {
      await this.getDadosUsuarioPromise(u);
    }
    this.loading = false;
    console.log('------')
    this.geraSQLDropAllSemRegistro();
  }

  getDadosUsuarioPromise(u: UserDatabase) {
    return new Promise<void>((resolve) => {
      u.loading = true;
      const service = this.regService;
      this.regService
        .findByCnpj(FormatUtils.clear(u.tn).replace("tn", ""))
        .subscribe({
          next: (value) => {
            try {
              if (value) {
                u.registro = value[0];
                service
                  .getRegistroLicenca(u.registro.id, "HABIL_10")
                  .subscribe({
                    next: (value) => {
                      u.loading = false;

                      u.licenca = { ...value.body };
                      u.expDate = u.licenca.expDate;
                      u.email = u.registro.email;
                      u.nome = u.registro.nome;

                      //console.log(u.licenca);
                      resolve();
                    },
                    error: (err) => {
                      u.loading = false;
                      resolve();
                    },
                  });
                if (u.registro?.dadosAcesso) {
                  u.ultimaEntrada = u.registro.dadosAcesso.data;
                  u.servidor = u.registro.dadosAcesso.onde;
                  //console.log(value);
                }
              } else {
                u.loading = false;
                resolve();
              }
            } catch (error) {
              u.loading = false;
              resolve();
            }
          },
          error: (err) => {
            u.loading = false;
            console.error(err);
            resolve();
          },
        });
    });
  }

  ngOnInit(): void {}
}
