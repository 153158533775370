import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewsComponent } from './news.component';
import { CadastroNewsComponent } from './cadastro-news/cadastro-news.component';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressBarModule } from 'primeng/progressbar';
import { RippleModule } from 'primeng/ripple';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { BaseListaModule } from 'src/app/utilities/base-lista/base-lista.module';
import { BotoesCadastroModule } from 'src/app/utilities/botoes-cadastro/botoes-cadastro.module';
import { GrupoModule } from 'src/app/utilities/grupo/grupo.module';
import { LabelModule } from 'src/app/utilities/label/label.module';
import { DropdownModule } from 'primeng/dropdown';
import { DateEditModule } from 'src/app/utilities/date-edit/date-edit.module';
import { ListboxModule } from 'primeng/listbox';
import { TableModule } from 'primeng/table';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { EditorModule } from "primeng/editor";
import { DialogModule } from 'primeng/dialog';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ChipsModule } from 'primeng/chips';


@NgModule({
  declarations: [
    NewsComponent,
    CadastroNewsComponent
  ],
  imports: [
    CommonModule,
    BaseListaModule,
    FormsModule,
    ChipsModule,
    BrowserModule,
    ConfirmDialogModule,
    GrupoModule,
    BotoesCadastroModule,
    TooltipModule,
    AccordionModule,
    LabelModule,
    ProgressBarModule,
    RippleModule,
    ToastModule,
    ScrollPanelModule,
    InputTextModule,
    DropdownModule,
    DateEditModule,
    ListboxModule,
    TableModule,
    CheckboxModule,
    ButtonModule,
    FileUploadModule,
    EditorModule,
    InputNumberModule,
    DialogModule,
    AngularEditorModule
  ]
})
export class NewsModule { }
