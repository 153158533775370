import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HabilServerService {

  showDatabases(api: string): Observable<string[]> {
    const url = api + "/testes/tn/view";
    const h = new HttpHeaders({
      "X-Auth-Token": DEFAULT_TOKEN,
    });
    return this.http.get<string[]>(url, { headers: h });
  }


  removeDatabase(api: string, tn: string): Observable<string> {
    const url = api + "/testes/tn/remove/" + tn;
    const h = new HttpHeaders({
      "X-Auth-Token": DEFAULT_TOKEN,
    });
    return this.http.get(url, { headers: h, responseType: 'text' });
  }

  constructor(private http: HttpClient) { }
}

const DEFAULT_TOKEN = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJub21lTWFxdWluYSI6IjU2MGFjNzk3LWU2ZDktNDhjNy05YzNjLTBhZWQ5NzQ4ZWM5OCIsInN1YiI6IjUwNDEyNjU2MDAwMTkwIiwibWFjQWRkcmVzcyI6IjU2MGFjNzk3LWU2ZDktNDhjNy05YzNjLTBhZWQ5NzQ4ZWM5OCIsInVzZXJfaWQiOjI4LCJpc3MiOiJLU0gxMCIsIm9yaWdlbSI6IlJFVEFHVUFSREEifQ.xJuVFWpJDaZV_nYyIWYluWicUlCus9nhtuZahhJoSoY"