import { Component, OnInit } from '@angular/core';
import { LiberacaoService } from '../../../services/liberacao.service';
import { MessageService } from 'primeng/api';
import { LiberacaoUltimoCodigo } from '../../../models/liberacao';

@Component({
  selector: 'habil-consulta-liberacoes-he',
  templateUrl: './consulta-liberacoes-he.component.html',
  styleUrls: ['./consulta-liberacoes-he.component.scss']
})
export class ConsultaLiberacoesHeComponent implements OnInit {
  
  cnpj: string;
  liberacao: LiberacaoUltimoCodigo = new LiberacaoUltimoCodigo();
  loading: boolean = false;
  
  constructor(
      private service: LiberacaoService,
      private msg: MessageService,
  ) {
  }
  
  ngOnInit(): void {
    this.montaLiberacaoVazia();
  }
  
  private montaLiberacaoVazia(): void {
    this.liberacao = new LiberacaoUltimoCodigo();
    this.liberacao.cnpj = '(vazio)';
    this.liberacao.codigo = '';
  }
  
  getGuia(): string {
    return !this.liberacao.guia ?
        'Não' :
        `Sim. Identificação do usuário: ${this.liberacao.guia}`;
  }
  
  buscar(): void {
    if (!this.cnpj) {
      this.msg.add({
        severity: 'info',
        detail: 'Informe o CNPJ para pesquisar',
        summary: 'Informação'
      });
    } else {
      this.loading = true;
      this.service.consultaLiberacaoHe(this.cnpj).subscribe({
        next: (result: any): void => {
          if (result.length) {
            const registro: any = result[0];
            this.liberacao.data = registro?.dataDownload;
            this.liberacao.vcto = registro?.dataVencimento;
            
          } else {
            this.montaLiberacaoVazia();
            this.msg.add({
              severity: 'info',
              detail: 'Nenhuma informação para esse CNPJ',
              summary: 'Informação'
            });
          }
        },
        error: (error): void => {
          this.msg.add({
            sticky: true,
            severity: 'error',
            detail: error.error.value,
            summary: 'Erro'
          });
        }
      }).add((): void => {
        this.loading = false;
      });
    }
  }
  
}
