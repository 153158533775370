import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Atendimento } from 'src/app/models/atendimento';
import { ELiberacaoTipo, Liberacao } from 'src/app/models/liberacao';
import { AtendimentoService } from 'src/app/services/atendimento.service';
import { LiberacaoService } from 'src/app/services/liberacao.service';
import { PlanoSuporteService } from 'src/app/services/plano-suporte.service';
import { DateUtils } from 'src/app/utilities/util/date-utils';
import { DateValidationItem, FormValidation, SearchValidationItem, StringValidationItem } from 'src/app/utilities/util/form-validation';
import { FormatUtils } from 'src/app/utilities/util/format-utils';

@Component({
  selector: 'habil-liberacao-suporte',
  templateUrl: './liberacao-suporte.component.html',
  styleUrls: ['./liberacao-suporte.component.scss']
})
export class LiberacaoSuporteComponent implements OnInit {

  obj: Atendimento = new Atendimento();
  liberacao: Liberacao = new Liberacao( ELiberacaoTipo.SUPORTE_CLUBE );
  loading: boolean = false;
  procuraAberto: boolean = false;
  procuraDisable: boolean = false;
  lancaAberto: boolean = false;
  lancaDisable: boolean = false;
  cnpj: string;
  vctoOriginal: Date;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    public planoSuporteService: PlanoSuporteService,
    public liberacaoService: LiberacaoService,
    private message: MessageService,
    private service: AtendimentoService 
  ) { }

  ngOnInit(): void {
    this.liberacao = this.config.data.liberacao;
    this.service.localizaPelaLiberacao( this.liberacao ).subscribe(
      lib => {
        if ( lib ) {
          this.obj = lib;
          this.obj.inicio = new Date();
          this.obj.fim = DateUtils.incMonth( this.obj.inicio, 1 );
          this.vctoOriginal = this.obj.fim;
        }
        this.estadoDaSanfona();
      }
    );
  }

  maisDiasClick( mais30: boolean ) {
    let dias = 15;
    if ( mais30 ) {
      dias = 30;
    }
    this.obj.fim = DateUtils.incDay( this.obj.fim, dias );
  }

  fazPesquisa() {
    this.cnpj = FormatUtils.clear( this.cnpj );
    this.loading = true;
    let liberacao = new Liberacao();
    liberacao.dadosCliente.cpfCnpj = this.cnpj;
    this.service.localizaPelaLiberacao( liberacao ).subscribe( 
      data => {
        this.obj = data;
        this.loading = false;
        this.estadoDaSanfona();

      }
    )
  }

  private estadoDaSanfona() {
    this.procuraAberto = this.liberacao.id == undefined;
    this.procuraDisable = this.liberacao.id > 0;

    console.log( this.obj );

    this.lancaAberto = ( this.liberacao.id > 0 ) || ( this.obj.cpfCnpj != null && this.obj.cpfCnpj != undefined );
    this.lancaDisable = this.obj.cpfCnpj == undefined;
  }

  fazValidacao(): boolean {
    this.obj.cpfCnpj = FormatUtils.clear( this.obj.cpfCnpj );

    let v: FormValidation = new FormValidation()
      .add( new StringValidationItem( this.obj.cpfCnpj, "CPF/CNPJ obrigatório!" ) )
      .add( new SearchValidationItem( this.obj.planoSuporte, "Plano de Suporte obrigatório!" ) )
      .add( new DateValidationItem( this.obj.inicio, "Data de início obrigatória!" ) )
      .add( new DateValidationItem( this.obj.fim, "Data de fim obrigatória!" ) );
    if ( !v.isValid() ) {
      this.message.addAll( v.msgs() );
    }
    return v.isValid();
  }

  finalizar() {
    if ( this.fazValidacao() ) {
      this.loading = true;
      if ( this.liberacao.id > 0 ) {
        this.liberacaoService.finaliza( this.liberacao, "", this.obj ).subscribe( 
          data => {
            this.ref.close( data );
            this.loading = false;
          },
          error => {
            this.service.handleError( error );
            this.loading = false;
          }
        )
      }
      else {
        this.service.save( this.obj ).subscribe(
          () => {
            this.ref.close();
            this.loading = false;
          },
          error => {
            this.service.handleError( error );
            this.loading = false;
          }
        )
      }
    }
  }

  fechar() {
    this.ref.close();
  }


}
