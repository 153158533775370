<p-confirmDialog header="Atenção" icon="pi pi-exclamation-triangle">
</p-confirmDialog>
<p-toast></p-toast>
<div class="p-field p-col-12 p-md-12 p-lg-12" *ngIf="_loading">
  <p-progressBar
    mode="indeterminate"
    [style]="{ height: '6px' }"
  ></p-progressBar>
</div>
<habil-grupo>
  <p-tabView>
    <p-tabPanel header="Dados Básicos" [selected]="true">
      <p-scrollPanel [style]="{ width: '100%', height: getScrollHeight() }">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-lg-5">
            <habil-label name="txtCnpj" label="CPF / CNPJ">
              <input
                [readonly]="_readonly"
                type="text"
                name="txtCnpj"
                pInputText
                [(ngModel)]="obj.cnpj"
              />
            </habil-label>
          </div>
          <div class="p-field p-col-12 p-lg-7">
            <habil-label name="txtNome" label="Nome">
              <input
                [readonly]="_readonly"
                type="text"
                name="txtNome"
                pInputText
                [(ngModel)]="obj.nome"
              />
            </habil-label>
          </div>
          <div class="p-field p-col-12 p-lg-4">
            <habil-label name="txtEmail" label="E-mail">
              <input
                [readonly]="_readonly"
                type="text"
                name="txtEmail"
                pInputText
                [(ngModel)]="obj.email"
              />
            </habil-label>
          </div>
          <div class="p-field p-col-12 p-lg-4">
            <habil-label name="txtResponsavel" label="Responsável">
              <input
                [readonly]="_readonly"
                type="text"
                name="txtResponsavel"
                pInputText
                [(ngModel)]="obj.responsavel"
              />
            </habil-label>
          </div>
          <div class="p-field p-col-4">
            <habil-label
              name="searchParceiro"
              label="Somente mostrar clientes do parceiro"
            >
              <habil-autocomplete-edit
                [service]="parceiroService"
                [(ngModel)]="obj.parceiro"
                [readonly]="_readonly"
                name="searchParceiro"
                field="nome"
                dataKey="id"
              >
              </habil-autocomplete-edit>
            </habil-label>
          </div>
          <div class="p-field p-col-4">
            <habil-label
              name="searchClass"
              label="Classificação"
              title="Esse campo será preenchido quando o cliente for cadastrado automaticamente. Caso a classificação mude, será necessário alterá-la manualmente!"
            >
              <habil-autocomplete-edit
                [service]="classificacaoService"
                [(ngModel)]="obj.classificacao"
                [readonly]="_readonly"
                name="searchClass"
                field="descricao"
                dataKey="id"
              >
              </habil-autocomplete-edit>
            </habil-label>
          </div>
          <div class="p-field p-col-2" *ngIf="mostrarCancelamento()">
            <habil-label name="cancelamento" label="Cancelado em">
              <habil-date-edit [(ngModel)]="obj.cancelamento"></habil-date-edit>
            </habil-label>
          </div>
          <div class="p-field p-col-12 p-md-6 p-lg-3">
            <habil-label name="hostHabil" label="Servidor nuvem (Hábil 10)">
              <p-dropdown
                appendTo="body"
                [options]="servidoresNuvem"
                [(ngModel)]="obj.idHabil"
                optionLabel="label"
                optionValue="value"
                name="hostHabil"
                [showClear]="true"
                [readonly]="_readonly"
              >
              </p-dropdown>
            </habil-label>
          </div>
          <div class="p-field p-col-12 p-md-6 p-lg-3">
            <habil-label name="hostHabilBD" label="Servidor Banco de dados">
              <p-dropdown
                appendTo="body"
                [options]="servidoresBd"
                optionLabel="label"
                optionValue="value"
                [(ngModel)]="dbLocation.databaseIp"
                name="hostHabilBD"
                [showClear]="true"
                [readonly]="_readonly"
              >
              </p-dropdown>
            </habil-label>
          </div>
          <div class="p-field p-col-fixed p-col-align-center">
            <br />
            <p-checkbox
              [binary]="true"
              label="Ignorar na contagem de emissão"
              [(ngModel)]="obj.ignorar"
            ></p-checkbox>
          </div>
          <div class="p-field p-col-12">
            <habil-endereco
              titulo="Endereço"
              [(ngModel)]="obj.endereco"
              (onChange)="changeEndereco($event)"
              [readonly]="_readonly"
            ></habil-endereco>
          </div>
          <div class="p-field p-col-12">
            <habil-grid-fones
              [fones]="obj.telefones"
              [readonly]="_readonly"
              (onChange)="onFoneChange($event)"
            ></habil-grid-fones>
          </div>
        </div>
      </p-scrollPanel>
    </p-tabPanel>
    <p-tabPanel header="Licenças">
      <p-scrollPanel [style]="{ width: '100%', height: getScrollHeight() }">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12">
            <p-table
              [value]="obj.licencas"
              styleClass="p-datatable-sm"
              responsiveLayout="scroll"
              dataKey="id"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th>Software</th>
                  <th>Gerado em</th>
                  <!-- <th>Tipo</th> -->
                  <th>Usou Demo</th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-product>
                <tr>
                  <td>
                    {{
                      (product.deleted ? "(Inativo) - " : "") +
                        getSoftware(product.software)
                    }}
                  </td>
                  <td>{{ product.creationDate | date : "dd/MM/yyy HH:mm" }}</td>
                  <!-- <td>{{ getTipo(product.tipo) }}</td> -->
                  <td [style]="{ width: '120px' }">
                    <p-checkbox
                      [readonly]="true"
                      [binary]="true"
                      [(ngModel)]="obj.usouDemo"
                    >
                    </p-checkbox>
                  </td>
                  <td [style]="{ width: '10px' }">
                    <p-button
                      styleClass="p-button-text"
                      [disabled]="_readonly"
                      title="Alterar"
                      icon="pi pi-pencil"
                      (onClick)="editar(product)"
                    >
                    </p-button>
                  </td>
                  <td [style]="{ width: '10px' }">
                    <p-button
                      styleClass="p-button-text"
                      [disabled]="_readonly"
                      title="Enviar chave por e-mail"
                      (onClick)="enviaChavePorEmail(product)"
                      icon="pi pi-envelope"
                    >
                    </p-button>
                  </td>
                  <td [style]="{ width: '10px' }">
                    <p-button
                      styleClass="p-button-text"
                      [disabled]="_readonly"
                      title="Liberar nova instalação"
                      (onClick)="liberaNovaInstalacao(product)"
                      tooltipPosition="left"
                      icon="pi pi-unlock"
                    >
                    </p-button>
                  </td>
                  <td [style]="{ width: '10px' }">
                    <p-button
                      styleClass="p-button-text"
                      [disabled]="_readonly || product.software == 'HABIL_10'"
                      [title]="product.deleted ? 'Reativar' : 'Inativar'"
                      (onClick)="product.deleted = !product.deleted"
                      tooltipPosition="left"
                      [icon]="product.deleted ? 'pi pi-refresh' : 'pi pi-times'"
                    >
                    </p-button>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
          <div class="p-col-fixed" *ngIf="!existeLicenca('HABIL_COBRANCAS')">
            <p-button
              styleClass="p-button-text"
              icon="pi pi-plus"
              [disabled]="_readonly"
              label="Hábil Cobranças"
              (onClick)="adicionaLicenca('HABIL_COBRANCAS')"
            ></p-button>
          </div>
          <div class="p-col-fixed" *ngIf="!existeLicenca('LIMBER_CHEF')">
            <p-button
              styleClass="p-button-text"
              icon="pi pi-plus"
              [disabled]="_readonly"
              label="Limber Chef"
              (onClick)="adicionaLicenca('LIMBER_CHEF')"
            ></p-button>
          </div>
          <div class="p-col-fixed" *ngIf="!existeLicenca('HABIL_FRANQUEADORA')">
            <p-button
              styleClass="p-button-text"
              icon="pi pi-plus"
              [disabled]="_readonly"
              label="Hábil Franqueadora"
              (onClick)="adicionaLicenca('HABIL_FRANQUEADORA')"
            ></p-button>
          </div>
          <div
            class="p-col-fixed"
            *ngIf="!existeLicenca('HABIL_MOBILE_PDV')"
          >
            <p-button
              styleClass="p-button-text"
              icon="pi pi-plus"
              [disabled]="_readonly"
              label="Hábil Mobile PDV"
              (onClick)="adicionaLicenca('HABIL_MOBILE_PDV')"
            ></p-button>
          </div>
          <div
            class="p-col-fixed"
            *ngIf="!existeLicenca('HABIL_PLAY')"
          >
            <p-button
              styleClass="p-button-text"
              icon="pi pi-plus"
              [disabled]="_readonly"
              label="Hábil Play"
              (onClick)="adicionaLicenca('HABIL_PLAY')"
            ></p-button>
          </div>
        </div>
      </p-scrollPanel>
    </p-tabPanel>
    <p-tabPanel
      header="Usuários Franqueadora"
      *ngIf="existeLicenca('HABIL_FRANQUEADORA')"
    >
      <p-scrollPanel [style]="{ width: '100%', height: getScrollHeight() }">
        <br />
        <div class="p-fluid p-grid">
          <div class="p-field p-col-2">
            <button
              pButton
              label="Novo"
              [disabled]="_readonly"
              icon="pi pi-plus"
              class="p-button-secondary"
              (click)="novoUsuarioHabilFranqueadora()"
            ></button>
          </div>
          <div class="p-field p-col-12">
            <p-table
              [value]="usuariosFranqueadora"
              styleClass="p-datatable-sm"
              responsiveLayout="scroll"
              dataKey="id"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th>Nome</th>
                  <th>Login</th>
                  <th>Telefone</th>
                  <th>Lembrete senha</th>
                  <th>Criado em</th>
                  <th></th>
                  <th></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-usr>
                <tr>
                  <td>{{ usr.nome }}</td>
                  <td>{{ usr.login }}</td>
                  <td>{{ usr.telefone }}</td>
                  <td>{{ usr.lembreteSenha }}</td>
                  <td>{{ usr.creationDate | date : "dd/MM/yyy HH:mm" }}</td>
                  <!-- <td>{{ getTipo(usr.tipo) }}</td> -->
                  <td [style]="{ width: '10px' }">
                    <p-button
                      styleClass="p-button-text"
                      [disabled]="_readonly"
                      title="Alterar"
                      (onClick)="editarUsuarioHabilFranqueadora(usr)"
                      icon="pi pi-pencil"
                    >
                    </p-button>
                  </td>
                  <td [style]="{ width: '10px' }">
                    <p-button
                      styleClass="p-button-text"
                      [disabled]="_readonly"
                      title="Excluir"
                      (onClick)="excluirUsuarioHabilFranqueadora(usr)"
                      tooltipPosition="left"
                      [icon]="usr.deleted ? 'pi pi-refresh' : 'pi pi-times'"
                    >
                    </p-button>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </p-scrollPanel>
    </p-tabPanel>
    <p-tabPanel header="Outros dados">
      <p-scrollPanel [style]="{ width: '100%', height: getScrollHeight() }">
        <p-accordion>
          <p-accordionTab header="Outros Dados" [selected]="true">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12 p-lg-3">
                <habil-label
                  name="lbnrofuncionarios"
                  label="Número de Funcionários"
                >
                  <input pInputText [(ngModel)]="obj.nroFuncionarios" />
                </habil-label>
              </div>
              <div class="p-field p-col-12 p-lg-9">
                <!-- vazio -->
              </div>
              <div class="p-field p-col-12 p-lg-4">
                <habil-label name="lbindicacao" label="Indicação">
                  <p-dropdown
                    [options]="tipoIndicacoes"
                    label="label"
                    value="value"
                    [(ngModel)]="obj.indicacao"
                  ></p-dropdown>
                </habil-label>
              </div>
              <div class="p-field p-col-12 p-lg-8">
                <habil-label name="lbindicacaooutros" label="Outros">
                  <input pInputText [(ngModel)]="obj.indicacaoOutros" />
                </habil-label>
              </div>
              <div class="p-field p-col-12 p-lg-4">
                <habil-label name="lbareaatuacao" label="Área de Atuação">
                  <input pInputText [(ngModel)]="obj.areaAtuacao" />
                </habil-label>
              </div>
              <div class="p-field p-col-12 p-lg-8">
                <habil-label name="lbareaatuacaooutros" label="Outros">
                  <input pInputText [(ngModel)]="obj.areaAtuacaoOutros" />
                </habil-label>
              </div>
              <div class="p-field p-col-12 p-lg-4">
                <habil-label
                  name="lbprincipaisnecessidades"
                  label="Principais Necessidades"
                >
                  <input pInputText [(ngModel)]="obj.principaisNecessidades" />
                </habil-label>
              </div>
              <div class="p-field p-col-12 p-lg-8">
                <habil-label
                  name="lbprincipaisnecessidadesoutros"
                  label="Outros"
                >
                  <input
                    pInputText
                    [(ngModel)]="obj.principaisNecessidadesOutros"
                  />
                </habil-label>
              </div>
            </div>
          </p-accordionTab>
        </p-accordion>
        <br />
        <p-accordion (onOpen)="carregaDadosPrimeiroAcesso()">
          <p-accordionTab header="Dados de Primeiro Acesso">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12">
                <p-progressBar
                  *ngIf="loadingDadosPrimeiroAcesso"
                  mode="indeterminate"
                ></p-progressBar>
                <p-table
                  [value]="dadosPrimeiroAcesso"
                  styleClass="p-datatable-sm"
                  responsiveLayout="scroll"
                >
                  <ng-template pTemplate="header">
                    <tr>
                      <th>Usuário</th>
                      <th>E-mail</th>
                      <th>Data</th>
                      <th>IP</th>
                      <th>Software</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-product>
                    <tr>
                      <td>{{ product.nomeUsuario }}</td>
                      <td>{{ product.emailUsuario }}</td>
                      <td>
                        {{
                          product.dataHoraPrimeiroAcesso
                            | date : "dd/MM/yyy hh:mm"
                        }}
                      </td>
                      <td>{{ product.ipMaquina }}</td>
                      <td>{{ product.software }}</td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </p-accordionTab>
        </p-accordion>
        <br />
        <p-accordion (onOpen)="carregaDadosAlteracoes()">
          <p-accordionTab header="Alterações de Informação">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12">
                <p-progressBar
                  *ngIf="loadingDadosPrimeiroAcesso"
                  mode="indeterminate"
                ></p-progressBar>
                <p-table
                  [value]="dadosAlteracoes"
                  styleClass="p-datatable-sm"
                  responsiveLayout="scroll"
                >
                  <ng-template pTemplate="header">
                    <tr>
                      <th>CPF / CNPJ</th>
                      <th>E-mail</th>
                      <th>Home Page</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-product>
                    <tr>
                      <td>{{ product.cnpj }}</td>
                      <td>{{ product.email }}</td>
                      <td>{{ product.homePage }}</td>
                    </tr>
                  </ng-template>
                </p-table>
                <br />
                <p-table
                  [value]="fonesAlteracoes"
                  styleClass="p-datatable-sm"
                  responsiveLayout="scroll"
                >
                  <ng-template pTemplate="header">
                    <tr>
                      <th>Telefones</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-fone>
                    <tr>
                      <td>{{ fone.telefone }}</td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </p-accordionTab>
        </p-accordion>
      </p-scrollPanel>
    </p-tabPanel>
  </p-tabView>
</habil-grupo>
<br />
<habil-botoes-cadastro
  (onError)="onErrorHandled()"
  [obj]="getObject.bind(this)"
  [dialog]="_isDialog"
  (onSaved)="onSave($event)"
  (onClosed)="onCancel()"
  [service]="service"
  [readonly]="_readonly"
  [validate]="doValidate.bind(this)"
  [doBeforeSave]="doBeforeSave.bind(this)"
  (onSaveAndNew)="onSaveAndNew($event)"
>
</habil-botoes-cadastro>
