import { AbstractModel } from "./abstract-model";
import { Rotina } from "./eacesso-rotina";
import { Classificacao } from "./registro-produto";

export class HabilCobrancasProduto extends AbstractModel {
    nomeProduto: string = "";
    codigo: string = "";
    acessos: string[] = [];
    acessosComDescricao?: Rotina[];
    classificacao: Classificacao;
}


export class HabilCobrancasRegistroLicenca {
    expDate: Date = new Date();
    nroLicencas: number = 1;
    acessoGeral: boolean = false;
    acessos: string[] = [];
    empresas: HabilCobrancasRegistroLicencaEmpresa[] = [];
    ip?: string;
}

export class HabilCobrancasRegistroLicencaEmpresa {
    documento: string = "";
    nome: string = "";
    vcto: Date = new Date();
    licencas: number = 0;
}