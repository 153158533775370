<p-toast></p-toast>
<habil-grupo>
  <div class="p-grid p-fluid" *ngIf="!loading && obj?.centralCliente">
    <div class="p-field p-col-12">
      <habil-label name="txtEmail" label="E-mail interno para avisos">
        <input
          type="text"
          name="txtEmail"
          [(ngModel)]="obj.centralCliente.email"
          pInputText
        />
      </habil-label>
    </div>
    <div class="p-field p-col-12">
      <p-panel header="Dados para conexão com Hábil 10">
        <div class="p-grid p-fluid">
          <div class="p-field p-col-4">
            <habil-label name="txtusuario" label="Usuário">
              <input
                type="text"
                name="txtusuario"
                [(ngModel)]="obj.centralCliente.usuarioHabil"
                pInputText
              />
            </habil-label>
          </div>
          <div class="p-field p-col-4">
            <habil-label name="txtsenha" label="Senha">
              <p-password
                name="edtsenha"
                pPassword
                [feedback]="false"
                [toggleMask]="true"
                [(ngModel)]="obj.centralCliente.senhaHabil"
              >
              </p-password> 
            </habil-label>
          </div>
          <div class="p-field p-col-2">
            <habil-label name="txtsenha" label="ID do Funcionário">
              <p-inputNumber
                [(ngModel)]="obj.centralCliente.idFuncionarioHabil"
              ></p-inputNumber>
            </habil-label>
          </div>
          <div class="p-field p-col-2">
            <habil-label name="lbplanosuporte" label="ID Plano de suporte padrão">
              <p-inputNumber
                [(ngModel)]="obj.centralCliente.idPlanoSuporte"
              ></p-inputNumber>
            </habil-label>
          </div>
        </div>
      </p-panel>
    </div>
  </div>
</habil-grupo>
<br />
<habil-grupo>
  <div class="p-fluid p-grid">
    <div class="p-col"></div>
    <div class="p-col-2">
      <button
        pButton
        class="p-button-success"
        icon="pi pi-check"
        label="Salvar"
        (click)="saveCfg()"
      ></button>
    </div>
  </div>
</habil-grupo>
