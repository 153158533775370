import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MensagemComponent } from './mensagem.component';
import { DynamicDialogModule } from 'primeng/dynamicdialog';

@NgModule({
  declarations: [
    MensagemComponent,
  ],
  imports: [
    CommonModule,
    DynamicDialogModule
  ]
})
export class MensagemModule { }
