<p-confirmDialog header="Atenção" icon="pi pi-exclamation-triangle">
</p-confirmDialog>
<p-overlayPanel #op appendTo="body">
  <ng-template pTemplate>
    <p-table [value]="detalhes" responsiveLayout="scroll">
      <ng-template pTemplate="header">
        <tr>
          <th>Tipo</th>
          <th>Franquia</th>
          <th>Contagem</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product>
        <tr>
          <td>{{ product.tipoEmissao }}</td>
          <td>{{ product.contagemAtual }}</td>
          <td>{{ product.contagem }}</td>
        </tr>
      </ng-template>
    </p-table>
  </ng-template>
</p-overlayPanel>
<p-dialog header="Detalhes" [(visible)]="display">
  <p-table [value]="detalhes" responsiveLayout="scroll">
    <ng-template pTemplate="header">
      <tr>
        <th>Tipo</th>
        <th>Franquia</th>
        <th>Contagem</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product>
      <tr>
        <td>{{ product.tipoEmissao }}</td>
        <td>{{ product.contagemAtual }}</td>
        <td>{{ product.contagem }}</td>
      </tr>
    </ng-template>
  </p-table>
</p-dialog>
<habil-grupo>
  <div class="p-fluid p-grid">
    <div class="p-col-6">
      <habil-label name="msClass" label="Classificações">
        <p-multiSelect
          name="msClass"
          [disabled]="loading"
          [options]="classificacoesTodas"
          defaultLabel="Todas"
          [(ngModel)]="classificacoes"
          optionLabel="descricao"
          optionValue="id"
        ></p-multiSelect>
      </habil-label>
    </div>
    <div class="p-col">
      <habil-label name="ddMes" label="Mês">
        <p-dropdown
          name="ddMes"
          [disabled]="loading"
          [options]="meses"
          optionLabel="label"
          optionValue="value"
          [(ngModel)]="mes"
          [showClear]="false"
          [filter]="false"
        ></p-dropdown>
      </habil-label>
    </div>
    <div class="p-col">
      <habil-label name="inAno" label="Ano">
        <p-inputNumber
          name="inAno"
          [disabled]="loading"
          [(ngModel)]="ano"
          [format]="false"
        ></p-inputNumber>
      </habil-label>
    </div>
    <div class="p-col p-col-align-end">
      <p-button
        label="Visualizar"
        [disabled]="loading"
        (onClick)="busca()"
      ></p-button>
    </div>
    <div class="p-col p-col-align-end">
      <p-button
        label="Exportar"
        [disabled]="loading"
        (onClick)="dt.exportCSV()"
      ></p-button>
    </div>
    <div class="p-col-12">
      <div class="p-fluid p-grid">
        <div class="p-col-fixed">
          <p-message
            severity="success"
            [text]="'Empresas que emitiram: ' + totalEmpresasEmissoras"
          ></p-message>
        </div>
        <div class="p-col-fixed">
          <p-message
            severity="success"
            [text]="'Total franquia: ' + totalFranquia"
          ></p-message>
        </div>
        <div class="p-col-fixed">
          <p-message
            [severity]="totalEmissoes > 10000 ? 'error' : 'success'"
            [text]="'Total contagem: ' + totalEmissoes"
          ></p-message>
        </div>
        <div class="p-col-fixed">
          <p-message
            severity="success"
            [text]="'Total excedente clientes: ' + totalExcedido"
          ></p-message>
        </div>
      </div>
    </div>
    <div class="p-col-12">
      <p-table
        #dt
        [value]="list"
        [loading]="loading"
        [columns]="exportColumns"
        csvSeparator=";"
      >
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="id">
              Código<p-sortIcon field="id"></p-sortIcon>
            </th>
            <th pSortableColumn="cnpj">
              CNPJ<p-sortIcon field="cnpj"></p-sortIcon>
            </th>
            <th pSortableColumn="nome">
              Nome<p-sortIcon field="nome"></p-sortIcon>
            </th>
            <th pSortableColumn="email">
              E-mail<p-sortIcon field="email"></p-sortIcon>
            </th>
            <th pSortableColumn="contagemAtual">
              Franquia<p-sortIcon field="contagem"></p-sortIcon>
            </th>
            <th pSortableColumn="contagem">
              Contagem<p-sortIcon field="contagem"></p-sortIcon>
            </th>
            <th pSortableColumn="excedente">
              Excedente<p-sortIcon field="excedente"></p-sortIcon>
            </th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-cliente>
          <tr>
            <td>{{ cliente.registro }}</td>
            <td>{{ cliente.cnpj }}</td>
            <td>{{ cliente.nome }}</td>
            <td>{{ cliente.email }}</td>
            <td>{{ cliente.contagemAtual }}</td>
            <td>{{ cliente.contagem }}</td>
            <td>{{ cliente.excedente }}</td>
            <td>
              <p-button
                icon="pi pi-eye"
                (onClick)="showDialog(cliente.detalhes); op.toggle($event)"
              ></p-button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</habil-grupo>
