import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { Classificacao } from 'src/app/models/registro-produto';
import { AbstractService } from 'src/app/services/abstract.service';
import { ClassificacaoService } from 'src/app/services/classificacao.service';
import { GenericCadastro } from 'src/app/utilities/generic-cadastro';
import { clone } from 'src/app/utilities/util/clone-utils';
import { FormValidation } from 'src/app/utilities/util/form-validation';

@Component({
  selector: 'habil-cadastro-classificacao',
  templateUrl: './cadastro-classificacao.component.html',
  styleUrls: ['./cadastro-classificacao.component.scss'],
  providers: [MessageService, DialogService]
})
export class CadastroClassificacaoComponent  extends GenericCadastro<Classificacao, ClassificacaoService> implements OnInit {

  newObject(): Classificacao {

    let obj: Classificacao = {
      deleted: false,
      descricao: '',
      cancelados: false,
      id: 0,
      idInterno: 0,
      creationDate: undefined,
      idHabil: '',
      lastModifiedDate: undefined,
      createdBy: 0,
      lastModifiedBy: 0
    };
    return obj;
  }

  doValidate(): boolean {
    this.corrigeAtributos();
    let validation: FormValidation = new FormValidation();
    validation.toValidate = [
      {
        condition: this.obj.descricao != undefined && this.obj.descricao.length > 0,
        message: "O nome é obrigatório!",
      }
    ];
    if (!validation.isValid()) {
      validation.message().forEach((m) => {
        this.messageService.add({
          severity: "warn",
          summary: "Atenção",
          detail: m,
        });
      });
    }
    return validation.isValid();
  }

  constructor(
    public service: ClassificacaoService,
    public messageService: MessageService,
    public dialogService: DialogService,  
    public route: ActivatedRoute,
    public router: Router,
    public confirmationService: ConfirmationService,
    public titleService: Title,
    public breadcrumbService: BreadcrumbService
  ) {
    super();
    this.breadcrumbService.setItems([{label: 'Classificações', routerLink: '/clientes/classificacao'},{label: 'Cadastro'}]);
    
  }

  ngOnInit(): void {
    if (this._isDialog) {
      this._loading = true;
      if (this._externalObj != null && this._externalObj != undefined) {
        this.obj = clone(this._externalObj);
        this.id = this.obj.id;
        this.idCampos = (this.id == undefined ? "0" : this.id.toString());
        this._loading = false;
      } else {
        this.obj = this.newObject();
        this.idCampos = (this.id == undefined ? "0" : this.id.toString());
        this.loadAuxObjects();
        this._loading = false;
      }
    } else {
      this.titleService.setTitle('Classificação - ' + AbstractService.app);
      super.ngOnInit();
    }
  }

  doBeforeSave() {}

}
