import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DatabaseLocation } from '../models/database-location';
import { AbstractService } from './abstract.service';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class DatabaseLocationService extends AbstractService<DatabaseLocation> {
  titulo: string = "Bancos de dados";

  endpoint(): string {
    return "user/database-location";
  }
  rotina(): string {
    return "DATABASE_LOCATION";
  }
  colunas(): any[] {
    return [
      { header: "Código", field: "id" },
      { header: "Nome", field: "nome" },
      { header: "URL", field: "url" }
    ]
  }
  private uri(ehLista?: boolean): string {
    let ret = "/adm/nuvem";
    if (ehLista) {
      return ret;
    }
    return ret + "/cadastro";
  }

  novo(selection?: any) {
    AbstractService.readonly = false;
    this.router.navigate([this.uri()])
  }
  abrir(obj: DatabaseLocation) {
    AbstractService.readonly = true;
    this.router.navigate([this.uri()], { queryParams: { id: obj.id } });
  }
  edit(obj: DatabaseLocation) {
    AbstractService.readonly = false;
    this.router.navigate([this.uri()], { queryParams: { id: obj.id } });
  }
  routeLista() {
    return this.uri(true);
  }

  constructor(public http: HttpClient, public loginService: LoginService, private router: Router) {
    super(http, loginService);
  }
}
