import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Atendimento, AtendimentoExcecao } from 'src/app/models/atendimento';
import { AbstractService } from 'src/app/services/abstract.service';
import { AtendimentoService } from 'src/app/services/atendimento.service';
import { PlanoSuporteService } from 'src/app/services/plano-suporte.service';
import { DateUtils } from 'src/app/utilities/util/date-utils';
import { CpfCnpjValidationItem, FormValidation, NumberValidationItem, StringValidationItem } from 'src/app/utilities/util/form-validation';
import { FormatUtils } from 'src/app/utilities/util/format-utils';

@Component({
  selector: 'habil-atendimento-cliente-novo',
  templateUrl: './atendimento-cliente-novo.component.html',
  styleUrls: ['./atendimento-cliente-novo.component.scss']
})
export class AtendimentoClienteNovoComponent implements OnInit {

  obj: Atendimento;
  excecao: AtendimentoExcecao;
  loading: boolean = false;

  constructor(
    private service: AtendimentoService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private message: MessageService,
    private planoSuporteService: PlanoSuporteService
  ) { }

  ngOnInit(): void {
    this.obj = new Atendimento();    
    this.obj.inicio = DateUtils.incDay( new Date(), -1 );
    this.obj.fim = DateUtils.incDay( new Date(), -1 );
    this.excecao = new AtendimentoExcecao();
    this.excecao.atendente = AbstractService.getLocalStorage( "usuario" ).nome;
    this.planoSuporteService.findById( this.config.data.idPlanoSuporte ).subscribe( 
      plano => {
        this.obj.planoSuporte = plano;
      }
    )
  }

  private fazValidacao(): boolean {
    this.obj.cpfCnpj = FormatUtils.clear( this.obj.cpfCnpj );
    let valid: FormValidation = new FormValidation()
      .add( new CpfCnpjValidationItem( this.obj.cpfCnpj, "CPF/CNPJ obrigatório!", true ) )
      .add( new StringValidationItem( this.excecao.atendente, "Atendente obrigatório!" ) )
      .add( new StringValidationItem( this.excecao.observacoes, "Observaçõces obrigatórias!" ) );
    
    let tamanho = this.obj.cpfCnpj.length;
    if (tamanho != 11 && tamanho != 14 ) {
      // que safadeza isso aqui, me julguem.
      valid.add( new NumberValidationItem( 0, "CPF/CNPJ inválido!" ) );
    }

    if ( !valid.isValid() ) {
      this.message.addAll( valid.msgs() );
    }
    return valid.isValid();
  }

  salvar() {
    if ( this.fazValidacao() ) {
      this.loading = true;
      this.obj.excecoes.push( this.excecao ); 
      this.service.save( this.obj ).subscribe(
        data => {
          this.ref.close( data );
          this.loading = false;
        },
        error => {
          this.service.handleError( error );
          this.loading = false;
        }
      )
    }
  }

  cancelar() {
    this.ref.close();
  }

}
