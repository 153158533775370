import { News, NewsLogs, NewsNovidades } from './../models/news';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractService } from './abstract.service';
import { LoginService } from './login.service';
import { HttpRequest, UriBuilder } from '../utilities/util/http-request';
import { Observable } from 'rxjs';
import { FormatUtils } from '../utilities/util/format-utils';

@Injectable({
  providedIn: 'root'
})
export class NewsService extends AbstractService<News> {

  titulo: string = 'News';
  endpoint(): string {
    return "admin/news";
  }
  rotina(): string {
    return "NEWS";
  }
  colunas(): any[] {
    return [
      { header: "Código", field: "id" },
      { header: "Assunto", field: "assunto" },
      { header: "Tipo", field: "tipo" },
      { header: "Versão", field: "versao" },
      { header: "Downloads", field: "downloads" },
      { header: "Visualizações", field: "visualizacoes" }
    ];
  }
  novo(selection?: any) {
    AbstractService.readonly = false;
    this.router.navigate(['news/cadastro']);
  }
  abrir(obj: News) {
    AbstractService.readonly = true;
    this.router.navigate(['news/cadastro'], { queryParams: { id: obj.id } });
  }
  edit(obj: News) {
    AbstractService.readonly = false;
    this.router.navigate(['news/cadastro'], { queryParams: { id: obj.id } });
  }
  routeLista() {
    return '/news';
  }

  newsLogsCount(id: number, tipo): Observable<number> {
    return new HttpRequest<number>(this.http)
      .endpoint(this.endpoint())
      .uri(`/logs/count/news-id/${id}/${tipo}`)
      .doGet();
  }

  allNewsLogs(id: number, tipo?): Observable<NewsLogs[]> {
    return new HttpRequest<NewsLogs[]>(this.http)
      .endpoint(this.endpoint())
      .uri(`/logs/all/news-id/${id}`)
      .doGet();
  }

  getPlanejamento(): Observable<NewsLogs[]> {
    return new HttpRequest<NewsLogs[]>(this.http)
      .endpoint(this.endpoint())
      .uri(`/planejamento/get`)
      .doGet();
  }

  savePlanejamento(p: any): Observable<any> {
    const url = this.baseUrl() + "/planejamento/save";
    const params = new URLSearchParams();
    params.append("planejamento", FormatUtils.toJson(p));

    const h = new HttpHeaders({
      Accept: "application/json; charset=utf-8",
      "Content-Type": "application/x-www-form-urlencoded",
      "X-Auth-Token": AbstractService.token(),
    });
    return this.http.post<any>(url, params.toString(), { headers: h, observe: 'response' });
  }

  uploadHtmls(id: number, content: string): Observable<any> {
    const url = this.baseUrl() + "/html/upload";
    const params = new URLSearchParams();
    params.append("id-news", id.toString());
    params.append("content", content);

    const h = new HttpHeaders({
      Accept: "application/json; charset=utf-8",
      "Content-Type": "application/x-www-form-urlencoded",
      "X-Auth-Token": AbstractService.token(),
    });
    return this.http.post<any>(url, params.toString(), { headers: h, observe: 'response' });
  }

  constructor(
    http: HttpClient,
    private router: Router,
    loginService: LoginService
  ) {
    super(http, loginService);
  }

  corrigeAtributos(t: any): News {
    t = super.corrigeAtributos(t)
    if ( !t.htmlInfo ) {
      t.htmlInfo = [];
    }
    return t;
  }

  getRotinas(): Observable<any> {
    return new HttpRequest<any>( this.http )
      .endpoint( this.endpoint() )
      .uri(
        new UriBuilder()
          .subpath( "novidades" )
          .subpath( "rotinas" )
          .build()
      )
      .doGet();
  }

  downloadFeedback( idNews: number ): Observable<any> {
    return new HttpRequest<any>( this.http )
      .endpoint( this.endpoint() )
      .uri( 
        new UriBuilder()
          .subpath( "feedback/resultado" )
          .subpath( idNews.toString() )
          .build()
      )
      .doGet()
  }

  convertCsv( csvBase64: string ): Observable<NewsNovidades> {
    return new HttpRequest<NewsNovidades>( this.http )
      .endpoint( this.endpoint() )
      .uri( "/novidades/convert-csv" )
      .param( "csv-base64", csvBase64 )
      .doPost();
  }
}
