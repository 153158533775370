import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'habil-balanceamento',
  templateUrl: './balanceamento.component.html',
  styleUrls: ['./balanceamento.component.scss']
})
export class BalanceamentoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
