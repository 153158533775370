<habil-grupo>
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-col p-col-12">
      <p-table [value]="list" selectionMode="single" [(selection)]="obj" [scrollable]="true" scrollHeight="400px" dataKey="id">
        <ng-template pTemplate="header">
          <tr>
            <th>CNPJ</th>
            <th>Nome</th>
            <th>Razão Social</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-at>
          <tr [pSelectableRow]="at">
            <td>{{ at.cpfCnpj }}</td>
            <td>{{ at.nome }}</td>
            <td>{{ at.razaoSocial }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>  
</habil-grupo>
<br />
<habil-grupo>
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-col p-col-8">
    </div>
    <div class="p-col p-col-2">
      <button pButton label="Selecionar" class="p-button-success" [disabled]="obj == null || obj == undefined" (click)="selecionar()" ></button>
    </div>
    <div class="p-col p-col-2">
      <button pButton label="Cancelar" (click)="cancelar()" ></button>
    </div>
  </div>
</habil-grupo>