import { Injectable } from '@angular/core';
import { Observable, onErrorResumeNext, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Municipio } from './municipio';
import { Endereco } from 'src/app/models/abstract-model';
import { AbstractService } from 'src/app/services/abstract.service';

@Injectable({
  providedIn: 'root'
})
export class EnderecoService {

  constructor(private http: HttpClient) { }

  _listaMunicipios: Municipio[] = [];


  buscaCep(cep: string): Observable<Endereco> {
    const url = 'https://viacep.com.br/ws/' + cep.replace('.', '').replace('-', '') + '/json/';
    return this.http.get<String>(url)
      .pipe(
        map(end => {
          var endereco: Endereco = {
            logradouro: '',
            numero: '',
            complemento: '',
            bairro: '',
            cep: '',
            pais: '',
            uf: '',
            municipio: ''
          };
          endereco.cep = end['cep'];
          endereco.municipio = end['localidade'];
          endereco.municipio = endereco.municipio.toUpperCase();
          endereco.bairro = end['bairro'];
          endereco.logradouro = end['logradouro'];
          endereco.complemento = '';
          endereco.uf = end['uf'];
          return endereco;
        }),
      );
  }

  buscaMunicipios(uf: string): Observable<String[]> {
    const url = environment.api + '/localizacao/lista-municipios/' + uf;
    return this.http.get<String[]>(url);
  }

  buscaTodosMunicipios(): Observable<Municipio[]> {
    const url = environment.api + '/localizacao/angular/lista-municipios';
    const h = new HttpHeaders({
      Accept: "application/json; charset=utf-8",
      "X-Auth-Token": AbstractService.token(),
    });
    return this.http.get<Municipio[]>(url, { headers: h });
  }

  buscaTodosPaises(): Observable<Municipio[]> {
    const url = environment.api + '/localizacao/lista-paises';
    return this.http.get<Municipio[]>(url);
  }

  carregaMunicipios(): Observable<boolean> {
    let sub: Subject<boolean> = new Subject();
    this.buscaTodosMunicipios().subscribe(m => {
      this._listaMunicipios = [...m];
      sub.next(true);
    }, error => {
      sub.error(false);
    });
    return sub;
  }


}
