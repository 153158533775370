import { ContagemEmissao, ContagemEmissaoView } from './../models/registro';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractService } from './abstract.service';
import { LoginService } from './login.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContagemEmissaoService extends AbstractService<ContagemEmissao> {

  titulo: string = 'Contagem de Emissões';
  endpoint(): string {
    return "admin/contagem-emissao";
  }
  rotina(): string {
    return "CONTAGEM_EMISSAO";
  }
  colunas(): any[] {
    return [
      { header: "Código", field: "id" },
    ];
  }
  novo(selection?: any) {
    AbstractService.readonly = false;
    this.router.navigate(['clientes/contagem/cadastro']);
  }
  abrir(obj: ContagemEmissao) {
    AbstractService.readonly = true;
    this.router.navigate(['clientes/contagem/cadastro'], { queryParams: { id: obj.id } });
  }
  edit(obj: ContagemEmissao) {
    AbstractService.readonly = false;
    this.router.navigate(['clientes/contagem/cadastro'], { queryParams: { id: obj.id } });
  }
  routeLista() {
    return '/clientes/contagem';
  }

  consulta(mesAno: string, classificacoes): Observable<ContagemEmissaoView[]> {
    const url = this.baseUrl() + "/consulta?mesAno=" + mesAno + '&classificacoes%5B%5D=' + classificacoes;

    const h = new HttpHeaders({
      Accept: "application/json; charset=utf-8",
      "X-Auth-Token": AbstractService.token(),
    });
    return this.http.get<ContagemEmissaoView[]>(url, { headers: h });
  }

  
  atualizarAgoraMes(mes: string, ano, notificarUsuario: boolean): Observable<any> {
    const url = this.baseUrl() + 
      "/atualizar-agora?mes=" + mes + 
      '&ano=' + ano + 
      "&notificar=" + (notificarUsuario ? "True" : "False" ) + 
      "&notificarUsuario=" + (notificarUsuario ? "True" : "False" );

    const h = new HttpHeaders({
      Accept: "application/json; charset=utf-8",
      "X-Auth-Token": AbstractService.token(),
    });
    return this.http.get<any>(url, { headers: h });
  }

  constructor(
    http: HttpClient,
    private router: Router,
    loginService: LoginService
  ) {
    super(http, loginService);
  }
}
