<div class="p-grid">
	<div class="p-col-12">

		<p-toast></p-toast>

		<div class="card">
			<p-toolbar styleClass="p-mb-4">
				<ng-template pTemplate="left">
					<button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success p-mr-2 p-mb-2" (click)="openNew()"></button>
					<button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-danger p-mb-2" (click)="deleteSelectedProducts()" [disabled]="!selectedProducts || !selectedProducts.length"></button>
				</ng-template>

				<ng-template pTemplate="right">
					<p-fileUpload mode="basic" accept="image/*" [maxFileSize]="1000000" label="Import" chooseLabel="Import" class="p-mr-2 p-mb-2 p-d-inline-block"></p-fileUpload>
					<button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help p-mb-2" (click)="dt.exportCSV()"></button>
				</ng-template>
			</p-toolbar>

			<p-table #dt [value]="products" [columns]="cols" [rows]="10" [paginator]="true" [globalFilterFields]="['name','country.name','representative.name','status']"
					 [(selection)]="selectedProducts" [rowHover]="true" dataKey="id"
					 styleClass="p-datatable-customers"
					 currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
				<ng-template pTemplate="caption">
					<div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
						<h5 class="p-m-0">Manage Products</h5>
						<span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
                    </span>
					</div>
				</ng-template>
				<ng-template pTemplate="header">
					<tr>
						<th style="width: 3rem">
							<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
						</th>
						<th pSortableColumn="name">Name <p-sortIcon field="name"></p-sortIcon></th>
						<th>Image</th>
						<th pSortableColumn="price">Price <p-sortIcon field="price"></p-sortIcon></th>
						<th pSortableColumn="category">Category <p-sortIcon field="category"></p-sortIcon></th>
						<th pSortableColumn="rating">Reviews <p-sortIcon field="rating"></p-sortIcon></th>
						<th pSortableColumn="inventoryStatus">Status <p-sortIcon field="inventoryStatus"></p-sortIcon></th>
						<th></th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-product>
					<tr>
						<td>
							<p-tableCheckbox [value]="product"></p-tableCheckbox>
						</td>
						<td><span class="p-column-title">Name</span>
							{{product.name}}
						</td>
						<td><span class="p-column-title">Image</span>
							<img [src]="'assets/demo/images/product/' + product.image" [alt]="product.name" width="100" class="p-shadow-4" />
						</td>
						<td><span class="p-column-title">Price</span>
							{{product.price | currency:'USD'}}
						</td>
						<td><span class="p-column-title">Category</span>
							{{product.category}}
						</td>
						<td><span class="p-column-title">Reviews</span>
							<p-rating [ngModel]="product.rating" [readonly]="true" [cancel]="false"></p-rating>
						</td>
						<td><span class="p-column-title">Status</span>
							<span [class]="'product-badge status-' + (product.inventoryStatus ? product.inventoryStatus.toLowerCase() : '')">{{product.inventoryStatus}}</span>
						</td>
						<td>
							<button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" (click)="editProduct(product)"></button>
							<button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="deleteProduct(product)"></button>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="summary">
					<div class="p-d-flex p-ai-center p-jc-between">
						In total there are {{products ? products.length : 0 }} products.
					</div>
				</ng-template>
			</p-table>
		</div>

		<p-dialog [(visible)]="productDialog" [style]="{width: '450px'}" header="Product Details" [modal]="true" styleClass="p-fluid">
			<ng-template pTemplate="content">
				<img [src]="'assets/demo/images/product/' + product.image" [alt]="product.image" class="product-image" *ngIf="product.image">
				<div class="p-field">
					<label for="name">Name</label>
					<input type="text" pInputText id="name" [(ngModel)]="product.name" required autofocus />
					<small class="p-invalid" *ngIf="submitted && !product.name">Name is required.</small>
				</div>
				<div class="p-field">
					<label for="description">Description</label>
					<textarea id="description" pInputTextarea [(ngModel)]="product.description" required rows="3" cols="20"></textarea>
				</div>

				<div class="p-field">
					<label class="p-mb-3">Category</label>
					<div class="p-formgrid p-grid">
						<div class="p-field-radiobutton p-col-6">
							<p-radioButton id="category1" name="category" value="Accessories" [(ngModel)]="product.category"></p-radioButton>
							<label for="category1">Accessories</label>
						</div>
						<div class="p-field-radiobutton p-col-6">
							<p-radioButton id="category2" name="category" value="Clothing" [(ngModel)]="product.category"></p-radioButton>
							<label for="category2">Clothing</label>
						</div>
						<div class="p-field-radiobutton p-col-6">
							<p-radioButton id="category3" name="category" value="Electronics" [(ngModel)]="product.category"></p-radioButton>
							<label for="category3">Electronics</label>
						</div>
						<div class="p-field-radiobutton p-col-6">
							<p-radioButton id="category4" name="category" value="Fitness" [(ngModel)]="product.category"></p-radioButton>
							<label for="category4">Fitness</label>
						</div>
					</div>
				</div>

				<div class="p-formgrid p-grid">
					<div class="p-field p-col">
						<label for="price">Price</label>
						<p-inputNumber id="price" [(ngModel)]="product.price" mode="currency" currency="USD" locale="en-US"></p-inputNumber>
					</div>
					<div class="p-field p-col">
						<label for="quantity">Quantity</label>
						<p-inputNumber id="quantity" [(ngModel)]="product.quantity"></p-inputNumber>
					</div>
				</div>
			</ng-template>

			<ng-template pTemplate="footer">
				<button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
				<button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveProduct()"></button>
			</ng-template>
		</p-dialog>

		<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
	</div>
</div>
