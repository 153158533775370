export const GREATER_THAN = 'GREATER_THAN';
export const LOWER_THAN = 'LOWER_THAN';
export const EQUAL = 'EQUAL';
export const LIKE = 'LIKE';
export const BETWEEN = 'BETWEEN';
export const GREATER_THAN_OR_EQUAL_TO = 'GREATER_THAN_OR_EQUAL_TO';
export const LOWER_THAN_OR_EQUAL_TO = 'LOWER_THAN_OR_EQUAL_TO';
export const IS_NULL = 'NULL';
export const NOT_NULL = 'NOT_NULL';
export const NOT_EQUAL = 'NOT_EQUAL';
export const ENUM_IN = 'ENUM_IN';
export const LIST_CONTAINS = 'LIST_CONTAINS';


export class SearchParams {
    paramName: string = '';
    paramValue: string[] = [];
    compareType?: string = EQUAL;

    constructor(paramName: string = '', paramValue: string[] = [], compareType: string = EQUAL) {
        this.paramName = paramName;
        this.paramValue = paramValue;
        this.compareType = compareType;
    }



}

export class SearchParamsBuilder {
    public _params: SearchParams[] = [];

    public addNew(param: SearchParams): SearchParamsBuilder {
        this._params.push(param);
        return this;
    }
    public add(paramName: string, paramValue: string[], compareType: string = EQUAL): SearchParamsBuilder {
        return this.addNew(new SearchParams(paramName, paramValue, compareType));
    }

    public build() : SearchParams[]{
        return [...this._params];
    }
}


