import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { LiberacaoUltimoCodigo } from 'src/app/models/liberacao';
import { LiberacaoService } from 'src/app/services/liberacao.service';

@Component({
  selector: 'habil-consulta-liberacoes-herp',
  templateUrl: './consulta-liberacoes-herp.component.html',
  styleUrls: ['./consulta-liberacoes-herp.component.scss']
})
export class ConsultaLiberacoesHerpComponent implements OnInit {

  cnpj: string;
  liberacao: LiberacaoUltimoCodigo = new LiberacaoUltimoCodigo();
  loading: boolean;

  getGuia(): string {
    if ( !this.liberacao.guia ) {
      return "Não"
    }
    return `Sim. Identificação do usuário: ${this.liberacao.guia}`;
  }

  buscar() {
    if ( !this.cnpj ) {
      this.msg.add({
        severity: "info",
        detail: "Informe o CNPJ para pesquisar",
        summary: "Informação"
      })
    }
    else {
      this.loading = true;
      this.service.consultaLiberacaoHerp( this.cnpj ).subscribe(
        retorno => {
          this.loading = false;
          if ( retorno ) {
            this.liberacao = retorno;
          }
          else {
            this.montaLiberacaoVazia();
            this.msg.add( {
              severity: "info",
              detail: "Nenhuma informação para esse CNPJ",
              summary: "Informação"
            })
          }
        },
        error => {
          this.loading = false;
          this.msg.add(
            {
              sticky: true,
              severity: "error",
              detail: error.error.value,
              summary: "Erro"
            }
          )
        }
      )
    }
  }

  private montaLiberacaoVazia() {
    this.liberacao = new LiberacaoUltimoCodigo();
    this.liberacao.cnpj = "(vazio)";
    this.liberacao.codigo = ""
  }

  constructor( private service: LiberacaoService, private msg: MessageService ) { }

  ngOnInit(): void {
    this.montaLiberacaoVazia()
  }

}
