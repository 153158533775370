<p-confirmDialog header="Atenção" icon="pi pi-exclamation-triangle">
</p-confirmDialog>
<p-toast></p-toast>
<div class="p-field p-col-12 p-md-12 p-lg-12" *ngIf="_loading">
    <p-progressBar mode="indeterminate" [style]="{'height': '6px'}"></p-progressBar>
</div>
<habil-grupo>
    <p-scrollPanel [style]="{width: '100%', height: getScrollHeight()}">
        <p-accordion>
            <p-accordionTab header="Dados Básicos" [selected]="true">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-lg-6">
                        <habil-label name="txtNome" label="Nome">
                            <input [readonly]="_readonly" type="text" name="txtNome" pInputText [(ngModel)]="obj.nome"/>
                        </habil-label>
                    </div>
                    <div class="p-field p-col-12 p-lg-6">
                        <habil-label name="txtCnpj" label="CPF / CNPJ">
                            <input [readonly]="_readonly" type="text" name="txtCnpj" pInputText [(ngModel)]="obj.cpfCnpj"/>
                        </habil-label>
                    </div>
                    <div class="p-field p-col-12 p-lg-6">
                        <habil-label name="txtTelefone" label="Telefone">
                            <input [readonly]="_readonly" type="text" name="txtTelefone" pInputText [(ngModel)]="obj.telefone"/>
                        </habil-label>
                    </div>
                    <div class="p-field p-col-12 p-lg-6">
                        <habil-label name="txtEmail" label="E-mail">
                            <input [readonly]="_readonly" type="text" name="txtEmail" pInputText [(ngModel)]="obj.email"/>
                        </habil-label>
                    </div>
                    <div class="p-field p-col-12">
                        <habil-endereco titulo='Endereço' [(ngModel)]="obj.endereco" [ngModelOptions]="{standalone: true}" [readonly]="_readonly"></habil-endereco>
                    </div>
                </div>
            </p-accordionTab>
        </p-accordion>
    </p-scrollPanel>
</habil-grupo>
<br>
<habil-botoes-cadastro (onError)="onErrorHandled()"  [obj]="getObject.bind(this)" [dialog]="_isDialog" (onSaved)="onSave($event)" (onClosed)="onCancel()" [service]="service" [readonly]="_readonly" [validate]="doValidate.bind(this)" [doBeforeSave]="doBeforeSave.bind(this)" (onSaveAndNew)="onSaveAndNew($event)">
</habil-botoes-cadastro>