import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AtendimentoComponent } from './atendimento.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { FormsModule } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { PanelModule } from 'primeng/panel';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { AutocompleteEditModule } from 'src/app/utilities/autocomplete-edit/autocomplete-edit.module';
import { DateEditModule } from 'src/app/utilities/date-edit/date-edit.module';
import { GrupoModule } from 'src/app/utilities/grupo/grupo.module';
import { LabelModule } from 'src/app/utilities/label/label.module';
import { AtendimentoExcecaoComponent } from './atendimento-excecao/atendimento-excecao.component';
import { AtendimentoClienteNovoComponent } from './atendimento-cliente-novo/atendimento-cliente-novo.component';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { AtendimentoBuscarComponent } from './atendimento-buscar/atendimento-buscar.component';



@NgModule({
  declarations: [
    AtendimentoComponent,
    AtendimentoExcecaoComponent,
    AtendimentoClienteNovoComponent,
    AtendimentoBuscarComponent
  ],
  imports: [
    CommonModule,
    TableModule,
    FormsModule,    
    GrupoModule,
    SkeletonModule,
    LabelModule,
    DateEditModule,
    DropdownModule,
    InputTextModule,
    ButtonModule,
    DialogModule,
    InputNumberModule,
    CheckboxModule,
    DynamicDialogModule,
    ToastModule,
    TooltipModule,
    CalendarModule,
    PanelModule,
    ScrollPanelModule,
    AccordionModule,
    ChipsModule,
    InputTextareaModule,
    BadgeModule,
    DividerModule,
    ClipboardModule,
    PickListModule,
    TagModule,
    MessagesModule,
    MessageModule,
    ProgressBarModule,
    AutocompleteEditModule
  ]
})
export class AtendimentoModule { }
