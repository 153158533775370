import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import {  Rotina } from 'src/app/models/eacesso-rotina';
import { Classificacao, RegistroProduto } from 'src/app/models/registro-produto';
import { AbstractService } from 'src/app/services/abstract.service';
import { CategoriaRelatorioService } from 'src/app/services/categoria-relatorio.service';
import { ClassificacaoService } from 'src/app/services/classificacao.service';
import { ProdutoService } from 'src/app/services/produto.service';
import { GenericCadastro } from 'src/app/utilities/generic-cadastro';
import { clone } from 'src/app/utilities/util/clone-utils';
import { FormValidation } from 'src/app/utilities/util/form-validation';

@Component({
  selector: 'habil-cadastro-produtos',
  templateUrl: './cadastro-produtos.component.html',
  styleUrls: ['./cadastro-produtos.component.scss'],
  providers: [MessageService, DialogService]
})
export class CadastroProdutosComponent extends GenericCadastro<RegistroProduto, ProdutoService> implements OnInit {

  tiposFrontEnd = [{ label: 'Retaguarda', value: 'RETAGUARDA' }, { label: 'Web', value: 'WEB' }];
  rotinas: Rotina[] = [];

  categorias: any[] = [];
 
  newObject(): RegistroProduto {
    let c: Classificacao = {
      cancelados: false,
      descricao: '',
      id: 0,
      idInterno: 0,
      creationDate: undefined,
      idHabil: '',
      lastModifiedDate: undefined,
      createdBy: 0,
      lastModifiedBy: 0,
      deleted: false
    };
    let obj: RegistroProduto = {
      deleted: false,
      acessosComDescricao: [],
      nomeProduto: '',
      codigo: '',
      acessos: [],
      ehGratuito: false,
      ehDemo: false,
      validade: 0,
      tipoFrontend: '',
      categoriasRelatorio: '',
      classificacao: c,
      id: 0,
      idInterno: 0,
      creationDate: undefined,
      idHabil: '',
      lastModifiedDate: undefined,
      createdBy: 0,
      lastModifiedBy: 0
    };
    return obj;
  }

  doValidate(): boolean {
    this.corrigeAtributos();
    this.obj.categoriasRelatorio = '';
    for (const cat of this.categorias) {
      if (cat.habilitado) this.obj.categoriasRelatorio += `${cat.id},`
    }
    this.obj.categoriasRelatorio += '*';
    this.obj.categoriasRelatorio = this.obj.categoriasRelatorio.replace(',*', '').replace('*', '');
    const validation: FormValidation = new FormValidation();
    validation.toValidate = [
      {
        condition: this.obj.nomeProduto != undefined && this.obj.nomeProduto.length > 0,
        message: "O nome é obrigatório!",
      }
    ];
    if (!validation.isValid()) {
      validation.message().forEach((m) => {
        this.messageService.add({
          severity: "warn",
          summary: "Atenção",
          detail: m,
        });
      });
    }
    return validation.isValid();
  }

  constructor(
    public service: ProdutoService,
    public messageService: MessageService,
    public dialogService: DialogService,
    public route: ActivatedRoute,
    public router: Router,
    public confirmationService: ConfirmationService,
    public titleService: Title,
    public breadcrumbService: BreadcrumbService,
    public classificacaoService: ClassificacaoService,
    public categoriaService: CategoriaRelatorioService
  ) {
    super();
    this.breadcrumbService.setItems([{label: 'Produtos', routerLink: '/adm/produtos'},{label: 'Cadastro'}]);
    this.categoriaService.findAll(0, 0, '','', [{paramName: 'deleted', paramValue: ['false']}]).subscribe(cat => {
      if (cat && cat.length > 0) {
        this.categorias = [...cat];
      }
    }, e => {
      this.categoriaService.handleError(e);
    })
  }

  carregado = false;

  ngOnInit(): void {
    if (this._isDialog) {
      this._loading = true;
      if (this._externalObj != null && this._externalObj != undefined) {
        this.obj = clone(this._externalObj);
        this.id = this.obj.id;
        this.idCampos = (this.id == undefined ? "0" : this.id.toString());
        this._loading = false;
      } else {
        this.obj = this.newObject();
        this.idCampos = (this.id == undefined ? "0" : this.id.toString());
        this.loadAuxObjects();
        this._loading = false;
      }
    } else {
      this.titleService.setTitle('Produtos - ' + AbstractService.app);
      super.ngOnInit();
    }
  }

  private carregaAcessos(): Promise<void> {
    return new Promise((sim, nao) => {
      this.service.getAcessos().subscribe({
        next: acessos => {
          this.rotinas = [];
          if ( acessos ) {
            acessos.forEach(a => {
              this.rotinas.push({
                label: a.description,
                value: a.value,
                routeCadastro: "",
                routeLista: "",
                temImpressao: false
              })
            });
          }
          sim();
        },
        error: (erro) => this.service.handleError(erro)
      })
    })
  }

  stopLoading(): void {
    this._loading = false;
    if (!this.carregado) {
      for (let cat of this.categorias) {
        cat.habilitado = this.obj.categoriasRelatorio.includes(`${cat.id}`);
      }
      
      this.obj.acessosComDescricao = [];
      //this.rotinas = [...EAcessoRotina]
      this.carregaAcessos().then( () => {
        this.rotinas.sort((a, b) => {
          if (a.label > b.label) {
            return 1;
          }
          if (a.label < b.label) {
            return -1;
          }
          return 0;
        });
        for (const rotina of this.obj.acessos) {
          let acesso = this.rotinas.find(r => r.value == rotina);
          if (!acesso) acesso = {label: rotina, value: rotina, temImpressao: null, routeCadastro: null, routeLista: null}
          this.obj.acessosComDescricao.push(acesso);
          this.rotinas = [...this.rotinas.filter(r => r.value != rotina)];
        }
        this.obj.acessosComDescricao.sort((a, b) => {
          if (a.label > b.label) {
            return 1;
          }
          if (a.label < b.label) {
            return -1;
          }
          return 0;
        });
        this.carregado = true;
      })
    }
  }

  doBeforeSave() {}

}
