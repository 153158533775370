<div class="exception-body">

    <!-- <div class="exception-topbar">
        <a href="#"  class="logo">
            <img src="assets/layout/images/ecuador-logo.png" alt="ecuador-layout"/>
        </a>
    </div> -->

    <div class="exception-panel">
        <div class="top">
            <img src="assets/layout/images/curvy-left.svg" alt="ecuador-layout" class="left"/>
            <!-- <img src="assets/img/shall-not.jpg" alt="ecuador-layout" class="main" /> -->
            <img src="assets/img/gandalf.gif" alt="ecuador-layout" class="main" />
            <!-- <img src="assets/layout/images/access.svg" alt="ecuador-layout" class="main"/> -->
            <img src="assets/layout/images/curvy-right.svg" alt="ecuador-layout" class="right"/>
            <div class="seperator"></div>
        </div>
        <div class="bottom">
            <span class="exception-panel-header">Acesso Negado</span>
            <span class="exception-panel-subheader">Você não tem autorização para acessar essa página!</span>
            <button type="button" pButton label="Voltar pra home" [routerLink]="['/']"></button>
        </div>
    </div>
</div>
