import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { AdminLog } from 'src/app/models/admin-usuario';
import { AbstractService } from 'src/app/services/abstract.service';
import { AdminLogService } from 'src/app/services/admin-log.service';
import { AdminUsuarioService } from 'src/app/services/admin-usuario.service';
import { DateUtils } from 'src/app/utilities/util/date-utils';
import { BETWEEN, SearchParams } from 'src/app/utilities/util/search/search-params';

@Component({
  selector: 'habil-log-operacoes',
  templateUrl: './log-operacoes.component.html',
  styleUrls: ['./log-operacoes.component.scss'],
  providers: [MessageService]
})

export class LogOperacoesComponent implements OnInit {

  opcoesTipo = [{ label: 'Alterou Registro', value: 'ALTEROU_REGISTRO' }, { label: 'Gerou contra-senha', value: 'GEROU_CONTRA_SENHA' }]
  filtro = { usuario: null, tipo: 'ALTEROU_REGISTRO', dataInicial: new Date(), dataFinal: new Date() }
  list: AdminLog[] = [];
  showDialog = false;
  textoDialog = '';

  constructor(public service: AdminLogService, 
      public userService: AdminUsuarioService,
      private breadcrumbService: BreadcrumbService,
      titleService: Title, public messageService: MessageService) {
    this.breadcrumbService.setItems([{label: 'Log de Operações'}]);
    titleService.setTitle('Log de Operações - ' + AbstractService.app);
  }

  ngOnInit(): void {
  }

  deserializeJson(jsonText) {
    if (jsonText.includes('OldData: ')){
      jsonText = jsonText.split('OldData: ')[1];
      try {
        const obj = JSON.parse(jsonText);
        jsonText = JSON.stringify(obj);
      } finally {
        return jsonText;
      }
    }
  }

  getTipo(tipo): string {
    return this.opcoesTipo.find(t => t.value == tipo)?.label;
  }
  
  buscar() {
    let searchParam: SearchParams[] = [];
    if (this.filtro.usuario) {
      searchParam.push({paramName: 'usuario.id', paramValue: [`${this.filtro.usuario.id}`]});
    }
    if (this.filtro.dataInicial && this.filtro.dataFinal) {
      searchParam.push({paramName: 'date', paramValue: [DateUtils.dateToString(this.filtro.dataInicial), DateUtils.dateToString(this.filtro.dataFinal)], compareType: BETWEEN});
    } else {
      this.messageService.add({severity: 'warn', summary: 'Atenção', detail: 'É necessário preencher as datas inicial e final!'})
    }
    searchParam.push({paramName: 'tipo', paramValue: [this.filtro.tipo]});
    this.service.findAll(0, 1000, '', '', searchParam).subscribe(lst => {
      if (lst) {
        this.list = [...lst];
      } else {
        this.list = [];
      }
    }, e => {
      this.service.handleError(e);
    })
  }

}
