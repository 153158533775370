<p-dialog header="Detalhes" [(visible)]="showDialog"  [style]="{width: '50vw'}">
    <p>{{textoDialog}}</p>
</p-dialog>
<habil-grupo>
  <div class="p-fluid p-grid">
    <div class="p-col">
        <habil-label name="searchUser" label="Usuário">
            <habil-autocomplete-edit
                [service]="userService"
                [(ngModel)]="filtro.usuario"
                name="searchUser"
                field="nome"
                dataKey="id">
            </habil-autocomplete-edit>
        </habil-label>
    </div>
    <div class="p-col">
        <habil-label name="tipo" label="Tipo">
            <p-dropdown
                optionLabel="label"
                optionValue="value"
                [options]="opcoesTipo" 
                [(ngModel)]="filtro.tipo" 
                [showClear]="false" 
                [filter]="false">
            </p-dropdown>
        </habil-label>
    </div>
    <div class="p-col">
        <habil-label name="dataInicial" label="Data Inicial">
            <habil-date-edit [(ngModel)]="filtro.dataInicial"></habil-date-edit>
        </habil-label>
    </div>
    <div class="p-col">
        <habil-label name="dataFinal" label="Data Final">
            <habil-date-edit [(ngModel)]="filtro.dataFinal"></habil-date-edit>
        </habil-label>
    </div>
    <div class="p-col p-col-align-end">
        <p-button label="Buscar" icon="pi pi-search" (onClick)="buscar()"></p-button>
    </div>
    <div class="p-col-12">
        <p-table [value]="list">
            <ng-template pTemplate="header">
                <tr>
                    <th>Operação</th>
                    <th>Data</th>
                    <th>Usuário</th>
                    <th>Detalhes</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-log>
                <tr>
                    <td>{{getTipo(log.operacao)}}</td>
                    <td>{{log.date | date: 'dd/MM/yyy'}}</td>
                    <td>{{log.usuario.nome}}</td>
                    <td>
                        <button pButton pRipple
                         type="button"
                         icon="pi pi-eye" 
                         class="p-button-rounded p-button-text"
                         (click)="showDialog = true; textoDialog = deserializeJson(log.descricao)">
                        </button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

</div>  
</habil-grupo>
