import {Component, Input, forwardRef, EventEmitter, Output, ViewChild, OnInit, Renderer2, ElementRef} from '@angular/core';
import { NG_VALUE_ACCESSOR, DefaultValueAccessor } from "@angular/forms";
import {MessageService, PrimeNGConfig} from 'primeng/api';
import { DateUtils } from '../util/date-utils';
import { clone } from '../util/clone-utils';


@Component({
  selector: "habil-date-edit",
  templateUrl: "./date-edit.component.html",
  styleUrls: ["./date-edit.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateEditComponent),
      multi: true,
    },
  ],
})
export class DateEditComponent extends DefaultValueAccessor implements OnInit {
  onChange: (_: any) => void;
  onTouched: () => void;

  @Input('nome')
  name: string;
  @Input("showTime")
  _showTime: boolean = false;
  @Input("permitirDatasAnteriores")
  _permitirDatasAnteriores = true;

  @Input("placeholder")
  _placeholder: string;

  @Input('readonly')
  _readonly: boolean = false;  

  @Input('disabled')
  _disabled: boolean = false;

  ptBr = DateUtils.ptBr;

  val: any;

  @Output('onBlur')
  _onBlur: EventEmitter<any> = new EventEmitter();
  @Output('onChange')
  _onChange: EventEmitter<any> = new EventEmitter();

  @ViewChild('dateKs')
  _ksDate: ElementRef;

  _minDate: Date;

  _lastString = '';

  constructor(
    private messageService: MessageService,
    _renderer: Renderer2,
    _elementRef: ElementRef,
    private config: PrimeNGConfig) {
    super(_renderer, _elementRef, false);
  }

  onFocus() {
    document.getElementsByName('dateKs')[0].focus()
  }

  ngOnInit(): void {
    this.config.setTranslation({
      dayNames: [
        "Domingo",
        "Segunda-Feira",
        "Terça-Feira",
        "Quarta-Feira",
        "Quinta-Feira",
        "Sexta-Feira",
        "Sábado",
      ],
      dayNamesShort: ["Dom", "Deg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
      dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
      monthNames: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      monthNamesShort: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
      today: "Hoje",
      clear: "Limpar",

    });
    if (!this._permitirDatasAnteriores) {
      this._minDate = new Date();
    } else {
      this._minDate = new Date("1900/01/01");
    }

  }

  set value(val: Date) {
    let d: string;
    if (val != null) {
      d = val.toString();
      if (d == "Invalid Date") {
        this.val = new Date();
        this.onChange(this.val);
        this.onTouched();
        return
      }
      let dt = '';
      if (d.split('/').length == 3 && !d.includes(':')) {
        dt = dt + d.split('/')[2] + '-';
        dt = dt + d.split('/')[1] + '-';
        dt = dt + d.split('/')[0];
        val = new Date(dt);
      }else if (d.split('/').length == 3 && (d.length == 16 || d.length == 19)){
        dt = dt + d.split('/')[2].split(' ')[0] + '-';
        dt = dt + d.split('/')[1] + '-';
        dt = dt + d.split('/')[0] + ' ' + d.split(" ")[1];
        dt = dt + d.split('/')[0] + ' ' + d.split(" ")[1];
        if (dt.length == 16) {
          dt = dt + ':00';
        }
        val = new Date(dt);
      }
    } else {
      this.val = val;
      this.onChange(this.val);
      this.onTouched();
      return
    }

    if (typeof val == "string") {

      // if (val != "") {
      //   let sVal: string = val;
      //   if (sVal.indexOf("/") < 0 && sVal.length == 8) {
      //     sVal = sVal.replace(
      //       /^(\d{2})(\d{2})(\d{4})/,
      //       "$3-$2-$1 00:00:00.000-0300"
      //     );
      //   } else if (sVal.indexOf("-") > 0 && sVal.length == 10) {
      //     sVal += " 00:00:00.000-0300";
      //   }
      //   if (sVal != "" && !this._showTime) {
      //     this.val = new Date(sVal);
      //   } else {
      //     this.val = undefined;
      //   }
      // } else {
      //   this.val = undefined;
      // }
      this.val = val;
    } else {
      this.val = val;

    }
    // if (this.val == null) {
    //   this.val = this._lastString;
    // }
    if (this.val != undefined) {
      if (this._showTime && typeof this.val != "string") {
        this._lastString = DateUtils.dateTimeToString(this.val);
      }else if (!this._showTime && typeof this.val != "string") {
        this._lastString = DateUtils.dateToString(this.val);
      }
    }

    this.onChange(this.val);
    this.onTouched();
  }

  keyUp(event) {
    try {
      if (event.key == 'Tab') {
        if (this.txtData.toString().length == 6) {
          const val = this.txtData.toString().split('/');
          this.val = new Date(new Date().getFullYear(), parseInt(val[1]) -1, parseInt(val[0]));
          this.onChange(this.val);
        }
      }
    } catch (ignored) { }

  }

  get value() {
    //console.log(this.val)
    return this.val;
    //new Date(this.val);
  }

  writeValue(value: any): void {
    if (typeof value == 'string') {
      value = new Date(value)
    }
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  onBlur(event) {
    try {
      if (this.val != undefined) {
        if (this.val.toString().includes('-') && this.val.toString().length == 10){
          this.val = new Date(this.val.toString() + " 00:00:00");
          this.onChange(this.val);
        }
        if (this.val.toString().includes('-') && this.val.toString().length == 19){
          this.val = new Date(this.val.toString());
          this.onChange(this.val);
        }

        if (this.val.toString().length < 10 && !this._showTime) {
          this.val = undefined;
          this.onChange(this.val);
        }
        if (this.val.toString().length < 16 && this._showTime) {
          this.val = undefined;
          this.onChange(this.val);
        }
        // let d = this.val.toString();
        // let dt = '';
        // if (d.split('/').length == 3 && (d.length == 16 || d.length == 19)){
        //   dt = dt + d.split('/')[2].split(' ')[0] + '-';
        //   dt = dt + d.split('/')[1] + '-';
        //   dt = dt + d.split('/')[0] + ' ' + d.split(" ")[1];
        //   dt = dt + d.split('/')[0] + ' ' + d.split(" ")[1];
        //   if (dt.length == 16) {
        //     dt = dt + ':00';
        //   }
        //   this.val = new Date(dt);
        //   this.onChange(this.val);
        //   this.onTouched();
        // }
      }
    } catch (e) {

    }
    try {
      if (this.value != null) {
        this.val.getMonth();
      } else  {
        this.value = undefined;
        this.onChange(null);
      }
    } catch (e) {
      this.val = null;
      this.messageService.add({severity: 'warn', summary: 'Atenção', detail: 'Data inválida!'});
    }

    this._onBlur.emit(event);
  }

  txtData = '';

  doOnChange(event) {
    let txtData: string = event?.target?.value;
    // console.log(event)
    if (!this._showTime && txtData && event.inputType != "deleteContentBackward") {
      switch(txtData?.length){
        case 2:
          txtData += '/'
          break;
        case 5:
          txtData += '/'
          break;
      }
      event.target.value = txtData;
    } else if (this._showTime && txtData && event.inputType != "deleteContentBackward") {
        switch(txtData?.length){
          case 2:
            txtData += '/'
            break;
          case 5:
            txtData += '/'
            break;
          case 10:
            txtData += ' '
            break;
          case 13:
            txtData += ':'
            break;
        }
      event.target.value = txtData;
    } else if (event.inputType == "deleteContentBackward") {
      if (txtData != null && txtData != "") {
        switch(txtData?.length){
          case 2:
          case 5:
          case 10:
          case 13:
            event.target.value = txtData.substring(0, txtData.length - 1);
            this._lastString = txtData.substring(0, txtData.length - 1);
        }

      }else {
        switch(txtData?.length){
          case 2:
          case 5:
          case 10:
          case 13:
            event.target.value = this._lastString.substring(0, this._lastString.length - 1);
            this._lastString = this._lastString.substring(0, this._lastString.length - 1);
        }

      }

    }
    this.txtData = clone(txtData);
    this._onChange.emit(event);

  }



}
