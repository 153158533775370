import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { MessageService } from "primeng/api";
import { AbstractTelefone } from "src/app/models/abstract-model";
import { FormatUtils } from "../util/format-utils";

@Component({
  selector: "habil-grid-fones",
  templateUrl: "./grid-fones.component.html",
  styleUrls: ["./grid-fones.component.scss"],
  providers: [MessageService],
})
export class GridFonesComponent implements OnInit {
  constructor(private messageService: MessageService) {
    this.fones = [];
  }

  @Input()
  fones: AbstractTelefone[] = [];
  @Input("readonly")
  _readonly: boolean = false;

  tipos = [{label: "Residencial", value: "Residencial"},
          {label: "Comercial", value: "Comercial"},
          {label: "Celular", value: "Celular"},
          {label: "Outro", value: "Outro"}]

  selectedFone: AbstractTelefone;

  clonedFones: { [id: number]: AbstractTelefone } = {};
  tempId = 0;

  @Output()
  onChange: EventEmitter<AbstractTelefone[]> = new EventEmitter<
  AbstractTelefone[]
  >();

  ngOnInit() {
    if (this.fones.length > 0) {
      this.fones.forEach((f) => {
        if (Math.abs(f.id) < Math.abs(this.tempId)) {
          this.tempId = Math.abs(f.id) * -1;
        }
      });
    }
  }

  novoFone() {
    this.tempId--;
    let fone: AbstractTelefone = {
      padrao: false,
      tipo: "",
      ddd: "",
      numero: "",
      ramal: "",
      observacoes: "",
      id: 0,
      idInterno: 0,
      creationDate: undefined,
      idHabil: "",
      lastModifiedDate: undefined,
      createdBy: 0,
      lastModifiedBy: 0,
      deleted: false
    }
    fone.id = this.tempId;

    this.fones.push(fone);
    this.onChange.emit(this.fones);
  }

  onRowEditInit(fone: AbstractTelefone) {
    this.clonedFones[fone.id] = { ...fone };
  }

  onRowEditSave(fone: AbstractTelefone) {
    if (fone == undefined || fone == null) {
      return;
    }
    delete this.clonedFones[fone.id];
    console.log(FormatUtils.toJson(this.fones));
    this.onChange.emit(this.fones);
  }

  onRowEditCancel(fone: AbstractTelefone, index: number) {
    this.fones[index] = this.clonedFones[fone.id];
    delete this.clonedFones[fone.id];
  }

  deleteFone() {
    if (this.selectedFone != undefined && this.selectedFone != null) {
      let index = this.fones.indexOf(this.selectedFone);
      this.fones = this.fones.filter((val, i) => i != index);
      this.selectedFone = null;
      this.onChange.emit(this.fones);
    } else {
      this.messageService.add({
        severity: "info",
        summary: "Atenção",
        detail: "Selecione um item para excluir",
      });
    }
  }
}
