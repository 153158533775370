
import { DropdownModule } from 'primeng/dropdown';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { FormsModule } from '@angular/forms';
import { GridFonesComponent } from './grid-fones.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';



@NgModule({
  declarations: [
    GridFonesComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    TableModule,
    MessagesModule,
    MessageModule,
    DropdownModule,
    MessagesModule,
    MessageModule,
    InputTextModule,
    CheckboxModule
  ],
  exports:[
    GridFonesComponent
  ],
  providers: [
    MessageService
  ]
})
export class GridFonesModule { }
