<p-toast></p-toast>
<habil-grupo>
  <div class="p-fluid p-grid">
    <div class="p-col-10">
      <habil-label label="Pesquisa por CNPJ" name="pesquisacnpj">
        <input pInputText [(ngModel)]="cnpj" (keydown.enter)="buscar()" />
      </habil-label>
    </div>
    <div class="p-col-2 p-col-align-end">
      <button
        [loading]="loading"
        pButton
        icon="pi pi-search"
        label="Pesquisar"
        (click)="buscar()"
      ></button>
    </div>
  </div>
</habil-grupo>
<br />
<habil-grupo>
  <div class="p-fluid p-grid">
    <div class="p-field p-col-12">
      <habil-label name="cnpj" label="CNPJ">
        <input pInputText [value]="liberacao.cnpj" [readonly]="true" />
      </habil-label>
    </div>
    <div class="p-field p-col-12">
      <habil-label name="data" label="Liberado em">
        <input
          pInputText
          [value]="liberacao.data | date: 'dd/MM/yyyy hh:mm:ss'"
          [readonly]="true"
        />
      </habil-label>
    </div>
    <div class="p-field p-col-12">
      <habil-label name="vcto" label="Vencimento">
        <input
          pInputText
          [value]="liberacao.vcto | date: 'dd/MM/yyyy'"
          [readonly]="true"
        />
      </habil-label>
    </div>
    <div class="p-field p-col-12">
      <habil-label name="codigo" label="Código de liberação">
        <input pInputText [value]="liberacao.codigo" [readonly]="true" />
      </habil-label>
    </div>
    <div class="p-field p-col-12">
      <habil-label name="guia" label="Foi lido pelo usuário?">
        <input pInputText [value]="getGuia()" [readonly]="true" />
      </habil-label>
    </div>
  </div>
</habil-grupo>
