import { SelectItem } from "primeng/api";
import { AbstractModel, AbstractTelefone, Endereco } from "./abstract-model";
import { Parceiro } from "./admin-usuario";
import { Classificacao } from "./registro-produto";

export interface Registro extends AbstractModel {
    nome: string;
    cnpj: string;
    email: string;
    endereco: Endereco;
    responsavel: string;
    telefones: AbstractTelefone[];
    licencas: Licenca[];
    dadosAcesso?: RegistroDadosAcesso;
    parceiro?: Parceiro;
    classificacao?: Classificacao;
    cancelamento: Date,
    ignorar?: boolean;
    indicacao?: string;
    indicacaoOutros?: string;
    areaAtuacao?: string;
    areaAtuacaoOutros?: string;
    nroFuncionarios?: string;
    principaisNecessidades?: string;
    principaisNecessidadesOutros?: string;
}

export interface RegistroDadosAcesso {
    data: Date;
    onde: string;
}

export interface RegistroDadosPrimeiroAcesso extends AbstractModel {
  registro: Registro;
  software: string;
  nomeUsuario: string;
  emailUsuario: string;
  dataHoraPrimeiroAcesso: Date;
  ipMaquina: string;
}

export interface RegistroDadosAlteracoes extends AbstractModel {
  registro?;
  cnpj: string;
  telefones: any[];
  email: string;
  homePage: string;
}

export interface Licenca extends AbstractModel {
  software: string;
  dbToken: string;
  modulosLicenciados: LicencaModulos[];
  licenceKey: string;
  timestamp: string;
  personalizada: boolean;
  usouDemo: boolean;
  tipo: string;
}

export interface LicencaModulos extends AbstractModel {
  idModulo: number;
  licenca?: any;
  descricaoModulo?: string;
}

export interface RegistroLicenca {
  expDate: Date;
  nroLicencas: number;
  empresas?: number;
  boletos: number;
  acessos: string[];
  emissoes: RegistroLicencaEmissao[];
  adicionais: RegistroLicencaAdicional[];
  categoriasRelatorio: string;
}

export interface RegistroLicencaEmissao {
  documento: string;

  nfe: boolean;
  vctoNfe: Date;
  qtdeDocsNfe: number;

  nfce: boolean;
  vctoNfce: Date;
  qtdeDocsNfce: number;

  nfs: boolean;
  vctoNfs: Date;
  qtdeDocsNfs: number;

  sat: boolean;
  vctoSat: Date;
  qtdeDocsSat: number;
}

export interface RegistroLicencaAdicional {
  documento: string;
  vcto: Date;
  modulo: string;
  licencas: number;
}

export interface ConsultaClienteDTO {
  id: number;
  cnpj: string;
  nome: string;
  email: string;
  classificacao: string;
  vencimento: Date;
  apagado: boolean;
  cadastro: Date;
  tipo: string;
}

export interface ContagemEmissaoView extends ContagemEmissao {
  nome: string;
  email: string;
  detalhes: ContagemEmissao[];
  excedente?;
}

export interface ContagemEmissao extends AbstractModel {
  registro?: any;
  cnpj: string;
  mesAno: string;
  tipoEmissao: string;
  contagem: number;
  contagemAtual: number;
}

export const ELicencaTipo: SelectItem[] = [
  { label: "Pago", value: "PAGO" },
  { label: "Gratuito", value: "FREE" },
  { label: "Demonstração", value: "DEMO" },
];
