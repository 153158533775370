<!-- <p-messages></p-messages> -->
<div class="p-d-block p-d-md-none">
  <habil-grupo [dif]="0" class="botoes-cadastro">
    <div class="p-fluid p-formgrid p-grid p-jc-end" style="height: 45px;" (keydown)="handleKeyboardEvent($event)">
      <div class="p-col-fixed" style="width: 130px">
        <button pButton icon="pi pi-times" [label]="(readonly ? 'Voltar' : 'Cancelar')" class="p-button-outlined p-button-danger" title="Esc" (click)="cancelar()" pRipple></button>
      </div>
      <div class="p-col" *ngIf="!btnFinal"></div>
      <div *ngIf="!btnFinal">
        <ng-content></ng-content>
      </div>

      <div class="p-col" *ngIf="btnFinal">
        <ng-content></ng-content>
      </div>

      <div class="p-col-fixed" style="width: 120px" *ngIf="!readonly">
        <button pButton icon="pi pi-check" [loading]="_loading" class="p-button-success" label="Salvar" (click)="salvar()" pRipple *ngIf="!readonly"></button>
      </div>
    </div>
  </habil-grupo>
</div>
<div class="p-d-none p-d-md-block">
  <habil-grupo [dif]="0" class="botoes-cadastro">
    <div class="p-fluid p-formgrid p-grid p-jc-end" style="height: 45px;" (keydown)="handleKeyboardEvent($event)">
      <div class="p-col-2">
        <button pButton icon="pi pi-times" [label]="(readonly ? 'Fechar' : 'Cancelar')" class="p-button-outlined p-button-danger" title="Esc" (click)="cancelar()" pRipple></button>
      </div>
      <div class="p-col" *ngIf="!btnFinal"></div>
      <div *ngIf="!btnFinal">
        <ng-content></ng-content>
      </div>

      <div class="p-col" *ngIf="btnFinal">
        <ng-content></ng-content>
      </div>
      <div class="p-col-2" *ngIf="!readonly && !isDialog" style="min-width: 177px">
        <button pButton icon="pi pi-undo" label="Salvar e Inserir" class="p-button-outlined p-button-success" title="Ctrl + Shift + I" (click)="salvarNovo()" pRipple *ngIf="!readonly"></button>
      </div>
      <div class="p-col-2" *ngIf="!readonly && !contaPaga && (service.rotina() == 'CONTA_RECEBER' || service.rotina() == 'CONTA_PAGAR')" style="min-width: 179px">
        <button pButton icon="pi pi-check-square" label="Salvar e Baixar" class="p-button-outlined p-button-success" (click)="salvarBaixar()" pRipple *ngIf="!readonly"></button>
      </div>

      <div class="p-col-2" *ngIf="!readonly">
        <button pButton icon="pi pi-check" [loading]="_loading" label="Salvar" title="Ctrl + Shift + S" class="p-button-success" (click)="salvar()" pRipple *ngIf="!readonly"></button>
      </div>
    </div>
  </habil-grupo>
</div>


<p-confirmDialog header="Atenção" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<!-- <p-toast></p-toast> -->
