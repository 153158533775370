import { NewsService } from './../../services/news.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { AbstractService } from 'src/app/services/abstract.service';
import { clone } from 'src/app/utilities/util/clone-utils';
import { BaseListaComponent } from 'src/app/utilities/base-lista/base-lista.component';
import { News } from 'src/app/models/news';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { EMime, FileUtils } from 'src/app/utilities/util/file-utils';

@Component({
  selector: 'habil-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class NewsComponent implements OnInit {

  @ViewChild('base')
  base: BaseListaComponent<any>;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['bold']
      ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  logs = [];
  showEstatisticas = false;
  showPlanejamento = false;
  loading = false;
  planejamento: any = {texto: ''};
  selection: News = {
    assunto: '',
    tipo: '',
    aPartirDe: undefined,
    validade: undefined,
    software: '',
    modulos: [],
    cnpjs: [],
    ufs: [],
    versao: '',
    versaoCompareType: '',
    nfe: '',
    nfce: '',
    nfse: '',
    cupom: '',
    somenteParaAdmins: false,
    bannerInfo: undefined,
    mensagemInfo: undefined,
    novidadesInfo: null,
    pesquisaInfo: null,
    htmlInfo: [],
    cancelado: false,
    read: false,
    id: 0,
    idInterno: 0,
    creationDate: undefined,
    idHabil: '',
    lastModifiedDate: undefined,
    createdBy: 0,
    lastModifiedBy: 0,
    deleted: false
  }

  constructor(
    private breadcrumbService: BreadcrumbService,
    public service: NewsService, titleService: Title,
    public confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {
    this.breadcrumbService.setItems([{label: 'News'}]);
    titleService.setTitle('News - ' + AbstractService.app);
  }

  acoes = [
    {
      label: 'Cancelar News',
      command: () => {
        this.confirmationService.confirm({
          header: 'Atenção',
          message: 'Deseja realmente cancelar?',
          rejectLabel: 'Não',
          acceptLabel: 'Sim',
          accept: () => {
            this.base._loading = true;
            this.service.findById(this.base._selection.id).subscribe(ret => {
              ret.cancelado = true;
              this.service.save(ret).subscribe(s => {
                this.base._loading = false;
                this.base.reset();
              }, e => {
                this.base._loading = false;
                this.service.handleError(e);
              })
            }, e => {
              this.base._loading = false;
              this.service.handleError(e);
            })
          }
        })
      }
    },
    {
      label: 'Estatísticas detalhadas',
      command: () => {
        this.loading = true;
        this.showEstatisticas = true;
        this.service.allNewsLogs(this.base._selection.id).subscribe(baixados =>{
          this.selection = {...this.base._selection};
          this.loading = false;
          if (baixados) {
            for (const b of baixados) {
              const index = this.logs.findIndex(l => l.cnpj == b.registro?.cnpj);
              if (index == -1) {
                let log = { cnpj: b.registro?.cnpj, download: null, view: null }
                if (b.tipoLog == 'BAIXADO') {
                  log.download = new Date(b.dhOperacao)
                } else {
                  log.view = new Date(b.dhOperacao);
                }
                this.logs.push(log);
              } else {
                if (b.tipoLog == 'BAIXADO') {
                  this.logs[index].download = new Date(b.dhOperacao)
                } else {
                  this.logs[index].view = new Date(b.dhOperacao);
                }
              }

            }
          }
        });
      }
    },
    {
      label: 'Planejamento anual',
      command: () => {
        this.service.getPlanejamento().subscribe(p => {
          this.planejamento = {...p};
          this.showPlanejamento = true;
        });
      }
    },
    {
      label: "Baixar feedback da pesquisa",
      command: () => {
        if ( this.base._selection.tipo == "PESQUISA" ) {
          this.service.downloadFeedback( this.base._selection.id ).subscribe(
            data => {
              if ( data && data.value ) {
                // const mime = "data:application/text/csv,";
                // let file = mime.concat( data.value );
                FileUtils.downloadBas64( data.value, EMime.CSV, "pesquisa.csv" );
              }
              else {
                this.messageService.add({
                  detail: "Ainda não existe feedback dessa pesquisa!",
                  icon: "info",
                  severity: "info"
                })
              }
            }
          )
        }
        else {
          this.messageService.add( {
            detail: "A news selecionada não é do tipo PESQUISA!",
            icon: "info",
            severity: "info"
          } )
        }
      }
    }
  ];

  montaObjeto: Function = (objs) => {
    for (let obj of objs) {
      if (obj.cancelado) obj.assunto = 'CANCELADO: ' + obj.assunto;
      this.service.newsLogsCount(obj.id, 'BAIXADO').subscribe(count => {
        obj.downloads = clone(count);
      });
      this.service.newsLogsCount(obj.id, 'LIDO').subscribe(count => {
        obj.visualizacoes = clone(count);
      });
    }
    return objs;
  };

  salvarPlanejamento() {
    this.service.savePlanejamento(this.planejamento).subscribe(ret=>{
      this.showPlanejamento = false;
    });
  }

  ngOnInit(): void {

  }

}
