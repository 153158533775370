import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Atendimento, AtendimentoExcecao } from 'src/app/models/atendimento';
import { AtendimentoService } from 'src/app/services/atendimento.service';
import { LoginService } from 'src/app/services/login.service';
import { FormatUtils } from 'src/app/utilities/util/format-utils';
import { AtendimentoBuscarComponent } from './atendimento-buscar/atendimento-buscar.component';
import { AtendimentoClienteNovoComponent } from './atendimento-cliente-novo/atendimento-cliente-novo.component';
import { AtendimentoExcecaoComponent } from './atendimento-excecao/atendimento-excecao.component';
import { clone } from 'src/app/utilities/util/clone-utils';
import { LiberacaoService } from 'src/app/services/liberacao.service';
import { FollowUp } from 'src/app/models/follow-up';

@Component({
  selector: 'habil-atendimento',
  templateUrl: './atendimento.component.html',
  styleUrls: ['./atendimento.component.scss']
})
export class AtendimentoComponent implements OnInit {

  valor: string;
  obj: Atendimento = new Atendimento();
  loading: boolean = false;
  excecoes: AtendimentoExcecao[] = [];
  atendimentos: FollowUp[] = [];
  resumo: string;
  showResumo = false;

  filtroFollowUp = {
    inicial: new Date(),
    final: new Date()
  }

  constructor(
    private service: AtendimentoService,
    private loginService: LoginService,
    private message: MessageService,
    private dialogService: DialogService,
    private liberacaoService: LiberacaoService,
    private route: ActivatedRoute
  ) { }

  buscarAtendimentos() {
    this.liberacaoService.getFollowUps(this.filtroFollowUp.inicial, this.filtroFollowUp.final, this.obj.cpfCnpj).subscribe({
      next: (val) => {
        this.atendimentos = [...val];
        console.log(val);
      },
      error: (err) => {
        this.service.handleError(err);
      }
    })
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(p => {
      if (p.valor) {
        this.valor = clone(p.valor);
        this.buscar()
      }
    });


    this.filtroFollowUp.inicial.setMonth(this.filtroFollowUp.inicial.getMonth() - 6)
  }

  verResumo(resumo: string) {
    this.resumo = resumo;
    this.showResumo = true;
  }

  lancarExcecao() {
    this.dialogService.open(AtendimentoExcecaoComponent, {
      header: "Lançar Exceção",
      width: '50%'
    }).onClose.subscribe(
      ex => {
        if (ex) {
          this.service.salvaExcecao(this.obj.id, ex).subscribe(
            () => {
              this.excecoes.unshift(ex);
            },
            error => {
              this.service.handleError(error);
            }
          )
        }
      }
    )
  }

  buscarExcecoes(start?: number, length?: number) {
    if (!length) {
      length = 20;
    }
    if (!start) {
      start = 0;
    }
    this.service.getExcecoes(this.obj.id, start, length).subscribe(
      list => {
        this.excecoes = [...list];
      }
    )
  }

  private zera() {
    this.obj = new Atendimento();
    this.excecoes = [];
    this.atendimentos = [];
  }

  private cadastraNovo() {
    this.loginService.getGlobal().subscribe(
      cfg => {
        if (cfg.centralCliente.idPlanoSuporte > 0) {
          this.dialogService.open(
            AtendimentoClienteNovoComponent, {
            header: "Novo atendimento",
            data: {
              idPlanoSuporte: cfg.centralCliente.idPlanoSuporte
            }
          }
          )
            .onClose.subscribe(
              data => {
                if (data) {
                  this.obj = data;
                }
              }
            )
        }
        else {
          this.message.add(
            {
              detail: "Cliente não localizado!",
              severity: "info",
              summary: "Informação"
            }
          )
        }
      }
    );
  }

  buscar() {
    this.valor = FormatUtils.clear(this.valor, "./-");
    if (!this.valor) {
      this.message.add(
        {
          detail: "CNPJ, Nome ou Razão Social obrigatório!",
          severity: "info",
          summary: "Informação"
        }
      )
    }
    else {
      this.loading = true;
      this.zera();
      this.service.localizar(this.valor).subscribe(
        data => {
          if (data) {
            if (data.length == 1) {
              this.selecionaAtendimento(data[0]);
            }
            else {
              this.dialogService.open(
                AtendimentoBuscarComponent, {
                header: "Vários clientes encontrados",
                width: '80%',
                data: data
              }
              )
                .onClose.subscribe(
                  at => {
                    if (at) {
                      this.selecionaAtendimento(at);
                    }
                  }
                )
            }

          }
          else {
            this.cadastraNovo();
          }
          this.loading = false;
        },
        error => {
          this.service.handleError(error);
          this.loading = false;
        }
      )
    }
  }

  private selecionaAtendimento(atendimento: Atendimento) {
    this.obj = atendimento;
    this.buscarAtendimentos();
    this.buscarExcecoes();
  }

  implantacao(): boolean {
    if (this.obj.primeiraLiberacao) {
      const primeiraLiberacao = new Date(this.obj.primeiraLiberacao);
      const currentDate = new Date();
      const diffInMilliseconds = currentDate.getTime() - primeiraLiberacao.getTime();
      const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);
      return diffInDays <= 60;
    }
    return false;
  }

  fornecerSuporte(): boolean {
    if (!this.obj.id) {
      return false;
    }
    const hoje = new Date();
    hoje.setHours(0, 0, 0, 0);
    if (this.obj.inicio > hoje || this.obj.fim < hoje) {
      return false;
    }
    return true;
  }

}
