<div *ngIf="rotina == 'USUARIO' && inicializado">
    <habil-cadastro-admin-usuario [value]="obj" [dialog]="true" [readonly]="_readonly" (saving)="onSave($event)" (cancel)="onCancel()"></habil-cadastro-admin-usuario>
</div>
<div *ngIf="rotina == 'PARCEIROS' && inicializado">
    <habil-cadastro-parceiro [value]="obj" [dialog]="true" [readonly]="_readonly" (saving)="onSave($event)" (cancel)="onCancel()"></habil-cadastro-parceiro>
</div>
<div *ngIf="rotina == 'PRODUTOS' && inicializado">
    <habil-cadastro-produtos [value]="obj" [dialog]="true" [readonly]="_readonly" (saving)="onSave($event)" (cancel)="onCancel()"></habil-cadastro-produtos>
</div>
<div *ngIf="rotina == 'CLASSIFICACOES' && inicializado">
    <habil-cadastro-classificacao [value]="obj" [dialog]="true" [readonly]="_readonly" (saving)="onSave($event)" (cancel)="onCancel()"></habil-cadastro-classificacao>
</div>

