<div class="p-grid p-fluid">
  <div class="p-field p-col-fixed" style="width: 40px">
    <i class="pi pi-times-circle" style="font-size: 2rem; color: #d32300"></i>
  </div>
  <div class="p-field p-col">
    <div [innerHTML]="textoErro"></div>
  </div>
</div>
<hr>
<div class="p-grid p-fluid">
  <div class="p-field p-col-6" style="margin: 0">
    <!-- <button pButton label="Sair do sistema" (click)="sairDoHabil()" *ngIf="showDeslogar">
    </button> -->
  </div>
  <div class="p-field p-col-6" style="margin: 0">
    <button pButton label="Continuar" (click)="continuar()">
    </button>
  </div>
</div>
