import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { AtendimentoExcecao } from 'src/app/models/atendimento';
import { DateValidationItem, FormValidation, StringValidationItem } from 'src/app/utilities/util/form-validation';

@Component({
  selector: 'habil-atendimento-excecao',
  templateUrl: './atendimento-excecao.component.html',
  styleUrls: ['./atendimento-excecao.component.scss']
})
export class AtendimentoExcecaoComponent implements OnInit {

  obj: AtendimentoExcecao = new AtendimentoExcecao();

  constructor(
    private message: MessageService,
    private ref: DynamicDialogRef
  ) { }

  ngOnInit(): void {
  }

  private validar(): boolean {
    let v: FormValidation = new FormValidation()
      .add( new DateValidationItem( this.obj.data, "Data obrigatória!" ) )
      .add( new StringValidationItem( this.obj.atendente, "Atendente obrigatório!" ) )
      .add( new StringValidationItem( this.obj.observacoes, "Observação obrigatória!" ) );  
    if ( !v.isValid() )  {
      this.message.addAll( v.msgs() );
    }
    return v.isValid();
  }

  salvar() {
    if ( this.validar() ) {
      this.ref.close( this.obj )
    }
  }

  cancelar() {
    this.ref.close();
  }

}
