import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'habil-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss']
})
export class LabelComponent implements OnInit {

  @Input()
  name: string;
  @Input()
  label: string;
  @Input()
  visible: boolean = true;

  constructor() { }

  ngOnInit() {
  }

}
