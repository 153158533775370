<p-confirmDialog header="Atenção" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-dialog header="Planejamento" [(visible)]="showPlanejamento" [style]="{width: '60vw'}">
    <angular-editor [(ngModel)]="planejamento.texto" [config]="config"></angular-editor>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="salvarPlanejamento()" label="Salvar" styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>
<p-dialog header="Estatísticas" [(visible)]="showEstatisticas" [style]="{width: '50vw'}">
    <div class="p-fluid p-grid">
        <div class="p-col-8">
            <habil-label name="lblAssunto" label="Assunto">
                <h5 name="lblAssunto">{{selection.assunto}}</h5>
            </habil-label>
        </div>
        <div class="p-col-6 p-lg-2">
            <habil-label name="lblInicio" label="Enviado Em">
                <h5 name="lblInicio">{{selection.aPartirDe | date: 'dd/MM/yyy'}}</h5>
            </habil-label>
        </div>
        <div class="p-col-6 p-lg-2">
            <habil-label name="lblVal" label="Válido Até">
                <h5 name="lblVal">{{selection.validade | date: 'dd/MM/yyy'}}</h5>
            </habil-label>
        </div>
        <div class="p-col-6">
            <habil-label name="lblSoftware" label="Software">
                <h5 name="lblSoftware">{{selection.software}}</h5>
            </habil-label>
        </div>
        <div class="p-col-6">
            <habil-label name="lblTipo" label="Tipo">
                <h5 name="lblTipo">{{selection.tipo}}</h5>
            </habil-label>
        </div>
        <div class="p-col">
        </div>
        <div class="p-col">
        </div>
        <div class="p-col-12">
            <p-table #dt [value]="logs">
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="cnpj">CNPJ<p-sortIcon field="cnpj"></p-sortIcon></th>
                        <th pSortableColumn="download">Baixou em<p-sortIcon field="download"></p-sortIcon></th>
                        <th pSortableColumn="view">Visualizou em<p-sortIcon field="view"></p-sortIcon></th>
                    </tr>
                    <tr>
                        <th>
                            <p-columnFilter type="text" field="cnpj"></p-columnFilter>
                        </th>
                        <th>
                            <p-columnFilter type="text" field="download"></p-columnFilter>
                        </th>
                        <th>
                            <p-columnFilter type="text" field="view"></p-columnFilter>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-log>
                    <tr>
                        <td>{{log.cnpj}}</td>
                        <td>{{log.download | date: 'dd/MM/yyy hh:mm'}}</td>
                        <td>{{log.view | date: 'dd/MM/yyy hh:mm'}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>  
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="showEstatisticas=false" label="Ok" styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>
<p-messages></p-messages>
<habil-base-lista #base [service]="service" [montaObjeto]="montaObjeto" [subActions]="acoes" [showEditar]="false"> </habil-base-lista>